// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchDetailsTab_custom_arrow_desing__QSjrF {
    background-color: rgb(114, 141, 182);
    height: 2px;
    width: 100px;
    position: relative;
    margin: 0px 18px;
    display: inline-block;
  }
  
  .SearchDetailsTab_custom_arrow_desing__QSjrF::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 8px 8px 0px 0px;
    border-color: rgb(114, 141, 182) transparent transparent;
    right: -2px;
    top: -6px;
    transform: scaleY(-1);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SearchDetails/SearchDetailsTab.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;EACvB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,6BAA6B;IAC7B,wDAAwD;IACxD,WAAW;IACX,SAAS;IACT,qBAAqB;EACvB","sourcesContent":[".custom_arrow_desing {\n    background-color: rgb(114, 141, 182);\n    height: 2px;\n    width: 100px;\n    position: relative;\n    margin: 0px 18px;\n    display: inline-block;\n  }\n  \n  .custom_arrow_desing::after {\n    content: '';\n    position: absolute;\n    width: 0px;\n    height: 0px;\n    border-style: solid;\n    border-width: 8px 8px 0px 0px;\n    border-color: rgb(114, 141, 182) transparent transparent;\n    right: -2px;\n    top: -6px;\n    transform: scaleY(-1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_arrow_desing": `SearchDetailsTab_custom_arrow_desing__QSjrF`
};
export default ___CSS_LOADER_EXPORT___;
