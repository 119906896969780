import React from "react";
import MainLayout from "../../components/Layouts";
import GetFlightList from "../../components/TricketBooking/Flight/FlightList";

const TricketBookingDetailsPage = () => {
    return (
        <MainLayout>
            <GetFlightList/>
        </MainLayout>
    );
};

export default TricketBookingDetailsPage;
