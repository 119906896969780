import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import {getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../utils/services";
import AddAirport from "./AddAirport";

import { AddBtn, Loader, Pagination } from "../common";

const AirportList = (): ReactElement => {
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(10);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedAirport, setSelectedAirport] = useState(undefined);
  const [selectedAirportForEdit, setSelectedAirportForEdit] =
    useState(undefined);
  const [airportId, setAirportId] = useState<any>();
  const [countryCode, setCountryCode] = useState<any>();
  console.log("Get country Code", countryCode);
  const [airportCode, setAirportCode] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const isDeleted = "false";
  const [airportOptions, setAirportOptions] = useState({
    loading: false,
    list: [],
  });

  const [airportCodeOptions, setAirportCodeOptions] = useState({
    loading: false,
    list: [],
  });
  const [countryCodeOptions, setCountryCodeOptions] = useState({
    loading: false,
    list: [],
  });

  console.log("get country list:", countryCodeOptions);

  const [airportData, setAirportData] = useState<any>({
    loading: false,
    data: null,
  });

  const getAirports = useCallback(async () => {
    setAirportData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    axios
      .get(
        `${encodedUri}/admin/airport/get-all?limit=${limit}` +
          (airportId ? `&key=${airportId}` : ``) +
          (airportCode ? `&key=${airportCode}` : ``) +
          (countryCode ? `&countryCode=${countryCode}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (page ? `&page=${page}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAirportData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setAirportData({ loading: false, data: [] });
        console.error("Airport: Error", err);
      });
  }, [type, airportId, airportCode,countryCode, isActive, limit, page]);

  useEffect(() => {
    getAirports();
  }, [getAirports]);

  const onClose = () => {
    getAirports();
    setVisible(undefined);
    setSelectedAirport(undefined);
    setSelectedAirportForEdit(undefined);
  };

  const getAirportOptions = useCallback(async (key?: string) => {
    setAirportOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/airport/get-all?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAirportOptions({
      loading: false,
      list: res?.data?.airports?.map((airport: any) => ({
        label: airport.name,
        value: airport.name,
      })),
    });
  }, []);

  const getAirportCodeOptions = useCallback(async (key?: string) => {
    setAirportCodeOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/airport/get-all?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAirportCodeOptions({
      loading: false,
      list: res?.data?.airports?.map((airport: any) => ({
        label: airport.code,
        value: airport.code,
      })),
    });
  }, []);

  const getCountryCodeOptions = useCallback(async (key?: string) => {
    setCountryCodeOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/country/all?page=0&limit=10` + (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryCodeOptions({
      loading: false,
      list: res?.data?.countries?.map((countrie: any) => ({
        label: countrie.code,
        value: countrie.code,
      })),
    });
  }, []);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/airport`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          airports: [
            {
              id: id,
              isActive: val,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getAirports();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "airport") getAirportOptions(value);
        if (field === "airportCode") getAirportCodeOptions(value);
        if (field === "countryCode") getCountryCodeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getAirportOptions, getAirportCodeOptions,getCountryCodeOptions]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };

  const reseAllFieldData = () => {
    setAirportId("");
    setAirportCode("");
    setKey("");
    setIsActive("");
    setCountryCode("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getAirportOptions("");
      getAirportCodeOptions("");
      getCountryCodeOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title={`Airport List`}
        subTitle={`${
          airportData?.data?.totalElements ||
          airportData?.data?.airports?.length ||
          0
        } Airport(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="vertical" form={form} className={styles.formInline}>
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <Form.Item label="Airport Name" name="airport_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Airport Name"
                  optionFilterProp="children"
                  onChange={(val) => setAirportId(val)}
                  onSearch={(e) => handleSearch(e, "airport")}
                  filterOption={() => {
                    return true;
                  }}
                  options={airportOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item label="Airport Code" name="airport_code">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Airport Code"
                  optionFilterProp="children"
                  onChange={(val) => setAirportCode(val)}
                  onSearch={(e) => handleSearch(e, "airportCode")}
                  filterOption={() => {
                    return true;
                  }}
                  options={airportCodeOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item label="Country Code" name="country">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Country Code"
                  optionFilterProp="children"
                  onChange={(val) => setCountryCode(val)}
                  onSearch={(e) => handleSearch(e, "countryCode")}
                  filterOption={() => {
                    return true;
                  }}
                  options={countryCodeOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                label="Status"
                name="deleted_search"
                initialValue={
                  isActive == "true"
                    ? "ACTIVE"
                    : isActive == "false"
                    ? "INACTIVE"
                    : "ALL"
                }
              >
                <Select
                  showSearch
                  placeholder="Status"
                  onChange={(val) => setIsActive(val as string)}
                >
                  <Select.Option value={undefined}>ALL</Select.Option>
                  <Select.Option value={"true"} title="ACTIVE">
                    ACTIVE
                  </Select.Option>
                  <Select.Option value={"false"} title="INACTIVE">
                    INACTIVE
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Form>

          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {airportData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col">Airport Name</th>
                      <th scope="col">Airport Code</th>
                      <th scope="col">Country Name</th>
                      <th scope="col">Airport Status</th>
                      <th scope="col">Airport Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Operation Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {airportData?.data?.airports?.length ? (
                      airportData?.data?.airports?.map(
                        (airport: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            // onClick={() =>
                            //   navigate(`/airports/${airport?.id}/details`)
                            // }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {airport?.name}
                              </span>
                            </td>
                            <td>{airport?.code}</td>
                            <td>{airport?.countryName}</td>
                            <td>API</td>
                            <td>{airport?.type}</td>
                            <td>
                              {airport?.isActive == true && (
                                <span className="border p-1 px-5 rounded-2xl bg-white">
                                  Active
                                </span>
                              )}
                              {airport?.isActive == false && (
                                <span className="border p-1 px-5 rounded-2xl bg-white text-red-600">
                                  Inactive
                                </span>
                              )}
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={airport?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(airport?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  airport?.id === confirmLoading
                                }
                              />
                            </td>

                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedAirportForEdit(airport);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...airportData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Airport"
            : selectedAirportForEdit
            ? "Edit Airport"
            : "Airport Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedAirport || selectedAirportForEdit}
        width={450}
      >
        {visible || selectedAirportForEdit ? (
          <AddAirport
            onCloseMethod={onClose}
            visibleData={selectedAirportForEdit}
          />
        ) : undefined}
      </Drawer>
    </>
  );
};

export default AirportList;
