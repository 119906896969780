import { DatePicker, Divider, Form, Input, Select } from "antd";
import React, { useState } from "react";
import ImageUploader from "../../common/ImageUploader";

const BankAccountInfo = () => {
  const [docType, setDocType] = useState<string>("Document");

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-x-6">
        <div>
          <Form.Item hasFeedback label="Account Name" name="accountName">
            <Input
              id="accountName"
              type="text"
              placeholder="Enter accountName"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Account Number" name="accountNumber">
            <Input
              id="accountNumber"
              type="text"
              placeholder="Enter accountNumber"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Bank Name" name="bankName">
            <Input id="bankName" type="text" placeholder="Enter bankName" />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Branch Name" name="branchName">
            <Input id="branchName" type="text" placeholder="Enter branchName" />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Routing Number" name="routingNumber">
            <Input
              id="routingNumber"
              type="text"
              placeholder="Enter routingNumber"
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default BankAccountInfo;
