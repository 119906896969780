import {Col, Divider, Form, Input, Row, Select, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../common/ImageUploader";
import SubmitResetBtn from "../common/SubmitBtn";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { QuestionCircleOutlined } from "@ant-design/icons";

const AddCapping = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [loading, setLoading] = useState(false);
  const [cappingData, setCappingData] = useState<any>(undefined);
  const [destinationCountryCode, setDestinationCountryCode] =
    useState<string>();
  const [destinationCountryOptions, setDestinationCountryOptions] =
    useState<any>({
      list: [],
      loading: false,
    });
    useEffect(() => {
      if (startUpload === "Uploaded") {
        form?.submit();
      }
    }, [startUpload]);
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      careers: [
        {
          careerCode: data.careerCode,
          careerCodeTD: data.careerCodeTD,
          country: data.country,
          isActive: true,
          name: data.name,
          nameWithCode: data.nameWithCode,
          logo: generatedImgUrl ? generatedImgUrl : data?.logo,
        },
      ],
    };

    if (cappingData) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/capping/career`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          careers: [
            {
              id: cappingData?.id,
              careerCode: data.careerCode,
              careerCodeTD: data.careerCodeTD,
              country: data.country,
              isActive: true,
              name: data.name,
              nameWithCode: data.nameWithCode,
              logo: generatedImgUrl ? generatedImgUrl : data?.logo,
            },
          ],
        }),
      }) 
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Capping Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/capping/career`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Capping Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const getDestinationCountryOptions = useCallback(async (val?: string) => {
    setDestinationCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_BOOKING_API}/country/all?page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setDestinationCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((countrie: any) => {
        return {
          value: countrie?.code,
          label: countrie?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    if (visibleData) {
      setCappingData(visibleData);
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [visibleData]);

  useEffect(() => {
    if (cappingData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [cappingData, form, visibleData]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "destinationCountry")
            getDestinationCountryOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getDestinationCountryOptions, type]);
  useEffect(() => {
    getDestinationCountryOptions();
  }, [getDestinationCountryOptions]);
  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setStartUpload(undefined);
  };
  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...cappingData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Capping Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Capping Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Career Code"
          rules={[
            {
              required: false,
              message: "Career Code is Required!",
            },
          ]}
          name="careerCode"
        >
          <Input id="careerCode" type="text" placeholder="Enter Career Code" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Career Code TD"
          rules={[
            {
              required: true,
              message: "Career Code is Required!",
            },
          ]}
          name="careerCodeTD"
        >
          <Input
            id="careerCodeTD"
            type="text"
            placeholder="Enter Career Code TD"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`Country`}
          rules={[
            {
              required: false,
              message: "Country is required!",
            },
          ]}
          name="country"
        >
          <Select
            showSearch
            placeholder="Select Country"
            optionFilterProp="children"
            onSearch={(val) => {
              handleSearch(val, "destinationCountry");
            }}
            onChange={(val) => setDestinationCountryCode(val)}
            filterOption={() => {
              return true;
            }}
            options={destinationCountryOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item hasFeedback label="Name With Code" name="nameWithCode">
          <Input
            id="nameWithCode"
            type="text"
            placeholder="Enter Career Name With Code"
          />
        </Form.Item>
        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Logo
              <Tooltip
                placement="bottom"
                title={
                  "Career image must be transparent (.png format) & max height 100px and width auto"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              // crop={1}
            />
          </Col>
        </Row>
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={resetData}
          disabled={loading || startUpload === "Uploading" || !!error}
          loading={loading || startUpload === "Uploading" || !!error}
        />
      </Form>
    </>
  );
};

export default AddCapping;
