import React from "react";
import MainLayout from "../../components/Layouts";
import AddGroupTicket from "../../components/Group-Ticket/AddGroupTicket";

const GroupTicketAddPage = () => {
  return (
    <MainLayout>
      <AddGroupTicket />
    </MainLayout>
  );
};
export default GroupTicketAddPage;
