import React from "react";
import MainLayout from "../../components/Layouts";
import TagList from "../../components/Tags";

const TagPage = () => {
  return (
    <MainLayout>
      <TagList />
    </MainLayout>
  );
};

export default TagPage;
