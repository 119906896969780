import React from "react";
import MainLayout from "../../components/Layouts";
import SearchGroupTicket from "../../components/Group-Ticket/SearchGroupTicket";

const GroupTicketSearchPage = () => {
  return (
    <MainLayout>
      <SearchGroupTicket />
    </MainLayout>
  );
};
export default GroupTicketSearchPage;
