import React, {useState} from 'react';
import Flight from './flight';
import Stays from '../Stays/stays';
import Tour from '../Tour/tour';
import Bus from '../Bus/bus';
import CarRentals from '../CarRentals/carRentals';
import Train from '../Train/train';
import Others from '../Others/others';

function BookingHome(props) {
    const [selectedServices, setSelectedServices] = useState(1);
    return (
        <div>
            <div className="container mx-auto">
                <div className="h-auto w-full bg-white shadow-lg rounded-lg mt-20">
                    <div className="border-b-2 border-gray-300">
                        <div
                            className="flex flex-row justify-between text-black mx-[2%] lg:mx-[12%] md:h-12">
                            <div>
                                <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                     onClick={() => setSelectedServices(1)}>
                                    <div className="ml-3">
                                        <i className="bi bi-airplane h5"></i>
                                    </div>
                                    <div className={selectedServices === 1 ?
                                        "md:text-lg md:font-bold text-center text-red-600" : "md:text-lg md:font-bold"
                                    }>Flight
                                    </div>
                                </div>
                                {selectedServices === 1 ?
                                    <div
                                        className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                }
                            </div>
                            <div>
                                <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                     onClick={() => setSelectedServices(2)}>
                                    <div className="ml-2">
                                        <i className="bi bi-buildings h5"></i>
                                    </div>
                                    <div className={selectedServices === 2 ?
                                        "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                    }>
                                        Stays
                                    </div>
                                </div>
                                {selectedServices === 2 ?
                                    <div
                                        className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                }
                            </div>

                            <div>
                                <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                     onClick={() => setSelectedServices(3)}>
                                    <div className="ml-2">
                                        <i className="bi bi-umbrella h5"></i>
                                    </div>
                                    <div className={selectedServices === 3 ?
                                        "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                    }>Tour
                                    </div>
                                </div>
                                {selectedServices === 3 ?
                                    <div
                                        className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                }
                            </div>

                            <div>
                                <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                     onClick={() => setSelectedServices(4)}>
                                    <div className="ml-1">
                                        <i className="bi bi-bus-front h5"></i>
                                    </div>
                                    <div className={selectedServices === 4 ?
                                        "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                    }>Bus
                                    </div>
                                </div>
                                {selectedServices === 4 ?
                                    <div
                                        className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                }
                            </div>
                            <div>
                                <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                     onClick={() => setSelectedServices(5)}>
                                    <div className="ml-1">
                                        <i className="bi bi-car-front-fill h5"></i>
                                    </div>
                                    <div className={selectedServices === 5 ?
                                        "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                    }>Car
                                    </div>
                                </div>
                                {selectedServices === 5 ?
                                    <div
                                        className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                }
                            </div>

                            <div>
                                <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                     onClick={() => setSelectedServices(6)}>
                                    <div className="ml-2">
                                        <i className="bi bi-train-lightrail-front h5"></i>
                                    </div>
                                    <div className={selectedServices === 6 ?
                                        "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                    }>Train
                                    </div>
                                </div>
                                {selectedServices === 6 ?
                                    <div
                                        className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                }
                            </div>
                        </div>
                    </div>

                    <div>
                        {/*all services*/}
                        {selectedServices === 1 ?
                            <Flight/> : <></>
                        }
                        {selectedServices === 2 ?
                            <Stays/> : <></>
                        }
                        {selectedServices === 3 ?
                            <Tour/> : <></>
                        }
                        {selectedServices === 4 ?
                            <Bus/> : <></>
                        }
                        {selectedServices === 5 ?
                            <CarRentals/> : <></>
                        }
                        {selectedServices === 6 ?
                            <Train/> : <></>
                        }
                        {selectedServices === 7 ?
                            <Others/> : <></>
                        }
                        {/*all services*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookingHome;