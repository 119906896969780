import { Col, Divider, Form, Input, Row, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { QuestionCircleOutlined } from "@ant-design/icons";
import SubmitResetBtn from "../common/SubmitBtn";
import React from "react";
import ImageUploader from "../common/ImageUploader";

const AddBank = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [bankData, setBankData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      bankList: [
        {
          name: data.name,
          email: data.email,
          swiftCode: data.swiftCode,
          helpline: data.helpline,
          isActive: true,
          logo: generatedImgUrl ? generatedImgUrl : data?.logo,
        },
      ],
    };

    if (bankData) {
      await fetch(`${process.env.REACT_APP_PAY_API}/admin/bank`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bankList: [
            {
              name: data.name,
              email: data.email,
              swiftCode: data.swiftCode,
              helpline: data.helpline,
              isActive: true,
              id: bankData.id,
              logo: generatedImgUrl ? generatedImgUrl : data?.logo,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Bank Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod(); 
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_PAY_API}/admin/bank`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.status === 200) {
            responseNotification("Bank Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);
  useEffect(() => {
    if (visibleData) {
      setBankData(visibleData);
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [visibleData]);

  useEffect(() => {
    if (bankData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [bankData, form, visibleData]);

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...bankData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="email" type="text" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is Required!",
            },
          ]}
          name="email"
        >
          <Input id="email" type="text" placeholder="Enter Email" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Swift Code"
          rules={[
            {
              required: true,
              message: "Swift Code is Required!",
            },
          ]}
          name="swiftCode"
        >
          <Input id="swiftCode" type="text" placeholder="Enter Swift Code" />
        </Form.Item>

        <Form.Item hasFeedback label="Help Line" name="helpline">
          <Input
            id="helpline"
            type="number"
            min={0}
            placeholder="Enter Help line"
          />
        </Form.Item>
        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Logo
              <Tooltip
                placement="bottom"
                title={
                  "Career image must be transparent (.png format) & max height 100px and width auto"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              // crop={1}
            />
          </Col>
        </Row>

        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={resetData}
          disabled={loading || startUpload === "Uploading" || !!error}
          loading={loading || startUpload === "Uploading" || !!error}
        />
      </Form>
    </>
  );
};

export default AddBank;
