import React from "react";
import MainLayout from "../../../components/Layouts";
import AddCampaign from "../../../components/Promotions/Campaign/AddCampaign";

const CampaignAddPage = () => {
  return (
    <MainLayout>
      <AddCampaign />
    </MainLayout>
  );
};

export default CampaignAddPage;
