import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import CancelRefundableOrderList from "../../components/Orders/CancelRefunableOrder";
import React from "react";

const CancelRefundableOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <CancelRefundableOrderList />
    </MainLayout>
  );
};

export default CancelRefundableOrderPage;
