import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { Avatar, Button, Drawer, Empty, Form, Select, Switch } from "antd";
import { responseNotification } from "../../../utils/notify";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getImgUrl, getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import AddAdvertise from "./AddAdvertise";
import SingleAdvertise from "./Details";
import { AddBtn, Loader, Pagination } from "../../common";

const AdvertiseList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedPromo, setSelectedPromo] = useState(undefined);
  const [selectedPromoForEdit, setSelectedPromoForEdit] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [promotionOptions, setPromotionOptions] = useState({
    loading: false,
    list: [],
  });
  const [promotionData, setPromotionData] = useState<any>({
    loading: false,
    data: null,
  });

  const getPromoCode = useCallback(async () => {
    setPromotionData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/promotion?type=${type}&page=${page || 0}&limit=${
          limit || 16
        }` +
          (key ? `&name=${key}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPromotionData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPromotionData({ loading: false, data: [] });
        console.error("Promotion: Error", err);
      });
  }, [key, limit, page, isActive, type]);

  const getpromotionOptions = useCallback(
    async (key?: string) => {
      setPromotionOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/promotion?page=0&limit=20` +
          (key ? `&name=${key}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPromotionOptions({
        loading: false,
        list: res.data?.promotions?.map((promotion: any) => ({
          label: promotion.title,
          value: promotion.name,
        })),
      });
    },
    [type]
  );

  useEffect(() => {
    getPromoCode();
  }, [getPromoCode]);

  const onClose = () => {
    getPromoCode();
    setVisible(undefined);
    setSelectedPromo(undefined);
    setSelectedPromoForEdit(undefined);
  };

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/promotion`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getPromoCode();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "promotion") getpromotionOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getpromotionOptions]);

  const reseAllFieldData = () => {
    setKey("");
    setIsActive("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getpromotionOptions("");
    }
  }, [showSearch]);

  return (
    <>
      <BreadCrumb
        title={`Advertise List`}
        subTitle={`${
          promotionData?.data?.totalElements ||
          promotionData?.data?.categories?.length ||
          0
        } Advertise(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="name_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Title"
                optionFilterProp="children"
                onChange={(val) => setKey(val)}
                onSearch={(e) => handleSearch(e, "promotion")}
                filterOption={() => {
                  return true;
                }}
                options={promotionOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="isActive_search" initialValue={isActive}>
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {promotionData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Placement</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {promotionData?.data?.promotions?.length ? (
                      promotionData?.data?.promotions?.map(
                        (promotion: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedPromo(promotion)}
                          >
                            <td>
                              <Avatar
                                size={40}
                                src={getImgUrl(promotion?.imageUrl)}
                              />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {promotion?.title}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {promotion?.placement}
                              </span>
                            </td>

                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={promotion?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(promotion?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  promotion?.id === confirmLoading
                                }
                              />
                            </td>

                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() =>
                                  setSelectedPromoForEdit(promotion)
                                }
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...promotionData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Advertise"
            : selectedPromoForEdit
            ? "Edit Advertise"
            : "Advertise Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedPromo || selectedPromoForEdit}
        width={selectedPromoForEdit ? 600 : 500}
      >
        {visible || selectedPromoForEdit ? (
          <AddAdvertise
            onCloseMethod={onClose}
            visibleData={selectedPromoForEdit}
          />
        ) : (
          <SingleAdvertise PromotiopnDetails={selectedPromo} />
        )}
      </Drawer>
    </>
  );
};

export default AdvertiseList;
