import BreadCrumb from "../Layouts/Breadcrumb";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Line, Column } from "@ant-design/plots";
import { Pie } from "@ant-design/charts";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import moment from "moment";
import { jcTypeArray } from "../../utils";

const Dashboard = (): ReactElement => {
   const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [type1, setType1] = useState(type);
  return (
    <>
      <BreadCrumb title="Dashboard" />
      <div className="">

           <h1>Dashboard</h1>   
                  
      </div>

    
    </>
  );
};

export default Dashboard;
