import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import BankBranchList from "../../components/Bank/BankBranchList";
import React from "react";

const BankBranchPage = (): ReactElement => {
  return (
    <MainLayout>
      <BankBranchList />
    </MainLayout>
  );
};

export default BankBranchPage;
