import React from "react";
import MainLayout from "../../../components/Layouts";
import MerchantAddProduct from "../../../components/Merchant/RequestedProduct/AddProduct";

const MerchantRequestedProductEdit = () => {
  return (
    <MainLayout>
      <MerchantAddProduct />
    </MainLayout>
  );
};

export default MerchantRequestedProductEdit;
