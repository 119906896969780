import {
  AutoComplete,
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FlighStatusArray,
  getPage,
  getParamValue,
  PNRStatusArray,
} from "../../utils";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { debounce } from "lodash";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { isAllowedService } from "../../utils/services";
import React from "react";
import { PaginationThree, Loader } from "../common";
import Pagination from "../common/Pagination";
const { RangePicker } = DatePicker;

const PendingOrderList = (): ReactElement => {
  // const { type = "BOOKING" } = useSelector(
  //   (state) => (state as any)?.authReducer
  // );
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  console.log("get type ::", token);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [flightStatus, setFlightStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [pnrStatus, setPnrStatus] = useState<any>("PENDING");
  const [orderId, setOrderId] = useState("");
  const [pnrId, setPnrId] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [merchantNumber, setMerchantNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [passangerNumber, setPassangerNumber] = useState("");
  const [passengerName, setPassengerName] = useState("");
  const [key, setKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState<any>(false);
  const [error, setError] = useState("");
  //  booking filter option
  const [pmethodId, setPmethodId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<any>({
    loading: false,
    list: [],
  });
  const [crs, setCrs] = useState<any>();

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [usersOptions, setUsersOptions] = useState({
    loading: false,
    list: [],
  });
  const [nameOptions, setNameOptions] = useState({
    loading: false,
    list: [],
  });
  const [mobileOptions, setMobileOptions] = useState({
    loading: false,
    list: [],
  });

  console.log("get merchant data :: ", nameOptions);
  const [passangerNameOptions, setPassangerNameOptions] = useState({
    loading: false,
    list: [],
  });
  const [passangerPhoneOptions, setPassangerPhoneOptions] = useState({
    loading: false,
    list: [],
  });
  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: null,
  });

  const [pnrCodeOptions, setPnrCodeOptions] = useState({
    loading: false,
    list: [],
  });

  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });

  const fatchPaymentMethod = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_PAY_API}/payment-method/all?` +
        (type ? `&type=${type}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    );
    const response = await data.json();

    setPaymentMethod({
      loading: false,
      list: response?.methods?.map((method: { name: any }) => ({
        value: method.name,
        label: method.name,
      })),
    });
  };

  useEffect(() => {
    fatchPaymentMethod();
  }, []);

  const getOrders = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setOrderData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_BOOKING_API}`;
    axios
      .get(
        `${encodedUrl}/admin/booking-order?type=JC_AIRLINES_BOOKING` +
          (pnrId ? `&pnrCode=${pnrId}` : ``) +
          (crs ? `&crs=${crs}` : ``) +
          (orderId ? `&orderId=${orderId}` : ``) +
          (passengerName ? `&passengerName=${passengerName}` : ``) +
          (passangerNumber ? `&passengerMobile=${passangerNumber}` : ``) +
          (merchantNumber ? `&mobileNumber=${merchantNumber}` : ``) +
          (merchantName ? `&mobileNumber=${merchantName}` : ``) +
          (paymentStatus ? `&paymentStatus=${paymentStatus}` : ``) +
          (pmethodId ? `&paymentMethod=${pmethodId}` : ``) +
          (flightStatus ? `&flightStatus=${flightStatus}` : ``) +
          (pnrStatus ? `&status=${pnrStatus}` : ``) +
          (from ? `&flightDateFrom=${from}` : ``) +
          (to ? `&flightDateTo=${to}` : ``) +
          (page
            ? `&page=${
                page == orderData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrderData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrderData({ loading: false, data: [] });
        console.error("Order: Error", err);
      });
  }, [
    limit,
    page,
    pnrId,
    crs,
    orderId,
    passengerName,
    passangerNumber,
    merchantNumber,
    merchantName,
    paymentStatus,
    mobileNumber,
    pmethodId,
    flightStatus,
    pnrStatus,
    range,
  ]);

  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      setPnrCodeOptions({ loading: true, list: [] });
      const encodedUrl = `${process.env.REACT_APP_BOOKING_API}`;
      return axios
        .get(
          `${encodedUrl}/admin/booking-order?type=${"JC_AIRLINES_BOOKING"}` +
            (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
            `&page=${page || 0}` +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order: { orderId: string }) => ({
              label: `${order?.orderId}`,
              value: order?.orderId,
            })),
          });
          setPnrCodeOptions({
            loading: false,
            list: res.data?.orders?.map(
              (order: { bookingConfirmationCode: string }) => ({
                label: `${order?.bookingConfirmationCode}`,
                value: order?.bookingConfirmationCode,
              })
            ),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          setPnrCodeOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, type]
  );
  const getUsersOptions = useCallback(
    async (mobileNumber: any) => {
      setMobileOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      axios
        .get(
          `${encodedUri}/admin/user?` +
            `page=${0}` +
            `&limit=${20}` +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setMobileOptions({
            loading: false,
            list: res.data?.users?.map(
              (user: { fullName: any; mobileNumber: string }) => ({
                label: user.mobileNumber,
                value: user.mobileNumber.replace("+88", ""),
              })
            ),
          });
        })
        .catch((err) => {
          setMobileOptions({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  const getNameOptions = useCallback(
    async (key: any) => {
      setNameOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      return axios
        .get(
          `${encodedUri}/admin/user?` +
            `page=${0}` +
            `&limit=${20}` +
            (key ? `&fullName=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setNameOptions({
            loading: false,
            list: res.data?.users?.map(
              (merchant: { fullName: any; mobileNumber: string }) => ({
                label: merchant.fullName,
                value: merchant.mobileNumber.replace("+88", ""),
              })
            ),
          });
        })
        .catch((err) => {
          setNameOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [page, limit]
  );

  const getPassangerOptions = useCallback(
    async (key: any) => {
      setPassangerNameOptions({ loading: true, list: [] });
      setPassangerPhoneOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
      return axios
        .get(
          `${encodedUri}/admin/booking-order/passengers?` +
            `page=${0}` +
            `&limit=${20}` +
            (key ? `&name=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setPassangerNameOptions({
            loading: false,
            list: res.data?.passengerInfoDataList?.map(
              (passanger: { name: any }) => ({
                label: passanger.name,
                value: passanger.name,
              })
            ),
          });
          setPassangerPhoneOptions({
            loading: false,
            list: res.data?.passengerInfoDataList?.map(
              (passanger: { contactNumber: any; Phone: any }) => ({
                label: passanger?.contactNumber?.Phone,
                value: passanger?.contactNumber?.Phone,
              })
            ),
          });
        })
        .catch((err) => {
          setPassangerNameOptions({ loading: false, list: [] });
          setPassangerPhoneOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [page, limit]
  );

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
        if (field === "user") getUsersOptions(value);
        if (field === "merchant") getNameOptions(value);
        if (field === "passanger") getPassangerOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions, getPassangerOptions, getNameOptions, getUsersOptions]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  useEffect(() => {
    if (showSearch) {
      getOrderOptions("");
      getPassangerOptions("");
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form?.resetFields();
    setPnrId("");
    setCrs("");
    setPassengerName("");
    setPassangerNumber("");
    setMerchantNumber("");
    setMerchantName("");
    setPaymentMethod("");
    setPmethodId("");
    setFlightStatus("");
    setOrderId("");
    setMobileNumber("");
    setRange({
      flightDateFrom: null,
      flightDateTo: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Pending Order List"
        subTitle={`${orderData?.data?.totalElements} Order(s)`}
        childComponent={false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          isAllowedService(`Order Create`) && (
            <Button
              type="primary"
              danger
              shape="round"
              onClick={() => navigate(`/orders/create`)}
            >
              Manual Order
            </Button>
          ),
        ]}
      />
      {showSearch && (
        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 pb-2 mb-4">
          <Form
            name="control-hooks"
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            className={styles.formInline}
          >
            {/* <div className="grid grid-cols-3 gap-4"> */}

            <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <div className="sm-12 mb-6 lg-6 xl-6">
                <Form.Item hasFeedback label="CRS" name="crs">
                  <Select
                    options={[
                      { value: null, label: "NONE" },
                      { value: "Sabre", label: "Sabre" },
                      { value: "Amadeus", label: "Amadeus" },
                      { value: "Galileo GDS", label: "Galileo GDS" },
                    ]}
                    onChange={(val) => setCrs(val)}
                    placeholder="Select CRS"
                  />
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item label="PNR Code" name="pnrCode" initialValue={pnrId}>
                  <AutoComplete
                    backfill
                    allowClear
                    onSearch={(e) => handleSearch(e, "order")}
                    onSelect={(val: any) => {
                      setPnrId(val);
                    }}
                    options={pnrCodeOptions?.list}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                      pnrCodeOptions?.loading ? <Spin size="small" /> : null
                    }
                    dropdownMatchSelectWidth={335}
                  >
                    <Input.Search
                      placeholder="Order PNR Code"
                      onSearch={(val) => {
                        setPnrId(val);
                      }}
                      enterButton
                    />
                  </AutoComplete>
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item
                  label="Order ID"
                  name="orderId"
                  initialValue={orderId}
                >
                  <AutoComplete
                    backfill
                    allowClear
                    onSearch={(e) => handleSearch(e, "order")}
                    onSelect={(val: any) => {
                      setOrderId(val);
                    }}
                    options={orderOptions?.list}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                      orderOptions?.loading ? <Spin size="small" /> : null
                    }
                    dropdownMatchSelectWidth={335}
                  >
                    <Input.Search
                      placeholder="Order ID "
                      onSearch={(val) => {
                        setOrderId(val);
                      }}
                      enterButton
                    />
                  </AutoComplete>
                </Form.Item>
              </div>

              <div className="mb-6">
                <Form.Item label="Passanger Name" name="passengerName">
                  <AutoComplete
                    style={{ width: 250 }}
                    onSearch={(e) => handleSearch(e, "passanger")}
                    onSelect={(val: {
                      toString: () => SetStateAction<string>;
                    }) => setPassengerName(val?.toString())}
                    options={passangerNameOptions?.list}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                      passangerNameOptions?.loading ? (
                        <Spin size="small" />
                      ) : null
                    }
                  >
                    <Input.Search
                      placeholder="Search by Name"
                      onSearch={(val) => setPassengerName(val)}
                      enterButton
                      loading={passangerNameOptions.loading}
                    />
                  </AutoComplete>
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item
                  label="Passanger Mobile Number"
                  name="passengerMobile"
                  initialValue={passangerNumber}
                >
                  <AutoComplete
                    style={{ width: 250 }}
                    onSearch={(e) => handleSearch(e, "passanger")}
                    onSelect={(val: {
                      toString: () => SetStateAction<string>;
                    }) => setPassangerNumber(val?.toString())}
                    options={passangerPhoneOptions?.list}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                      passangerPhoneOptions?.loading ? (
                        <Spin size="small" />
                      ) : null
                    }
                  >
                    <Input.Search
                      placeholder="Search by Passanger Phone"
                      onSearch={(val) => setPassangerNumber(val)}
                      enterButton
                      loading={passangerPhoneOptions.loading}
                      maxLength={11}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </AutoComplete>
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item
                  label="Merchant Name"
                  name="merchantName"
                  initialValue={merchantName}
                >
                  <AutoComplete
                    style={{ width: 250 }}
                    onSearch={(e) => handleSearch(e, "merchant")}
                    onSelect={(val: {
                      toString: () => SetStateAction<string>;
                    }) => setMerchantName(val?.toString())}
                    options={nameOptions?.list}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                      nameOptions?.loading ? <Spin size="small" /> : null
                    }
                  >
                    <Input.Search
                      placeholder="Search by Merchant Name"
                      onSearch={(val) => setMerchantName(val)}
                      enterButton
                      loading={nameOptions.loading}
                      maxLength={11}
                      type="text"
                    />
                  </AutoComplete>
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item
                  label="Merchant Mobile Number"
                  name="merchantNumber"
                  initialValue={merchantNumber}
                >
                  <AutoComplete
                    style={{ width: 250 }}
                    onSearch={(e) => handleSearch(e, "user")}
                    onSelect={(val: {
                      toString: () => SetStateAction<string>;
                    }) => setMerchantNumber(val?.toString())}
                    options={mobileOptions?.list}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                      mobileOptions?.loading ? <Spin size="small" /> : null
                    }
                  >
                    <Input.Search
                      placeholder="Search by Merchant Mobile"
                      onSearch={(val) => setMerchantNumber(val)}
                      enterButton
                      loading={mobileOptions.loading}
                      maxLength={11}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </AutoComplete>
                </Form.Item>
              </div>

              <div className="mb-8">
                <Form.Item hasFeedback label="Order BY" name="orderBy">
                  <Select
                    options={[
                      { value: null, label: "NONE" },
                      { value: "PERCENTAGE", label: "PERCENTAGE" },
                      { value: "FLAT", label: "FLAT" },
                      { value: "FLAT", label: "FLAT" },
                    ]}
                    placeholder="Select Order By"
                  />
                </Form.Item>
              </div>

              <div className="mb-6">
                <Form.Item hasFeedback label="Branch Name/ID" name="branch">
                  <Select
                    options={[
                      { value: null, label: "NONE" },
                      { value: "PERCENTAGE", label: "PERCENTAGE" },
                      { value: "FLAT", label: "FLAT" },
                      { value: "FLAT", label: "FLAT" },
                    ]}
                    placeholder="Enter Commission Type"
                  />
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item
                  hasFeedback
                  label="Payment Status"
                  name="paymentType"
                >
                  <Select
                    options={[
                      { value: null, label: "NONE" },
                      { value: "PAID", label: "PAID" },
                      { value: "UNPAID", label: "UNPAID" },
                      { value: "PARTIAL_PAID", label: "PARTIAL_PAID" },
                    ]}
                    onChange={(val) => setPaymentStatus(val)}
                    placeholder="Slect Payment Status"
                  />
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item
                  hasFeedback
                  label="Payment Method"
                  name="paymentMethod"
                >
                  <Select
                    allowClear
                    placeholder="Filter by Payment Method"
                    optionFilterProp="children"
                    onChange={(val) => setPmethodId(val)}
                    filterOption={() => {
                      return true;
                    }}
                    options={paymentMethod?.list}
                  ></Select>
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item hasFeedback label="PNR Status" name="pnrStatus" initialValue={pnrStatus}>
                  <Select
                    showSearch
                    placeholder="Filter by Status"
                    onChange={(val) => setPnrStatus(val)}
                  >
                    <Select.Option value="">ALL STATUS</Select.Option>
                    {Object.values(PNRStatusArray)?.map((type, i) => (
                      <Select.Option value={type} key={i}>
                        {type?.split("_").join(" ")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item
                  hasFeedback
                  label="Flight Status"
                  name="floghtStatus"
                >
                  <Select
                    showSearch
                    placeholder="Filter by Status"
                    onChange={(val) => setFlightStatus(val)}
                  >
                    <Select.Option value="">ALL STATUS</Select.Option>
                    {Object.values(FlighStatusArray)?.map((type, i) => (
                      <Select.Option value={type} key={i}>
                        {type?.split("_").join(" ")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="mb-6">
                <Form.Item hasFeedback label="Flight Date & Time" name="range">
                  <RangePicker
                    defaultValue={
                      range.from != null && range.to != null
                        ? [moment(range.from), moment(range.to)]
                        : null
                    }
                    onChange={(e, f) => {
                      console.log("momnet", moment.utc(f[0])?.toISOString());
                      setRange({
                        from: moment.utc(f[0])?.toISOString(),
                        to: moment.utc(f[1])?.toISOString(),
                      });
                    }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment("00:00:00", "HH:mm:ss"),
                        moment("23:59:59", "HH:mm:ss"),
                      ],
                    }}
                    inputReadOnly
                    allowClear
                  />
                </Form.Item>
              </div>
            </div>
          </Form>

          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={loading}
              type="primary"
              htmlType="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-center mr-2"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Submit
            </Button>
            <Button
              type="primary"
              danger
              htmlType="button"
              onClick={reseAllFieldData}
              //   onClick={() => {
              //     form?.resetFields();
              //   }}
            >
              Reset
            </Button>
          </div>

          {/* <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button> */}
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {orderData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Method</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderData?.data?.orders?.length ? (
                      orderData?.data?.orders?.map((order: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100 cursor-pointer"
                          key={index}
                          onClick={() =>
                            navigate(`${order?.orderId}/details`)
                          }
                        >
                          <td>
                            <span className="font-medium text-slate-700">
                              {order?.orderId}
                            </span>
                            <br />
                            <span className="font-light text-slate-600">
                              {order?.baseOrderId}
                            </span>
                          </td>
                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(order?.createdAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(order?.createdAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(order?.createdAt).format("lll")}
                            </span>
                          </td>

                          <td>&#2547;{parseInt(order?.totalAmount)}</td>
                          <td>{order?.paymentMethod}</td>
                          <td>
                            <span className="border p-1 px-5 rounded-2xl bg-white">
                              {order?.status}
                            </span>
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="px-6"
                            ></Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...orderData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default PendingOrderList;
