import { TreeSelect } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { DefaultOptionType } from "antd/lib/select";

const TreeSelectTwo = ({
  setCategoryId,
  value,
}: {
  setCategoryId: any;
  value?: string;
}): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);

  // ************************
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const getCategories = useCallback(async (val: string) => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/category?type=${type}&page=0&limit=30` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTreeData(
      res.data?.categories?.map((item: Omit<DefaultOptionType, "label">) => ({
        ...item?.category,
        id: item?.category?.id,
        pId: 0,
        title: item?.category?.title,
        value: item?.category?.slug,
        isLeaf: item?.childCategories,
        children: item?.subCategories?.map(
          (sub: Omit<DefaultOptionType, "label">) => ({
            ...sub?.category,
            id: sub?.subCategory?.id,
            pId: 0,
            title: sub?.subCategory?.title,
            value: sub?.subCategory?.slug,
            isLeaf: sub?.childCategories.lenght,
            children: sub?.childCategories?.map(
              (child: Omit<DefaultOptionType, "label">) => ({
                ...child?.category,
                id: child?.id,
                // pId: 0,
                title: child?.title,
                value: child?.slug,
                isLeaf: child || false,
                treeIcon: false,
              })
            ),
          })
        ),
      }))
    );
  }, []);

  const onChange = (newValue: string) => {
    setCategoryId(newValue);
  };

  useEffect(() => {
    getCategories(``);
  }, []);

  return (
    <TreeSelect
      showSearch
      allowClear
      treeLine
      treeDataSimpleMode
      style={{ width: "100%", minWidth: 300 }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Select Category"
      value={value}
      onChange={onChange}
      treeData={treeData}
    />
  );
};
TreeSelectTwo.propTypes = {
  onClick: PropTypes.func,
};

export default TreeSelectTwo;
