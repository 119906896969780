import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import ModifyOrderList from "../../components/Orders/ModifyOrder";
import React from "react";

const ModifyOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <ModifyOrderList />
    </MainLayout>
  );
};

export default ModifyOrderPage;
