import { ReactElement } from "react";
import { Avatar, Image } from "antd";
import React from "react";

const SingleAdvertise = ({ PromotiopnDetails }: any): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={PromotiopnDetails?.imageUrl}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Title</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PromotiopnDetails?.title}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Description</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PromotiopnDetails?.description}</span>
            </div>
          </div>
        </div>

        {PromotiopnDetails?.brandId && (
          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">brandId</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{PromotiopnDetails?.brandId}</span>
              </div>
            </div>
          </div>
        )}

        {PromotiopnDetails?.campaignId && (
          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">campaignId</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{PromotiopnDetails?.campaignId}</span>
              </div>
            </div>
          </div>
        )}

        {PromotiopnDetails?.categoryId && (
          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">categoryId</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{PromotiopnDetails?.categoryId}</span>
              </div>
            </div>
          </div>
        )}

        {PromotiopnDetails?.productId && (
          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">productId</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{PromotiopnDetails?.productId}</span>
              </div>
            </div>
          </div>
        )}

        {PromotiopnDetails?.product && (
          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">product</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <Avatar src={PromotiopnDetails?.product?.productImage} />
                <span>{PromotiopnDetails?.product?.name}</span>
              </div>
            </div>
          </div>
        )}

        {PromotiopnDetails?.shopId && (
          <div className="flex flex-no-wrap py-2 mt-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">shopId</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{PromotiopnDetails?.shopId}</span>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{PromotiopnDetails?.type?.replace("_", " ")}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleAdvertise;
