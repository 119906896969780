import { Col, Divider, Form, Input, Row, Select, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { QuestionCircleOutlined } from "@ant-design/icons";
import SubmitResetBtn from "../common/SubmitBtn";
import React from "react";
import ImageUploader from "../common/ImageUploader";
import axios from "axios";
import { debounce } from "lodash";

const AddBank = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const fetchRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [bankId, setBankId] = useState<any>();

  console.log("Bank Id ::", bankId);

  const [branchId, setBranchId] = useState<any>();
  console.log("branch Id::", branchId);
  const [bankOptions, setBankOptions] = useState({
    loading: false,
    list: [],
  });
  const [branchOptions, setBranchOptions] = useState({
    loading: false,
    list: [],
  });

  console.log("Branch Options ::", branchOptions);

  const [bankData, setBankData] = useState<any>(undefined);

  const getBankOptions = useCallback(async (key?: string) => {
    setBankOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/bank?page=0&limit=20` + (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBankOptions({
      loading: false,
      list: res?.data?.banks?.map((bank: any) => ({
        label: bank.name,
        value: bank.id,
      })),
    });
  }, []);

  const getBankBranchList = useCallback(async () => {
    if (bankId) {
      setBranchOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_PAY_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/bank/details?bankId=${bankId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBranchOptions({
        loading: false,
        list: res?.data?.bank?.branches?.map((branch: any) => ({
          label: branch.name,
          value: branch.id,
        })),
      });
    }
  }, [bankId]);

  useEffect(() => {
    getBankBranchList();
  }, [getBankBranchList, onCloseMethod]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      accountNumber: data.accountNumber,
      bankId: bankId,
      branchId: branchId,
      routingNumber: data.routingNumber,
      isActive: true,
    };

    if (bankData) {
      await fetch(
        `${process.env.REACT_APP_PAY_API}/admin/jachai-bank-account`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accountNumber: data.accountNumber,
            bankId: bankId,
            branchId: branchId,
            routingNumber: data.routingNumber,
            isActive: true,
            id: bankData.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Bank Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_PAY_API}/admin/jachai-bank-account`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.status === 200) {
            responseNotification("Bank Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);
  useEffect(() => {
    if (visibleData) {
      setBankData(visibleData);
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [visibleData]);

  useEffect(() => {
    if (showSearch) {
      getBankOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (bankData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [bankData, form, visibleData]);

  const resetData = () => {
    form?.resetFields();
  };
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "bank") getBankOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getBankOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...bankData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Account Number"
          rules={[
            {
              required: true,
              message: "Account Number is Required!",
            },
          ]}
          name="accountNumber"
        >
          <Input
            id="accountNumber"
            type="text"
            placeholder="Enter Account Number"
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Routing Number"
          rules={[
            {
              required: true,
              message: "Routing Number is Required!",
            },
          ]}
          name="routingNumber"
        >
          <Input
            id="routingNumber"
            type="text"
            placeholder="Enter Routing Number"
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label={`Bank`}
          rules={[
            {
              required: true,
              message: "Bank is required!",
            },
          ]}
          name="bankId"
        >
          <Select
            showSearch
            placeholder="Select Bank"
            optionFilterProp="children"
            onSearch={(val) => {
              handleSearch(val, "bank");
            }}
            onChange={(val) => setBankId(val)}
            filterOption={() => {
              return true;
            }}
            options={bankOptions?.list}
          ></Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label={`Branch`}
          rules={[
            {
              required: true,
              message: "Branch is required!",
            },
          ]}
          name="branchId"
        >
          <Select
            showSearch
            placeholder="Select Branch"
            optionFilterProp="children"
            onSearch={(val) => {
              handleSearch(val, "branch");
            }}
            onChange={(val) => setBranchId(val)}
            filterOption={() => {
              return true;
            }}
            options={branchOptions?.list}
          ></Select>
        </Form.Item>

        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddBank;
