import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import CompletedOrderList from "../../components/Orders/CompletedOrder";
import React from "react";

const CompletedOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <CompletedOrderList />
    </MainLayout>
  );
};

export default CompletedOrderPage;
