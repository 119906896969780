import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import UsersList from "../../../Users";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Form, Modal, Select } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import { responseNotification } from "../../../../utils/notify";

const PromoUseUsersList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const route = useParams();
  const [loading, setLoading] = useState(false);
  const [shopId, setShopId] = useState();
  const [refetch, setRefetch] = useState(false);

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const promoCode = (route as any)?.promoCode;
  const [open, setOpen] = useState(false);

  const fetchRef = useRef(0);

  return (
    <React.Fragment>
      <div className="content-body_ rounded-2xl">
        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
          <UsersList
            inPromo={true}
            getPromoCode={(route as any)?.promoCode}
          />
        </div>
      </div> 
    </React.Fragment>
  );
};

PromoUseUsersList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default PromoUseUsersList;
