import { Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import SubmitResetBtn from "../common/SubmitBtn";
import React from "react";

const AddAirport = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [airportStatus, setAirportStatus] = useState("");
  const [airportType, setAirportType] = useState("");
  const [airportData, setAirportData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      airports: [
        {
          airportName: data.name,
          type: airportType,
          airportStatus: airportStatus,
          isActive: true,
          airportCode: data.code,
          cityName: data.cityName,
          countryName: data.countryName,
          countryShortname: data.countryShortName,
          identifier: data.identifier,
          latitudeDeg: data.latitudeDeg,
          longitudeDeg: data.longitudeDeg,
          worldAreaCode: data.worldAreaCode,
        },
      ],
    };

    if (airportData) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/airport`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          airports: [
            {
              airportName: data.name,
              type: airportType || data.type,
              airportStatus: airportStatus || data.airportStatus,
              isActive: true,
              airportCode: data.code,
              cityName: data.cityName,
              countryName: data.countryName,
              countryShortname: data.countryShortName,
              identifier: data.identifier,
              latitudeDeg: data.latitudeDeg,
              longitudeDeg: data.longitudeDeg,
              worldAreaCode: data.worldAreaCode,
              id: airportData.id,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Airport Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/airport`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Airport Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setAirportData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (airportData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [airportData, form, visibleData]);

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...airportData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Airport Code"
          rules={[
            {
              required: true,
              message: "Code is Required!",
            },
          ]}
          name="code"
        >
          <Input id="code" type="text" placeholder="Enter Code" />
        </Form.Item>

        <Form.Item hasFeedback label="Identifier" name="identifier">
          <Input id="identifier" type="text" placeholder="Enter identifier" />
        </Form.Item>

        <Form.Item hasFeedback label="Country Name" name="countryName">
          <Input
            id="countryName"
            type="text"
            placeholder="Enter Country Name"
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Country Short Name"
          name="countryShortName"
        >
          <Input
            id="countryShortName"
            type="text"
            placeholder="Enter Country Short Name"
          />
        </Form.Item>

        <Form.Item hasFeedback label="City Name" name="cityName">
          <Input id="cityName" type="text" placeholder="Enter City Name" />
        </Form.Item>

        <Form.Item hasFeedback label="World Area Code" name="worldAreaCode">
          <Input
            id="worldAreaCode"
            type="text"
            placeholder="Enter World Area Code"
          />
        </Form.Item>

        <Form.Item hasFeedback label="Latitude Degrees" name="latitudeDeg">
          <Input
            id="latitudeDeg"
            type="text"
            placeholder="Enter Latitude Degrees"
          />
        </Form.Item>
        <Form.Item hasFeedback label="Longitude Degrees" name="longitudeDeg">
          <Input
            id="longitudeDeg"
            type="text"
            placeholder="Enter Longitude Degrees"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Airport Status"
          rules={[
            {
              required: false,
              message: "Passenger Airport Status!",
            },
          ]}
          name="airportStatus"
          initialValue={airportStatus}
        >
          <Select
            options={[
              { value: null, label: "NONE" },
              { value: "LOCAL", label: "LOCAL AIRPORT" },
              { value: "INTERNATIONAL", label: "INTERNATIONAL AIRPORT" },
            ]}
            placeholder="Enter Airport Status"
            onChange={(val) => setAirportStatus(val)}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Airport Type"
          rules={[
            {
              required: false,
              message: "Passenger Air port!",
            },
          ]}
          name="type"
          initialValue={airportType}
        >
          <Select
            options={[
              { value: null, label: "NONE" },
              { value: "BOLLOONPORT", label: "BOLLOONPORT" },
              { value: "CLOSED", label: "CLOSED" },
              { value: "HELIPORT", label: "HELIPORT" },
              { value: "LARGE_AIRPORT", label: "LARGE AIRPORT" },
              { value: "MEDIUM_AIRPORT", label: "MEDIUM AIRPORT" },
              { value: "SEAPLANE_BASE", label: "SEAPLANE BASE" },
              { value: "SMALL_AIRPORT", label: "SMALL AIRPORT" },
            ]}
            placeholder="Enter Airport Type"
            onChange={(val) => setAirportType(val)}
          />
        </Form.Item>
        <input type="submit" />

        {/* <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
          }}
          onClickReset={resetData}
          disabled={loading || !!error}
          loading={loading || !!error}
        /> */}
      </Form>
    </>
  );
};

export default AddAirport;
