import MainLayout from "../../../components/Layouts";
import MerchantProductList from "../../../components/Merchant/Product";

const ShopProductPage = () => {
  return (
    <MainLayout>
      <MerchantProductList />
    </MainLayout>
  );
};

export default ShopProductPage;
