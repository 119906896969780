import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import CampaignLayout from "../Layout";
import SingleProduct from "../../../Product/Details";
import SingleOrder from "../../../Orders/Details";
import { useSelector } from "react-redux";

const CampaignOrderDetails = (): ReactElement => {
  const { campaignInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <CampaignLayout
        CampaignId={campaignInfo.id}
        title="Order List"
        subTitle="Order List"
        extra={<></>}
      >
        <div className="content-body p-0 rounded-2xl">
          <SingleOrder inCampaign={true} />
        </div>
        {/* </div> */}
      </CampaignLayout>
    </React.Fragment>
  );
};

CampaignOrderDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignOrderDetails;
