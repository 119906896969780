import { SearchOutlined, SnippetsOutlined } from "@ant-design/icons";
import axios from "axios";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Rate,
  Select,
  Spin,
  DatePicker,
  Modal,
} from "antd";
import { Link, PDFDownloadLink } from "@react-pdf/renderer";
import BreadCrumb from "../Layouts/Breadcrumb";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import { getPage, getParamValue, MerchantStatus } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import LoaderFull from "../common/LoaderFull";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import moment from "moment";
import MerchantDetails from "./Details";
import MerchantAcquisition from "./MerchantAcquisition";
import AddBtn from "../common/AddBtn";
import { isAllowedService } from "../../utils/services";
const { RangePicker } = DatePicker;

const MerchantList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const { showSearch } = useSelector((state) => (state as any)?.searchReducer);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loc = useLocation();
  //const page = getParamValue(loc.search, "page");
  const page = 0;
  const limit = 16;
  const pageType: any = getParamValue(loc.search, "page-type");

  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [merchantType, setMerchantType] = useState("");
  console.log("service-type ::", type);
  console.log("page-type ::", page);
  const [nameOrMobileNumber, setNameOrMobileNumber] = useState("");
  const [merchantId, setMerchantId] = useState<any>();
  const [selectedMerchant, setSelectedMerchant] = useState<any>();
  const [totalMerchant, setTotalMerchant] = useState<any>();
  const [status, setStatus] = useState("");

  const [merchantData, setMerchantData] = useState<any>({
    loading: false,
    data: null,
  });

  const [merchantOptions, setMerchantOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const [typeOptions, setTypeOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const setShowSearch = (value: boolean) => {
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        showSearch: value,
      },
    });
  };

  const getMerchants = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setMerchantData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/merchant/${pageType ? pageType : `all`}?page=0` +
          (limit ? `&limit=${limit}` : ``) +
          (status ? `&state=${status}` : ``) +
          (from ? `&startAt=${from}` : ``) +
          (to ? `&endAt=${to}` : ``) +
          (nameOrMobileNumber
            ? `&nameOrMobileNumber=${nameOrMobileNumber}`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMerchantData({ loading: false, data: res.data });
        setTotalMerchant(res.data?.totalElements);
      })
      .catch((err) => {
        setMerchantData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [
    merchantId,
    merchantType,
    status,
    nameOrMobileNumber,
    range,
    page,
    limit,
  ]);

  const getMerchantsOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/merchant/all?page=0&limit=20` +
          (key ? `&nameOrMobileNumber=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMerchantOptions({
        loading: false,
        list: res.data?.merchants?.map((merchant: any) => ({
          label: `${
            merchant?.fullName?.toUpperCase() ||
            merchant?.merchantPersonalDetail?.name?.toUpperCase()
          } - ${merchant?.mobileNumber?.replace("+88", "")}`,
          value: merchant?.mobileNumber?.replace("+88", ""),
        })),
      });
    },
    []
  );

  const getMerchantType = useCallback(async (key?: string) => {
    setTypeOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    const res = await axios.get(`${encodedUri}/merchant/type`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setTypeOptions({
      loading: false,
      list: res.data?.merchantTypes?.map((type: { name: any; id: any }) => ({
        label: type.name,
        value: type.name?.toUpperCase(),
      })),
    });
  }, []);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "merchant") getMerchantsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getMerchantsOptions]);

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  useEffect(() => {
    if (showSearch) {
      getMerchantType();
      getMerchantsOptions("");
    }
  }, [showSearch]);

  const onClose = () => {
    setSelectedMerchant(undefined);
    getMerchants();
  };

  const reseAllFieldData = () => {
    form.resetFields();
    setNameOrMobileNumber("");
    setMerchantType("");
    setMerchantId("");
    setStatus("");
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Merchant List"
        subTitle={`${merchantData?.data?.totalElements || 0} Merchant(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <div>
            <PDFDownloadLink
              document={
                <MerchantAcquisition data={totalMerchant} range={range} />
              }
              fileName="merchant_acquisition.pdf"
            >
              {({ blob, url, loading, error }) => (
                <Button type="dashed" shape="round">
                  {loading ? "Loading document..." : "Download"}
                </Button>
              )}
            </PDFDownloadLink>
          </div>,
          // isAllowedService(`Merchants Create`) && (
          //   <AddBtn
          //     onClick={() => {
          //       navigate("/merchants/create");
          //     }}
          //   />
          // ),
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "merchant")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setNameOrMobileNumber(val?.toString())
                }
                defaultActiveFirstOption={false}
                notFoundContent={
                  merchantOptions?.loading ? <Spin size="small" /> : null
                }
                options={merchantOptions?.list}
              >
                <Input.Search
                  placeholder="Name or Phone Number"
                  onSearch={(val) => setNameOrMobileNumber(val)}
                  enterButton
                  loading={merchantOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="type_search">
              <Select
                allowClear
                placeholder="Filter by Type"
                optionFilterProp="children"
                onChange={(val) => setMerchantType(val)}
                filterOption={() => {
                  return true;
                }}
                options={typeOptions.list}
              ></Select>
            </Form.Item>
            <Form.Item name="status_search" initialValue={status}>
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(MerchantStatus)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {merchantData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name & Phone</th>
                      <th scope="col">Merchant Type</th>
                      {/* <th scope="col">Total Shop</th>
                      <th scope="col">Total Product</th>
                      <th scope="col">Total Sales</th> */}
                      <th scope="col">Created At</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {merchantData?.data?.merchants?.length ? (
                      merchantData?.data?.merchants?.map(
                        (merchant: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            <td
                              onClick={() =>
                                navigate(
                                  `/merchants/${merchant?.merchantId}/details`
                                )
                              }
                            >
                              <div className="flex">
                                <Avatar
                                  size={40}
                                  src={
                                    merchant?.merchantPersonalDetail
                                      ?.profilePicture
                                  }
                                />
                                <span className="font-medium text-gray-500 ml-2">
                                  {merchant?.fullName?.toUpperCase() ||
                                    merchant?.merchantPersonalDetail?.name?.toUpperCase()}{" "}
                                  <br />
                                  <span className="font-light">
                                    {merchant?.mobileNumber?.split("+88")}
                                  </span>
                                </span>
                              </div>
                            </td>

                            <td>
                              <span className="text-gray-500">
                                {merchant?.type?.replace("_", " ")}
                              </span>
                            </td>

                            {/* <td>
                              <span className="text-gray-500">
                                {merchant?.totalShop || 0}
                              </span>
                            </td>

                            <td
                              onClick={() =>
                                navigate(
                                  `/merchants/products/${merchant?.merchantId}/list`
                                )
                              }
                            >
                              <span className="text-gray-500">
                                {merchant?.totalProduct || 0}
                              </span>
                            </td>

                            <td>
                              <span className="text-gray-500">
                                {merchant?.totalSales || 0}
                              </span>
                            </td> */}

                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    merchant?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(merchant?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(merchant?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="border border-green-200 px-4 bg-green-50 text-green-800 p-1 rounded-full">
                                {merchant?.state?.split("_").join(" ")}
                              </span>
                            </td>

                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="px-6"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedMerchant(merchant);
                                }}
                              >
                                <SnippetsOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...merchantData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        width={`80%`}
        title={`MERCHANT DETAILS - ${
          selectedMerchant?.name ||
          selectedMerchant?.mobileNumber?.replace("+88", "")
        }`}
        open={selectedMerchant}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
        <MerchantDetails
          selectedMerchant={selectedMerchant}
          onCloseMethod={onClose}
        />
      </Modal>
    </>
  );
};

export default MerchantList;
