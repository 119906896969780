import { Divider, Tag } from "antd";
import axios from "axios";
import { Key, ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../common";
import React from "react";

const SingleGroup = ({ GroupDetails }: { GroupDetails: any }): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState(undefined);
  const [groupServices, setGroupServices] = useState<any>({
    loading: false,
    list: [],
  });

  const getServicesByGroup = useCallback(async (groupId?: string) => {
    setLoading(true);
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    return axios
      .get(
        `${encodedUri}/service-group/services-by-group?groupId=${groupId}&serviceType=${type}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res: any) => {
        setGroupServices({
          loading: false,
          list: res?.data?.serviceGroups,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Services: Error", err);
      });
  }, []);

  // const onChange = (list) => {
  //   try {
  //     const temp = {} as any;
  //     list?.forEach((id) => {
  //       if (checkedList?.[id]) {
  //         temp[id] = checkedList?.[id];
  //       } else {
  //         temp[id] = "all";
  //       }
  //     });

  //     setCheckedList(temp);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    if (GroupDetails) {
      setGroupData(GroupDetails);
      getServicesByGroup(GroupDetails?.id);
    }
  }, [GroupDetails]);

  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Group Title</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GroupDetails?.name}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GroupDetails?.type?.replace("_", " ")}</span>
            </div>
          </div>
        </div>

        <Divider dashed orientation="left">
          Services
        </Divider>

        {groupServices?.loading ? (
          <Loader />
        ) : (
          groupServices?.list?.map(
            (serviceGroup: any, index: Key | null | undefined) =>
              serviceGroup?.serviceName?.isActive && (
                <Tag
                  color="#f50"
                  key={index}
                  className="bg-gradient-to-br from-green-500 to-green-700 text-xs font-bold uppercase text-white px-2 py-1 rounded-md mb-2 mr-2"
                  closable
                  // onClose={() => setGroupIds(grp?.group?.id || [])}
                >
                  {serviceGroup?.serviceName?.name} | {serviceGroup?.action}
                </Tag>
              )
          )
        )}
      </div>
      {console.log("checkedList", groupServices)}
    </>
  );
};

export default SingleGroup;
