import React from "react";
import MainLayout from "../../components/Layouts";
import MerchantRecruiterList from "../../components/Merchant/Recruiter";

const MerchantRecruiterPage = () => {
  return (
    <MainLayout>
      <MerchantRecruiterList />
    </MainLayout>
  );
};

export default MerchantRecruiterPage;
