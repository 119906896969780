import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import PendingOrderList from "../../components/Orders/PendingOrder";
import React from "react";

const PendingOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <PendingOrderList />
    </MainLayout>
  );
};

export default PendingOrderPage;
