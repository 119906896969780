import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../common/ImageUploader";
import SubmitResetBtn from "../common/SubmitBtn";
import React from "react";

const AddCareer = ({ visibleData, onCloseMethod }: any) => {
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [careerData, setCareerData] = useState<any>(undefined);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      careers: [
        {
          name: data.name,
          careerCode: data.careerCode,
          careerCodeTD: data.careerCodeTD,
          country: data.country,
          nameWithCode: data.nameWithCode,
          isActive: true,
          logo: generatedImgUrl ? generatedImgUrl : data?.logo,
        },
      ],
    };

    if (careerData) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/career`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          careers: [
            {
              name: data.name,
              careerCode: data.careerCode,
              careerCodeTD: data.careerCodeTD,
              country: data.country,
              nameWithCode: data.nameWithCode,
              isActive: true,
              id: careerData?.id,
              logo: generatedImgUrl ? generatedImgUrl : data?.logo,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Career Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/career`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Career Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setCareerData(visibleData);
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [visibleData]);

  useEffect(() => {
    if (careerData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [careerData, form, visibleData]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setStartUpload(undefined);
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...careerData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>

        <div className="grid grid-cols-2 gap-x-3">
          <Form.Item
            hasFeedback
            label="Career Code"
            rules={[
              {
                required: true,
                message: "Career Code is Required!",
              },
            ]}
            name="careerCode"
          >
            <Input
              id="careerCode"
              type="text"
              placeholder="Enter Career Code"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Career Code TD"
            rules={[
              {
                required: true,
                message: "Career Code is Required!",
              },
            ]}
            name="careerCodeTD"
          >
            <Input
              id="careerCodeTD"
              type="text"
              placeholder="Enter Career Code TD"
            />
          </Form.Item>
        </div>

        <Form.Item
          hasFeedback
          label="Country"
          rules={[
            {
              required: true,
              message: "Country is Required!",
            },
          ]}
          name="country"
        >
          <Input id="country" type="text" placeholder="Enter Career Country" />
        </Form.Item>

        <Form.Item hasFeedback label="Name With Code" name="nameWithCode">
          <Input
            id="nameWithCode"
            type="text"
            placeholder="Enter Career Name With Code"
          />
        </Form.Item>

        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Logo
              <Tooltip
                placement="bottom"
                title={
                  "Career image must be transparent (.png format) & max height 100px and width auto"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              // crop={1}
            />
          </Col>
        </Row>
        {/* <input type="submit" /> */}
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={resetData}
          disabled={loading || startUpload === "Uploading" || !!error}
          loading={loading || startUpload === "Uploading" || !!error}
        />
      </Form>
    </>
  );
};

export default AddCareer;
