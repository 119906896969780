import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Tag,
  DatePicker,
  Popconfirm,
  Drawer,
  Skeleton,
  Checkbox,
} from "antd";
import axios from "axios";
import BreadCrumb from "../Layouts/Breadcrumb";
import { CSVLink } from "react-csv";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import React from "react";
import QuickUpdate from "./QuickUpdate";
import { Link } from "react-router-dom";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import ProductReviewList from "./ProductReviewList";
//import AssignCampaignProductList from "./Product/campaignAssignProductList";
import _ from "lodash";
import CampaignProductAssign from "../Promotions/Campaign/Product/assign";
import {
  AddBtn,
  Loader,
  LoaderFull,
  PaginationThree,
  TreeSelectTwo,
} from "../common";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import ProductExtra from "./extra";
import { structurizeDownloadedData } from "../../utils/csv/ProductDownloadCSV";
const { RangePicker } = DatePicker;
const { success } = Modal;

const ProductList = ({
  inShop = false,
  inBrand = false,
  inMerchant = false,
  inCampaign = false,
  getShopId,
  getCategoryId,
  getBrandId,
  getMerchantId,
  getCampaignId,
}: {
  inShop?: boolean;
  inBrand?: boolean;
  inMerchant?: boolean;
  inCampaign?: boolean;
  getShopId?: string;
  getCategoryId?: string;
  getBrandId?: string;
  getMerchantId?: string;
  getCampaignId?: string;
}): ReactElement => {
  const router = useLocation();
  const liveUrl = `${process.env.REACT_APP_LIVE_URL}`;
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const route = useParams(); 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = 50;
  const pageType: any = getParamValue(loc.search, "page-type");
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");
  const [merchantId, setMerchantId] = useState<any>(getMerchantId);

  const [shopId, setShopId] = useState<any>(getShopId);
  const [categoryId, setCategoryId] = useState<any>(getCategoryId);
  const [campaignId, setCampaignId] = useState<any>(getCampaignId);
  const [brandId, setBrandId] = useState<any>(getBrandId);

  const [stockInOut, setStockInOut] = useState<any>();
  const [isActive, setIsActive] = useState<any>();
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const [shopToShopVisible, setShopToShopVisible] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [productReArrangeDrawer, setProductReArrangeDrawer] =
    useState<any>(undefined);
  const [open, setOpen] = useState(false);
  const [openGiftProduct, setOpenGiftProduct] = useState(false);
  // --------------------------------
  const [activeClone, setActiveClone] = useState(undefined);
  const [changeCat, setChangeCat] = useState(undefined);
  const [assignSupplier, setAssignSupplier] = useState(undefined);
  const [assignCampaign, setAssignCampaign] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [parentCategoryId, setParentCategoryId] = useState(
    getParamValue(router.search, "parentCategoryId")
  );
  const [selectedShopId, setSelectedShopId] = useState(
    assignSupplier ? shopId : [shopId] || []
  );
  const [targetCategoryId, setTargetCategoryId] = useState<any>();

  const [parentCatOptions, setParentCatOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [categoriesOptions, setCategoriesOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [suppliersOptions, setSuppliersOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });
  const [supplierId, setSupplierId] = useState(
    getParamValue(router.search, "supplierId")
  );
  const [onlyThisShop, setOnlyThisShop] = useState<boolean>();
  const [changeStatus, setChangeStatus] = useState(undefined);
  const [childrenDrawer, setChildrenDrawer] = useState(undefined);
  const [visible, setVisible] = useState(undefined);
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  // --------------------------------
  const [selectedProductForReview, setSelectedProductForReview] =
    useState<any>(undefined);

  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  // const [barCode, setBarCode] = useState(
  //   getParamValue(router.search, "barCode")
  // );
  const [barCode, setBarCode] = useState("");

  const [productDataForDownload, setProductDataForDownload] = useState<any>([]);
  const fileName = "Shop-Products";

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });

  const isDeleted = "false";

  // useEffect(() => {
  //   setCampaignId((route as any).campaignId || "");
  // }, [route]);

  // useEffect(() => {
  //   setBrandId((route as any)?.brandId || "");
  // }, [route]);

  const getProducts = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setProductData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    let url =
      `?type=${type}` +
      (shopId ? `&shopId=${shopId}` : ``) +
      (categoryId ? `&categoryId=${categoryId}` : ``) +
      (brandId ? `&brandId=${brandId}` : ``) +
      (campaignId ? `&campaignId=${campaignId}` : ``) +
      (merchantId ? `&merchantId=${merchantId}` : ``) +
      (barCode ? `&barCode=${barCode}` : ``) +
      (sortBy ? `&sortBy=${sortBy}` : ``) +
      (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
      (stockInOut ? `&stockInOut=${stockInOut}` : ``) +
      (key ? `&key=${key}` : ``) +
      (from ? `&startDate=${from}` : ``) +
      (to ? `&endDate=${to}` : ``) +
      (isDeleted ? `&isDeleted=${isDeleted}` : ``) +
      (isActive ? `&isActive=${isActive}` : ``) +
      (page
        ? `&page=${page == productData.data?.currentPageNumber ? 0 : page || 0}`
        : ``) +
      (limit ? `&limit=${limit}` : ``);
    url = encodeURI(url);
    axios
      .get(`${encodedUrl}/admin/product/${pageType ? pageType : `v2`}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProductData({ loading: false, data: res.data });
        setCheckedList(
          res.data.products.map((product: any) => product?.barCode)
        );
      })
      .catch((err) => {
        setProductData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [
    shopId,
    categoryId,
    merchantId,
    barCode,
    sortBy,
    sortOrder,
    brandId,
    campaignId,
    range,
    isActive,
    key,
    pageType,
    type,
    page,
    limit,
  ]);

  const changeProductOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    productData?.data?.products?.find((product: any, i: string) => {
      if (product?.id === node?.key) {
        curr = i;
        item = product;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? productData?.data?.products
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : productData?.data?.products?.slice(0, pos),
        item,
        curr > pos
          ? productData?.data?.products
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, productData?.data?.products?.length - 1)
          : productData?.data?.products?.slice(
              pos,
              productData?.data?.products?.length
            )
      )?.map((item, i) => ({
        id: item?.id,
        displayOrder: productData.data?.currentPageNumber * limit + i,
      }));

    const readyData = {
      shopId: shopId,
      brandId: brandId,
      campaignId: campaignId,
      categoryId: categoryId,
      displayOrders: formated,
    };

    if (formated) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/update-display-order-by-productId`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getProducts();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getProductByBarcodeOptions = useCallback(
    async (barCode: any) => {
      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (barCode ? `&barCode=${barCode}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductBarcodeOptions({
            loading: false,
            list: res.data?.keys?.map(
              (product: { name: any; barCode: any }) => ({
                label: product?.name,
                value: product?.barCode,
              })
            ),
          });
        })
        .catch((err) => {
          setProductBarcodeOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [barCode, shopId, categoryId, brandId, campaignId, isActive,type, page, limit]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [shopId, categoryId, brandId, campaignId, isActive, key,type, page, limit]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, [type]);

  const getCampaignOptions = useCallback(async (key?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign/by-type?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      })),
    });
  }, [type]);

  // ************************

  const campaignProductDragDropOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    productData?.data?.products?.find((product: any, i: string) => {
      if (product?.id === node?.key) {
        curr = i;
        item = product;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? productData?.data?.products
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : productData?.data?.products?.slice(0, pos),
        item,
        curr > pos
          ? productData?.data?.products
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, productData?.data?.products?.length - 1)
          : productData?.data?.products?.slice(
              pos,
              productData?.data?.products?.length
            )
      )?.map((item, i) => ({
        productId: item?.id,
        displayOrder: productData.data?.currentPageNumber * limit + i,
      }));

    if (formated) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/update-products-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaignId: campaignId,
            productIdAndDisplayOrderPairList: formated,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getProducts();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  // ************************
  const onProductRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Product Remove Successfully", "success");
            getProducts();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  //
  const onProductMoveToTop = async (id: any) => {
    const readyData = {
      shopId: shopId,
      brandId: brandId,
      campaignId: campaignId,
      categoryId: categoryId,
      displayOrders: [
        {
          id: id,
          isSendTop: true,
        },
      ],
    };

    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/update-display-order-by-productId`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...readyData }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            getProducts();
            responseNotification(
              "Product Moved To Top Successfully",
              "success"
            );
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else if (res.status === 400) {
            responseNotification(
              res.message ||
                "You can't sort Display orders with multiple filters. You can choose only one at a time. Choose only one from shop, category, brand and campaign.",
              "warning"
            );
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onStatusChange = async (
    id: SetStateAction<undefined>,
    val: boolean
  ) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getProducts();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const showDrawer = () => {
    setShopToShopVisible(true);
  };
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
        else if (field === "campaign") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getProductOptions,
    getShopOptions,
    getBrandsOptions,
    getProductByBarcodeOptions,
    getCampaignOptions,
  ]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getBrandsOptions("");
      getProductByBarcodeOptions("");
      getCampaignOptions();
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setShopId("");
    setBrandId("");
    setBarCode("");
    setCategoryId("");
    setCampaignId("");
    setSortBy("");
    setSortOrder("");
    setRange({
      from: null,
      to: null,
    });
    setIsActive(undefined);
  };

  function UnLoadWindow() {
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        shopId: shopId,
        categoryId: categoryId,
        brandId: brandId,
        campaignId: campaignId,
      },
    });
  }

  const initBeforeUnLoad = (showExitPrompt: any) => {
    window.onbeforeunload = async function (e: Event) {
      const agree = await confirm("Want to store search data?");
      console.log("agree: ", agree);
      if (agree) {
        if (showExitPrompt) {
          UnLoadWindow();
          return "Reload will store the search filter parameters";
        }
      }
      e.preventDefault();
      if (showExitPrompt) {
        UnLoadWindow();
        return "Reload will store the search filter parameters";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(shopId);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChangeCheckbox = (e: any) => {
    setSelectedProductBarcode((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length &&
        checkedList.length <= selectedProductBarcode.length
    );
    setCheckAll(checkedList.length === selectedProductBarcode.length);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(shopId);
  }, [shopId]);

  useEffect(() => {
    if (route?.containFilter !== undefined && !route?.containFilter) {
      dispatch({
        type: SHOW_SEARCHBAR,
        payload: {
          shopId: "",
          categoryId: "",
          brandId: "",
          campaignId: "",
        },
      });
    }
  }, [route?.containFilter]);

  const onClose = () => {
    setSubmitForm(false);
    setOpen(false);
    getProducts();
    getProductsForDownload();
    setSelectedProductForEdit(undefined);
    setReArrangeDrawer(undefined);
    setProductReArrangeDrawer(undefined);
    setSelectedProductForReview(undefined);
    setShopToShopVisible(false);
  };

  const getProductsForDownload = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    let url =
      `?type=${type}&page=0&limit=500` +
      (shopId ? `&shopId=${shopId}` : ``) +
      (categoryId ? `&categoryId=${categoryId}` : ``) +
      (brandId ? `&brandId=${brandId}` : ``) +
      (campaignId ? `&campaignId=${campaignId}` : ``) +
      (merchantId ? `&merchantId=${merchantId}` : ``) +
      (from ? `&startDate=${from}` : ``) +
      (to ? `&endDate=${to}` : ``) +
      (isDeleted ? `&isDeleted=${isDeleted}` : ``) +
      (isActive ? `&isActive=${isActive}` : ``);

    url = encodeURI(url);
    axios
      .get(`${encodedUrl}/admin/product/v2${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProductDataForDownload(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // setProductDataForDownload({ loading: false, data: [] });
        console.error("Download Data: Error", err);
        setLoading(false);
      });
  }, [
    shopId,
    categoryId,
    merchantId,
    brandId,
    campaignId,
    range,
    isActive,
    type,
    page,
    limit,
  ]);

  useEffect(() => {
    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const fetchData = () => {
      axios
        .get(
          `${encodedUrl}/admin/product/v2?type=${type}&page=0&limit=500` +
            (shopId ? `&shopId=${shopId}` : ``) +
            (isDeleted ? `&isDeleted=${isDeleted}` : ``) +
            (isActive ? `&isActive=${isActive}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((postData) => {
          // reshaping the array
          const customHeadings: any = structurizeDownloadedData(
            postData.data.products
          );

          setData(customHeadings);
          // setData(postData.data?.products);
        });
      // .then((r) => setData(r.data?.products));
    };
    fetchData();
  }, [type, page, limit, shopId, isDeleted, isActive]);

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setSingleCampaignInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCampaignInfo({
                loading: false,
                data: data?.data?.campaign,
              });
            } else {
              setSingleCampaignInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCampaignInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleCampaignInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);
  const campaign = singleCampaignInfo?.data;

  const onSubmit = async (data: any) => {
    // setLoading(true);

    const readyData = data && {
      campaignDisplayOrder: {
        campaignId: campaignId,
        displayOrder: 0,
      },
      shopIdList: shopId || data.shopId,
    };
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign-shop`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        // setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Shop Assigned Successfully", "success");
          form.resetFields();
          setOpen(false);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        // setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    if (openGiftProduct) {
      getShopOptions(``);
    }
  }, [openGiftProduct]);

  //new add
  const getMerchantShopOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/${
          merchantId ? "v2" : "by-merchant"
        }` +
          (merchantId ? `?merchantId=${merchantId}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type, merchantId]
  );
  const getMerchantsOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/merchant/all?page=0&limit=20` +
          (type ? `&type=${type}` : ``) +
          (key ? `&name=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMerchantOptions({
        loading: false,
        list: res.data?.merchants?.map((merchant) => ({
          label: merchant.name,
          value: merchant.id,
        })),
      });
    },
    [type]
  );
  const getSupplierOptions = useCallback(
    async (val?: string) => {
      setSuppliersOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/supplier/by-shop` +
          (selectedShopId ? `?shopId=${selectedShopId}` : ``) +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuppliersOptions({
        loading: false,
        list: res?.data?.suppliers?.map((supplier) => {
          return {
            value: supplier?.id,
            label: supplier?.name,
          };
        }),
      });
    },
    [type, shopId]
  );

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(undefined);
    setChangeStatus(undefined);
    setChangeStatus(undefined);
  };
  const getParentCategoryOptions = useCallback(
    async (val?: string) => {
      setParentCatOptions({ loading: true, list: [] });
      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/hub/categories/admin?id=${shopInfo?.hubId}&page=0&limit=20` +
          (val ? `&name=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setParentCatOptions({
        loading: false,
        list: res?.data?.categories?.map((category) => {
          return {
            value: category?.category?.id,
            label: category?.category?.title,
          };
        }),
      });
    },
    [type]
  );
  const getCategoriesOptions = useCallback(
    async (val?: string) => {
      setCategoriesOptions({ loading: true, list: [] });
      if (shopInfo?.hubId) {
        if (parentCategoryId) {
          const res = await axios.get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/child-category-by-parent?categoryId=${parentCategoryId}` +
              (val ? `&key=${val}` : ``) +
              (type ? `&type=${type}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCategoriesOptions({
            loading: false,
            list: res?.data?.categories?.map((category) => {
              return {
                value: category?.id,
                label: category?.title,
              };
            }),
          });
        } else {
          const res = await axios.get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/child-category-by-hub?hubId=${shopInfo?.hubId}&page=0&limit=20` +
              (val ? `&key=${val}` : ``) +
              (type ? `&type=${type}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCategoriesOptions({
            loading: false,
            list: res?.data?.categories?.map((category) => {
              return {
                value: category?.id,
                label: category?.title,
              };
            }),
          });
        }
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/categories?shopId=${shopId}&page=0&limit=20` +
            (val ? `&key=${val}` : ``) +
            (type ? `&type=${type}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategoriesOptions({
          loading: false,
          list: res?.data?.categories?.map((category) => {
            return {
              value: category?.category?.id,
              label: category?.category?.title,
            };
          }),
        });
      }
    },
    [type, parentCategoryId]
  );
  const onMultipleStatusChange = async () => {
    if (changeStatus === "DELETEANDACTIVE") {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/multiple-inactive-but-active-in-other-shops`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            barCodes: selectedProductBarcode,
            shopId: shopId,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Selected Item(s) Status Updated Successfully",
              "success"
            );
            getProducts();
            setVisible(undefined);
            setChildrenDrawer(undefined);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/multiple-active-inactive`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            barCodes: selectedProductBarcode,
            shopIds: selectedShopId || [shopId],
            isActive: changeStatus === "ACTIVE" ? true : false,
            deleted: changeStatus === "DELETE" ? true : false,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Selected Item(s) Status Updated Successfully",
              "success"
            );
            getProducts();
            setVisible(undefined);
            setChildrenDrawer(undefined);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    if (type) {
      getMerchantsOptions();
    }
  }, [getMerchantsOptions, type]);

  useEffect(() => {
    if (merchantId) {
      getMerchantShopOptions();
    }
  }, [merchantId, getMerchantShopOptions]);

  useEffect(() => {
    if (selectedShopId) {
      getSupplierOptions();
    }
  }, [getSupplierOptions, selectedShopId]);

  return (
    <>
      {!inBrand && !inMerchant && (
        <BreadCrumb
          title="Product List"
          subTitle={`${productData?.data?.totalElements}  Product(s)`}
          childComponent={inShop ? true : false}
          extra={[
            !pageType && (
              <Button
                type="dashed"
                shape="circle"
                onClick={() => setShowSearch(!showSearch)}
                key={1}
              >
                <SearchOutlined />
              </Button>
            ),
            <Button
              shape="round"
              type="primary"
              className="bg-blue-600"
              onClick={() => setProductReArrangeDrawer(true)}
            >
              <SwapOutlined />
            </Button>,
            inShop && productData?.data?.products?.length && (
              <Button
                shape="round"
                type="primary"
                onClick={() => showDrawer()}
                disabled={!selectedProductBarcode.length}
                title="Clone Product"
                className="bg-blue-600"
              >
                <SwapOutlined /> Action
              </Button>
            ),

            inShop && productData?.data?.products?.length && (
              <Button
                type="dashed"
                shape="round"
                //variant="contained"
                className="mx-2"
              >
                <CSVLink
                  //headers={headers}
                  data={data}
                  filename={fileName}
                >
                  <DownloadOutlined /> Download
                </CSVLink>
              </Button>
            ),
            inCampaign && (
              <Button
                shape="round"
                type="primary"
                className="bg-blue-600"
                onClick={() => setReArrangeDrawer(true)}
              >
                <SwapOutlined />
              </Button>
            ),
            inCampaign && (
              <Button
                type="dashed"
                shape="round"
                className="bg-white"
                onClick={() => {
                  // setOpen(true);
                  navigate(`/promotion/campaign/${campaignId}/products-assign`);
                }}
              >
                Assign Product
              </Button>
            ),
            isAllowedService(`Products Create`) && !inBrand && !inCampaign && (
              <AddBtn
                onClick={() => {
                  navigate(
                    inShop
                      ? `/shops/${getShopId}/products/create`
                      : `/products/create`
                  );
                }}
              />
            ),
          ]}
        />
      )}

      {showSearch && !pageType && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item name="barcode_search">
              <AutoComplete
                onSearch={(e) => handleSearch(e, "productByBarcode")}
                onSelect={(val: any) => setBarCode(val)}
                options={productBarcodeOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productBarcodeOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Bar Code"
                  onSearch={(val: any) => {
                    setBarCode(val);
                  }}
                  enterButton
                  loading={productBarcodeOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
            {!inShop && (
              <Form.Item name="shop_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Shop"
                  optionFilterProp="children"
                  onChange={(val) => setShopId(val)}
                  onSearch={(e) => handleSearch(e, "shop")}
                  filterOption={() => {
                    return true;
                  }}
                  options={shopOptions?.list}
                ></Select>
              </Form.Item>
            )}

            <Form.Item name="category_search">
              <TreeSelectTwo setCategoryId={setCategoryId} />
            </Form.Item>

            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>

            <Form.Item name="campaign_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Campaign"
                optionFilterProp="children"
                onChange={(val) => setCampaignId(val)}
                onSearch={(e) => handleSearch(e, "campaign")}
                filterOption={() => {
                  return true;
                }}
                options={campaignOptions?.list}
              ></Select>
            </Form.Item>
            {/* <Form.Item name="stockin_stockout_search">
              <Select
                showSearch
                placeholder="Filter By Stock In/ Stock Out"
                onChange={(val) => setStockInOut(val as string)}
              >
                <Select.Option value={undefined}>
                  Filter By Stock In/ Stock Out
                </Select.Option>
                <Select.Option value={"STOCK_IN"} title="STOCK IN">
                  STOCK IN
                </Select.Option>
                <Select.Option value={"STOCK_OUT"} title="STOCK OUT">
                  STOCK OUT
                </Select.Option>
              </Select>
            </Form.Item> */}

            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(_e, f) => {
                  // console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      {inShop && (
                        <th scope="col">
                          <div className="checkbox-large">
                            <Checkbox
                              indeterminate={indeterminate}
                              onChange={onCheckAllChange}
                              checked={
                                checkAll ||
                                selectedProductBarcode?.length ===
                                  checkedList?.length
                              }
                              className="mr-3"
                              disabled={!productData.data?.products?.length}
                            >
                              Check all
                            </Checkbox>
                          </div>
                        </th>
                      )} 
                      <th scope="col">Product</th>
                      <th scope="col">Shop</th>
                      <th scope="col">Category</th>
                      <th scope="col">Visitor</th>
                      <th scope="col">Sell</th>
                      <th scope="col">Price & Stock</th>
                      <th scope="col">Move TO Top</th>
                      {!inBrand && !inCampaign && (
                        <>
                          {isAllowedService(`Products Edit`) && (
                            <th scope="col">Status</th>
                          )}
                          <th scope="col">Action</th>
                          {/* <th scope="col">Ratings</th> */}
                          <th scope="col">Created Info</th>
                          <th scope="col">Updated Info</th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.products?.length ? (
                      productData?.data?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            {inShop && (
                              <td>
                                <div className="checkbox-large flex items-center gap-2">
                                  <Checkbox
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onChangeCheckbox(product?.barCode);
                                    }}
                                    checked={selectedProductBarcode?.find(
                                      (item: any) => product?.barCode === item
                                    )}
                                    value={product?.barCode}
                                  ></Checkbox>
                                </div>
                              </td>
                            )}
                            <td
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   e.stopPropagation();
                              //   navigate(
                              //     `${
                              //       inShop ? `/shops/${getShopId}` : ``
                              //     }/products/${product?.slug}/details`
                              //   );
                              //   dispatch({
                              //     type: SHOW_SEARCHBAR,
                              //     payload: {
                              //       shopId: shopId,
                              //       categoryId: categoryId,
                              //       brandId: brandId,
                              //       campaignId: campaignId,
                              //     },
                              //   });
                              // }}
                              className="flex items-center gap-2"
                            >
                              <a
                                href={liveUrl + `products/` + product?.slug}
                                target="_blank"
                                rel="noreferer noopener"
                              >
                                <Avatar
                                  size={45}
                                  src={product?.productImage}
                                  shape="square"
                                />
                              </a>
                              <div className="flex flex-col">
                                <span className="font-medium text-gray-500">
                                  {product?.name?.slice(0, 50)}
                                </span>
                                {product?.barCode && (
                                  <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                    <BarcodeOutlined /> {product?.barCode}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {product?.shop?.name?.slice(0, 50)}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {product?.category?.title?.slice(0, 50)}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {product?.visitors || 0}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {product?.salesCount || 0}
                              </span>
                            </td>
                            <td>
                              <div className="flex_">
                                {product?.variations?.length
                                  ? product?.variations?.map(
                                      (variant: any, index: string) => (
                                        <React.Fragment key={index}>
                                          <div className="flex justify-content-start mb-1">
                                            <Tag
                                              color={
                                                variant?.stock < 10
                                                  ? `red`
                                                  : `green`
                                              }
                                            >
                                              {variant?.variationName?.toUpperCase()}
                                              : <b>{variant?.stock || 0}</b>
                                            </Tag>

                                            <Tag>
                                              MRP {variant?.price?.currencyCode}
                                              {variant?.price
                                                ?.discountedPrice ||
                                                variant?.price?.mrp}
                                              {variant?.price
                                                ?.discountedPrice &&
                                                variant?.price
                                                  ?.discountedPrice !==
                                                  variant?.price?.mrp && (
                                                  <span className="text-red-600 ml-1">
                                                    <del>
                                                      {variant?.price
                                                        ?.currencyCode || "Tk"}
                                                      {variant?.price?.mrp}
                                                    </del>
                                                  </span>
                                                )}
                                            </Tag>
                                            <Tag>
                                              TP {variant?.price?.tp}
                                              {variant?.price?.currencyCode}
                                            </Tag>
                                            <Tag className="text-red-600 ">
                                              Revenue{" "}
                                              {Math.round(
                                                variant?.price
                                                  ?.discountedPrice -
                                                  variant?.price?.tp
                                              )}
                                              {variant?.price?.currencyCode}
                                            </Tag>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </td>

                            <td>
                              <Button
                                shape="round"
                                type="primary"
                                danger
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onProductMoveToTop(product?.id);
                                }}
                              >
                                <ToTopOutlined />
                              </Button>
                            </td>

                            {!inBrand && !inCampaign && (
                              <>
                                {isAllowedService(`Products Edit`) && (
                                  <td>
                                    <Switch
                                      checkedChildren={<CheckOutlined />}
                                      unCheckedChildren={<CloseOutlined />}
                                      defaultChecked={product?.isActive}
                                      onChange={(val, e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onStatusChange(product?.id, val);
                                      }}
                                      className="mr-1"
                                      loading={
                                        confirmLoading &&
                                        product?.id === confirmLoading
                                      }
                                      disabled={
                                        product?.shop?.deleted ||
                                        product?.status === "REJECTED"
                                      }
                                    />
                                  </td>
                                )}
                                <td>
                                  <div className="flex flex-grow gap-1">
                                    {/* <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      dispatch({
                                        type: SHOW_SEARCHBAR,
                                        payload: {
                                          shopId: shopId,
                                          categoryId: categoryId,
                                          brandId: brandId,
                                          campaignId: campaignId,
                                        },
                                      });
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button> */}

                                    <Button
                                      title="View Product"
                                      // to={
                                      //   inShop
                                      //     ? `/shops/${getShopId}/products/${product?.slug}/details?containFilter=true`
                                      //     : `/products/${product?.slug}/details?containFilter=true`
                                      // }
                                      // target={
                                      //   type == "JC_FOOD" ? "_self" : "_blank"
                                      // }
                                      onClick={() => {
                                        UnLoadWindow();
                                        navigate(
                                          inShop
                                            ? `/shops/${getShopId}/products/${product?.slug}/details?containFilter=true`
                                            : `/products/${product?.slug}/details?containFilter=true`
                                        );
                                      }}
                                      className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                    >
                                      <EyeOutlined />
                                    </Button>
                                    <Button
                                      title="Review List"
                                      shape="round"
                                      type="primary"
                                      danger
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedProductForReview(
                                          product?.id
                                        );
                                      }}
                                    >
                                      <CommentOutlined />
                                    </Button>
                                    {/* <Button
                                      shape="round"
                                      type="primary"
                                      danger
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedProductForEdit(product);
                                      }}
                                    >
                                      <FileDoneOutlined />
                                    </Button> */}
                                    {isAllowedService(`Products Edit`) && (
                                      <>
                                        <Link
                                          title="Edit Product"
                                          to={
                                            inShop
                                              ? `/shops/${getShopId}/products/${product?.slug}/edit`
                                              : `/products/${product?.slug}/edit`
                                          }
                                          target={
                                            type == "JC_FOOD"
                                              ? "_self"
                                              : "_blank"
                                          }
                                          className="py-2 px-4 rounded-full leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                        >
                                          <EditOutlined />
                                        </Link>
                                        <Button
                                          title="Quick update"
                                          shape="round"
                                          type="primary"
                                          danger
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedProductForEdit(product);
                                          }}
                                        >
                                          <FileDoneOutlined />
                                        </Button>
                                        {isAllowedService(
                                          `Products Delete`
                                        ) && (
                                          <Popconfirm
                                            placement="left"
                                            title="Are you sure to remove?"
                                            visible={
                                              product?.id === visiblePopconfirm
                                            }
                                            onConfirm={(e: any) => {
                                              //console.log(true);
                                              e.preventDefault();
                                              e.stopPropagation();
                                              onProductRemove(product?.id);
                                            }}
                                            okButtonProps={{
                                              loading: confirmLoading,
                                              className: "bg-blue-400",
                                            }}
                                            onCancel={handleCancel}
                                          >
                                            <Button
                                              className="bg-white"
                                              type="dashed"
                                              danger
                                              shape="round"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                showPopconfirm(product?.id);
                                              }}
                                            >
                                              <DeleteOutlined />
                                            </Button>
                                          </Popconfirm>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                                {/* <td>
                                  <Rate value={product?.rating || 1} disabled />
                                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                    {product?.numberOfRating || 0}
                                  </span>
                                </td> */}
                                <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {product?.createdBy?.name ||
                                      product?.createdBy?.mobileNumber?.replace(
                                        "+88",
                                        ""
                                      )}
                                    {" - "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        product?.createdAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(product?.createdAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(product?.createdAt).format("lll")}
                                  </span>
                                </td>

                                <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {product?.updatedBy?.name ||
                                      product?.updatedBy?.mobileNumber?.replace(
                                        "+88",
                                        ""
                                      )}
                                    {" - "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        product?.updatedAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(product?.updatedAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(product?.updatedAt).format("lll")}
                                  </span>
                                </td>
                              </>
                            )}
                            {/* {campaign?.campaignType == "GIFT_ITEMS" && (
                              <td>
                                <Button
                                  type="dashed"
                                  shape="round"
                                  className="bg-white"
                                  onClick={() => setOpenGiftProduct(true)}
                                >
                                  Assign Gift Product
                                </Button>
                              </td>
                            )} */}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title="Product Review List"
        onCancel={onClose}
        open={selectedProductForReview}
        width={700}
        centered
        footer={false}
      >
        <ProductReviewList
          onCloseMethod={onClose}
          visibleData={selectedProductForReview}
        />
      </Modal>

      <Drawer
        title="Re Arrange Product List"
        placement="right"
        onClose={onClose}
        open={productReArrangeDrawer}
        width={450}
      >
        {productData?.loading ? (
          <Skeleton />
        ) : productData?.data?.products?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={productData?.data?.products?.map((product: any) => ({
              title: product?.name,
              key: product?.id,
            }))}
            onDrop={(e: any) => {
              changeProductOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>

      <Drawer
        title="Re Arrange Campaign Product"
        placement="right"
        onClose={onClose}
        open={reArrangeDrawer}
        width={450}
      >
        {productData?.loading ? (
          <Skeleton />
        ) : productData?.data?.products?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={productData?.data?.products?.map((product: any) => ({
              title: product?.name,
              key: product?.id,
            }))}
            onDrop={(e: any) => {
              campaignProductDragDropOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>

      <Drawer
        title={activeClone ? "Clone Product" : "Change Option"}
        placement="right"
        onClose={onClose}
        open={shopToShopVisible}
        width={550}
      >
        <ProductExtra barcodes={selectedProductBarcode} />
      </Drawer>

      <Modal
        title="Campaign Product Assign List"
        centered
        open={open}
        onOk={() => {
          setSubmitForm(true);
        }}
        okText="Assign Product"
        onCancel={onClose}
        width={`80%`}
      >
        <CampaignProductAssign
          submitForm={submitForm}
          onAssignModal={true}
          onCloseMethod={onClose}
        />
      </Modal>

      <Modal
        title="Quick Update"
        onCancel={onClose}
        open={selectedProductForEdit}
        width={700}
        centered
        footer={false}
      >
        <QuickUpdate
          onCloseMethod={onClose}
          visibleData={selectedProductForEdit}
        />
      </Modal>

      <Modal
        title="Assign Gift Product"
        centered
        open={openGiftProduct}
        okText="Assign Gift Product"
        onCancel={() => setOpenGiftProduct(!openGiftProduct)}
        width={500}
        // okButtonProps={{ htmlType: "submit" }}
        onOk={onSubmit}
      >
        <div>
          <Form
            onFinish={onSubmit}
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
            size="large"
          >
            <Form.Item name="search">
              <Select
                filterOption={() => {
                  return true;
                }}
                showSearch
                onSearch={(e) => handleSearch(e, "shop")}
                onChange={(val) => setShopId(val)}
                options={shopOptions?.list}
                loading={shopOptions?.loading}
                placeholder="Select Gift Product"
                mode="multiple"
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ProductList;
