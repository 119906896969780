import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import ImageUploader from "../common/ImageUploader";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
const { TextArea } = Input;

const AddMethod = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >(undefined);
  const [form] = Form.useForm();

  const [error, setError] = useState("");
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const [methodData, setMethodData] = useState<any>(undefined);
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: {
    title: any;
    name: any;
    logo: any;
    description: any;
    type: any;
  }) => {
    if (generatedImgUrl) {
      setLoading(true);

      const readyData = data && {
        name: data.name,
        title: data.title,
        logo: generatedImgUrl ? generatedImgUrl : data.logo,
        description: data.description,
        type: type,
      };

      if (methodData) {
        await fetch(`${process.env.REACT_APP_PAY_API}/payment-method`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: methodData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Method Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_PAY_API}/payment-method`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Method Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    if (visibleData) {
      setMethodData(visibleData);
    }
  }, [form, visibleData]);

  useEffect(() => {
    if (methodData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [methodData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...methodData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Title"
          rules={[
            {
              required: true,
              message: "Title is Required!",
            },
          ]}
          style={{ minWidth: "100%" }}
          name="title"
        >
          <Input id="title" type="text" placeholder="Enter Title" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          style={{ minWidth: "100%" }}
          name="name"
        >
          <Input
            id="name"
            type="text"
            disabled={methodData}
            placeholder="Enter Name"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Description"
          name="description"
          style={{ minWidth: "100%" }}
        >
          <TextArea
            id="description"
            placeholder="Enter Method Description"
            rows={4}
          />
        </Form.Item>

        <div className="">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={1}
          />
        </div>
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={loading}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
            onClick={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
            }}
          >
            Submit
          </Button>
          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
              setGeneratedImgUrl(undefined);
              setStartUpload(undefined);
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddMethod;
