import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import GroupTicketList from "../../components/Group-Ticket";
import React from "react";

const GroupTicketPage = (): ReactElement => {
  return (
    <MainLayout>
      <GroupTicketList />
    </MainLayout>
  );
};

export default GroupTicketPage;
