import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import PartialConfirmedOrderList from "../../components/Orders/PertialConfirmedOrder";
import React from "react";

const PartialConfirmedOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <PartialConfirmedOrderList />
    </MainLayout>
  );
};

export default PartialConfirmedOrderPage;
