import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import GeneralConfigList from "../../components/GeneralConfiguration/index";
import React from "react";

const GeneralConfiguration = (): ReactElement => {
  return (
    <MainLayout>
      <GeneralConfigList />
    </MainLayout>
  );
};

export default GeneralConfiguration;
