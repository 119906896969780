import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import JachaiBankAccountList from "../../components/JachaiBankAccount/index";
import React from "react";

const JachaiBankAccountPage = (): ReactElement => {
  return (
    <MainLayout>
      <JachaiBankAccountList />
    </MainLayout>
  );
};

export default JachaiBankAccountPage;
