import { Image } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";

const MethodDetails = ({ MethodDetails }, props): ReactElement => {
  const [getMethodDetails, setMethodDetails] = useState(undefined);

  useEffect(() => {
    setMethodDetails(MethodDetails);
  }, [MethodDetails]);

  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={getMethodDetails?.logo}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Payment Method</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{getMethodDetails?.name}</span>
            </div>
          </div>
        </div>
        {getMethodDetails?.title ? (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Title</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{getMethodDetails?.title}</span>
              </div>
            </div>
          </div>
        ) : undefined}

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Method ID</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{getMethodDetails?.id}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{getMethodDetails?.type?.replace("_", " ")}</span>
            </div>
          </div>
        </div>
        {getMethodDetails?.status ? (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Status</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{getMethodDetails?.status}</span>
              </div>
            </div>
          </div>
        ) : undefined}
        {getMethodDetails?.description ? (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Description</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{getMethodDetails?.description}</span>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
    </>
  );
};
MethodDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MethodDetails;
