import React from "react";
import MainLayout from "../../components/Layouts";
import CappingList from "../../components/Capping";

const CappingPage = () => {
  return (
    <MainLayout>
      <CappingList />
    </MainLayout>
  );
};

export default CappingPage;
