import {
  DesktopOutlined,
  WifiOutlined,
  ApiOutlined,
  CoffeeOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Space, Badge, message, Avatar } from "antd";
import React, { useState } from "react";
import s from "./SearchCard.module.css";
import { useEffect } from "react";
import {
  GroupDescription,
  Itinerary,
  ItineraryGroup,
  PassengerCodes,
  PassengerInfo,
} from "../../../types";
import SearchDetailsTab from "../SearchDetails/SearchDetailsTab";
import moment from "moment";
import { useNavigate } from 'react-router-dom'

const { Panel } = Collapse;

const SearchCard = ({
  itinerary,
  group,
  lowFareSearchRqId,
}: {
  itinerary: Itinerary;
  group: GroupDescription;
  lowFareSearchRqId: string;
}) => {
  const [itineraryData, setItineraryData] = useState<Itinerary>();
  const [leg, setLeg] = useState(0);


  useEffect(() => {
    setItineraryData(itinerary);
  }, [itinerary]);

  const navigate = useNavigate();
  // const firstSchedule = itineraryData?.legs[0]?.schedules[0]
  const carrier = itineraryData?.legs[0]?.schedules?.[0]?.carrier?.details;
  const firstSchedule = itineraryData?.legs[0]?.schedules[0];
  const lastSchedule =
    itineraryData?.legs[0]?.schedules[
      itineraryData?.legs[0]?.schedules?.length - 1
    ];
  const fromAirport = firstSchedule?.departure;
  const toAirport = lastSchedule?.arrival;

  const fare = itinerary?.pricingInformation?.[0]?.fare;

  const passengerMap: { [key: string]: PassengerInfo } = {};
  const totalObj = fare?.passengerInfoList?.reduce(
    (obj, passengerInfo) => {
      passengerMap[passengerInfo?.passengerInfo?.passengerType] =
        passengerInfo?.passengerInfo;
      return {
        fare:
          obj?.fare +
            passengerInfo?.passengerInfo?.passengerTotalFare?.totalFare || 0,
        passenger:
          obj?.passenger + passengerInfo?.passengerInfo?.passengerNumber || 0,
      };
    },
    {
      fare: 0,
      passenger: 0,
    }
  );

  const passengerTypeValue = {
    ADT: "Adult",
    C09: "Children",
    INF: "Infant",
  };

  const onSelect = (
    itinerary: Itinerary | undefined,
    group: GroupDescription
  ) => {
    try {
      const data = 
        JSON.stringify({
          itinerary,
          group,
          lowFareSearchRqId,
        })
      ;

      localStorage.setItem("SDFL", `${data}`); // Selected Data From List

      setTimeout(() => {
        localStorage.removeItem("SDFL");
      }, 30 * 60 * 1000);

      return true;
    } catch (e) {
      console.log(e);
      message.error("Something went wrong");
    }
  };

  return (
    <div className=" bg-white rounded-xl shadow-sm last:mb-0 mb-6">
      <div className="grid grid-cols-4 items-center">
        <div className="col-span-3 p-2">
          <div className="flex flex-nowrap justify-between items-center p-3 mb-8">
            <div className="flex gap-2">
              <Badge
                count={"Cheapest"}
                size="default"
                style={{ backgroundColor: "#52c41a" }}
              />
              <Badge count={"Partially Refundable"} />
            </div>
            <span>
              {Math.floor(itinerary?.legs[0]?.elapsedTime / 60)}h{" "}
              {itinerary?.legs[0]?.elapsedTime % 60}m{" "}
            </span>
          </div>
          <div className="flex flex-nowrap justify-between items-center text-center text-xl p-2">
            <Avatar size={45} src={carrier?.logo} shape="square" />
            <p className="font-light">{carrier?.name}</p>
            <div className="grid">
              {itinerary?.legs?.map((leg, legIndex) => (
                <span className="text-sm">
                  {moment(
                    `${group?.legDescriptions[legIndex]?.departureDate}`
                  ).format("dd, DD MMM yyyy")}
                </span>
              ))}
 
              <span className="font-bold">
                {fromAirport?.time?.slice(0, 5)}
              </span>
              <span>{fromAirport?.airport}</span>
            </div>
            <div className="grid">
              <span className="text-sm text-gray-400 loading-none mb-2">
                {itinerary?.legs?.[0]?.schedules?.length > 1
                  ? `${itinerary?.legs?.[0]?.schedules?.length - 1} Stoppage`
                  : "Non-stop"}
              </span>
              <div className={s.custom_arrow_desing}></div>

              <span className="text-accent text-bamdse font-semibold mt-1">
                {Math.floor(itinerary?.legs[0]?.elapsedTime / 60)}h{" "}
                {itinerary?.legs[0]?.elapsedTime % 60}m{" "}
              </span>
            </div>
            <div className="grid">
              {itinerary?.legs?.map((leg, legIndex) => (
                <span className="text-sm">
                  {moment(
                    `${group?.legDescriptions[legIndex]?.departureDate}`
                  ).format("dd, DD MMM yyyy")}
                </span>
              ))}
              <span className="font-bold">{toAirport?.time?.slice(0, 5)}</span>
              <span>{toAirport?.airport}</span>
            </div>
          </div>
        </div>

        {/* new work  */}
        <div className="col-span-1 bg-red-50 p-6 border-l text-center">
          <span className="text-xl">
            {itineraryData?.fareSummary?.promoCode}
          </span>
          <div className="flex items-center justify-around p-4">
            <del className="text-sm">
              {
                itineraryData?.pricingInformation?.[0]?.fare?.totalFare
                  ?.currency
              }{" "}
              {/* {itineraryData?.pricingInformation?.[0]?.fare?.totalFare?.totalPrice} */}
              {itineraryData?.fareSummary?.subTotalAmount}
            </del>

            <span className="font-bold text-xl text-accent">
              {/* {fare?.totalFare?.currency} {fare?.totalFare?.totalPrice} */}
              {itineraryData?.fareSummary?.totalWithoutCharge}
            </span>
          </div>
          <Space size="large" />
          <Button
            block
            danger
            size="large"
            shape="round"
            type="primary"
              onClick={() => {
                const success = onSelect(itineraryData, group)
                if (success) {
                  navigate('/group-ticket/select-group-tricket-review')
                }
              }}
          >
            Select
          </Button>
        </div>

        {/* new work end */}
      </div>

      <div className="border-t">
        <Collapse ghost>
          <Panel
            header="Flight Details"
            key="1"
            extra={
              <div className="grid grid-cols-4">
                <Button type="text" size="small">
                  <WifiOutlined />
                </Button>
                <Button type="text" size="small">
                  {/* <YoutubeOutlined /> */}
                  <DesktopOutlined />
                </Button>
                <Button disabled type="text" size="small">
                  <ApiOutlined />
                </Button>
                <Button disabled type="text" size="small">
                  <CoffeeOutlined />
                </Button>
              </div>
            }
          >
            <SearchDetailsTab itinerary={itineraryData} group={group} />
          </Panel>
        </Collapse>{" "}
      </div>
    </div>
  );
};

export default SearchCard;
