import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import AssistantOtaList from "../../components/AssistantOta";
import React from "react";

const AssistantOtaPage = (): ReactElement => {
  return (
    <MainLayout>
      <AssistantOtaList />
    </MainLayout>
  );
};

export default AssistantOtaPage;
