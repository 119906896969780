// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Search Area */
.List_searchBox__Z\\+Smw {
  margin-bottom: 1rem;
  display: block;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored)
}
@media (min-width: 640px) {
  .List_searchBox__Z\\+Smw {
    display: flex
  }
}
.List_formInline__q5MTh {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll
}
/* Search Area */

/* Content Area */
.List_contentWrapper__E2Bus {
  z-index: 50;
  overflow: auto;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.List_mainTable__FZqvb thead th {
  position: sticky;
  top: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  z-index: 8
}

.List_mainTable__FZqvb {
  min-width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.List_mainTable__FZqvb tbody td {
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.List_tBody2__GNfVz {
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}
/* Content Area */
`, "",{"version":3,"sources":["webpack://./src/styles/tailwind/List.module.css"],"names":[],"mappings":"AAAA,gBAAgB;AAEd;EAAA,mBAAwG;EAAxG,cAAwG;EAAxG,mBAAwG;EAAxG,8BAAwG;EAAxG,sBAAwG;EAAxG,kBAAwG;EAAxG,yDAAwG;EAAxG,gBAAwG;EAAxG,+EAAwG;EAAxG,mGAAwG;EAAxG,uGAAwG;EAAxG,0BAAwG;EAAxG;AAAwG;AAAxG;EAAA;IAAA;EAAwG;AAAA;AAGxG;EAAA,aAA2D;EAA3D,iBAA2D;EAA3D,kBAA2D;EAA3D;AAA2D;AAE7D,gBAAgB;;AAEhB,iBAAiB;AAEf;EAAA,WAAyD;EAAzD,cAAyD;EAAzD,sBAAyD;EAAzD,kBAAyD;EAAzD,yDAAyD;EAAzD,oBAAyD;EAAzD,qBAAyD;EAAzD,0EAAyD;EAAzD,8FAAyD;EAAzD;AAAyD;;AAIzD;EAAA,gBAAkF;EAAlF,QAAkF;EAAlF,kBAAkF;EAAlF,yDAAkF;EAAlF,oBAAkF;EAAlF,qBAAkF;EAAlF,oBAAkF;EAAlF,uBAAkF;EAAlF,gBAAkF;EAAlF,mBAAkF;EAAlF,oBAAkF;EAAlF,gBAAkF;EAAlF,oBAAkF;EAAlF,6CAAkF;EAClF;AADkF;;AAKlF;EAAA,eAA0B;EAA1B,kBAA0B;EAA1B;AAA0B;;AAI1B;EAAA,mBAAmE;EAAnE,oBAAmE;EAAnE,qBAAmE;EAAnE,oBAAmE;EAAnE,uBAAmE;EAAnE,mBAAmE;EAAnE,oBAAmE;EAAnE,gBAAmE;EAAnE,oBAAmE;EAAnE;AAAmE;;AAInE;EAAA,mBAAoE;EAApE,oBAAoE;EAApE,qBAAoE;EAApE,oBAAoE;EAApE,uBAAoE;EAApE,mBAAoE;EAApE,oBAAoE;EAApE,gBAAoE;EAApE,oBAAoE;EAApE;AAAoE;AAEtE,iBAAiB","sourcesContent":["/* Search Area */\n.searchBox {\n  @apply block justify-between items-center p-3 mb-4 bg-white rounded-xl shadow-lg shadow-gray-200 sm:flex;\n}\n.formInline {\n  @apply flex flex-nowrap overflow-x-scroll overflow-y-hidden;\n}\n/* Search Area */\n\n/* Content Area */\n.contentWrapper {\n  @apply bg-white rounded-xl shadow px-2 z-50 overflow-auto;\n}\n\n.mainTable thead th {\n  @apply sticky top-0 bg-white text-sm font-medium text-gray-900 px-2 py-3 text-left;\n  z-index: 8;\n}\n\n.mainTable {\n  @apply min-w-full bg-white;\n}\n\n.mainTable tbody td {\n  @apply px-2 py-3 whitespace-nowrap text-sm font-light text-gray-900;\n}\n\n.tBody2 {\n  @apply px-2 py-3 whitespace-nowrap text-sm font-medium text-gray-900;\n}\n/* Content Area */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBox": `List_searchBox__Z+Smw`,
	"formInline": `List_formInline__q5MTh`,
	"contentWrapper": `List_contentWrapper__E2Bus`,
	"mainTable": `List_mainTable__FZqvb`,
	"tBody2": `List_tBody2__GNfVz`
};
export default ___CSS_LOADER_EXPORT___;
