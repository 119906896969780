import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useParams} from "react-router";
import {useSelector} from "react-redux";

const AppURL = process.env.NEXT_PUBLIC_BOOKING_API;
const PayURL = process.env.REACT_APP_PAY_API;
const localURL = process.env.NEXT_PUBLIC_LOCAL_URL;

function Payment(props) {
    const {type, token} = useSelector((state) => state?.authReducer);
    const route = useParams();
    const search = route?.slug;
    const searchArray = search?.split(",");

    const [paymentMethod, setPaymentMethod] = useState([]);
    const [checkPayment, setCheckPayment] = useState("");
    if (searchArray) {
        var totalAmount = searchArray[0];
        var orderID = searchArray[1];
    } else {
        var orderID = "";
        var totalAmount = "";
    }

    useEffect(() => {
        let staticURL = PayURL + "/payment-method/all?type=JC_BOOKING";
        console.log("==", staticURL);
        axios({
            method: 'GET',
            url: staticURL,
            headers: {
                'Authorization': 'bearer ' + token
            }
        }).then(response => {
            setPaymentMethod(response?.data?.methods);
        }).catch(error => {
            console.log(error);
        });
    }, [search]);

    const bookingPayment = () => {
        let staticURL = PayURL + "/pay";
        axios({
            method: 'POST',
            url: staticURL,
            data: {
                "amount": totalAmount,
                "cancelUrl": "",
                "failUrl": "",
                "gateway": checkPayment,
                "orderId": orderID,
                "savedCard": true,
                "successUrl": localURL + "/myOrder",
                "trxID": "",
                "type": "JC_BOOKING"
            },
            headers: {
                'Authorization': 'bearer ' + token
            }
        }).then(response => {
            const RedirectURL = response?.data?.url;
            setInterval(function () {
                window.location.replace(RedirectURL);
            }, 1000);
        }).catch(error => {
            console.log(error);
        });
    }


    return (
        <div className="container mx-auto my-16">
            <div
                className="grid grid-cols-3 lg:grid-cols-4 gap-3">
                {paymentMethod?.map((paymentMethod, index) => (
                    <div key={index}
                         onClick={() => setCheckPayment(paymentMethod.name)}
                         className=
                             {checkPayment === paymentMethod.name ?
                                 "shadow-md border border-green-500 bg-white rounded-lg cursor-pointer" :
                                 "shadow-md bg-white border border-gray-300 rounded-lg cursor-pointer"}>
                        <img className="mx-auto rounded-lg m-2"
                             width={70}
                             height={70}
                             src={paymentMethod.logo}
                             alt="Logo"/>
                    </div>
                ))}
            </div>

            <div
                className="grid grid-cols-3 lg:grid-cols-4 gap-3">
                <div onClick={() => bookingPayment()}
                     className="bg-red-500 text-white cursor-pointer p-2 text-center rounded-lg mt-3">
                    Clicked here to payment confirmed
                </div>
            </div>
        </div>
    );
}

export default Payment;