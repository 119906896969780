import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  WifiOutlined,
  DesktopOutlined,
  ApiOutlined,
  CoffeeOutlined,
  PlusOutlined,
  MinusOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Collapse,
  DatePicker,
  DatePickerProps,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Switch,
  message,
} from "antd";

import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import React from "react";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { countyList, getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import s from "./SearchCard.module.css";
import { Image } from "antd";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../utils/services";
import AddGroupTicket from "./AddGroupTicket";

import SearchCard from "../SearchCard/SearchCard";
import SearchDetailsTab from "../SearchDetails/SearchDetailsTab";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import type { Dayjs } from "dayjs";
import {
  BaggageAllowanceDescs,
  CarrierBody,
  GroupDescription,
  GroupedItineraryResponse,
  Itinerary,
  ItineraryGroup,
  LegDescs,
  PassengerInfo,
  ScheduleDesc,
} from "../../../types";
import {
  AddBtn,
  Loader,
  LoaderFull,
  Pagination,
  SubmitResetBtn,
} from "../common";
import moment from "moment";
import { Link } from "react-router-dom";
import { countries } from "../../../lib/countries";
import cn from "classnames";
import ReviewCalculation from "./ReviewCalculation";
import { format } from "path";
const { Panel } = Collapse;

interface Props {
  className: string;
  id: string;
}
interface SearchResponseType {
  lowFareSearchId: string;
  groupedItineraryResponse: GroupedItineraryResponse;
}
interface CarrierMapInterface {
  [key: string]: CarrierBody;
}

const SearchGroupTicketReview = (): ReactElement => {
  // add new
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [error, setError] = useState("");
  const [shopId, setShopId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [itineryData, setItineryData] = useState<Itinerary>();
  const [group, setGroup] = useState<GroupDescription>();
  const [lowFareSearchRqId, setLowFareSearchRqId] = useState<string>();
  const [open, setOpen] = useState<number>(0);
  const [key, setKey] = useState<string>();

  //const router = useRouter()
  const { Panel } = Collapse;

  const onDeSelect = () => {
    try {
      const data: any = localStorage.getItem("SDFL");
      const decryptedData = data;
      let parsedData = decryptedData ? JSON.parse(decryptedData) : null;
      const { itinerary, group, lowFareSearchRqId } = parsedData;

      setItineryData(itinerary);
      setGroup(group);
      setLowFareSearchRqId(lowFareSearchRqId);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    onDeSelect();
  }, []);

  const fare = itineryData?.pricingInformation?.[0]?.fare;
  const passengerMap: { [key: string]: PassengerInfo } = {};
  const totalObj = fare?.passengerInfoList?.reduce(
    (obj, passengerInfo) => {
      passengerMap[passengerInfo?.passengerInfo?.passengerType] =
        passengerInfo?.passengerInfo;
      return {
        fare:
          obj?.fare +
            passengerInfo?.passengerInfo?.passengerTotalFare?.totalFare || 0,
        passenger:
          obj?.passenger + passengerInfo?.passengerInfo?.passengerNumber || 0,
      };
    },
    {
      fare: 0,
      passenger: 0,
    }
  );
  const passengerTypeValue = {
    ADT: "Adult",
    C09: "Children",
    INF: "Infant",
  };

  const passengerValue = {
    ADT: 1,
    C09: 2,
    INF: 3,
  };

  console.log(
    "NN:",
    fare?.passengerInfoList?.[0]?.passengerInfo?.passengerType
  );

  useEffect(() => {
    form?.resetFields();
  }, [itineryData, group, fare]);

  // console.log("All itinery Data22 ::", itineryData);
  // console.log("All group ::", group);
  // console.log("All fare ::", fare);

  const onSubmit = async (data: any) => {
    setLoading(true);
    // add new start
    const travelerClass =
      fare?.passengerInfoList?.[0]?.passengerInfo?.fareComponents?.[0]
        ?.segments?.[0]?.segment?.cabinCode;
    const passengerType =
      fare?.passengerInfoList?.[0]?.passengerInfo?.passengerType;
    const baggageAllowanceUnitName =
      fare?.passengerInfoList?.[0]?.passengerInfo?.baggageInformation[0]
        ?.allowance?.unit;
    const baggageAllowance =
      fare?.passengerInfoList?.[0]?.passengerInfo?.baggageInformation[0]
        ?.allowance?.weight;
    const currencyName = fare?.totalFare?.equivalentCurrency;
    const careerCode = itineryData?.legs[0]?.schedules[0]?.carrier?.marketing;

    var arrivalDepartureDate = group?.legDescriptions[0]?.departureDate;
    var arrivalDays =
      itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.arrival
        ?.dateAdjustment || 0;
    var arrivalDay = new Date(
      new Date(arrivalDepartureDate).setDate(
        new Date(arrivalDepartureDate).getDate() + arrivalDays
      )
    );
    const arrivalDate = dayjs(arrivalDay).format("YYYY-MM-DD");
    const arrivalTimeGMT =
      itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.arrival?.time.split(
        "+06:00"
      );
    const arrivalTime = arrivalTimeGMT[0];
    const departureDate = group?.legDescriptions[0]?.departureDate;

    const departureTimeGMT =
      itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.departure?.time.split(
        "+06:00"
      );
    const departureTime = departureTimeGMT[0];
    const destinationCountryCode =
      itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.arrival?.country;
    const destinationLocationCode =
      itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.arrival?.airport;
    const originCountryCode =
      itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.departure?.country;
    const originLocationCode =
      itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.departure?.airport;
    const mrpPrice = itineryData?.fareSummary?.subTotalAmount;

    const readyData = data && {
      arrivalDate: arrivalDate, //Done
      arrivalTime: arrivalTime, //Done
      baggageAllowance: baggageAllowance, //Done
      baggageAllowanceUnitName: baggageAllowanceUnitName, //Done
      careerCode: careerCode, //Done
      currencyName: currencyName, //Done
      departureDate: departureDate, //Done
      departureTime: departureTime, //Done
      destinationCountryCode: destinationCountryCode, //Done
      destinationLocationCode: {
        LocationCode: destinationLocationCode, //Done
      },
      isActive: true, //Done
      originCountryCode: originCountryCode, //Done
      originLocationCode: {
        LocationCode: originLocationCode || "Null", //Done
      },
      passengerType: passengerType, //Done
      pnrCode: data.pnrCode, //Done
      mrp: mrpPrice, //Done
      tp: data.tpPrice, //Done
      price: data.price, //Done
      availableTicket: data.availableTicket, //Done
      shopId: shopId, //Done
      travelerClass: travelerClass, //Done
      wayType: "ONE_WAY", //Done
    };
    // add new end
    await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/group-ticket/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...readyData,
        status: true,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Group Ticket Create Successfully", "success");
          form.resetFields();
          navigate("/group-ticket/search");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getShopsOptions = useCallback(async (val?: string) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "shop") getShopsOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopsOptions, type]);

  useEffect(() => {
    getShopsOptions();
  }, [getShopsOptions]);
  // add new end

  return (
    <>
      <BreadCrumb title={`Search Group Ticket List`} />

      <div>
        <main className={styles.main}>
          <div>
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12 md:col-span-8 w-full">
                <div className="mb-12">
                  <h2 className="mb-8 mt-6 text-xl font-bold">
                    Enter Group Tricket Details: Please fill in with valid
                    information.
                  </h2>
                  <div>
                    <Form
                      form={form}
                      onFinish={onSubmit}
                      name="control-hooks"
                      layout="vertical"
                    >
                      <div className="bg-white p-0 md:p-4 rounded-md">
                        <div className="mb-6">
                          <div>
                            <div className="flex items-center">
                              <Form.Item
                                name="tpPrice"
                                label="TP Price"
                                className="flex-grow mr-4"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your tp!",
                                  },
                                ]}
                              >
                                <Input type="TP" placeholder="Enter your TP" />
                              </Form.Item>

                              <Form.Item
                                name="price"
                                label="Sale Price"
                                className="flex-grow"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your sale price!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter your sale price"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className="mb-0">
                          <div>
                            <div className="flex items-center">
                              <Form.Item
                                name="availableTicket"
                                label="Quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your quantity!",
                                  },
                                ]}
                                className="flex-grow mr-4"
                              >
                                <Input placeholder="Enter your quantity" />
                              </Form.Item>

                              <Form.Item
                                name="shopId"
                                label="Assign Merchant"
                                className="flex-grow"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please  Select your merchant",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Select Shop"
                                  optionFilterProp="children"
                                  onSearch={(val) => {
                                    handleSearch(val, "shop");
                                  }}
                                  onChange={(val) => setShopId(val)}
                                  filterOption={() => {
                                    return true;
                                  }}
                                  options={shopsOptions?.list}
                                ></Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="mb-0">
                          <div>
                            <div className="flex items-center">
                              <Form.Item
                                name="pnrCode"
                                label="PNR Code"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please input your pnrCode!",
                                  },
                                ]}
                                className="flex-grow mr-4"
                              >
                                <Input placeholder="Enter your pnrCode" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <Button className="mt-6 w-full" loading={loading}>
                        Create Tricket
                      </Button> */}
                      <SubmitResetBtn
                        disabled={loading || !!error}
                        loading={loading}
                      />
                    </Form>
                  </div>
                </div>
              </div>

              <div className="col-span-12 md:col-span-4">
                <ReviewCalculation />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default SearchGroupTicketReview;
