import MainLayout from "../../components/Layouts";
import AddShop from "../../components/Shop/AddShop";

const ShopAddPage = () => {
  return (
    <MainLayout>
      <AddShop />
    </MainLayout>
  );
};

export default ShopAddPage;
