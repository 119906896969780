import React from "react";
import MainLayout from "../../components/Layouts";
import MerchantTypeList from "../../components/Merchant/Type";

const MerchantTypePage = () => {
  return (
    <MainLayout>
      <MerchantTypeList />
    </MainLayout>
  );
};

export default MerchantTypePage;
