import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import airportList from "../../../airportList.json";
import airLinceList from "../../../airlines.json";
import country from '../../../country.json';
import axios from "axios";
import {useSelector} from "react-redux";

const AppURL = process.env.REACT_APP_BOOKING_API;
const OrderURL = process.env.REACT_APP_ORDER_API;
const authAPI = process.env.REACT_APP_AUTH_API;

function BookingPreview() {
    const {type, token} = useSelector((state) => state?.authReducer);
    const route = useParams();
    const search = route?.slug;
    const searchArray = search?.split(",");
    const [userList, setUserList] = useState([]);
    const [customerId, setCustomerId] = useState("");
    const [promoScrollIndexEnd, setPromoScrollIndexEnd] = useState(4);
    useEffect(() => {
        function handleResize() {
            if ((window.innerWidth <= 1024) && (window.innerWidth >= 768)) {
                setPromoScrollIndexEnd(2);
            } else {
                setPromoScrollIndexEnd(3);
            }
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);


    if (searchArray) {
        var searchArray0 = searchArray[0];
        var searchArray1 = searchArray[1];
        var searchArray2 = searchArray[2];
        var searchArray3 = searchArray[3];
        var searchArray4 = searchArray[4];
        var searchArray5 = searchArray[5];
        var searchArray6 = searchArray[6];
        var searchArray7 = searchArray[7];
        var searchArray8 = searchArray[8];
        var searchArray9 = searchArray[9];
        var searchArray10 = searchArray[10];
        var searchArray11 = searchArray[11];
        var searchArray12 = searchArray[12];
        var searchArray13 = searchArray[13];

        var childrenOneAge = searchArray[14];
        var childrenTwoAge = searchArray[15];
        var childrenThreeAge = searchArray[16];
        var childrenFourAge = searchArray[17];

        var searchArray18 = searchArray[18];
        var searchArray19 = searchArray[19];
        var searchArray20 = searchArray[20];
    }


    const [pnrMsg, setPnrMsg] = useState("");
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);
    const [allPromo, setAllPromo] = useState([]);
    const [promoCode, setPromoCode] = useState([]);
    const [partnerPromos1, setPartnerPromos] = useState([]);
    const [platformPromos1, setPlatformPromos] = useState([]);
    const [sellerPromos1, setSellerPromos] = useState([]);
    const [promoArray, setPromoArray] = useState([]);
    const [promoScrollIndexStart, setPromoScrollIndexStart] = useState(0);
    const [promoSlider, setPromoSlider] = useState(0);
    const [checkSearchClickPromo, setCheckSearchClickPromo] = useState("");
    const [selectedServicesPreview, setSelectedServicesPreview] = useState(1);
    const [groupedItineraryResponse, setGroupedItineraryResponse] = useState([]);
    const [flightLegs, setFlightLegs] = useState([]);
    const [flightLegDescs, setFlightLegDescs] = useState([]);
    const [flightScheduleDescs, setFlightScheduleDescs] = useState([]);
    const [pricingInformation, setPricingInformation] = useState([]);
    const [lowFareSearchId, setLowFareSearchId] = useState("");
    const [fareSummary, setFareSummary] = useState("");
    const [fareSummaryByTravelers, setFareSummaryByTravelers] = useState([]);


    useEffect(() => {
        let staticURL = OrderURL + "/promo/my-all-promo?type=JC_BOOKING";
        axios({
            method: 'GET',
            url: staticURL,
            headers: {
                'Authorization': 'bearer ' + token
            }
        }).then(response => {
            setPartnerPromos(response.data.partnerPromos);
            setPlatformPromos(response.data.platformPromos);
            if (response.data.sellerPromos === null) {
                var sellerPromos = [];
            } else {
                setSellerPromos(response.data.sellerPromos);
                var sellerPromos = response.data.sellerPromos;
            }
            const arry1 = response.data.partnerPromos;
            const arry2 = response.data.platformPromos;
            const arry3 = sellerPromos;
            const sliceArray = arry1.concat(arry2).concat(arry3);
            setAllPromo(sliceArray);
            setPromoCode(sliceArray);
        }).catch(error => {
            console.log(error);
        });
    }, [token]);


    const [properType1, setProperType1] = useState([
        {
            "name": "Hotels",
            "total": "557 Hotels",
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:33:37.837_property1.webp",
        },
        {
            "name": "Resorts",
            "total": "10 Resorts",
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:33:54.404_property2.webp",
        },
    ]);

    useEffect(() => {
        let myJourneyDate = new Date(searchArray6);
        let getMyJourneyDate = myJourneyDate.toISOString().split('T')[0];
        if (searchArray7) {
            var myReturnDate = new Date(searchArray7);
            var getMyReturnDate = myReturnDate.toISOString().split('T')[0];
        }
        const journeyDate = getMyJourneyDate + "T00:00:00";
        const returnDate = getMyReturnDate + "T00:00:00";
        const flightClass = [];
        if (searchArray10 > 0) {
            flightClass.push({"Code": "ADT", "Quantity": searchArray10})
        }

        if (searchArray11 > 0) {
            if (searchArray11 == 1) {
                flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
            } else if (searchArray11 == 2) {
                if (childrenOneAge === childrenTwoAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "2"});
                } else {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
                    flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "1"});
                }
            } else if (searchArray11 == 3) {
                if (childrenOneAge === childrenTwoAge === childrenThreeAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "3"});
                } else if (childrenOneAge === childrenTwoAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "2"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                } else if (childrenOneAge === childrenThreeAge) {
                    flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "2"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                } else if (childrenTwoAge === childrenThreeAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "2"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                } else {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
                    flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "1"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                }
            } else {
                flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
                flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "1"});
                flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                flightClass.push({"Code": "C" + childrenFourAge, "Quantity": "1"});
            }
        }

        if (searchArray12 > 0) {
            flightClass.push({"Code": "INF", "Quantity": searchArray12})
        }

        const originDestination = [];
        if (searchArray13 == 1) {
            originDestination.push({
                "DepartureDateTime": journeyDate,
                "DestinationLocation": {
                    "LocationCode": searchArray4
                },
                "OriginLocation": {
                    "LocationCode": searchArray1
                },
                "RPH": "0"
            })
        }

        if (searchArray13 == 2) {
            originDestination.push({
                "DepartureDateTime": journeyDate,
                "DestinationLocation": {
                    "LocationCode": searchArray4
                },
                "OriginLocation": {
                    "LocationCode": searchArray1
                },
                "RPH": "0"
            });

            originDestination.push({
                "DepartureDateTime": returnDate,
                "DestinationLocation": {
                    "LocationCode": searchArray1
                },
                "OriginLocation": {
                    "LocationCode": searchArray4
                },
                "RPH": "1"
            });
        }

        let staticURL = AppURL + "/booking/search-low-fare";
        const dataString = {
            "originDestinationInformations": originDestination,
            "passengerTypeQuantities": flightClass,
            "travelerClass": searchArray9
        }
        axios({
            method: 'POST',
            url: staticURL,
            data: dataString,
        }).then(response => {
            setLoader1(true);
            setLowFareSearchId(response.data.lowFareSearchId);
            setGroupedItineraryResponse(response.data.groupedItineraryResponse);
            setFlightLegDescs(response.data.groupedItineraryResponse.legDescs);
            setFlightScheduleDescs(response.data.groupedItineraryResponse.scheduleDescs);
            setFlightLegs(response.data.groupedItineraryResponse.itineraryGroups[0].itineraries[searchArray19 - 1]?.legs);
            setFareSummary(response.data.groupedItineraryResponse.itineraryGroups[0].itineraries[searchArray19 - 1]?.fareSummary);
            setFareSummaryByTravelers(response.data.groupedItineraryResponse.itineraryGroups[0].itineraries[searchArray19 - 1]?.pricingInformation[0]?.fare?.passengerInfoList);
            setPricingInformation(response.data.groupedItineraryResponse.itineraryGroups[0].itineraries[searchArray19 - 1]?.pricingInformation);
        }).catch(error => {
            console.log(error);
        });
    }, [searchArray13]);


    const [ContactNumber, setContactNumber] = useState([]);
    const [email, setEmail] = useState([]);
    const [personName, setPersonName] = useState([]);
    const [mainArray, setMainArray] = useState([]);
    const passengerArray = search?.slice(search.indexOf('=') + 1);
    const ContactNumberArray = search?.slice(search.indexOf(',c') + 2);
    const emailArray = search?.slice(search.indexOf(',e') + 2);
    const personNameArray = search?.slice(search.indexOf(',p') + 2);
    const result = passengerArray?.split(',c')[0];

    const contactResult = ContactNumberArray?.split(',e')[0];
    const emailResult = emailArray?.split(',p')[0];
    const personNameResult = personNameArray?.split(',-')[0];

    var totalTravelers = parseInt(searchArray10) + parseInt(searchArray11) + parseInt(searchArray12);

    useEffect(() => {
        if (result) {
            setMainArray(JSON.parse(result));
            setContactNumber(JSON.parse(contactResult));
            setEmail(JSON.parse(emailResult));
            setPersonName(JSON.parse(personNameResult));
        } else {
            setMainArray([]);
            setContactNumber([]);
            setEmail([]);
            setPersonName([]);
        }
    }, [result]);


    const mainArrayChange = (index, status) => {
        if (status == 1) {
            var set_status = "";
        } else {
            var set_status = 1;
        }
        let i_data = [...mainArray];
        i_data[index]["status"] = set_status;
        setMainArray(i_data);
    }

    const handelCheckPhone = (index, e, selected) => {
        let temp = [...ContactNumber];
        temp[index][selected] = e.target.value;
        setContactNumber(temp);
    }

    const handelCheckEmail = (index, e, selected) => {
        let temp = [...email];
        temp[index][selected] = e.target.value;
        setEmail(temp);
    }

    const handelCheckPersonName = (index, e, selected) => {
        let temp = [...personName];
        if ((selected == "ExpirationDate") || (selected == "IssueCountry") || (selected == "NationalityCountry") || (selected == "Number") || (selected == "Type")) {
            if ((selected == "IssueCountry") || (selected == "IssueCountry")) {
                let countryShortName = country.filter(function (el) {
                        return el.name == e.target.value;
                    }
                );
                temp[index]["document"][selected] = countryShortName[0].code;
            } else {
                temp[index]["document"][selected] = e.target.value;
            }
        } else {
            temp[index][selected] = e.target.value;
        }
        setPersonName(temp);
    }

    const create_pnr = () => {
        setLoader(true);
        let staticURL = AppURL + "/booking/create-pnr";
        if (searchArray20 != "undefined") {
            var set_group_ticket_id = searchArray20;
            var set_ticketing_type = "GROUP_TICKET";
        } else {
            var set_group_ticket_id = null
            var set_ticketing_type = null;
        }

        const dataString = {
            "bookingCode": pricingInformation[0]?.fare?.passengerInfoList[0]?.passengerInfo?.fareComponents[0]?.segments[0]?.segment?.bookingCode,
            "customerInfo": {
                "ContactNumbers": {ContactNumber},
                "Email": email,
                "PersonName": personName
            },
            "groupTicketId": set_group_ticket_id,
            "ticketingType": set_ticketing_type,
            "itineraryNumber": searchArray19,
            "lowFareSearchRqId": lowFareSearchId,
            "customerId": customerId,
        }

        axios({
            method: 'POST',
            url: staticURL,
            data: dataString,
            headers: {
                'Authorization': 'bearer ' + token
            }
        }).then(response => {
            if (response.data.statusCode == 200) {
                setLoader(false);
                setInterval(function () {
                    window.location.replace('/tricket-booking/' + response.data.total + ',' + response.data.ourBookingOrderId + "/paymentPreview");
                }, 1000);
            } else {
                return false;
            }
        }).catch(error => {
            setLoader(false);
            setPnrMsg("No result found");
            console.log(error);
        });
    }

    const promoIncrementOrDecrement = (e) => {
        if (e === "next") {
            setPromoScrollIndexStart((prevPromoScrollIndexStart) => prevPromoScrollIndexStart + 1);
            setPromoScrollIndexEnd((prevPromoScrollIndexEnd) => prevPromoScrollIndexEnd + 1);
            setPromoSlider((prevPromoSlider) => prevPromoSlider + 1);
        } else {
            setPromoScrollIndexStart((prevPromoScrollIndexStart) => prevPromoScrollIndexStart - 1);
            setPromoScrollIndexEnd((prevPromoScrollIndexEnd) => prevPromoScrollIndexEnd - 1);
            setPromoSlider((prevPromoSlider) => prevPromoSlider - 1);
        }
    }

    useEffect(() => {
        setPromoArray(promoCode.slice(promoScrollIndexStart, promoScrollIndexEnd));
    }, [promoCode, promoScrollIndexStart, promoScrollIndexEnd]);

    const viewAllPromo = (val) => {
        if (val === "all") {
            setPromoCode(allPromo);
        } else if (val === "seller") {
            setPromoCode(sellerPromos1);
        } else if (val === "platform") {
            setPromoCode(platformPromos1);
        } else if (val === "partner") {
            setPromoCode(partnerPromos1);
        } else {
            setPromoCode(allPromo);
        }
    }

    const promoCodeSearchCheck = (index, val) => {
        if (checkSearchClickPromo === index) {
            setCheckSearchClickPromo("");
        } else {
            setCheckSearchClickPromo(index);
        }
        let staticURL = AppURL + "/promo/apply-promo?promoCode=" + val + "&lowFareSearchId=" + lowFareSearchId + "&itineraryId=" + searchArray18;
        axios({
            method: 'GET',
            url: staticURL,
            headers: {
                'Authorization': 'bearer ' + token
            }
        }).then(response => {
            if (response) {
                setFareSummary(response.data.fareSummary);
            } else {
                return false;
            }
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        var staticURL = authAPI + "/admin/user?page=0&limit=20";
        axios({
            method: 'GET',
            url: staticURL,
            headers: {
                'Authorization': 'bearer ' + token
            }
        }).then(response => {
            if (response) {
                setUserList(response?.data?.users);
                console.log(response);
            } else {
                return false;
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);


    const handelUser = (event) => {
        setCustomerId(event.target.value);
    }

    return (
        <>
            <div className="container mx-auto bg-white mt-16">
                <div>
                    <div
                        className="h-auto w-full bg-white border-r border-l shadow-lg mx-auto">
                        <div className="border-b border-gray-300">
                            <div
                                className="flex flex-row justify-between text-black mx-[2%] lg:mx-[12%] md:h-12">
                                <div>
                                    <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                         onClick={() => setSelectedServicesPreview(1)}>
                                        <div className="ml-3">
                                            <i className="bi bi-airplane h5"></i>
                                        </div>
                                        <div className={selectedServicesPreview == 1 ?
                                            "md:text-lg md:font-bold text-center text-red-600" : "md:text-lg md:font-bold"
                                        }>Flight
                                        </div>
                                    </div>
                                    {selectedServicesPreview == 1 ?
                                        <div
                                            className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                    }
                                </div>
                                <div>
                                    <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                         onClick={() => setSelectedServicesPreview(2)}>
                                        <div className="ml-2">
                                            <i className="bi bi-buildings h5"></i>
                                        </div>
                                        <div className={selectedServicesPreview == 2 ?
                                            "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                        }>
                                            Stays
                                        </div>
                                    </div>
                                    {selectedServicesPreview == 2 ?
                                        <div
                                            className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                    }
                                </div>
                                <div>
                                    <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                         onClick={() => setSelectedServicesPreview(3)}>
                                        <div className="ml-2">
                                            <i className="bi bi-umbrella h5"></i>
                                        </div>
                                        <div className={selectedServicesPreview == 3 ?
                                            "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                        }>Tour
                                        </div>
                                    </div>
                                    {selectedServicesPreview == 3 ?
                                        <div
                                            className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                    }
                                </div>
                                <div>
                                    <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                         onClick={() => setSelectedServicesPreview(4)}>
                                        <div className="ml-1">
                                            <i className="bi bi-bus-front h5"></i>
                                        </div>
                                        <div className={selectedServicesPreview == 4 ?
                                            "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                        }>Bus
                                        </div>
                                    </div>
                                    {selectedServicesPreview == 4 ?
                                        <div
                                            className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                    }
                                </div>
                                <div>
                                    <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                         onClick={() => setSelectedServicesPreview(5)}>
                                        <div className="ml-1">
                                            <i className="bi bi-car-front-fill h5"></i>
                                        </div>
                                        <div className={selectedServicesPreview == 5 ?
                                            "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                        }>Car
                                        </div>
                                    </div>
                                    {selectedServicesPreview == 5 ?
                                        <div
                                            className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                    }
                                </div>
                                <div>
                                    <div className="pt-1 md:flex md:flex-row md:gap-1 cursor-pointer"
                                         onClick={() => setSelectedServicesPreview(6)}>
                                        <div className="ml-2">
                                            <i className="bi bi-train-lightrail-front h5"></i>
                                        </div>
                                        <div className={setSelectedServicesPreview == 6 ?
                                            "md:text-lg md:font-bold text-red-600" : "md:text-lg md:font-bold"
                                        }>Train
                                        </div>
                                    </div>
                                    {selectedServicesPreview == 6 ?
                                        <div
                                            className="bg-red-600 h-0.5 w-9 mx-auto mt-2.5"></div> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between mt-10">
                        <div className="font-bold text-2xl">Booking</div>
                        <div className="md:w-[30%]">
                            <div>
                                <img width={500} height={500}
                                     src="/images/preview.png"
                                     alt="Logo"/>
                            </div>
                            <div className="flex flex-row justify-between">
                                <div>Flight Selection</div>
                                <div>Booking</div>
                                <div>Payment</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row gap-x-10 mt-8">
                    <div className="w-[95%] md:w-[75%] mx-auto h-auto mb-10">
                        {loader1 ?
                            <>
                                {flightLegs.map((v_legs, indexLeg) => {
                                    const dep_and_ari = groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg];
                                    return (
                                        <div id="font_size" key={indexLeg}
                                             className="rounded-lg border md:mx-[4%] xl:mx-0 border-gray-300">
                                            <div className="mx-[4%] font-bold text-sm md:text-lg mt-5">
                                                {dep_and_ari.departureLocation} to {dep_and_ari.arrivalLocation} City
                                                Airport ,
                                                {groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg].departureDate}
                                            </div>
                                            <div
                                                className="border-b border-gray-300 w-[100%] mx-auto my-6"></div>
                                            <div>
                                                {
                                                    flightLegDescs[v_legs.ref - 1].schedules.map((v_LegDescs, index) => {
                                                        let arrivalAirport = airportList.filter(function (el) {
                                                                return el.code == flightScheduleDescs[v_LegDescs.ref - 1].arrival.airport
                                                            }
                                                        );
                                                        let departureAirport = airportList.filter(function (el) {
                                                                return el.code == flightScheduleDescs[v_LegDescs.ref - 1].departure.airport
                                                            }
                                                        );

                                                        let filterAirlince = airLinceList.careers.filter(function (el) {
                                                                return el.careerCode == flightScheduleDescs[v_LegDescs.ref - 1].carrier.operating
                                                            }
                                                        );
                                                        var travelsTimes = flightScheduleDescs[v_LegDescs.ref - 1].elapsedTime;
                                                        var hour = Math.floor(travelsTimes / 60);
                                                        var minutes = travelsTimes % 60;
                                                        return (
                                                            <div key={index} className="mx-[1%] md:mx-[4%]">
                                                                <div className="flex flex-row space-x-8">
                                                                    <div>
                                                                        <img className="my-5 ml-4"
                                                                             width={60}
                                                                             height={60}
                                                                             src={filterAirlince[0]?.logo}
                                                                             alt="Logo"/>
                                                                    </div>
                                                                    <div>
                                                                        <div>{flightScheduleDescs[v_LegDescs.ref - 1].departure.airport}</div>
                                                                        <div>Aircraft: {flightScheduleDescs[v_LegDescs.ref - 1].carrier.equipment.code}</div>
                                                                        <div>Flight
                                                                            Number: {flightScheduleDescs[v_LegDescs.ref - 1].carrier.operatingFlightNumber}</div>
                                                                        <div>Operated
                                                                            by: {flightScheduleDescs[v_LegDescs.ref - 1].carrier.operating}</div>
                                                                        <div>Economy
                                                                            ({pricingInformation[0].fare.passengerInfoList[0].passengerInfo.fareComponents[0].segments[0].segment.bookingCode})
                                                                        </div>
                                                                        <div>Available
                                                                            Seats: {pricingInformation[0].fare.passengerInfoList[0].passengerInfo.fareComponents[0].segments[0].segment.seatsAvailable}</div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="my-2">{filterAirlince[0]?.name} </div>
                                                                <div
                                                                    className="flex flex-row justify-between mt-5 w-[100%]">
                                                                    <div className="text-sm w-[40%]">
                                                                        <div
                                                                            className="font-bold">{flightScheduleDescs[v_LegDescs.ref - 1].departure.time.substring(0, 5)} ({flightScheduleDescs[v_LegDescs.ref - 1].departure.airport})
                                                                        </div>
                                                                        <div>{groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg].departureDate}</div>
                                                                        <div>Tarminal-{flightScheduleDescs[v_LegDescs.ref - 1].departure.terminal}</div>
                                                                        <div>{departureAirport[0].name}</div>
                                                                    </div>


                                                                    <div className="my-auto text-sm w-[18%]">
                                                                        <img width={100} height={100}
                                                                             src="/images/arrow1.png"
                                                                             alt="Logo"/>
                                                                        <div
                                                                            className="text-center">{hour}h {minutes}m
                                                                        </div>
                                                                    </div>


                                                                    <div className="text-sm w-[40%] ml-5">
                                                                        <div
                                                                            className="font-bold">{flightScheduleDescs[v_LegDescs.ref - 1].arrival.time.substring(0, 5)} ({flightScheduleDescs[v_LegDescs.ref - 1].arrival.airport})
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                flightScheduleDescs[v_LegDescs.ref - 1].arrival.dateAdjustment == null ?
                                                                                    groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg].departureDate :
                                                                                    "+1"
                                                                            }
                                                                        </div>
                                                                        <div>Tarminal-{flightScheduleDescs[v_LegDescs.ref - 1].arrival.terminal}</div>
                                                                        <div>{arrivalAirport[0].name}</div>
                                                                    </div>

                                                                </div>

                                                                {indexLeg == 0 ?
                                                                    <div className="mb-5"></div> :
                                                                    <div
                                                                        className="mt-6 flex flex-row mx-[10%] md:mx-[22%] my-5">
                                                                        <div
                                                                            className="border-b border-gray-300 w-[5%] md:w-[15%] my-3"></div>
                                                                        <div
                                                                            className="bg-red-100 rounded-full px-2 md:px-4 py-1">Change
                                                                            plane at AMS |
                                                                            2h 55m layover
                                                                        </div>
                                                                        <div
                                                                            className="border-b border-gray-300 w-[5%] md:w-[15%] my-3"></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </> : <div><img className="my-5 mx-auto" width={100} height={100}
                                            src="/images/jachai-loder-2nds.gif"
                                            alt="Logo"/></div>
                        }
                        <div className="my-4 text-2xl font-bold md:mx-1 mx-[2%]">TODAY'S OFFERS
                        </div>

                        <div className="rounded-lg border border-gray-300 mt-5 md:mx-[4%] xl:mx-0">
                            <div
                                className="shadow-md border border-gray-300 rounded-md w-[95%] mx-auto my-5">
                                <div className="font-bold p-2">Point Promotion</div>

                                <div
                                    className="flex flex-row justify-between">
                                    <div className="w-[10%] md:w-[5%]">
                                        <img width={20} style={{marginTop: 2, marginLeft: 5}}
                                             height={20} src="/images/coin.png"
                                             alt="Logo"/>
                                    </div>
                                    <div
                                        className="flex flex-row justify-between w-[90%] md:w-[95%] mb-3">
                                        <div>Earn up to 872 point on this item</div>
                                        <div>
                                            <img width={10} style={{marginTop: 2, marginRight: 5}}
                                                 height={10} src="/images/arrow.png"
                                                 alt="Logo"/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/*promo code for desktop*/}
                            {searchArray20 != "undefined" ?
                                <></> :
                                <div className="d-none d-md-block">
                                    <div className="w-[95%] mx-auto my-5">
                                        <div className="font-bold">Product Promotion</div>
                                        <div className="flex flex-row w-[60%] justify-between">
                                            <div className="cursor-pointer" onClick={() => viewAllPromo("all")}>All
                                                Promotions
                                                ({allPromo.length})
                                            </div>
                                            <div className="cursor-pointer"
                                                 onClick={() => viewAllPromo("platform")}>Platform
                                                ({platformPromos1.length})
                                            </div>
                                            <div className="cursor-pointer"
                                                 onClick={() => viewAllPromo("seller")}>Seller
                                                ({sellerPromos1.length})
                                            </div>
                                            <div className="cursor-pointer"
                                                 onClick={() => viewAllPromo("partner")}>Partner
                                                ({partnerPromos1.length})
                                            </div>
                                        </div>
                                        <div className="border-b border-gray-300 w-[100%] mx-auto"></div>
                                        <div className="mt-5 flex flex-row space-x-2">
                                            {promoSlider > 0 ?
                                                <div className="absolute">
                                                    <div onClick={() => promoIncrementOrDecrement("prev")}
                                                         className="bg-white w-8 rounded-full shadow-md mt-7 ml-1 cursor-pointer md:w-9 px-1">
                                                        <i className="bi bi-arrow-left-short h3"></i>
                                                    </div>
                                                </div> : ""
                                            }
                                            {promoArray.map((v_promoCode, index) => {
                                                return (
                                                    <>
                                                        {checkSearchClickPromo === index ?
                                                            <div id="cupon_code_details"
                                                                 className="cursor-pointer border border-gray-300"
                                                                 onClick={() => promoCodeSearchCheck(index, v_promoCode.promoCode)}>
                                                                <div
                                                                    className="flex flex-row w-[100%] text-black">
                                                                    <div className="w-[75%]">
                                                                        <div className="pl-5 pt-1 font-bold">First
                                                                            Order Voucher
                                                                        </div>
                                                                        <div
                                                                            className="pl-5">{v_promoCode.message}</div>
                                                                    </div>
                                                                    <div
                                                                        className="w-[20%] text-center mt-5 h-5 bg-red-500 rounded-lg text-white">
                                                                        COLLECT
                                                                    </div>
                                                                </div>
                                                                <div className="text-center text-black">
                                                                    {v_promoCode.startFrom}
                                                                </div>
                                                            </div> :
                                                            <div id="cupon_code_details" className="cursor-pointer"
                                                                 onClick={() => promoCodeSearchCheck(index, v_promoCode.promoCode)}>
                                                                <div
                                                                    className="flex flex-row w-[100%] text-black">
                                                                    <div className="w-[75%]">
                                                                        <div className="pl-5 pt-1 font-bold">First
                                                                            Order Voucher
                                                                        </div>
                                                                        <div
                                                                            className="pl-5">{v_promoCode.message}</div>
                                                                    </div>
                                                                    <div
                                                                        className="w-[20%] text-center mt-5 h-5 bg-red-500 rounded-lg text-white">
                                                                        COLLECT
                                                                    </div>
                                                                </div>
                                                                <div className="text-center text-black">
                                                                    {v_promoCode.startFrom}
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                );
                                            })}

                                            {allPromo.length > 2 ?
                                                <>
                                                    {parseInt(allPromo.length) - parseInt(promoSlider + 3) > 0 ?
                                                        <div
                                                            className="absolute m-auto left-1/3 right-0 text-right">
                                                            <div onClick={() => promoIncrementOrDecrement("next")}
                                                                 className="bg-white w-8 rounded-full shadow-md mt-7 ml-[55%] md:ml-[67%] lg:ml-[49%] cursor-pointer px-1">
                                                                <i className="bi bi-arrow-right-short h3"></i>
                                                            </div>
                                                        </div> : ""
                                                    }
                                                </> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="mt-5 md:mx-[4%] xl:mx-0">
                                <div className="mx-[4%]">
                                    <div>Registered User list</div>
                                    <div>
                                        <select
                                            onChange={(e) => handelUser(e)}
                                            name="title"
                                            className="h-10 rounded-lg outline-0 border border-gray-300 w-[100%]">
                                            {userList.map((v_user, index) => (
                                                <option value={v_user.id}
                                                        key={index}>{v_user.mobileNumber} - {v_user.fullName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="md:mx-[4%] xl:mx-0 border border-gray-300 mt-5 rounded-lg space-x-2 flex flex-row px-5 py-3">
                            <div>
                                <img width={20} style={{marginTop: 15, marginLeft: 5}}
                                     height={20} src="/images/Promotions.png"
                                     alt="Logo"/>
                            </div>
                            <div className="pt-3">
                                Become a Jachai Membership to redeem coins
                                for discount on checkout
                            </div>
                        </div>


                        <div className="mt-5 font-bold text-lg md:mx-[4%] xl:mx-0">
                            Enter Traveller
                            Details: Please fill in with
                            valid information
                        </div>

                        {mainArray.map((v_mainArray, indexA) => (
                            <div
                                className="rounded-lg border border-gray-300 mt-5 md:mx-[4%] xl:mx-0">
                                <div className="flex flex-row mx-[3%] justify-between">
                                    <div className="flex flex-row">
                                        <div
                                            className="text-lg font-bold">Passenger {v_mainArray.sl + 1}
                                            {v_mainArray.check == 1 ?
                                                " Adult" :
                                                ""
                                            }
                                            {v_mainArray.check == 2 ?
                                                " Child" :
                                                ""
                                            }
                                            {v_mainArray.check == 3 ?
                                                " Infant" :
                                                ""
                                            }
                                        </div>
                                        <div className="pt-1 pl-2"> (Primary Contact)</div>
                                    </div>
                                    {v_mainArray.status == 1 ?
                                        <div onClick={() => mainArrayChange(indexA, v_mainArray.status)}
                                             className="bg-gray-200 w-auto h-6 px-1 rounded-full mt-2 cursor-pointer">
                                            <img width={15} style={{marginTop: 8}}
                                                 height={15} src="/images/arrow-down1.png"
                                                 alt="Logo"/>
                                        </div> :
                                        <div onClick={() => mainArrayChange(indexA, v_mainArray.status)}
                                             className="bg-gray-200 w-auto h-7 px-1 rounded-full mt-2 cursor-pointer">
                                            <img width={21} style={{marginTop: 2}}
                                                 height={21} src="/images/arrow-up1.png"
                                                 alt="Logo"/>
                                        </div>
                                    }
                                </div>
                                <div
                                    className="border-b border-gray-300 w-[100%] mx-auto my-6"></div>
                                {v_mainArray.status == 1 ?
                                    <div className="mx-[3%]" id="font_size">
                                        <div className="text-lg font-bold">Personal Details</div>
                                        <div className="text-sm">As mentioned on your passport or government
                                            approed
                                            IDs
                                        </div>
                                        <div
                                            className="grid grid-cols-1 md:grid-cols-4 gap-2 mt-5">
                                            <div>
                                                <div>Select Title</div>
                                                <select
                                                    className="h-10 rounded-lg outline-0 border border-gray-300 w-[100%]">
                                                    <option>Select One</option>
                                                    <option>MR</option>
                                                    <option>MRS</option>
                                                    <option>MSTR</option>
                                                    <option>MISS</option>
                                                </select>
                                            </div>
                                            <div>
                                                <div>Select Gender</div>
                                                <select
                                                    onChange={(e) => handelCheckPersonName(indexA, e, "Gender")}
                                                    className="h-10 rounded-lg outline-0 border border-gray-300 w-[100%]">
                                                    <option>Select One</option>
                                                    <option>M</option>
                                                    <option>F</option>
                                                    <option>U</option>
                                                </select>
                                            </div>
                                            <div>
                                                <div>First Name</div>
                                                <div><input
                                                    onChange={(e) => handelCheckPersonName(indexA, e, "GivenName")}
                                                    type="text" placeholder="First Name"
                                                    className="h-10 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div>Last Name</div>
                                                <div><input
                                                    onChange={(e) => handelCheckPersonName(indexA, e, "Surname")}
                                                    type="text" placeholder="Last Name"
                                                    className="h-10 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
                                            <div>
                                                <div>Date of Birth</div>
                                                <div>
                                                    <input
                                                        onChange={(e) => handelCheckPersonName(indexA, e, "DateOfBirth")}
                                                        placeholder="Date of Birth" type="date"
                                                        className="h-10 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div>Issue Country</div>
                                                <select
                                                    onChange={(e) => handelCheckPersonName(indexA, e, "IssueCountry")}
                                                    name="title"
                                                    className="h-10 rounded-lg outline-0 border border-gray-300 w-[100%]">
                                                    {country.map((v_country, index) => (
                                                        <option key={index}>{v_country.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <div>Nationality Country</div>
                                                <select
                                                    onChange={(e) => handelCheckPersonName(indexA, e, "NationalityCountry")}
                                                    name="title"
                                                    className="h-10 rounded-lg outline-0 border border-gray-300 w-[100%]">
                                                    {country.map((v_country, index) => (
                                                        <option key={index}>{v_country.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="text-lg font-bold mt-5">Contact Details</div>
                                        <div className="text-sm">Receive booking confirmation & Updates</div>
                                        <div
                                            className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
                                            <div>
                                                <div>Email</div>
                                                <div><input
                                                    onChange={(e) => handelCheckEmail(indexA, e, "Address")}
                                                    type="text" placeholder="Enter your email"
                                                    className="h-10 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div>Phone Number</div>
                                                <div><input
                                                    onChange={(e) => handelCheckPhone(indexA, e, "Phone")}
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    className="h-10 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-lg font-bold mt-5">Passport Information
                                        </div>
                                        <div className="text-sm">Passport should be valid for 6 months from
                                            the
                                            date
                                            of
                                            entry
                                        </div>
                                        <div
                                            className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
                                            <div>
                                                <div>Passport Number</div>
                                                <div><input
                                                    onChange={(e) => handelCheckPersonName(indexA, e, "Number")}
                                                    type="text" placeholder="Enter your Passport Number"
                                                    className="h-10 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div>Passport Expiry Date</div>
                                                <div><input
                                                    onChange={(e) => handelCheckPersonName(indexA, e, "ExpirationDate")}
                                                    type="date" placeholder="Enter your Passport Expiry Date"
                                                    className="h-10 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
                                            <div>
                                                <div>Passport Copy (max 2MB)</div>
                                                <div><input type="file" placeholder="Enter your Passport Number"
                                                            className="h-10 pt-1 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div>Visa Copy (max 2MB)</div>
                                                <div><input type="file"
                                                            placeholder="Enter your Passport Expiry Date"
                                                            className="h-10 pt-1 outline-0 pl-3 rounded-lg border border-gray-300 w-[100%]"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-x-2 mt-5">
                                            <div><input className="form-check-input" type="radio"
                                                        name="flexRadioDefault"/></div>
                                            <div>Save this to my traveler list</div>
                                        </div>
                                    </div> : <></>
                                }
                            </div>
                        ))}


                        <div
                            className="bg-yellow-100 md:mx-[4%] xl:mx-0 border border-gray-300 mt-5 rounded-lg justify-between flex flex-row px-5 py-3">
                            <div>Choose Your Seats</div>
                            <div><i className="bi bi-caret-right"></i></div>
                        </div>

                        <div className="rounded-lg border border-gray-300 mt-5 md:mx-[4%] xl:mx-0">
                            <div className="mx-[3%] mb-10">
                                <div>Add-Ons</div>
                                <div
                                    className="bg-white border border-gray-300 mt-5 rounded-lg justify-between flex flex-row px-5 py-3">
                                    <div>Add Extra Baggage</div>
                                    <div><img width={22}
                                              height={22} src="/images/Check.png"
                                              alt="Logo"/></div>
                                </div>
                                <div
                                    className="bg-white border border-gray-300 mt-5 rounded-lg justify-between flex flex-row px-5 py-3">
                                    <div>Sepcial Service Request</div>
                                    <div><img width={22}
                                              height={22} src="/images/arrow-right.png"
                                              alt="Logo"/></div>
                                </div>
                                <div
                                    className="bg-white border border-gray-300 mt-5 rounded-lg justify-between flex flex-row px-5 py-3">
                                    <div>Lost Baggage Protection</div>
                                    <div><img width={22}
                                              height={22} src="/images/arrow-right.png"
                                              alt="Logo"/></div>
                                </div>
                                <div
                                    className="bg-white border border-gray-300 mt-5 rounded-lg justify-between flex flex-row px-5 py-3">
                                    <div>Travel Insurance</div>
                                    <div><img width={22}
                                              height={22} src="/images/arrow-right.png"
                                              alt="Logo"/></div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="bg-white md:mx-[4%] xl:mx-0 border border-gray-300 mt-5 rounded-lg justify-between flex flex-row px-5 py-3">
                            <div>Fare Summary</div>
                            <div><i className="bi bi-caret-down"></i></div>
                        </div>
                        {loader ?
                            <div><img className="my-5 mx-auto" width={100} height={100}
                                      src="/images/jachai-loder-2nds.gif"
                                      alt="Logo"/></div> :
                            <div onClick={() => create_pnr()}
                                 className="bg-red-500 md:mx-[4%] xl:mx-0 text-center rounded-md text-white mt-5 p-2 cursor-pointer">
                                Confirm Booking
                            </div>
                        }
                    </div>


                    {/*right side*/}
                    <div className="w-[25%] h-auto d-none d-md-block text-sm" id="font_size">
                        <div className="rounded-lg border border-gray-300 space-y-2">
                            <div className="text-2xl text-center">Book before it sold out</div>
                            <div className="flex flex-row gap-x-2 mx-auto w-20">
                                00:00
                            </div>
                            <div className="flex flex-row gap-x-2 mx-auto w-20">
                                <div className="px-2">min</div>
                                <div></div>
                                <div>sec</div>
                            </div>
                        </div>
                        <div className="rounded-lg border border-gray-300 mt-5">
                            <div className="mx-[4%]">
                                <div className="text-lg font-bold">Flight</div>
                                <div
                                    className="flex flex-row justify-between w-[80%] mt-5 mx-auto">
                                    <div>
                                        <img width={80}
                                             height={80} src="/images/flight.png"
                                             alt="Logo"/>
                                    </div>
                                    <div>
                                        <div>One-Way</div>
                                        {flightLegs.map((v_legs1, indexLeg1) => {
                                            const dep_and_ari = groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg1]
                                            return (
                                                <div>{dep_and_ari.departureLocation} - {dep_and_ari.arrivalLocation}</div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="border-b border-gray-300 w-[90%] mx-auto my-6"></div>
                                <div className="text-lg font-bold">Fare Summary</div>

                                {/*{*/}
                                {/*    searchArray11 > 0 ?*/}
                                {/*        <div>Child ({searchArray11} Traveler)</div> : ""*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    searchArray12 > 0 ?*/}
                                {/*        <div>Infant ({searchArray12} Traveler)</div> : ""*/}
                                {/*}*/}


                                {fareSummaryByTravelers.map((v_summary, index) => (
                                    <div key={index} className="mb-5">
                                        <div>
                                            {
                                                v_summary?.passengerInfo?.passengerType == "ADT" ?
                                                    "Adult " : ""
                                            }
                                            {
                                                v_summary?.passengerInfo?.passengerType == "C09" ?
                                                    "Children " : ""
                                            }
                                            {
                                                v_summary?.passengerInfo?.passengerType == "INF" ?
                                                    "Infant " : ""
                                            }
                                            ({v_summary?.passengerInfo?.passengerNumber} Traveler)
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <div>Base Fare</div>
                                            <div>BDT {v_summary?.passengerInfo?.passengerTotalFare?.baseFareAmount}</div>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <div>Tax</div>
                                            <div>BDT {v_summary?.passengerInfo?.passengerTotalFare?.totalTaxAmount}</div>
                                        </div>
                                    </div>
                                ))}

                                <div className="border-b border-gray-300 w-[90%] mx-auto my-6"></div>
                                <div className="flex flex-row justify-between">
                                    <div>Sub Total</div>
                                    <div>BDT {fareSummary.subTotalAmount}</div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Discount</div>
                                    <div>BDT {fareSummary.totalDiscount}</div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Promo Discount</div>
                                    <div
                                        className="bg-gray-200 rounded-lg xl:px-1 lg:px-0.5">{fareSummary.promoCode}</div>
                                    <div>BDT {fareSummary.promoDiscount}</div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Service Fee</div>
                                    <div>BDT 0.00</div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Convenience Charge</div>
                                    <div>BDT {fareSummary.convenienceCharge}</div>
                                </div>
                            </div>
                            <div className="bg-red-200 rounded-b-lg">
                                <div className="mx-[4%]">
                                    <div className="flex flex-row justify-between pt-3">
                                        <div>You Pay (for {totalTravelers} Traveler)</div>
                                        <div className="font-bold text-lg">BDT {fareSummary.total}</div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div>You Save</div>
                                        <div>BDT {fareSummary.totalDiscount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingPreview;