import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { countyList, getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../utils/services";
import AddGroupTicket from "./AddGroupTicket";

import { AddBtn,ManualAddBtn, Loader, Pagination } from "../common";
import moment from "moment";
import { Link } from "react-router-dom";

const GroupTicketList = (): ReactElement => {
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(10);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedGroupTicket, setSelectedGroupTicket] = useState(undefined);
  const [selectedGroupTicketForEdit, setSelectedGroupTicketForEdit] =
    useState(undefined);
  const [groupTicketId, setGroupTicketId] = useState<any>();
  const [originAirportCode, setOriginAirportCode] = useState<any>();
  const [destinationAirportCode, setDestinationAirportCode] = useState<any>();
  const [groupName, setGroupName] = useState<any>();
  const [countryCode, setCountryCode] = useState<any>();
  const [groupTicketCode, setGroupTicketCode] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const isDeleted = "false";
  const [groupTicketOptions, setGroupTicketOptions] = useState({
    loading: false,
    list: [],
  });
  const [groupTicketCodeOptions, setGroupTicketCodeOptions] = useState({
    loading: false,
    list: [],
  });
  const [airportCodeOptions, setAirportCodeOptions] = useState({
    loading: false,
    list: [],
  });
  const [destinationAirportOptions, setDestinationAirportOptions] = useState({
    loading: false,
    list: [],
  });
  const [countryOptions, setCountryOptions] = useState({
    loading: false,
    list: [],
  });

  const [groupTicketData, setGroupTicketData] = useState<any>({
    loading: false,
    data: null,
  });

  const getGroupTickets = useCallback(async () => {
    setGroupTicketData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    axios
      .get(
        `${encodedUri}/admin/group-ticket/?page=0&limit=20` +
          (groupTicketId ? `&name=${groupTicketId}` : ``) +
          (originAirportCode ? `&originAirportCode=${originAirportCode}` : ``) +
          (destinationAirportCode
            ? `&destinationAirportCode=${destinationAirportCode}`
            : ``) +
          (isActive ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setGroupTicketData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setGroupTicketData({ loading: false, data: [] });
        console.error("GroupTicket: Error", err);
      });
  }, [
    type,
    groupTicketId,
    originAirportCode,
    destinationAirportCode,
    isActive,
    limit,
    page,
  ]);

  useEffect(() => {
    getGroupTickets();
  }, [getGroupTickets]);

  const onClose = () => {
    getGroupTickets();
    setVisible(undefined);
    setSelectedGroupTicket(undefined);
    setSelectedGroupTicketForEdit(undefined);
  };

  const getGroupTicketOptions = useCallback(async (key?: string) => {
    setGroupTicketOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/group-ticket/?page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setGroupTicketOptions({
      loading: false,
      list: res?.data?.groupTickets?.map((ticket: any) => ({
        label: ticket.name,
        value: ticket.name,
      })),
    });
  }, [limit,page]); 

  const getAirportListOptions = useCallback(async (key?: string) => {
    setAirportCodeOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/airport/get-all?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAirportCodeOptions({
      loading: false,
      list: res?.data?.airports?.map((airport: any) => ({
        label: airport.code.concat(" - ", airport.name),
        value: airport.code,
      })),
    });
  }, [limit,page]);

  const getDestionationAirportListOptions = useCallback(
    async (key?: string) => {
      setDestinationAirportOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/airport/get-all?page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDestinationAirportOptions({
        loading: false,
        list: res?.data?.airports?.map((airport: any) => ({
          label: airport.code.concat(" - ", airport.name),
          value: airport.code,
        })),
      });
    },
    [limit,page]
  );

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/group-ticket/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getGroupTickets();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "groupTicket") getGroupTicketOptions(value);
        if (field === "airport") getAirportListOptions(value);
        if (field === "destinationAirport")
          getDestionationAirportListOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getGroupTicketOptions,
    getAirportListOptions,
    getDestionationAirportListOptions,
  ]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };

  const reseAllFieldData = () => {
    setGroupTicketId("");
    setOriginAirportCode("");
    setDestinationAirportCode("");
    setIsActive("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getGroupTicketOptions("");
      getAirportListOptions("");
      getDestionationAirportListOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title={`Group Ticket List`}
        subTitle={`${
          groupTicketData?.data?.totalElements ||
          groupTicketData?.data?.airports?.length ||
          0
        } Group Ticket(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => navigate("/group-ticket/search")} key={2} />,
          <ManualAddBtn onClick={() => navigate("/group-ticket/create")} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="vertical" form={form} className={styles.formInline}>
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <Form.Item label="Airport Name" name="airport_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Name"
                  optionFilterProp="children"
                  onChange={(val) => setGroupTicketId(val)}
                  onSearch={(e) => handleSearch(e, "groupTicket")}
                  filterOption={() => {
                    return true;
                  }}
                  options={groupTicketOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item label="Origin Airport Code" name="originAirportCode">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Origin Airport Code"
                  optionFilterProp="children"
                  onChange={(val) => setOriginAirportCode(val)}
                  onSearch={(e) => handleSearch(e, "airport")}
                  filterOption={() => {
                    return true;
                  }}
                  options={airportCodeOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                label="Destination Airport Code"
                name="destinationAirportCode"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Destination Airport Code"
                  optionFilterProp="children"
                  onChange={(val) => setDestinationAirportCode(val)}
                  onSearch={(e) => handleSearch(e, "destinationAirport")}
                  filterOption={() => {
                    return true;
                  }}
                  options={destinationAirportOptions?.list}
                ></Select>
              </Form.Item>

              <Form.Item
                label="Status"
                name="deleted_search"
                initialValue={
                  isActive == "true"
                    ? "ACTIVE"
                    : isActive == "false"
                    ? "INACTIVE"
                    : "ALL"
                }
              >
                <Select
                  showSearch
                  placeholder="Status"
                  onChange={(val) => setIsActive(val as string)}
                >
                  <Select.Option value={undefined}>ALL</Select.Option>
                  <Select.Option value={"true"} title="ACTIVE">
                    ACTIVE
                  </Select.Option>
                  <Select.Option value={"false"} title="INACTIVE">
                    INACTIVE
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Form>

          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {groupTicketData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col">Group Name</th>
                      <th scope="col">Career Code</th>
                      <th scope="col">Shop Name</th>
                      <th scope="col">Departure Date</th>
                      <th scope="col">Departure Time</th>
                      <th scope="col">Arrival Date</th>
                      <th scope="col">Arrival Time</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">TP</th>
                      <th scope="col">MRP</th>
                      <th scope="col">Sale Price</th>
                      <th scope="col">Operation Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {groupTicketData?.data?.groupTickets?.length ? (
                      groupTicketData?.data?.groupTickets?.map(
                        (groupTicket: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            // onClick={() =>
                            //   navigate(`/airports/${airport?.id}/details`)
                            // }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {groupTicket?.name}
                              </span>
                            </td>
                            <td>{groupTicket?.careerCode}</td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {groupTicket?.shop?.name?.slice(0, 50)}
                              </span>
                              <span className="font-medium text-gray-500">
                                {groupTicket?.shop?.mobileNumber}
                              </span>
                              </td>
                            <td>
                              <small>{groupTicket?.departureDate}</small>
                            </td>
                            <td>
                              <small>{groupTicket?.departureTime}</small>
                            </td>
                            <td>
                              <small>{groupTicket?.arrivalDate}</small>
                            </td>
                            <td>
                              <small>{groupTicket?.arrivalTime}</small>
                            </td>
                            <td>
                              <small>{groupTicket?.availableTicket}</small>
                            </td>
                            <td>
                              <small>{groupTicket?.tp}</small>
                            </td>
                            <td>
                              <small>{groupTicket?.mrp}</small>
                            </td>
                            <td>
                              <small>{groupTicket?.price}</small>
                            </td>
                            {/* <td>
                              {groupTicket?.isActive == true && (
                                <span className="border p-1 px-5 rounded-2xl bg-white">
                                  Active
                                </span>
                              )}
                              {groupTicket?.isActive == false && (
                                <span className="border p-1 px-5 rounded-2xl bg-white text-red-600">
                                  Inactive
                                </span>
                              )}
                            </td> */}
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={groupTicket?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(groupTicket?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  groupTicket?.id === confirmLoading
                                }
                              />
                            </td>

                            <td>
                              <Link
                                title="Edit Trick"
                                to={`/group-ticket/${groupTicket?.id}/edit`}
                                // target="_blank"
                                className="py-2 px-4 rounded-full m-0 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                              >
                                <EditOutlined />
                              </Link>

                              {/* <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedGroupTicketForEdit(groupTicket);
                                }}
                              > 
                                <EditOutlined />
                              </Button> */}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...groupTicketData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default GroupTicketList;
