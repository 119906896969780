import {
  IdcardOutlined,
  SolutionOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../../../utils/services";

const ShopSidebar = ({ campaignId }: any): ReactElement => {
  const pathname = window.location?.pathname;

  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 h-full">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/details"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/promotion/campaign/${campaignId}/details`}>
                General Information
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ShopSidebar;
