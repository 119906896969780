import { Button, Empty, Pagination, Popconfirm } from "antd";
import { useEffect, useState, useCallback } from "react";
import { responseNotification } from "../../utils/notify";
import { DeleteOutlined } from "@ant-design/icons";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import React from "react";
import axios from "axios";
import { Loader } from "../common";
import { isAllowedService } from "../../utils/services";
import { getPage } from "../../utils";

const TagShopList = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [shopList, setShopList] = useState<any>(undefined);
  const withProduct = false;

  const getTagShopList = useCallback(async () => {
    setShopList({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

    axios
      .get(
        `${encodedUri}/admin/tag-shop/by-tag?tagId=${visibleData}&withProduct=${withProduct}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setShopList({ loading: false, data: res.data });
      })
      .catch((err) => {
        setShopList({ loading: false, data: [] });
        console.error("Product: Error", err);
      });
  }, [visibleData]);

  const onTagRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/tag-shop`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tagIds: [visibleData],
          shopId: id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Shop Remove Successfully", "success");
            getTagShopList();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getTagShopList();
  }, [getTagShopList, onCloseMethod]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {shopList?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {shopList?.data?.shops?.length ? (
                      shopList?.data?.shops?.map((shop: any, index: any) => (
                        <tr className="border-t hover:bg-gray-100" key={index}>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {shop?.name}
                            </span>
                          </td>
                          <td>
                            <div className="flex gap-2">
                              {isAllowedService(`Tags Delete`) && (
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to shop remove?"
                                  visible={shop?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    console.log(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onTagRemove(shop?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(shop?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...shopList?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default TagShopList;
