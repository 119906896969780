import {
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Select,
  Switch,
  TimePicker,
} from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import { useSelector } from "react-redux";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useNavigate, useParams } from "react-router";
import SubmitResetBtn from "../common/SubmitBtn";
import { debounce } from "lodash";
import { baggageAllowanceUnit } from "../../utils";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DownOutlined } from "@ant-design/icons";
import type { Dayjs } from "dayjs";
const format = "HH:mm:ss";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const AddGroupTicket = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [departureTime, setDepartureTime] = useState("12:08:00");
  const [arrivalTime, setArrivalTime] = useState('12:08:00');

  const route = useParams();
  const navigate = useNavigate();
  const [value,onChange] = useState<Value>(new Date());
  const [arrivalDate, setArrivalDate] = useState<DatePickerProps["value"]>();
  dayjs.extend(customParseFormat);

  const onChangeArrival = (arrivalTime: Dayjs, timeString: string) => {
    console.log(arrivalTime, timeString);
  };
  const onChange2 = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
  };
  const onChangeArrivalDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setArrivalDate(dateString);
  };

  // const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  //   console.log(date);
  // };
  const [departureDate, setDepartureDate] =
    useState<DatePickerProps["value"]>();
  const onChangeDepartureDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setDepartureDate(dateString);
  };
  // const [arrivalTime, setArrivalTime] = useState<any>("00:00:00");
  //console.log("get Arrival Time", arrivalTime);

  // const [departureTime, setDepartureTime] = useState<any>("00:00:00");
  const [baggageAllowanceUnitName, setBaggageAllowanceUnitName] =
    useState("KG");
  const [currencyName, setCurrencyName] = useState("BDT");
  const [passengerType, setPassengerType] = useState("");
  const [travelerClass, setTravelerClass] = useState("");
  const [wayType, setWayType] = useState("");
  const [careerCode, setCareerCode] = useState<any>("");
  const [shopId, setShopId] = useState<string>();

  const [destinationCountryCode, setDestinationCountryCode] =
    useState<string>();
  const [originCountryCode, setOriginCountryCode] = useState<string>();
  const [destinationLocationCode, setDestinationLocationCode] =
    useState<string>();
  const [originLocationCode, setOriginLocationCode] = useState<string>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [selectedArrivalTime, setSelectedArrivalTime] = useState("00:00:00");

  const [selectedDepartureTime, setSelectedDepartureTime] =
    useState<any>("00:00:00");
  const [startAt, setStartAt] = useState<any>("00:00");
  const [endAt, setEndAt] = useState<any>("00:00");
  const [careerOptions, setCareerOptions] = useState<any>({
    list: [],
    loading: false, 
  });
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [destinationCountryOptions, setDestinationCountryOptions] =
    useState<any>({
      list: [],
      loading: false,
    });
  const [originCountryOptions, setOriginCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [destinationLocationOptions, setDestinationLocationOptions] =
    useState<any>({
      list: [],
      loading: false,
    });
  const [originLocationOptions, setOriginLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [error, setError] = useState("");
  const [groupTicketData, setGroupTicketData] = useState<any>({
    loading: false,
    data: null,
  });

  const [currentRecord, setCurrentRecord] = useState();
  useEffect(() => {
    if (currentRecord) {
      const formattedBirthday = dayjs(currentRecord.birthday)
        .tz("Asia/Dhaka")
        .format("YYYY-MM-DD");

      setCurrentRecord({
        ...currentRecord,
        birthday: formattedBirthday,
      });

      form.setFieldsValue({
        birthday: formattedBirthday, // removed moment
      });
    }
  }, [currentRecord, setCurrentRecord, form]);
  //console.log("group Ticket Data ::",groupTicketData);

  const getShopsOptions = useCallback(async (val?: string) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=JC_COMMERCE&page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);

  const getDestinationCountryOptions = useCallback(async (val?: string) => {
    setDestinationCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_BOOKING_API}/country/all?page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setDestinationCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((countrie: any) => {
        return {
          value: countrie?.code,
          label: countrie?.name,
        };
      }),
    });
  }, []);
  const getOriginCountryOptions = useCallback(async (val?: string) => {
    setOriginCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_BOOKING_API}/country/all?page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setOriginCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((countrie: any) => {
        return {
          value: countrie?.code,
          label: countrie?.name,
        };
      }),
    });
  }, []);
  const getDestinationLocationOptions = useCallback(async (val?: string) => {
    setDestinationLocationOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_BOOKING_API}/admin/airport/get-all?page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setDestinationLocationOptions({
      loading: false,
      list: res?.data?.airports?.map((airport: any) => {
        return {
          value: airport?.code,
          label: airport?.name,
        };
      }),
    });
  }, []);
  const getOriginLocationOptions = useCallback(async (val?: string) => {
    setOriginLocationOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_BOOKING_API}/admin/airport/get-all?page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setOriginLocationOptions({
      loading: false,
      list: res?.data?.airports?.map((airport: any) => {
        return {
          value: airport?.code,
          label: airport?.name,
        };
      }),
    });
  }, []);
  const fetchGroupTicketDetails = useCallback((ticketId: any) => {
    if (ticketId) {
      try {
        setGroupTicketData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_BOOKING_API}/admin/group-ticket/details?id=${ticketId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setGroupTicketData({
                loading: false,
                data: data?.data?.groupTicket,
              });
            } else {
              setGroupTicketData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setGroupTicketData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setGroupTicketData({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  const getCareerOptions = useCallback(async (key?: any) => {
    setCareerOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_BOOKING_API}/admin/career?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCareerOptions({
      loading: false,
      list: res?.data?.careers?.map((career: any) => {
        return {
          value: career?.careerCode,
          label: career?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getCareerOptions();
  }, [getCareerOptions]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "career") getCareerOptions(value);
          if (field === "shop") getShopsOptions(value);
          if (field === "destinationCountry")
            getDestinationCountryOptions(value);
          if (field === "destinationLocation")
            getDestinationLocationOptions(value);
          if (field === "originCountry") getOriginCountryOptions(value);
          if (field === "originLocation") getOriginLocationOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getCareerOptions,
    getShopsOptions,
    getDestinationCountryOptions,
    getDestinationLocationOptions,
    getOriginCountryOptions,
    getOriginLocationOptions,
    type,
  ]);
  useEffect(() => {
    getShopsOptions();
    getDestinationCountryOptions();
    getDestinationLocationOptions();
    getOriginCountryOptions();
    getOriginLocationOptions();
  }, [
    getShopsOptions,
    getDestinationCountryOptions,
    getDestinationLocationOptions,
    getOriginCountryOptions,
    getOriginLocationOptions,
  ]);
  //   useEffect(() => {
  //     if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
  //       form?.submit();
  //     }
  //   }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      arrivalDate: arrivalDate,
      arrivalTime: arrivalTime,
      baggageAllowance: data.baggageAllowance,
      baggageAllowanceUnitName: baggageAllowanceUnitName,
      cabinBaggageAllowance: data.cabinBaggageAllowance,
      careerCode: careerCode,
      currencyName: currencyName,
      departureDate: departureDate,
      departureTime: departureTime,
      destinationCountryCode: destinationCountryCode,
      destinationLocationCode: {
        LocationCode: destinationLocationCode,
      },
      isActive: true,
      name: data.name,
      originCountryCode: originCountryCode,
      originLocationCode: {
        LocationCode: originLocationCode || "Null",
      },
      passengerType: passengerType,
      pnrCode: data.pnrCode,
      price: data.price,
      availableTicket: data.availableTicket,
      refundable: data.refundable,
      shopId: shopId,
      travelerClass: travelerClass,
      wayType: wayType,
    };

    if (groupTicketData.data) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/group-ticket/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: groupTicketData?.data?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Group Ticket Updated Successfully",
              "success"
            );
            form.resetFields();
            navigate("/group-ticket");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/group-ticket/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          status: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Ticket Create Successfully", "success");
            form.resetFields();
            navigate("/group-ticket");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    fetchGroupTicketDetails((route as any)?.ticketId);
  }, [fetchGroupTicketDetails]);

  useEffect(() => {
    if (groupTicketData?.data) {
      form.resetFields();
      // setArrivalDate(groupTicketData?.data?.arrivalDate);
      setDepartureDate(groupTicketData?.data?.departureDate);
      // setArrivalTime(groupTicketData?.data?.arrivalTime);
      // setDepartureTime(groupTicketData?.data?.departureTime);
      setSelectedArrivalTime(groupTicketData?.data?.arrivalTime || "00:00:00");
      setSelectedDepartureTime(
        groupTicketData?.data?.departureTime || "00:00:00"
      );
      setBaggageAllowanceUnitName(
        groupTicketData?.data?.baggageAllowanceUnitName
      );
      setCurrencyName(groupTicketData?.data?.currencyName);
      setPassengerType(groupTicketData?.data?.passengerType);
      setTravelerClass(groupTicketData?.data?.travelerClass);
      setWayType(groupTicketData?.data?.wayType);
    }
  }, [groupTicketData]);

  const resetData = () => {
    form?.resetFields();
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={groupTicketData?.data ? "Edit Group Ticket" : "Add Group Ticket"}
        extra={""}
      />

      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          hideRequiredMark
          initialValues={{
            ...groupTicketData?.data,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <div className="col-span-2">
              <Form.Item
                hasFeedback
                label="Group Name"
                rules={[
                  {
                    required: true,
                    message: "Group Name is Required!",
                  },
                ]}
                name="name"
              >
                <Input id="name" type="text" placeholder="Enter Group Name" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Departure Date"
                rules={[
                  {
                    required: false,
                    message: "Departure Date is Required!",
                  },
                ]}
                name="departureDate"
              >
                <DatePicker
                  id="startFrom"
                  placeholder="Enter Starting Date"
                  onChange={onChangeDepartureDate}
                  style={{ minWidth: "100%" }}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Departure Time"
                rules={[
                  {
                    required: true,
                    message: "Departure Time is Required!",
                  },
                ]}
                name="departureTime"
              >
                <TimePicker
                  format={format}
                  value={moment(departureTime, format)}
                  placeholder="10:20:00"
                  // onChange?: ((value: moment.Moment | null, dateString: string) => void) | undefined
                  onChange={(value, dateString) => {
                    console.log("Time", value, dateString);
                    setDepartureTime(dateString);
                  }}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Arrival Date"
                rules={[
                  {
                    required: false,
                    message: "Arrival Date is Required!",
                  },
                ]}
                name="arrivalDate"
              >
                <DatePicker
                  id="arrivalDate"
                  //onChange={onChange}
                  placeholder="Enter Arrival Date"
                  onChange={onChangeArrivalDate}
                  style={{ minWidth: "100%" }}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label={`Arrival Time`}
                rules={[
                  {
                    required: false,
                    message: "Arrival Time is Required!",
                  },
                ]}
                name="arrivalTime"
              >
                      <TimePicker
            format={format}
            value={moment(arrivalTime, format)}
            placeholder='12:10:00'
            onChange={(value, dateString) => {
                console.log('Time', value, dateString);
                setArrivalTime(dateString);
            }}
        />
              </Form.Item>
            </div>

            <div className="">
              <Form.Item
                hasFeedback
                label="Baggage Allowance"
                rules={[
                  {
                    required: false,
                    message: "Baggage Allowance is Required!",
                  },
                ]}
                name="baggageAllowance"
              >
                <Input
                  id="baggageAllowance"
                  type="text"
                  placeholder="Enter Baggage Allowance"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Baggage Allowance Unit Name"
                name="baggageAllowanceUnitName"
                rules={[
                  {
                    required: true,
                    message: "Baggage Allowance Unit Name is Required!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Baggage Allowance Unit Name"
                  onChange={(val) => setBaggageAllowanceUnitName(val)}
                >
                  {Object.values(baggageAllowanceUnit)?.map((type, i) => (
                    <Option value={type} key={i}>
                      {type?.split("_").join(" ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="">
              <Form.Item
                hasFeedback
                label="Cabin Baggage Allowance"
                rules={[
                  {
                    required: false,
                    message: "Cabin Baggage Allowance is Required!",
                  },
                ]}
                name="cabinBaggageAllowance"
              >
                <Input
                  id="cabinBaggageAllowance"
                  type="text"
                  placeholder="Enter Cabin Baggage Allowance"
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Career Code"
                rules={[
                  {
                    required: true,
                    message: "Career Code is required!",
                  },
                ]}
                name="careerCode"
              >
                <Select
                  showSearch
                  placeholder="Select Career Code"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "career");
                  }}
                  onChange={(val) => setCareerCode(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={careerOptions?.list}
                ></Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Currency Name"
                rules={[
                  {
                    required: false,
                    message: "Currency Name is Required!",
                  },
                ]}
                name="currencyName"
                initialValue={currencyName}
              >
                <Select
                  options={[
                    { value: null, label: "NONE" },
                    { value: "BDT", label: "BDT" },
                  ]}
                  placeholder="Enter Currency Name"
                  onChange={(val) => setCurrencyName(val)}
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Passenger Type"
                rules={[
                  {
                    required: true,
                    message: "Passenger Type is Required!",
                  },
                ]}
                name="passengerType"
                initialValue={passengerType}
              >
                <Select
                  options={[
                    { value: null, label: "NONE" },
                    { value: "ADT", label: "ADULT - ADT" },
                    { value: "C09", label: "CHILD - C09" },
                    { value: "INF", label: "INFANT - INF" },
                  ]}
                  placeholder="Enter Passenger Type"
                  onChange={(val) => setPassengerType(val)}
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Price"
                rules={[
                  {
                    required: true,
                    message: "Price is Required!",
                  },
                ]}
                name="price"
              >
                <Input id="price" type="number" placeholder="Enter Price" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Available Ticket"
                rules={[
                  {
                    required: true,
                    message: "Available Ticket is Required!",
                  },
                ]}
                name="availableTicket"
              >
                <Input
                  id="availableTicket"
                  type="number"
                  placeholder="Enter Available Ticket"
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="Refundable?"
                name="refundable"
                className="mr-5"
              >
                <Switch
                  checkedChildren={`Yes`}
                  unCheckedChildren={`NO`}
                  defaultChecked={(groupTicketData as any)?.data?.refundable}
                />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label="PNR Code"
                rules={[
                  {
                    required: false,
                    message: "PNR Code is Required!",
                  },
                ]}
                name="pnrCode"
              >
                <Input id="pnrCode" type="text" placeholder="Enter PNR Code" />
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label={`Organization`}
                rules={[
                  {
                    required: false,
                    message: "Organization is required!",
                  },
                ]}
                name="shopId"
              >
                <Select
                  showSearch
                  placeholder="Select Organization"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "organization");
                  }}
                  onChange={(val) => setShopId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={shopsOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label={`Destination Country Code`}
                rules={[
                  {
                    required: false,
                    message: "Destination Country Code is required!",
                  },
                ]}
                name="destinationCountryCode"
              >
                <Select
                  showSearch
                  placeholder="Select Destination Country Code"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "destinationCountry");
                  }}
                  onChange={(val) => setDestinationCountryCode(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={destinationCountryOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label={`Destination Location Code`}
                rules={[
                  {
                    required: false,
                    message: "Destination Location Code is required!",
                  },
                ]}
                name="destinationLocationCode"
              >
                <Select
                  showSearch
                  placeholder="Select Destination Location Code"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "destinationLocation");
                  }}
                  onChange={(val) => setDestinationLocationCode(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={destinationLocationOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label={`Origin Country Code`}
                rules={[
                  {
                    required: false,
                    message: "Origin Country Code is required!",
                  },
                ]}
                name="originCountryCode"
              >
                <Select
                  showSearch
                  placeholder="Select Origin Country Code"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "originCountry");
                  }}
                  onChange={(val) => setOriginCountryCode(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={originCountryOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div className="">
              <Form.Item
                hasFeedback
                label={`Origin Location Code`}
                rules={[
                  {
                    required: false,
                    message: "Origin Location Code is required!",
                  },
                ]}
                name="originLocationCode"
              >
                <Select
                  showSearch
                  placeholder="Select Origin Location Code"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "originLocation");
                  }}
                  onChange={(val) => setOriginLocationCode(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={originLocationOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Traveler Class"
                rules={[
                  {
                    required: true,
                    message: "Traveler Class is Required!",
                  },
                ]}
                name="travelerClass"
                initialValue={travelerClass}
              >
                <Select
                  options={[
                    { value: null, label: "NONE" },
                    { value: "Y", label: "ECONOMY - Y" },
                    { value: "J", label: "BUSSINESS - J" },
                    { value: "F", label: "FIRST Class - F" },
                  ]}
                  placeholder="Enter Traveler Class"
                  onChange={(val) => setTravelerClass(val)}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Way Type"
                rules={[
                  {
                    required: false,
                    message: "Way Type is Required!",
                  },
                ]}
                name="wayType"
                initialValue={wayType}
              >
                <Select
                  options={[
                    { value: null, label: "NONE" },
                    { value: "ONE_WAY", label: "ONE WAY" },
                    { value: "ROUND_WAY", label: "ROUND WAY" },
                  ]}
                  placeholder="Enter Way Type"
                  onChange={(val) => setWayType(val)}
                />
              </Form.Item>
            </div>
          </div>
          <SubmitResetBtn disabled={loading || !!error} loading={loading} />
        </Form>
      </div>
    </>
  );
};

export default AddGroupTicket;
