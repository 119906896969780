import MainLayout from "../../components/Layouts";
import MerchantList from "../../components/Merchant";

const MerchantPage = () => {
  return (
    <MainLayout>
      <MerchantList />
    </MainLayout>
  );
};

export default MerchantPage;
