export const regions = {
  City: [
    "Dhaka",
    "Chittagong",
    "Khulna",
    "Rajshahi",
    "Mymensingh",
    "Rajshahi",
    "Rangpur",
    "Sylhet",
    "Barisal",
  ],
  Dhaka: [
    "Demra",
    "Dhaka Cantt.",
    "Dhamrai",
    "Dhanmondi",
    "Gulshan",
    "Jatrabari",
    "Joypara",
    "Keraniganj",
    "Khilgaon",
    "Khilkhet",
    "Lalbag",
    "Mirpur",
    "Mohammadpur",
    "Motijheel",
    "Nawabganj",
    "New market",
    "Palton",
    "Ramna",
    "Sabujbag",
    "Savar",
    "Sutrapur",
    "Tejgaon",
    "Tejgaon Industrial Area",
    "Uttara",
    "Gazipur Sadar",
    "Kaliakaar",
    "Kaliganj",
    "Kapashia",
    "Monnunagar",
    "Sreepur",
    "Sripur",
    "Alfadanga",
    "Bhanga",
    "Boalmari",
    "Charbhadrasan",
    "Faridpur Sadar",
    "Madukhali",
    "Nagarkanda",
    "Sadarpur",
    "Shriangan",
    "Kawraid",
    "Araihazar",
    "Baidder Bazar",
    "Bandar",
    "Fatullah",
    "Narayanganj Sadar",
    "Rupganj",
    "Siddirganj",
    "Atpara",
    "Barhatta",
    "Dharmapasha",
    "Dhobaura",
    "Kalmakanda",
    "Kendua",
    "Khaliajuri",
    "Madan",
    " Moddhynagar ",
    " Mohanganj ",
    " Netrakona Sadar ",
    " Purbadhola ",
    " Susung Durgapur ",
    " Baliakandi ",
    " Pangsha ",
    "Rajbari Sadar",
    "Bhedorganj",
    "Damudhya",
    "Gosairhat",
    "Jajira",
    "Naria",
    "Shariatpur Sadar",
    "Bakshigonj",
    "Jhinaigati",
    "Nakla",
    "Nalitabari",
    "Sherpur Shadar",
    "Shribardi",
    "Basail",
    "Bhuapur",
    "Delduar",
    "Ghatail",
    "Gopalpur",
    "Kalihati",
    "Kashkaolia",
    "Madhupur",
    "Mirzapur",
    "Nagarpur",
    "Sakhipur",
    " Tangail Sadar ",
    " Gopalganj Sadar ",
    " Kotalipara ",
    " Maksudpur ",
    " Tungipara ",
    " Dewangonj ",
    " Islampur ",
    "Jamalpur ",
    " Malandah ",
    " Mathargonj ",
    " Shorishabari ",
    " Bajitpur ",
    " Bhairob ",
    " Hossenpur ",
    " Itna ",
    " Karimganj ",
    " Katiadi ",
    " Kishoreganj Sadar ",
    " Kuliarchar ",
    " Mithamoin ",
    " Nikli ",
    " Ostagram ",
    " Pakundia ",
    " Tarial ",
    " Barhamganj ",
    " kalkini ",
    " Madaripur Sadar ",
    " Rajoir ",
    " Doulatpur ",
    " Gheor ",
    " Lechhraganj ",
    " Manikganj Sadar ",
    " Saturia ",
    " Shibloya ",
    " Singari ",
    " Gajaria ",
    " Lohajong ",
    " Munshiganj Sadar ",
    " Sirajdikhan ",
    "Sreenagar",
    "Tangibari",
    "Bhaluka",
    "Fulbaria",
    "Gaforgaon",
    "Gouripur",
    "Haluaghat",
    "Isshwargonj",
    "Muktagachha",
    "Mymensingh Sadar",
    "Nandail",
    "Phulpur",
    " Trishal ",
    " Dhamrai ",
    " Dohar ",
    " Keraniganj ",
    " Nawabganj ",
    " Savar ",
    " Alfadanga ",
    " Bhanga ",
    " Boalmari ",
    " Charbhadrasan ",
    " Madhukhali ",
    " Nagarkanda ",
    " Sadarpur ",
    "Saltha",
    "Gazipur Sadar",
    "Kaliakair",
    "Kaliganj",
    "Kapasia",
    "Sreepur",
    "Gopalganj Sadar",
    "Kashiani",
    "Kotalipara",
    "Muksudpur",
    "Tungipara",
    "Baksiganj",
    "Dewanganj",
    "Islampur",
    "Jamalpur Sadar",
    "Madarganj",
    "Melandaha",
    "Sarishabari",
    "Astagram",
    " Bajitpur ",
    " Bhairab ",
    " Hossainpur ",
    " Itna ",
    " Karimganj ",
    " Katiadi ",
    " Kuliarchar ",
    " Mithamain ",
    " Nikli ",
    " Pakundia ",
    " Tarail ",
    " Rajoir ",
    " Madaripur Sadar ",
    " Kalkini ",
    " Shibchar ",
    " Daulatpur ",
    " Ghior ",
    " Harirampur ",
    " Manikgonj Sadar ",
    " Saturia ",
    "Shivalaya",
    "Singair",
    "Gazaria",
    "Lohajang",
    "Munshiganj Sadar",
    "Sirajdikhan",
    "Sreenagar",
    "Tongibari",
    "Bhaluka",
    "Dhobaura",
    "Fulbaria",
    "Gaffargaon",
    " Gauripur ",
    " Haluaghat ",
    " Ishwarganj ",
    " Muktagachha ",
    " Nandail ",
    " Phulpur ",
    " Trishal ",
    " Tara Khanda ",
    " Araihazar ",
    " Bandar ",
    " Rupganj ",
    " Sonargaon ",
    " Atpara ",
    " Barhatta ",
    " Durgapur ",
    " Khaliajuri ",
    " Kalmakanda ",
    " Kendua ",
    " Madan ",
    " Mohanganj ",
    "Netrokona Sadar ",
    " Purbadhala ",
    " Baliakandi ",
    " Goalandaghat ",
    " Pangsha ",
    " Rajbari Sadar ",
    " Kalukhali ",
    " Bhedarganj ",
    " Damudya ",
    " Gosairhat ",
    " Naria ",
    " Shariatpur Sadar ",
    "Zanjira",
    "Shakhipur",
    "Jhenaigati",
    "Nakla",
    "Nalitabari",
    "Sherpur Sadar",
    "Sreebardi",
    "Gopalpur",
    "Basail",
    "Bhuapur",
    "Delduar",
    "Ghatail",
    " Kalihati ",
    " Madhupur ",
    " Mirzapur ",
    " Nagarpur ",
    " Sakhipur ",
    " Dhanbari ",
    " Tangail Sadar ",
    "Narsingdi Sadar ",
    " Belabo ",
    " Monohardi ",
    " Palash ",
    " Raipura ",
    " Shibpur ",
    " Adabor ",
    " Uttar Khan ",
    " Kadamtali ",
    " Kalabagan ",
    " Kafrul ",
    " Kamrangirchar ",
    " Cantonment ",
    " Kotwali ",
    " Gendaria ",
    " Chawkbazar Model ",
    " Turag ",
    " Tejgaon I / A ",
    " Dakshinkhan ",
    " Darus Salam ",
    " Paltan ",
    " Pallabi ",
    " Bangshal ",
    " Badda ",
    " Bimanbandar ",
    " Mirpur Model ",
    " Rampura ",
    " Lalbagh ",
    " Shah Ali ",
    " Shahbagh ",
    " Sher-e-Bangla Nagar ",
    " Shyampur ",
    "Sabujbagh",
    "Hazaribagh",
  ],
  Chittagong: [
    "Chhagalnaiya",
    "Daganbhuiyan",
    "Parshuram",
    "Sonagazi",
    "Bandarban",
    "Khagrachhari",
    "Rangamati",
    "Rangunia",
    "Sandwip",
    " Fatikchhari ",
    " Nazir Hat ",
    " Baroiyarhat ",
    " Mirsharai ",
    " Sitakunda ",
    " Hathazari ",
    " Raozan ",
    " Patiya ",
    " Chandanaish ",
    " Satkania ",
    " Boalkhali ",
    " Akhaura ",
    " Sarail ",
    " Chowmuhani ",
    " Laksam ",
    " Haziganj ",
    " Matlab ",
    " Hatiya ",
    " Maijdee ",
    " Lakshmipur ",
    "Burichong ",
    " Chittagong ",
    " Ali Kadam ",
    " Bandarban Sadar ",
    " Lama ",
    " Naikhongchhari ",
    " Rowangchhari ",
    " Ruma ",
    " Thanchi ",
    " Akhaura ",
    " Bancharampur ",
    " Brahmanbaria Sadar ",
    "Kasba",
    "Nabinagar",
    "Nasirnagar",
    "Sarail",
    "Ashuganj",
    "Bijoynagar",
    "Chandpur Sadar",
    "Faridganj",
    "Haimchar",
    "Haziganj",
    "Kachua",
    "Matlab Dakshin",
    "Matlab Uttar",
    "Shahrasti",
    "Anwara",
    "Banshkhali",
    "Boalkhali",
    "Chandanaish",
    "Fatikchhari",
    "Hathazari ",
    " Lohagara ",
    " Mirsharai ",
    " Patiya ",
    " Rangunia ",
    " Raozan ",
    " Sandwip ",
    " Satkania ",
    " Sitakunda ",
    " Bandor (Chittagong Port) Thana ",
    " Chandgaon Thana ",
    " Double Mooring Thana ",
    " Kotwali Thana ",
    " Pahartali Thana ",
    " Panchlaish Thana ",
    " Barura ",
    " Brahmanpara ",
    " Burichang ",
    " Chandina ",
    " Chauddagram ",
    " Daudkandi ",
    " Debidwar ",
    " Homna ",
    " Laksam ",
    " Muradnagar ",
    " Nangalkot ",
    " Comilla Adarsha Sadar ",
    " Meghna ",
    " Titas ",
    " Monohargonj ",
    "Comilla Sadar Dakshin ",
    " Chakaria ",
    "Coxs Bazar Sadar",
    " Kutubdia ",
    " Maheshkhali ",
    " Ramu ",
    " Teknaf ",
    " Ukhia ",
    " Pekua ",
    " Chhagalnaiya ",
    " Daganbhuiyan ",
    " Feni Sadar ",
    " Parshuram ",
    " Sonagazi ",
    " Fulgazi ",
    " Dighinala ",
    " Khagrachhari ",
    " Lakshmichhari ",
    " Mahalchhari ",
    " Manikchhari ",
    " Matiranga ",
    " Panchhari ",
    " Ramgarh ",
    " Lakshmipur Sadar ",
    "Raipur",
    "Ramganj",
    "Ramgati",
    "Kamalnagar",
    "Begumganj",
    "Noakhali Sadar",
    "Chatkhil",
    "Companiganj",
    "Hatiya",
    "Senbagh",
    "Sonaimuri",
    "Subarnachar",
    "Kabirhat",
    "Bagaichhari",
    "Barkal",
    "Kawkhali (Betbunia)",
    "Belaichhari",
    "Kaptai",
    "Juraichhari",
    "Langadu",
    "Naniyachar",
    "Rajasthali",
    "Rangamati Sadar",
  ],
  Khulna: [
    "Khulna Sadar",
    "Bagerhat Sadar",
    "Chitalmari",
    "Fakirhat",
    "Kachua",
    "Mollahat",
    " Mongla ",
    " Morrelganj ",
    " Rampal ",
    " Sarankhola ",
    " Alamdanga ",
    " Chuadanga Sadar ",
    " Damurhuda ",
    " Jibannagar ",
    "Abhaynagar ",
    " Bagherpara ",
    " Chaugachha ",
    " Jhikargachha ",
    " Keshabpur ",
    " Jessore Sadar ",
    " Manirampur ",
    " Sharsha ",
    " Harinakunda ",
    " Jhenaidah Sadar ",
    " Kaliganj ",
    " Kotchandpur ",
    " Maheshpur ",
    " Shailkupa ",
    " Batiaghata ",
    " Dacope ",
    " Dumuria ",
    " Dighalia ",
    " Koyra ",
    " Paikgachha ",
    " Phultala ",
    " Rupsha ",
    " Terokhada ",
    " Daulatpur Thana ",
    " Khalishpur Thana ",
    " Khan Jahan Ali Thana ",
    " Kotwali Thana ",
    " Sonadanga Thana ",
    " Harintana Thana ",
    " Bheramara ",
    "Daulatpur ",
    " Khoksa ",
    " Kumarkhali ",
    " Kushtia Sadar ",
    " Mirpur ",
    " Shekhpara ",
    " Magura Sadar ",
    " Mohammadpur ",
    " Shalikha ",
    " Sreepur ",
    " Gangni ",
    " Meherpur Sadar ",
    "Mujibnagar",
    "Kalia",
    "Lohagara",
    "Narail Sadar",
    "Naragati Thana",
    "Assasuni",
    "Debhata",
    "Kalaroa",
    "Satkhira Sadar",
    "Shyamnagar",
    "Tala",
  ],
  Rajshahi: [
    "Joypurhat",
    "Rahanpur",
    "Kalai",
    "Khetlal",
    "Akkelpur",
    "Panchbibi",
    "Mundumala",
    "Natore",
    "Shahjadpur ",
    " Ullapara ",
    " Iswardi ",
    " Santhia ",
    " Dhunat ",
    " Sherpur, Bogra ",
    " Tanore ",
    " Akkelpur ",
    " Joypurhat Sadar ",
    " Kalai ",
    " Khetlal ",
    " Panchbibi ",
    "Adamdighi",
    "Bogra Sadar",
    "Dhunat",
    "Dhupchanchia",
    "Gabtali",
    "Kahaloo",
    "Nandigram",
    "Sariakandi",
    "Shajahanpur",
    "Sherpur",
    "Shibganj",
    "Sonatola",
    " Atrai ",
    " Badalgachhi ",
    " Manda ",
    " Dhamoirhat ",
    " Mohadevpur ",
    " Naogaon Sadar ",
    " Niamatpur ",
    " Patnitala ",
    "Porsha ",
    " Raninagar ",
    " Sapahar ",
    " Bagatipara ",
    " Baraigram ",
    " Gurudaspur ",
    " Lalpur ",
    " Natore Sadar ",
    " Singra ",
    " Naldanga ",
    " Bholahat ",
    " Gomastapur ",
    " Nachole ",
    " Nawabganj Sadar ",
    " Ataikula ",
    " Atgharia ",
    " Bera ",
    " Bhangura ",
    " Chatmohar ",
    " Faridpur ",
    " Ishwardi ",
    " Pabna Sadar ",
    " Santhia ",
    " Sujanagar ",
    " Belkuchi ",
    " Chauhali ",
    " Kamarkhanda ",
    " Kazipur ",
    " Raiganj ",
    " Shahjadpur ",
    " Sirajganj Sadar ",
    " Tarash ",
    "Ullahpara ",
    " Bagha ",
    " Bagmara ",
    " Charghat ",
    " Durgapur ",
    " Godagari ",
    " Mohanpur ",
    " Paba ",
    " Puthia ",
    " Tanore ",
    " Boalia Thana ",
    " Matihar Thana ",
    " Rajpara Thana ",
    " Shah Mokdum Thana ",
  ],
  Mymensingh: [
    " Ishwarganj ",
    " Gaffargaon ",
    " Gauripur ",
    " Trishal ",
    " Dhobaura ",
    " Nandail ",
    " Phulpur ",
    " Fulbaria ",
    " Bhaluka ",
    "Mymensingh Sadar",
    "Muktagachha",
    "Haluaghat",
  ],
  Rangpur: [
    "Rangpur",
    "Birampur",
    "Birganj",
    "Biral",
    "Bochaganj",
    "Chirirbandar",
    "Phulbari",
    "Ghoraghat",
    "Hakimpur",
    "Kaharole",
    "Khansama",
    "Dinajpur Sadar",
    "Nawabganj",
    "Parbatipur",
    "Phulchhari",
    "Gaibandha Sadar",
    "Gobindaganj",
    "Palashbari",
    "Sadullapur",
    "Sughatta",
    "Sundarganj",
    "Bhurungamari",
    "Char Rajibpur",
    "Chilmari",
    "Kurigram Sadar",
    "Nageshwari",
    "Rajarhat",
    "Raomari",
    "Ulipur",
    "Aditmari",
    "Hatibandha",
    "Kaliganj",
    "Lalmonirhat Sadar",
    "Patgram",
    "Dimla",
    "Domar",
    "Jaldhaka",
    "Kishoreganj",
    "Nilphamari Sadar",
    "Saidpur",
    "Atwari",
    "Boda",
    "Debiganj",
    "Panchagarh Sadar",
    "Tetulia",
    "Badarganj",
    "Gangachhara",
    "Kaunia",
    "Rangpur Sadar",
    "Mithapukur",
    "Pirgachha",
    "Pirganj",
    "Taraganj",
    "Baliadangi",
    "Haripur",
    "Ranisankail",
    "Thakurgaon Sadar",
    "Kurigram",
    "Lalmonirhat",
    "Nilphamari",
    "Panchagarh",
    "Thakurgaon",
  ],
  Sylhet: [
    "Golapganj",
    "Beanibazar",
    "Habiganj",
    "Moulvibazar",
    "Sreemangal ",
    " Kulaura ",
    " Sunamganj ",
    " Chhatak ",
    " Chunarughat ",
    " Nabiganj ",
    " Madhabpur ",
    " Shaistaganj ",
    " Jagannathpur ",
    " Derai ",
    " Komolganj ",
    " Kanaighat ",
    " Barlekha ",
    "Zakiganj",
    "Ajmiriganj",
    "Bisawanth",
    "Sylhet",
    "Ajmiriganj",
    "Bahubal",
    "Baniyachong",
    "Chunarughat",
    "Habiganj Sadar",
    "Lakhai",
    "Madhabpur",
    "Nabiganj",
    "Barlekha",
    "Kamalganj",
    "Kulaura",
    "Moulvibazar Sadar",
    "Rajnagar",
    "Sreemangal",
    "Juri",
    "Bishwamvarpur",
    "Chhatak",
    "Derai",
    "Dharampasha",
    "Dowarabazar",
    "Jagannathpur",
    "Jamalganj",
    "Sullah",
    "Sunamganj Sadar",
    "Tahirpur",
    "South Sunamganj",
    "Balaganj",
    "Beanibazar",
    "Bishwanath",
    "Companigonj",
    "Fenchuganj",
    "Golapganj",
    "Gowainghat",
    "Jaintiapur",
    "Kanaighat",
    "Sylhet Sadar",
    "Zakiganj",
    "South Shurma",
  ],
  Barisal: [
    "Bhola",
    "Patuakhali",
    "Pirojpur",
    "Barguna",
    "Jhalokati",
    "Amtali",
    "Bakerganj",
    "Char Fasson ",
    " Gournadi ",
    " Swarupkati ",
    " Kuakata ",
    " Muladi ",
    " Bhandaria ",
    " Mathbaria ",
    " Lalmohan ",
    " Borhanuddin ",
    " Daulatkhan ",
    " Banaripara ",
    " Mehendiganj ",
    " Nalchity ",
    " Patharghata ",
    " Kalapara ",
    " Wazirpur ",
    " Betagi ",
    " Galachipa ",
    " Bauphal ",
  ],
};
