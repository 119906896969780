import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopTagsList from "../../../components/Shop/Tags";

const ShopCategoryPage = () => {
  return (
    <MainLayout>
      <ShopTagsList />
    </MainLayout>
  );
};

export default ShopCategoryPage;
