import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import BankList from "../../components/Bank/index";
import React from "react";

const BankPage = (): ReactElement => {
  return (
    <MainLayout>
      <BankList />
    </MainLayout>
  );
};

export default BankPage;
