import React from "react";
import MainLayout from "../../../../components/Layouts";
import PromoUsesUsersList from "../../../../components/Promotions/PromoCode/Users/index";

const PromoUseUserPage = () => {
  return (
    <MainLayout>
      <PromoUsesUsersList />
    </MainLayout>
  );
};

export default PromoUseUserPage;
