import {
  IdcardOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../../utils/services";
const ShopSidebar = ({ shopId }: any): ReactElement => {
  const pathname = window.location?.pathname;
  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 h-full">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/details"
              className={`hover:text-white hover:bg-gray-100 ${
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }`}
              icon={<IdcardOutlined />}
            >
              <Link
                to={`/shops/${shopId}/details`}
                className="hover:text-white"
              >
                Basic Information
              </Link>
            </Menu.Item>
            {isAllowedService(`Shops Products`) && (
              <Menu.Item
                key="/products"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/products") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/products`}>Product Management</Link>
              </Menu.Item>
            )}
            {isAllowedService(`Shops Orders`) && (
              <Menu.Item
                key="/orders"
                icon={<ShoppingCartOutlined />}
                className={`hover:text-white hover:bg-gray-100 ${
                  pathname.includes("/orders") ? "active bg-gray-100" : ""
                }`}
              >
                <Link to={`/shops/${shopId}/orders`}>Orders History</Link>
              </Menu.Item>
            )}

            <Menu.Item
              key="/out-of-stock"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/out-of-stock") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/shops/${shopId}/out-of-stock`}>Out of Stock</Link>
            </Menu.Item>

            {isAllowedService(`Shops Categories`) && (
              <Menu.Item
                key="/categories"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/categories") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/categories`}>
                  Category Management
                </Link>
              </Menu.Item>
            )}

            {isAllowedService(`Shops Products`) && (
              <Menu.Item
                key="/brands"
                icon={<SolutionOutlined />}
                className={
                  pathname.includes("/brands") ? "active bg-gray-100" : ""
                }
              >
                <Link to={`/shops/${shopId}/brands`}>Brand List</Link>
              </Menu.Item>
            )}
            <Menu.Item
              key="/tags"
              icon={<SolutionOutlined />}
              className={pathname.includes("/tags") ? "active" : ""}
            >
              <Link to={`/shops/${shopId}/tags`}>Tag Management</Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ShopSidebar;
