import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ShopLayout from "../Layout";
import BrandList from "../../../Brands";
import { useSelector } from "react-redux"; 
import { useParams } from "react-router";

const CampaignBrandsList = (): ReactElement => {
  const route = useParams();
  return (
    <React.Fragment>
      <ShopLayout
        campaignId={(route as any)?.campaignId}
        title="Campaign Details"
        subTitle="Order List"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <BrandList
              inCampaign={true}
              getCampaignId={(route as any)?.campaignId}
            />
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

CampaignBrandsList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignBrandsList;
