import React, {useState, useEffect} from 'react';
import {useParams} from "react-router";
import Flight from './flight';
import axios from "axios";
import {Link} from "react-router-dom";
import airportList from "../../../airportList.json";
import airLinceList from "../../../airlines.json";


const AppURL = process.env.REACT_APP_BOOKING_API;
const OrderURL = process.env.REACT_APP_ORDER_API;

function FlightList(props) {
    const route = useParams();
    const [cheapestFlightsPrice, setCheapestFlightsPrice] = useState(true);
    const [fastestFlightsPrice, setFastestFlightsPrice] = useState(false);
    const [earliestFlightsPrice, setEarliestFlightsPrice] = useState(false);
    const [promoScrollIndexEnd, setPromoScrollIndexEnd] = useState(4);
    const [token, setToken] = useState(null);

    const flightPrevewData = route?.listOfData;
    console.log(flightPrevewData);

    if (flightPrevewData) {
        var searchArray = JSON.parse(flightPrevewData)?.split(",");
    }


    const [waitingLoader, setWaitingLoader] = useState(false);
    const [promoCode, setPromoCode] = useState([]);
    const [allPromo, setAllPromo] = useState([]);
    const [promoScrollIndexStart, setPromoScrollIndexStart] = useState(0);
    const [promoSlider, setPromoSlider] = useState(0);
    const [airlinesSlider, setAirlinesSlider] = useState(0);
    const [promoArray, setPromoArray] = useState([]);
    const [promoCodeSearch, setPromoCodeSearch] = useState("");
    const [partnerPromos1, setPartnerPromos] = useState([]);
    const [platformPromos1, setPlatformPromos] = useState([]);
    const [sellerPromos1, setSellerPromos] = useState([]);
    const [selectedServices1, setSelectedServices1] = useState(1);
    const [journeyDate, setJourneyDate1] = useState("");
    const [returnDate, setReturnDate1] = useState("");
    const [checkWay, setCheckWay1] = useState(0);
    const [childrenOneAge, setChildrenOneAge1] = useState(0);
    const [childrenTwoAge, setChildrenTwoAge1] = useState(0);
    const [childrenThreeAge, setChildrenThreeAge1] = useState(0);
    const [childrenFourAge, setChildrenFourAge1] = useState(0);

    const [mainArray, setMainArray] = useState([]);
    const [cityNameFrom, setCityNameFrom1] = useState("");
    const [codeFrom, setCodeFrom1] = useState("");
    const [nameFrom, setNameFrom1] = useState("");
    const [cityNameTo, setCityNameTo1] = useState("");
    const [codeTo, setCodeTo1] = useState("");
    const [nameTo, setNameTo1] = useState("");
    const [totalTravelers, setTotalTravelers1] = useState("");
    const [travelerClass, setTravelerClass1] = useState("");
    const [adults, setAdults1] = useState("");
    const [children, setChildren1] = useState("");
    const [infant, setInfant1] = useState("");
    const [arrayFlightList, setArrayFlightList] = useState([]);
    const [checkFlightListDetails, setCheckFlightListDetails] = useState("");
    const [groupedItineraryResponse, setGroupedItineraryResponse] = useState([]);
    const [flightLegDescs, setFlightLegDescs] = useState([]);
    const [flightScheduleDescs, setFlightScheduleDescs] = useState([]);
    const [pricingInformation, setPricingInformation] = useState([]);
    const [detailsFaireSummaryList, setDetailsFaireSummaryList] = useState(1);
    const [lowFareSearchId, setLowFareSearchId] = useState("");
    const [filterByFlight, setFilterByFlight] = useState("");
    const [baggesInfo, setBaggesInfo] = useState([]);
    const [viewAirlinceSearch, setViewAirlinceSearch] = useState(false);
    const [viewNumberOfStops, setViewNumberOfStops] = useState(false);
    const [viewFareType, setViewFareType] = useState(false);
    const [searchSchedule, setSearchSchedule] = useState(false);
    const [searchAirlines, setSearchAirlines] = useState(false);
    const [searchAircraft, setSearchAircraft] = useState(false);
    const [searchLayoverAirport, setSearchLayoverAirport] = useState(false);
    const [searchLayoverTime, setSearchLayoverTime] = useState(false);
    const [viewSearch, setViewSearch] = useState(false);
    const [departureTime, setDepartureTime] = useState([
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:21:07.973_schedule4.webp",
            "viewTime": "00:00 - 05:59",
            "valueTime": "00:00-05:59",
            "checkSelect": false
        },
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:19:59.729_schedule1.webp",
            "viewTime": "06:00 - 11:59",
            "valueTime": "06:00-11:59",
            "checkSelect": false
        },
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:20:30.495_schedule2.webp",
            "viewTime": "12:00 - 17:59",
            "valueTime": "12:00-17:59",
            "checkSelect": false
        },
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:20:50.564_schedule3.webp",
            "viewTime": "18:00 - 23:59",
            "valueTime": "18:00-23:59",
            "checkSelect": false
        }
    ]);
    const [arrivalTime, setArrivalTime] = useState([
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:21:07.973_schedule4.webp",
            "viewTime": "00:00 - 05:59",
            "valueTime": "00:00-05:59",
            "checkSelect": false
        },
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:20:30.495_schedule2.webp",
            "viewTime": "06:00 - 11:59",
            "valueTime": "06:00-11:59",
            "checkSelect": false
        },
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:20:30.495_schedule2.webp",
            "viewTime": "12:00 - 17:59",
            "valueTime": "12:00-17:59",
            "checkSelect": false
        },
        {
            "image": "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-10-18T16:20:50.564_schedule3.webp",
            "viewTime": "18:00 - 23:59",
            "valueTime": "18:00-23:59",
            "checkSelect": false
        }
    ]);

    const airlinesList = [];
    const aircraftList = [];
    const layoverAirportList = [];
    flightScheduleDescs.map((v_search, index) => {
        airlinesList.push(v_search.carrier.marketing);
        aircraftList.push(v_search.carrier.equipment.code);
        layoverAirportList.push(v_search.arrival.airport);
    });

    function removeDuplicates(data) {
        return [...new Set(data)];
    }

    const [scrollIndexStart, setScrollIndexStart] = useState(0);
    const [scrollIndexEnd, setScrollIndexEnd] = useState(4);
    const [sliceArray, setSliceArray] = useState([]);

    useEffect(() => {
        setSliceArray(removeDuplicates(airlinesList).slice(scrollIndexStart, scrollIndexEnd));
    }, [flightScheduleDescs, scrollIndexStart, scrollIndexEnd]);

    const incrementOrDecrement = () => {
        if (e === "next") {
            setScrollIndexStart((prevScrollIndexStart) => prevScrollIndexStart + 1);
            setScrollIndexEnd((prevScrollIndexEnd) => prevScrollIndexEnd + 1);
            setAirlinesSlider((prevAirlinesSlider) => prevAirlinesSlider + 1);
        } else {
            setScrollIndexStart((prevScrollIndexStart) => prevScrollIndexStart - 1);
            setScrollIndexEnd((prevScrollIndexEnd) => prevScrollIndexEnd - 1);
            setAirlinesSlider((prevAirlinesSlider) => prevAirlinesSlider - 1);
        }
    }

    useEffect(() => {
        let staticURL = OrderURL + "/promo/my-all-promo?type=JC_BOOKING";
        axios({
            method: 'GET',
            url: staticURL,
        }).then(response => {
            setPartnerPromos(response.data.partnerPromos);
            setPlatformPromos(response.data.platformPromos);
            if (response.data.sellerPromos === null) {
                var sellerPromos = [];
            } else {
                setSellerPromos(response.data.sellerPromos);
                var sellerPromos = response.data.sellerPromos;
            }
            const arry1 = response.data.partnerPromos;
            const arry2 = response.data.platformPromos;
            const arry3 = sellerPromos;
            const sliceArray = arry1.concat(arry2).concat(arry3);
            setAllPromo(sliceArray);
            setPromoCode(sliceArray);
        }).catch(error => {
        });
    }, [token]);

    const viewAllPromo = (val) => {
        if (val === "all") {
            setPromoCode(allPromo);
        } else if (val === "seller") {
            setPromoCode(sellerPromos1);
        } else if (val === "platform") {
            setPromoCode(platformPromos1);
        } else if (val === "partner") {
            setPromoCode(partnerPromos1);
        } else {
            setPromoCode(allPromo);
        }
    }

    const promoCodeSearchCheck = (index, val) => {
        setPromoCodeSearch(val);
        if (checkSearchClickPromo === index) {
            setCheckSearchClickPromo("");
        } else {
            setCheckSearchClickPromo(index);
        }
    }

    const promoIncrementOrDecrement = () => {
        if (e === "next") {
            setPromoScrollIndexStart((prevPromoScrollIndexStart) => prevPromoScrollIndexStart + 1);
            setPromoScrollIndexEnd((prevPromoScrollIndexEnd) => prevPromoScrollIndexEnd + 1);
            setPromoSlider((prevPromoSlider) => prevPromoSlider + 1);
        } else {
            setPromoScrollIndexStart((prevPromoScrollIndexStart) => prevPromoScrollIndexStart - 1);
            setPromoScrollIndexEnd((prevPromoScrollIndexEnd) => prevPromoScrollIndexEnd - 1);
            setPromoSlider((prevPromoSlider) => prevPromoSlider - 1);
        }
    }
    const [numberOfStopsArray, setNumberOfStopsArray] = useState([]);
    const numberOfStops = (val) => {
        if (numberOfStopsArray.includes(val) === true) {
            const index = numberOfStopsArray.indexOf(val);
            numberOfStopsArray.splice(index, 1);
            setNumberOfStopsArray([...numberOfStopsArray]);
        } else {
            setNumberOfStopsArray([...numberOfStopsArray, val]);
        }
    }


    useEffect(() => {
        console.log("searchArray===", searchArray);
        console.log("searchArray[10]===", searchArray[10]);
        if (searchArray?.length > 0) {
            let myJourneyDate = new Date(searchArray[6]);
            let getMyJourneyDate = myJourneyDate.toISOString().split('T')[0];
            if (searchArray[7]) {
                var myReturnDate = new Date(searchArray[7]);
                var getMyReturnDate = myReturnDate.toISOString().split('T')[0];
            }
            const newJourneyDate = getMyJourneyDate + "T00:00:00";
            const newReturnDate = getMyReturnDate + "T00:00:00";
            setCityNameFrom1(searchArray[0]);
            setCodeFrom1(searchArray[1]);
            setNameFrom1(searchArray[2]);
            setCityNameTo1(searchArray[3]);
            setCodeTo1(searchArray[4]);
            setNameTo1(searchArray[5]);
            setJourneyDate1(newJourneyDate);
            setReturnDate1(newReturnDate);
            setTotalTravelers1(searchArray[8]);
            setTravelerClass1(searchArray[9]);
            setAdults1(searchArray[10]);
            setChildren1(searchArray[11]);
            setInfant1(searchArray[12]);
            setCheckWay1(searchArray[13]);
            setChildrenOneAge1(searchArray[14]);
            setChildrenTwoAge1(searchArray[15]);
            setChildrenThreeAge1(searchArray[16]);
            setChildrenFourAge1(searchArray[17]);
        }
    }, [searchArray]);


    const searchMultiCity = route?.listOfData
    if (searchMultiCity) {
        var travelerInfo = flightPrevewData?.split(",");
    }

    useEffect(() => {
        if (travelerInfo) {
            setTotalTravelers1(travelerInfo.totalTravelers);
            setTravelerClass1(travelerInfo.travelerClass);
            setAdults1(travelerInfo.adults);
            setChildren1(travelerInfo.children);
            setInfant1(travelerInfo.infant);
            setCheckWay1(travelerInfo.checkWay);
            setChildrenOneAge1(travelerInfo.childrenOneAge);
            setChildrenTwoAge1(travelerInfo.childrenTwoAge);
            setChildrenThreeAge1(travelerInfo.childrenThreeAge);
            setChildrenFourAge1(travelerInfo.childrenFourAge);
        }
    }, [travelerInfo]);

    const [searchTravelerClass, setSearchTravelerClass] = useState('');
    const filterByFareType = (val) => {
        setSearchTravelerClass(val);
    }

    useEffect(() => {
        if (journeyDate) {
            searchFlight();
        }
        setWaitingLoader(false);
    }, [journeyDate]);

    const searchFlight = () => {
        const flightClass = [];
        if (searchArray[10] > 0) {
            flightClass.push({"Code": "ADT", "Quantity": searchArray[10]})
        }
        if (searchArray[11] > 0) {
            if (searchArray[11] == 1) {
                flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
            } else if (searchArray[11] == 2) {
                if (childrenOneAge === childrenTwoAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "2"});
                } else {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
                    flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "1"});
                }
            } else if (searchArray[11] == 3) {
                if (childrenOneAge === childrenTwoAge === childrenThreeAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "3"});
                } else if (childrenOneAge === childrenTwoAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "2"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                } else if (childrenOneAge === childrenThreeAge) {
                    flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "2"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                } else if (childrenTwoAge === childrenThreeAge) {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "2"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                } else {
                    flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
                    flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "1"});
                    flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                }
            } else {
                flightClass.push({"Code": "C" + childrenOneAge, "Quantity": "1"});
                flightClass.push({"Code": "C" + childrenTwoAge, "Quantity": "1"});
                flightClass.push({"Code": "C" + childrenThreeAge, "Quantity": "1"});
                flightClass.push({"Code": "C" + childrenFourAge, "Quantity": "1"});
            }
        }

        if (searchArray[12] > 0) {
            flightClass.push({"Code": "INF", "Quantity": searchArray[12]})
        }

        const originDestination = [];
        if (searchArray[13] == 1) {
            originDestination.push({
                "DepartureDateTime": journeyDate,
                "DestinationLocation": {
                    "LocationCode": codeTo
                },
                "OriginLocation": {
                    "LocationCode": codeFrom
                },
                "RPH": "0"
            })
        }

        if (searchArray[13] == 2) {
            originDestination.push({
                "DepartureDateTime": journeyDate,
                "DestinationLocation": {
                    "LocationCode": codeTo
                },
                "OriginLocation": {
                    "LocationCode": codeFrom
                },
                "RPH": "0"
            })

            originDestination.push({
                "DepartureDateTime": returnDate,
                "DestinationLocation": {
                    "LocationCode": codeFrom
                },
                "OriginLocation": {
                    "LocationCode": codeTo
                },
                "RPH": "1"
            })
        }

        if (searchArray[13] == 3) {
            var setArray = getResult;
        } else {
            var setArray = originDestination;
        }

        let staticURL = AppURL + "/booking/search-low-fare";
        if (searchTravelerClass) {
            var set_travelerClass = searchTravelerClass;
        } else {
            var set_travelerClass = searchArray[9];
        }

        const dataString = {
            "originDestinationInformations": setArray,
            "passengerTypeQuantities": flightClass,
            "travelerClass": set_travelerClass
        }

        console.log(dataString);

        axios({
            method: 'POST',
            url: staticURL,
            data: dataString,
        }).then(response => {
            setWaitingLoader(true);
            setBaggesInfo(response.data.groupedItineraryResponse.baggageAllowanceDescs);
            setLowFareSearchId(response.data.lowFareSearchId);
            setMainArray(response.data.groupedItineraryResponse);
            setGroupedItineraryResponse(response.data.groupedItineraryResponse);
            setFlightLegDescs(response.data.groupedItineraryResponse?.legDescs);
            setFlightScheduleDescs(response.data.groupedItineraryResponse?.scheduleDescs);
            const itineraries = response.data.groupedItineraryResponse.itineraryGroups[0]?.itineraries;
            const groupTickets = response.data.groupedItineraryResponse.itineraryGroups[0]?.groupTickets;
            const groupTicketsArray = [];
            if (groupTickets != null) {
                for (let i = 0; i < groupTickets.length; i++) {
                    const getArray = groupTickets[i]['itinerary'];
                    getArray["jachaiSpecial"] = groupTickets[i]?.id;
                    getArray["groupTicketId"] = groupTickets[i]?.id;
                    groupTicketsArray.push(getArray);
                }
            }
            const mergeArray = groupTicketsArray.concat(itineraries);
            setArrayFlightList(mergeArray);
        }).catch(error => {
            setWaitingLoader(true);
        });
    }

    const filterByFlighthcfe = (val) => {
        if (val === "CHEAPEST") {
            setCheapestFlightsPrice(true);
            setFastestFlightsPrice(false);
            setEarliestFlightsPrice(false);
        } else if (val === "FASTEST") {
            setCheapestFlightsPrice(false);
            setFastestFlightsPrice(true);
            setEarliestFlightsPrice(false);
        } else {
            setCheapestFlightsPrice(false);
            setFastestFlightsPrice(false);
            setEarliestFlightsPrice(true);
        }

        if (filterByFlight === val) {
            setFilterByFlight("");
        } else {
            setFilterByFlight(val);
        }
    }

    const [checkSearchClickPromo, setCheckSearchClickPromo] = useState("");
    const [checkSearchClick, setCheckSearchClick] = useState("");
    const [airlinesArray, setAirlinesArray] = useState([]);

    const airlinesSearch = (index, val) => {
        if (checkSearchClick === index) {
            setCheckSearchClick("");
        } else {
            setCheckSearchClick(index);
        }

        if (airlinesArray.includes(val) === true) {
            const index = airlinesArray.indexOf(val);
            airlinesArray.splice(index, 1);
            setAirlinesArray([...airlinesArray]);
        } else {
            setAirlinesArray([...airlinesArray, val]);
        }
    }

    const [aircraftArray, setAircraftArray] = useState([]);
    const aircraftSearch = (index, val) => {
        if (aircraftArray.includes(val) === true) {
            const index = aircraftArray.indexOf(val);
            aircraftArray.splice(index, 1);
            setAircraftArray([...aircraftArray]);
        } else {
            setAircraftArray([...aircraftArray, val]);
        }
    }

    const [layoverAirportArray, setLayoverAirportArray] = useState([]);
    const layoverAirportSearch = (index, val) => {
        if (layoverAirportArray.includes(val) === true) {
            const index = layoverAirportArray.indexOf(val);
            layoverAirportArray.splice(index, 1);
            setLayoverAirportArray([...layoverAirportArray]);
        } else {
            setLayoverAirportArray([...layoverAirportArray, val]);
        }
    }

    const [layoverTimesArray, setLayoverTimesArray] = useState([]);
    const layoverTimeSearch = (val) => {
        if (layoverTimesArray.includes(val) === true) {
            const index = layoverTimesArray.indexOf(val);
            layoverTimesArray.splice(index, 1);
            setLayoverTimesArray([...layoverTimesArray]);
        } else {
            setLayoverTimesArray([...layoverTimesArray, val]);
        }
    }


    const [passengerArray, setPassengerArray] = useState([]);
    useEffect(() => {
        console.log("searchArray[10]===", searchArray[10]);
        for (let a = 0; a < searchArray[10]; a++) {
            console.log("loop check == ", a);
            if (a == 0) {
                var set_status = 1;
            } else {
                var set_status = "";
            }
            const MainArray = {
                "status": set_status,
                "check": 1,
                "sl": a
            }
            passengerArray.push(MainArray);
        }

        for (let b = 0; b < searchArray[11]; b++) {
            const MainArray = {
                "status": "",
                "check": 2,
                "sl": b
            }
            passengerArray.push(MainArray);
        }

        for (let c = 0; c < searchArray[12]; c++) {
            const MainArray = {
                "status": "",
                "check": 3,
                "sl": c
            }
            passengerArray.push(MainArray);
        }
    }, [searchArray[10], searchArray[11], searchArray[12]]);

    console.log("passengerArray==", passengerArray);


    const [ContactNumber, setContactNumber] = useState([]);
    const [email, setEmail] = useState([]);
    const [personName, setPersonName] = useState([]);
    useEffect(() => {
        console.log("totalTravelers===", searchArray[8]);
        for (let a = 0; a < searchArray[8]; a++) {
            const ContactNumberArray = {
                "NameNumber": a + 1 + ".1",
                "Phone": "",
                "PhoneUseType": "H"
            }
            const Email = {
                "Address": "",
                "NameNumber": a + 1 + ".1",
            }

            const PersonName = {
                "DateOfBirth": "",
                "Gender": "",
                "GivenName": "",
                "Infant": false,
                "NameNumber": a + 1 + ".1",
                "NameReference": "",
                "PassengerType": "ADT",
                "Surname": "",
                "document": {
                    "ExpirationDate": "",
                    "IssueCountry": "",
                    "NationalityCountry": "",
                    "Number": "",
                    "Type": "P"
                }
            }
            ContactNumber.push(ContactNumberArray);
            email.push(Email);
            personName.push(PersonName);
        }
    }, [searchArray[8]]);


    return (
        <>
            <Flight/>
            <div className="mb-10 rounded-t-lg md:mx-[2%] lg:mx-[10%] bg-gray-100">
                <div className="mt-10 ml-2">TODAY'S OFFERS</div>
                <div className="w-[95%] mx-auto mt-5">
                    <div className="font-bold">Product Promotion</div>
                    <div className="flex flex-row w-[60%] justify-between">
                        <div className="cursor-pointer" onClick={() => viewAllPromo("all")}>All Promotions
                            ({allPromo.length})
                        </div>
                        <div className="cursor-pointer" onClick={() => viewAllPromo("platform")}>Platform
                            ({platformPromos1.length})
                        </div>
                        <div className="cursor-pointer" onClick={() => viewAllPromo("seller")}>Seller
                            ({sellerPromos1.length})
                        </div>
                        <div className="cursor-pointer" onClick={() => viewAllPromo("partner")}>Partner
                            ({partnerPromos1.length})
                        </div>
                    </div>
                    <div className="border-b border-gray-300 w-[100%] mx-auto"></div>
                    <div className="mt-5 flex flex-row space-x-2">
                        {promoSlider > 0 ?
                            <div className="absolute">
                                <div onClick={() => promoIncrementOrDecrement("prev")}
                                     className="bg-white w-8 rounded-full shadow-md mt-7 ml-1 cursor-pointer md:w-9 px-1">
                                    <i className="bi bi-arrow-left-short h3"></i>
                                </div>
                            </div> : ""
                        }
                        {promoArray.map((v_promoCode, index) => {
                            return (
                                <>
                                    {checkSearchClickPromo === index ?
                                        <div id="cupon_code"
                                             className="cursor-pointer border border-gray-300"
                                             onClick={() => promoCodeSearchCheck(index, v_promoCode.promoCode)}>
                                            <div className="flex flex-row w-[100%] text-black">
                                                <div className="w-[75%]">
                                                    <div className="pl-5 pt-1 font-bold">First Order Voucher
                                                    </div>
                                                    <div className="pl-5">{v_promoCode.message}</div>
                                                </div>
                                                <div
                                                    className="w-[20%] text-center mt-5 h-5 bg-red-500 rounded-lg text-white">
                                                    COLLECT
                                                </div>
                                            </div>
                                            <div className="text-center text-black mt-1" id="dateTime">
                                                {v_promoCode.startFrom}
                                            </div>
                                        </div>
                                        :
                                        <div id="cupon_code" className="cursor-pointer"
                                             onClick={() => promoCodeSearchCheck(index, v_promoCode.promoCode)}>
                                            <div className="flex flex-row w-[100%] text-black">
                                                <div className="w-[75%]">
                                                    <div className="pl-5 pt-1 font-bold">First Order Voucher
                                                    </div>
                                                    <div id="messageFont" className="pl-5 w-[85%]">
                                                        {v_promoCode.message}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-[20%] text-center mt-5 h-5 bg-red-500 rounded-lg text-white">
                                                    COLLECT
                                                </div>
                                            </div>
                                            <div className="text-center text-black mt-1" id="dateTime">
                                                {v_promoCode.startFrom}
                                            </div>
                                        </div>
                                    }
                                </>
                            );
                        })}

                        {allPromo.length > promoScrollIndexEnd - 1 ?
                            <>
                                {parseInt(allPromo.length) - parseInt(promoSlider + promoScrollIndexEnd) > 0 ?
                                    <div className="absolute m-auto left-3/4 right-0 text-right">
                                        <div onClick={() => promoIncrementOrDecrement("next")}
                                             className="bg-white w-8 rounded-full shadow-md mt-7 ml-[55%] md:ml-[67%] lg:ml-[49%] cursor-pointer px-1">
                                            <i className="bi bi-arrow-right-short h3"></i>
                                        </div>
                                    </div> : ""
                                }
                            </> : ""
                        }
                    </div>
                </div>


                <div className="flex flex-row gap-x-5 mt-8 mb-10">
                    <div className="w-[20%]">
                        <div className="bg-white mx-[2%] rounded-lg h-28">
                            <div className="text-center pt-2" id="font_size">Book before it sold out</div>
                            <div className="flex flex-row mx-auto w-20 gap-x-1 mt-3">
                                00:00
                            </div>
                            <div className="flex flex-row w-20 mx-auto gap-x-5">
                                <div>Min</div>
                                <div>Sec</div>
                            </div>
                        </div>

                        <div className="bg-white mx-[2%] rounded-lg mt-5" id="font_size">
                            <div className="flex flex-row py-5 border-b justify-between px-[5%]">
                                <div className="text-xl">Sort & Filter</div>
                                <div className="text-xl text-red-500">RESET</div>
                            </div>
                            <div className="font-bold  px-[5%]">
                                Fare Type
                                <div className="border-b"></div>
                            </div>
                            <div className="mx-[4%]">
                                <div className="space-x-2 flex flex-row">
                                    <div onClick={() => filterByFareType('Y')}>
                                        <input type="checkbox"
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>
                                        Economy
                                    </div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div onClick={() => filterByFareType('J')}>
                                        <input type="checkbox"
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>
                                        Business
                                    </div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div onClick={() => filterByFareType('F')}>
                                        <input type="checkbox"
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>
                                        First Class
                                    </div>
                                </div>
                            </div>

                            <div className="font-bold  px-[5%] mt-5">
                                Schedule
                                <div className="border-b"></div>
                            </div>


                            <div className="mx-[4%] space-y-3">
                                <div className="py-1.5 text-xl">
                                    Departure Time
                                </div>
                                {departureTime.map((v_departureTime, indexDepartureTime) => (
                                    <div
                                        onClick={() => filterFlightByDepartureTime(indexDepartureTime, v_departureTime.valueTime)}
                                        className=
                                            {v_departureTime.checkSelect === true ?
                                                "border-red-600 cursor-pointer border rounded-lg py-1.5 flex flex-row xl:pl-6 md:pl-2 xl:space-x-8 lg:space-x-4 space-x-3" :
                                                "border rounded-lg cursor-pointer py-1.5 flex flex-row xl:pl-6 md:pl-2 xl:space-x-8 lg:space-x-4 space-x-3"
                                            }>
                                        <div>
                                            <img className="mx-auto rounded-lg m-2"
                                                 width={18}
                                                 height={18}
                                                 src={v_departureTime.image}
                                                 alt="Logo"/>
                                        </div>
                                        <div className="pt-1">{v_departureTime.viewTime}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="mx-[4%] space-y-3">
                                <div className="py-1.5 text-xl">
                                    Arrival Time
                                </div>
                                {arrivalTime.map((v_arrivalTime, indexArrivalTime) => (
                                    <div
                                        onClick={() => filterFlightByArrivalTime(indexArrivalTime, v_arrivalTime.valueTime)}
                                        className=
                                            {v_arrivalTime.checkSelect === true ?
                                                "border-red-600 cursor-pointer border rounded-lg py-1.5 flex flex-row xl:pl-6 md:pl-2 xl:space-x-8 lg:space-x-4 space-x-3" :
                                                "border rounded-lg cursor-pointer py-1.5 flex flex-row xl:pl-6 md:pl-2 xl:space-x-8 lg:space-x-4 space-x-3"
                                            }>
                                        <div>
                                            <img className="mx-auto rounded-lg m-2"
                                                 width={18}
                                                 height={18}
                                                 src={v_arrivalTime.image}
                                                 alt="Logo"/>
                                        </div>
                                        <div className="pt-1">{v_arrivalTime.viewTime}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="font-bold  px-[5%] mt-5">
                                Number of Stops
                                <div className="border-b"></div>
                            </div>
                            <div className="mx-[4%]">
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => numberOfStops(0)}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>
                                        Nonstop
                                    </div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => numberOfStops(1)}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div className="text-gray-400">
                                        1 Stop
                                    </div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => numberOfStops(2)}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div className="text-gray-400">
                                        2+ Stops
                                    </div>
                                </div>
                            </div>
                            <div className="font-bold  px-[5%] mt-5">
                                Airlines
                                <div className="border-b"></div>
                            </div>
                            <div className="mx-[4%]">
                                <div className="space-x-2 flex flex-row my-3 rounded-lg border">
                                    <i className="bi bi-search h6" style={{marginTop: 11, marginLeft: 5}}></i>
                                    <input className="h-10 pl-1 w-[94%] outline-0"
                                           placeholder="Search Airlines"/>
                                </div>
                                {removeDuplicates(airlinesList).map((v_airlinesList, index) => {
                                    let filterAirlince = airLinceList.careers.filter(function (el) {
                                            return el.careerCode == v_airlinesList
                                        }
                                    );
                                    return (
                                        <div
                                            className="space-x-2 flex flex-row">
                                            <div>
                                                <input type="checkbox"
                                                       onClick={() => airlinesSearch(index, v_airlinesList)}
                                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                            <div style={{fontSize: 12}}>
                                                {filterAirlince[0].name}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="font-bold  px-[5%] mt-5">
                                Aircraft
                                <div className="border-b"></div>
                            </div>


                            <div className="mx-[4%]">
                                <div className="space-x-2 flex flex-row my-3 rounded-lg border">
                                    <i className="bi bi-search h6" style={{marginTop: 11, marginLeft: 5}}></i>
                                    <input className="h-10 w-[94%] pl-1 outline-0"
                                           placeholder="Search Airlines"/>
                                </div>

                                {removeDuplicates(aircraftList).map((v_aircraftList, index) => (
                                    <div className="space-x-2 flex flex-row">
                                        <div>
                                            <input type="checkbox"
                                                   onClick={() => aircraftSearch(index, v_aircraftList)}
                                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        </div>
                                        <div>{v_aircraftList}</div>
                                    </div>
                                ))}
                            </div>

                            <div className="font-bold  px-[5%] mt-5">
                                Layover Airport
                                <div className="border-b"></div>
                            </div>
                            <div className="mx-[4%]">
                                {removeDuplicates(layoverAirportList).map((v_layoverAirportList, index) => (
                                    <div className="space-x-2 flex flex-row">
                                        <div>
                                            <input type="checkbox"
                                                   onClick={() => layoverAirportSearch(index, v_layoverAirportList)}
                                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        </div>
                                        <div>{v_layoverAirportList}</div>
                                    </div>
                                ))}
                            </div>

                            <div className="font-bold  px-[5%] mt-5">
                                Refundable
                                <div className="border-b"></div>
                            </div>
                            <div className="mx-[4%]">
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => refundable(false)}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>Non Refundable</div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => refundable(true)}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>Refundable</div>
                                </div>
                            </div>

                            <div className="font-bold  px-[5%] mt-5">
                                Layover Time
                                <div className="border-b"></div>
                            </div>
                            <div className="mx-[4%]">
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => layoverTimeSearch('0-5')}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>0h-5h</div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => layoverTimeSearch('5-10')}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>5h-10h</div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => layoverTimeSearch('10-15')}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>10-15h</div>
                                </div>
                                <div className="space-x-2 flex flex-row">
                                    <div>
                                        <input type="checkbox" onClick={() => layoverTimeSearch('15-500')}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                    <div>15h+</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-[80%]">
                        <div className="flex flex-row gap-x-5" id="font_size">
                            {airlinesSlider > 0 ?
                                <div onClick={() => incrementOrDecrement("prev")}
                                     className="bg-white shadow-lg h-10 px-1 cursor-pointer my-auto mr-2 rounded-full">
                                    <i className="bi bi-caret-left h2"></i>
                                </div> : <div
                                    className="bg-gray-300 shadow-lg h-10 px-1 my-auto mr-2 rounded-full">
                                    <i className="bi bi-caret-left h2"></i>
                                </div>
                            }

                            {sliceArray.map((v_airlinesList, index) => {
                                let filterAirlince = airLinceList.careers.filter(function (el) {
                                        return el.careerCode == v_airlinesList
                                    }
                                );

                                const flightArray = [];
                                for (let f = 0; f < flightScheduleDescs.length; f++) {
                                    const newObject = {
                                        'flightName': flightScheduleDescs[f]?.carrier?.marketing
                                    }
                                    flightArray.push(newObject);
                                }
                                var countFiltered = flightArray.filter(function (element) {
                                    return element.flightName == v_airlinesList;
                                }).length


                                return (
                                    <>
                                        {checkSearchClick === index ?
                                            <div onClick={() => airlinesSearch(index, v_airlinesList)}
                                                 className="flex flex-row bg-red-200 lg:w-64 space-x-2 border border-red-600 cursor-pointer">
                                                <div>
                                                    <img className="my-5 ml-4"
                                                         width={45}
                                                         height={45}
                                                         src={filterAirlince[0].logo}
                                                         alt="Logo"/>
                                                </div>
                                                <div className="my-auto">
                                                    <div className="mx-2"
                                                         style={{fontSize: 12}}>{filterAirlince[0].name}</div>
                                                    <div
                                                        className="mx-2">{countFiltered} Flights {v_airlinesList}</div>
                                                </div>
                                            </div> :
                                            <div onClick={() => airlinesSearch(index, v_airlinesList)}
                                                 className="flex flex-row bg-white lg:w-64 space-x-2 cursor-pointer">
                                                <div>
                                                    <img className="my-5 ml-4"
                                                         width={45}
                                                         height={45}
                                                         src={filterAirlince[0].logo}
                                                         alt="Logo"/>
                                                </div>
                                                <div className="my-auto">
                                                    <div className="mx-2"
                                                         style={{fontSize: 12}}>{filterAirlince[0].name} </div>
                                                    <div
                                                        className="mx-2"> {countFiltered} Flights {v_airlinesList}</div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                );
                            })}

                            {
                                removeDuplicates(airlinesList).length > 3 ?
                                    <>
                                        {parseInt(removeDuplicates(airlinesList).length) - parseInt(airlinesSlider + 4) > 0 ?
                                            <div onClick={() => incrementOrDecrement("next")}
                                                 className="bg-white shadow-lg h-10 px-1 cursor-pointer my-auto mr-2 rounded-full">
                                                <i className="bi bi-caret-right h2"></i>
                                            </div> : <div
                                                className="bg-gray-300 shadow-lg h-10 px-1 my-auto mr-2 rounded-full">
                                                <i className="bi bi-caret-left h2"></i>
                                            </div>
                                        }
                                    </> : ""
                            }
                        </div>

                        <div className="mt-5 flex flex-row gap-x-5 mx-[1%]">
                            <div onClick={() => filterByFlighthcfe('CHEAPEST')}
                                 className=
                                     {filterByFlight == "CHEAPEST" ?
                                         "border-red-600 flex p-5 border flex-row cursor-pointer bg-white gap-x-3 w-[32%]" :
                                         "flex border flex-row p-5 cursor-pointer bg-white gap-x-3 w-[32%]"
                                     }>
                                <div>
                                    <img width={40} height={40}
                                         src="/images/Money.png"
                                         alt="Logo" className="brand-image"/>
                                </div>
                                <div className="my-auto">
                                    <div>Cheapest Flights</div>
                                    {cheapestFlightsPrice ?
                                        <div>Tk {arrayFlightList[0]?.fareSummary.baseFareAfterDiscount}</div> :
                                        <div>Tk 0.00</div>
                                    }
                                </div>
                            </div>

                            <div onClick={() => filterByFlighthcfe('FASTEST')}
                                 className=
                                     {filterByFlight == "FASTEST" ?
                                         "border-red-600 flex border p-5 flex-row cursor-pointer bg-white gap-x-3 w-[32%]" :
                                         "flex border flex-row p-5 cursor-pointer bg-white gap-x-3 w-[32%]"
                                     }>
                                <div>
                                    <img width={40} height={40}
                                         src="/images/Earliest.png"
                                         alt="Logo" className="brand-image"/>
                                </div>
                                <div className="my-auto">
                                    <div>Fastest</div>
                                    {fastestFlightsPrice ?
                                        <div>Tk {arrayFlightList[0]?.fareSummary.baseFareAfterDiscount}</div> :
                                        <div>Tk 0.00 </div>
                                    }
                                </div>
                            </div>
                            <div onClick={() => filterByFlighthcfe('EARLIEST')}
                                 className=
                                     {filterByFlight == "EARLIEST" ?
                                         "border-red-600 flex border p-5 flex-row cursor-pointer bg-white gap-x-3 w-[32%]" :
                                         "flex border flex-row p-5 cursor-pointer bg-white gap-x-3 w-[32%]"
                                     }>
                                <div>
                                    <img width={40} height={40}
                                         src="/images/Rocket.png"
                                         alt="Logo" className="brand-image"/>
                                </div>
                                <div className="my-auto">
                                    <div>Earliest</div>
                                    {earliestFlightsPrice ?
                                        <div>Tk {arrayFlightList[0]?.fareSummary.baseFareAfterDiscount}</div> :
                                        <div>Tk 0.00</div>
                                    }
                                </div>
                            </div>
                        </div>

                        {waitingLoader ? 
                            <>
                                {arrayFlightList.length > 0 ? 
                                    <div className="h-auto mt-8 mx-[1%]" id="font_size">
                                        {arrayFlightList.map((v_flight, index) => {
                                            const legDescriptionsArray = mainArray.itineraryGroups[0].groupDescription.legDescriptions;
                                            if (v_flight.pricingInformation[0].fare.passengerInfoList[0].passengerInfo.fareComponents[0].segments[0].segment.flightAmenities != null) {
                                                const flightAmenity = v_flight.pricingInformation[0].fare.passengerInfoList[0].passengerInfo.fareComponents[0].segments[0].segment.flightAmenities[0];
                                                var beverageRef = flightAmenity?.beverageRef;
                                                var entertainmentRef = flightAmenity?.entertainmentRef;
                                                var layoutRef = flightAmenity?.layoutRef;
                                                var powerRef = flightAmenity?.powerRef;
                                                var foodRef = flightAmenity?.foodRef;
                                                var seatRef = flightAmenity?.seatRef;
                                                var wifiRef = flightAmenity?.wifiRef;

                                                if (mainArray.flightAmenities.wifi == null) {
                                                    var wifi = false;
                                                } else {
                                                    var wifi = mainArray.flightAmenities.wifi[wifiRef - 1]?.exists;
                                                }

                                                if (mainArray.flightAmenities.power == null) {
                                                    var power = false;
                                                } else {
                                                    var power = mainArray.flightAmenities.power[powerRef - 1]?.exists;
                                                }

                                                if (mainArray.flightAmenities.beverage == null) {
                                                    var beverage = false;
                                                } else {
                                                    var beverage = mainArray.flightAmenities.beverage[beverageRef - 1]?.exists;
                                                }

                                                if (mainArray.flightAmenities.entertainment == null) {
                                                    var entertainment = false;
                                                } else {
                                                    var entertainment = mainArray.flightAmenities.entertainment[entertainmentRef - 1]?.exists;
                                                }

                                                if (mainArray.flightAmenities.food == null) {
                                                    var food = false;
                                                } else {
                                                    var food = mainArray.flightAmenities.food[foodRef - 1]?.exists;
                                                }

                                            }
                                            return (
                                                <div className="rounded-lg bg-white mb-5" key={index}>
                                                    <div className="flex flex-row">
                                                        <div className="w-[75%] mx-[2%] mt-5">
                                                            <div className="flex flex-row justify-between">
                                                                <div
                                                                    className="flex flex-row gap-x-5 text-white">
                                                                    <div
                                                                        className="bg-blue-600 rounded-full px-5">Best
                                                                    </div>
                                                                    <div
                                                                        className="bg-red-500 rounded-full px-5">
                                                                        {v_flight.pricingInformation[0].fare.passengerInfoList[0]?.passengerInfo?.nonRefundable ?
                                                                            "Non Refundable" : "Partially Refundable"
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {searchTravelerClass ?
                                                                    <div>
                                                                        {searchTravelerClass === 'Y' ? "Economy" : ""}
                                                                        {searchTravelerClass === 'J' ? "Business" : ""}
                                                                        {searchTravelerClass === 'F' ? "First" : ""}
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        {travelerClass === 'Y' ? "Economy" : ""}
                                                                        {travelerClass === 'J' ? "Business" : ""}
                                                                        {travelerClass === 'F' ? "First" : ""}
                                                                    </div>
                                                                }
                                                            </div>

                                                            {legDescriptionsArray.map((v_legDescs, index) => {
                                                                const legRef = v_flight.legs[index].ref
                                                                const legDescs = mainArray.legDescs[legRef - 1]?.schedules[0].ref
                                                                const timeFrom = mainArray.scheduleDescs[legDescs - 1]?.departure.time;

                                                                let departureAirport = airportList.filter(function (el) {
                                                                        return el.code == v_legDescs.departureLocation
                                                                    }
                                                                );

                                                                let arrivalAirport = airportList.filter(function (el) {
                                                                        return el.code == v_legDescs.arrivalLocation
                                                                    }
                                                                );

                                                                let filterAirlince = airLinceList.careers.filter(function (el) {
                                                                        return el.careerCode == v_flight.pricingInformation[0].fare.validatingCarrierCode
                                                                    }
                                                                );

                                                                var travelsTimes = mainArray.legDescs[legRef - 1].elapsedTime;
                                                                var hour = Math.floor(travelsTimes / 60);
                                                                var minutes = travelsTimes % 60;
                                                                return (
                                                                    <div key={index}>
                                                                        <div
                                                                            className="flex flex-row justify-between mt-8 pt-2">
                                                                            <div>
                                                                                <img className="my-5 ml-4"
                                                                                     width={60}
                                                                                     height={60}
                                                                                     src={filterAirlince[0]?.logo}
                                                                                     alt="Logo"/>
                                                                            </div>
                                                                            <div>
                                                                                <div>{v_legDescs.departureDate}</div>
                                                                                <div
                                                                                    className="font-bold">{timeFrom.substring(0, 5)}</div>
                                                                                <div>{departureAirport[0].name}</div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="pl-5">
                                                                                    {mainArray.legDescs[legRef - 1]?.schedules.length > 1 ?
                                                                                        mainArray.legDescs[legRef - 1]?.schedules.length + " - Stops" :
                                                                                        "Non-Stop"
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    <img width={40} height={40}
                                                                                         src="/images/arrow.png"
                                                                                         alt="Logo"
                                                                                         className="brand-image"/>
                                                                                </div>
                                                                                <div
                                                                                    className="text-red-600 pl-5 font-bold">{hour}h {minutes}m
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div>
                                                                                    {
                                                                                        mainArray.scheduleDescs[legDescs - 1].arrival.dateAdjustment === null ?
                                                                                            mainArray.itineraryGroups[0].groupDescription.legDescriptions[0].departureDate :
                                                                                            ""
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    className="font-bold">{mainArray.scheduleDescs[legDescs - 1].arrival.time.substring(0, 5)}</div>
                                                                                <div>{arrivalAirport[0].name}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="flex flex-row justify-between">
                                                                            <div
                                                                                className="rounded-full py-1.5 px-5 mb-2 bg-white">{filterAirlince[0]?.name}</div>
                                                                            {v_flight?.jachaiSpecial ?
                                                                                <div
                                                                                    className="rounded-full py-1.5 px-5 mb-2 text-white"
                                                                                    style={{backgroundColor: '#047E94'}}>Jachai
                                                                                    Special</div> : ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                        <div
                                                            className="bg-red-100 h-auto w-[25%] rounded-tr-lg border-l">
                                                            <div
                                                                className="text-right xl:text-xl md:text-sm mt-5 pr-3 text-blue-400">{v_flight.fareSummary.promoCode}
                                                            </div>
                                                            <div
                                                                className="flex flex-row gap-x-5 mt-5 justify-between xl:text-xl md:text-sm mx-[4%]">
                                                                <div
                                                                    className="line-through">BDT {v_flight.fareSummary.baseFareAmount}</div>
                                                                <div>BDT {v_flight.fareSummary.baseFareAfterDiscount}</div>
                                                            </div>

                                                            <Link
                                                                to={`/tricket-booking/${(flightPrevewData + "," + index + "," + v_flight.id + "," + v_flight?.jachaiSpecial + ",=" + JSON.stringify(passengerArray) + ",c" + JSON.stringify(ContactNumber) + ",e" + JSON.stringify(email) + ",p" + JSON.stringify(personName) + ",-")}/flightPreview`}
                                                                className="no-underline xl:text-xl md:text-sm">
                                                                <div
                                                                    className="bg-red-600 rounded-full px-5 mt-5 w-[80%] mx-auto text-white py-2 text-center text-xl">
                                                                    Select
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="flex flex-row justify-between border-t border-b">
                                                        <div
                                                            className="flex flex-row mt-2 gap-x-2 ml-4">
                                                            <div>Flight Details</div>

                                                            <div className="cursor-pointer"
                                                                 onClick={() => checkFlightListDetailsFunction(v_flight.id)}>

                                                                {checkFlightListDetails == v_flight.id ?
                                                                    <>up</> :
                                                                    <>down</>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="flex flex-row gap-x-5 my-3 mr-4">

                                                            {wifi === true ?
                                                                <div>
                                                                    <img width={20} height={20} src="/images/wifi.png"
                                                                         alt="Logo" className="brand-image"/>
                                                                </div> : ""
                                                            }

                                                            {power === true ?
                                                                <div>
                                                                    <img width={20} height={20}
                                                                         src="/images/Plug-04.png" alt="Logo"
                                                                         className="brand-image"/>
                                                                </div> : ""
                                                            }
                                                            {beverage === true ?
                                                                <div>
                                                                    <img width={20} height={20} src="/images/coffee.png"
                                                                         alt="Logo" className="brand-image"/>
                                                                </div> : ""
                                                            }
                                                            {entertainment === true ?
                                                                <div>
                                                                    <img width={20} height={20}
                                                                         src="/images/youtube.png" alt="Logo"
                                                                         className="brand-image"/>
                                                                </div> : ""
                                                            }
                                                            {food === true ?
                                                                <div>
                                                                    <img width={20} height={20}
                                                                         src="/images/Restaurant.png"
                                                                         alt="Logo" className="brand-image"/>
                                                                </div> : ""
                                                            }
                                                        </div>
                                                    </div>

                                                    {checkFlightListDetails == v_flight.id ?
                                                        <>
                                                            {v_flight.legs.map((v_legs, indexLeg) => {
                                                                const dep_and_ari = groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg]
                                                                return (
                                                                    <div key={indexLeg}
                                                                         className="flex flex-row">
                                                                        <div className="w-[60%] border-r">
                                                                            <div className="mx-[4%]">
                                                                                {dep_and_ari.departureLocation} to {dep_and_ari.arrivalLocation} City
                                                                                Airport ,
                                                                                {groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg].departureDate}
                                                                            </div>
                                                                            <div
                                                                                className="border-b border-gray-300 w-[100%] mx-auto my-6"></div>
                                                                            <div>
                                                                                {
                                                                                    flightLegDescs[v_legs.ref - 1].schedules.map((v_LegDescs, index) => {
                                                                                        return (
                                                                                            <div key={index}
                                                                                                 className="mx-[1%] md:mx-[4%]">
                                                                                                <div
                                                                                                    className="flex flex-row space-x-8">
                                                                                                    <div>
                                                                                                        <img width={20}
                                                                                                             height={20}
                                                                                                             src="/images/flight.png"
                                                                                                             alt="Logo"
                                                                                                             className="brand-image"/>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div>{flightScheduleDescs[v_LegDescs.ref - 1]?.departure.airport}</div>
                                                                                                        <div>Aircraft: {flightScheduleDescs[v_LegDescs.ref - 1]?.carrier.equipment.code}</div>
                                                                                                        <div>Flight
                                                                                                            Number: {flightScheduleDescs[v_LegDescs.ref - 1]?.carrier.operatingFlightNumber}</div>
                                                                                                        <div>Operated
                                                                                                            by: {flightScheduleDescs[v_LegDescs.ref - 1]?.carrier.operating}</div>

                                                                                                        <div>Economy</div>
                                                                                                        <div>Available
                                                                                                            Seats: {
                                                                                                                groupedItineraryResponse.itineraryGroups[0].itineraries[searchArray[indexLeg]]?.pricingInformation[0]?.fare.passengerInfoList[0]?.passengerInfo?.fareComponents[0]?.segments[0]?.segment?.bookingCode
                                                                                                            }</div>

                                                                                                        {/*<div>Economy*/}
                                                                                                        {/*    ({pricingInformation[0]?.fare.passengerInfoList[0]?.passengerInfo?.fareComponents[0]?.segments[0]?.segment?.bookingCode})*/}
                                                                                                        {/*</div>*/}
                                                                                                        {/*<div>Available*/}
                                                                                                        {/*    Seats: {pricingInformation[0]?.fare.passengerInfoList[0]?.passengerInfo?.fareComponents[0]?.segments[0]?.segment?.seatsAvailable}</div>*/}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex flex-row justify-between mt-5">
                                                                                                    <div
                                                                                                        className="text-sm">
                                                                                                        <div
                                                                                                            className="font-bold text-lg">{flightScheduleDescs[v_LegDescs.ref - 1].departure.time} ({flightScheduleDescs[v_LegDescs.ref - 1].departure.airport})
                                                                                                        </div>
                                                                                                        <div>{groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg].departureDate}</div>
                                                                                                        <div>Tarminal-{flightScheduleDescs[v_LegDescs.ref - 1].departure.terminal}</div>
                                                                                                        <div>{flightScheduleDescs[v_LegDescs.ref - 1].departure.airport}</div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="my-auto text-sm">
                                                                                                        <img width={20}
                                                                                                             height={20}
                                                                                                             src="/images/arrow.png"
                                                                                                             alt="Logo"
                                                                                                             className="brand-image"/>
                                                                                                        <div
                                                                                                            className="text-center">{flightScheduleDescs[v_LegDescs.ref - 1].elapsedTime}</div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="text-sm">
                                                                                                        <div
                                                                                                            className="font-bold text-lg">{flightScheduleDescs[v_LegDescs.ref - 1].arrival.time} ({flightScheduleDescs[v_LegDescs.ref - 1].arrival.airport})
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {
                                                                                                                flightScheduleDescs[v_LegDescs.ref - 1].arrival.dateAdjustment === null ?
                                                                                                                    groupedItineraryResponse.itineraryGroups[0].groupDescription.legDescriptions[indexLeg].departureDate :
                                                                                                                    "+1"
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div>Tarminal-{flightScheduleDescs[v_LegDescs.ref - 1].arrival.terminal}</div>
                                                                                                        <div>{flightScheduleDescs[v_LegDescs.ref - 1].arrival.airport}</div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {indexLeg == 0 ?
                                                                                                    <div></div> :
                                                                                                    <div
                                                                                                        className="mt-6 flex flex-row mx-[1%] md:mx-[22%] my-5">
                                                                                                        <div
                                                                                                            className="border-b border-gray-300 w-[5%] md:w-[10%] my-3"></div>
                                                                                                        <div
                                                                                                            className="bg-red-100 rounded-full px-2 px-2 py-1">Change
                                                                                                            plane at
                                                                                                            AMS
                                                                                                            |
                                                                                                            2h 55m
                                                                                                            layover
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="border-b border-gray-300 w-[5%] md:w-[10%] my-3"></div>
                                                                                                    </div>
                                                                                                }

                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                        <div className="w-[40%]">

                                                                            <div
                                                                                className="p-3 border-b border-gray-300">
                                                                                <div
                                                                                    className="flex text-md flex-row justify-between mx-[8%]">
                                                                                    <div
                                                                                        className="cursor-pointer"
                                                                                        onClick={() => setDetailsFaireSummaryList(1)}>
                                                                                        Fare Summary
                                                                                    </div>
                                                                                    <div
                                                                                        className="cursor-pointer"
                                                                                        onClick={() => setDetailsFaireSummaryList(2)}>Baggage
                                                                                    </div>
                                                                                    <div
                                                                                        className="cursor-pointer"
                                                                                        onClick={() => setDetailsFaireSummaryList(3)}>Fare
                                                                                        Rules
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="flex text-md flex-row justify-between mx-[8%]">
                                                                                {detailsFaireSummaryList == 1 ?
                                                                                    <div
                                                                                        className="bg-blue-600 h-0.5 w-[33%]"></div> :
                                                                                    <div
                                                                                        className="h-0.5 w-[30%]"></div>
                                                                                }
                                                                                {detailsFaireSummaryList == 2 ?
                                                                                    <div
                                                                                        className="bg-blue-600 h-0.5 w-[33%]"></div> :
                                                                                    <div
                                                                                        className="h-0.5 w-[30%]"></div>
                                                                                }
                                                                                {detailsFaireSummaryList == 3 ?
                                                                                    <div
                                                                                        className="bg-blue-600 h-0.5 w-[33%]"></div> :
                                                                                    <div
                                                                                        className="h-0.5 w-[30%]"></div>
                                                                                }
                                                                            </div>

                                                                            <div className="h-auto mx-[8%]">

                                                                                {detailsFaireSummaryList == 1 ?
                                                                                    <>
                                                                                        {arrayFlightList[index].pricingInformation.map((v_fare_summary, summaryIndex) => (
                                                                                            <div key={summaryIndex}>
                                                                                                {v_fare_summary.fare.passengerInfoList.map((v_fare_summary_child, summaryChildIndex) => (
                                                                                                    <div
                                                                                                        key={summaryChildIndex}
                                                                                                        className="flex flex-row justify-between mt-5">
                                                                                                        <div>
                                                                                                            <div
                                                                                                                className="text-blue-400">Fare
                                                                                                                Summary
                                                                                                            </div>
                                                                                                            <div>{v_fare_summary_child.passengerInfo.passengerType} {v_fare_summary_child.passengerInfo.passengerNumber}</div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <div
                                                                                                                className="text-blue-400">Base
                                                                                                                Fare
                                                                                                            </div>
                                                                                                            <div> {v_fare_summary_child.passengerInfo.passengerTotalFare.currency} {v_fare_summary_child.passengerInfo.passengerTotalFare.baseFareAmount}</div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <div
                                                                                                                className="text-blue-400">Tax
                                                                                                            </div>
                                                                                                            <div>{v_fare_summary_child.passengerInfo.passengerTotalFare.currency} {v_fare_summary_child.passengerInfo.passengerTotalFare.totalTaxAmount}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                    : ""
                                                                                }
                                                                                {detailsFaireSummaryList == 2 ?
                                                                                    <div
                                                                                        className="mt-5">

                                                                                        <div
                                                                                            className="flex flex-row">
                                                                                            <div
                                                                                                className="text-blue-400 text-left w-[58%]">Flight
                                                                                            </div>
                                                                                            <div
                                                                                                className="text-blue-400 w-[14%]"> Flight
                                                                                                No
                                                                                            </div>
                                                                                            <div
                                                                                                className="text-blue-400 w-[14%]">Cabin
                                                                                            </div>
                                                                                            <div
                                                                                                className="text-blue-400 w-[14%]">Check
                                                                                                in
                                                                                            </div>
                                                                                        </div>

                                                                                        {arrayFlightList[index].pricingInformation.map((v_bagges, baggesIndex) => (
                                                                                            <div key={baggesIndex}>
                                                                                                {v_bagges.fare.passengerInfoList.map((v_bagges_child, baggesChildIndex) => (
                                                                                                    <div
                                                                                                        key={baggesChildIndex}>
                                                                                                        {v_bagges_child?.passengerInfo?.baggageInformation?.map((v_bagges_sub_child, baggesSubChildIndex) => {
                                                                                                            return (
                                                                                                                <div
                                                                                                                    key={baggesSubChildIndex}
                                                                                                                    className="flex flex-row border-b">
                                                                                                                    <div
                                                                                                                        className="w-[58%] text-left">
                                                                                                                        <div>DXB
                                                                                                                            -
                                                                                                                            FRA {v_bagges_sub_child.allowance.ref}</div>
                                                                                                                        <div>Deutsche
                                                                                                                            Lufthansa
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="w-[14%]">10
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="w-[14%]">7
                                                                                                                        KG
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="w-[14%]">{baggesInfo[v_bagges_sub_child.allowance.ref - 1].weight} {baggesInfo[v_bagges_sub_child.allowance.ref - 1].unit}</div>
                                                                                                                </div>
                                                                                                            );
                                                                                                        })}
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ))}
                                                                                    </div> : ""
                                                                                }
                                                                                {detailsFaireSummaryList == 3 ?
                                                                                    <div
                                                                                        className="mt-5 space-y-6">
                                                                                        <div
                                                                                            className="space-y-2">
                                                                                            <div
                                                                                                className=" text-blue-500">Cancellation
                                                                                            </div>
                                                                                            <div>Cancellation =
                                                                                                Airline's Fee +
                                                                                                Jachai
                                                                                                Fee
                                                                                            </div>
                                                                                            <div>Refund Amount =
                                                                                                Paid
                                                                                                Amount-Cancellation
                                                                                                Fee
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="space-y-2">
                                                                                            <div
                                                                                                className=" text-blue-500">Re-issue
                                                                                            </div>
                                                                                            <div>Re-issue Fee =
                                                                                                Airline's Fee + Fare
                                                                                                Difference + Jachai
                                                                                                Fee
                                                                                            </div>
                                                                                        </div>

                                                                                        <div>
                                                                                            *The airline's fee is
                                                                                            indicative and per
                                                                                            person. Convenience fee
                                                                                            non-refundation
                                                                                        </div>
                                                                                        {/*<div>*/}
                                                                                        {/*    <div*/}
                                                                                        {/*        className="justify-between flex flex-row">*/}
                                                                                        {/*        <div*/}
                                                                                        {/*            className="text-blue-500">Cancellation*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*        <div*/}
                                                                                        {/*            className="text-blue-500">Amount/Value*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*    <div*/}
                                                                                        {/*        className="justify-between flex flex-row">*/}
                                                                                        {/*        <div>(from Schedule*/}
                                                                                        {/*            flight*/}
                                                                                        {/*            departure)*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*        <div>(Per*/}
                                                                                        {/*            passenger)*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*</div>*/}

                                                                                        {/*<div*/}
                                                                                        {/*    className="border-b border-gray-300 w-[100%] mx-auto my-3"></div>*/}

                                                                                        {/*<div*/}
                                                                                        {/*    className="flex-row flex justify-between">*/}
                                                                                        {/*    <div>Maximum penalty*/}
                                                                                        {/*        amount*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*    <div>Adult: BDT 10,000*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*</div>*/}

                                                                                        {/*<div*/}
                                                                                        {/*    style={{backgroundColor: '#F4ECCE'}}*/}
                                                                                        {/*    className="rounded-lg p-2 justify-between my-5">*/}
                                                                                        {/*    <b>*Note For Voluntary*/}
                                                                                        {/*        Cancellation: </b>*/}
                                                                                        {/*    Service fees &*/}
                                                                                        {/*    gateway Charges will be*/}
                                                                                        {/*    additionally*/}
                                                                                        {/*    applied*/}
                                                                                        {/*    on*/}
                                                                                        {/*    top penalty amount. Some*/}
                                                                                        {/*    Taxes*/}
                                                                                        {/*    may*/}
                                                                                        {/*    not be*/}
                                                                                        {/*    refundable*/}
                                                                                        {/*</div>*/}

                                                                                        {/*<div>*/}
                                                                                        {/*    <div*/}
                                                                                        {/*        className="justify-between flex flex-row">*/}
                                                                                        {/*        <div*/}
                                                                                        {/*            className="text-blue-500">Date*/}
                                                                                        {/*            Change*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*        <div*/}
                                                                                        {/*            className="text-blue-500">Amount/Value*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*    <div*/}
                                                                                        {/*        className="justify-between flex flex-row">*/}
                                                                                        {/*        <div>(from Schedule*/}
                                                                                        {/*            flight*/}
                                                                                        {/*            departure)*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*        <div>(Per*/}
                                                                                        {/*            passenger)*/}
                                                                                        {/*        </div>*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*</div>*/}
                                                                                        {/*<div*/}
                                                                                        {/*    className="border-b border-gray-300 w-[100%] mx-auto my-3"></div>*/}
                                                                                        {/*<div*/}
                                                                                        {/*    className="flex-row flex justify-between">*/}
                                                                                        {/*    <div>Maximum penalty*/}
                                                                                        {/*        amount*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*    <div>Adult: BDT 10,000*/}
                                                                                        {/*    </div>*/}
                                                                                        {/*</div>*/}
                                                                                        {/*<div*/}
                                                                                        {/*    style={{backgroundColor: '#F4ECCE'}}*/}
                                                                                        {/*    className="rounded-lg p-2 justify-between my-5">*/}
                                                                                        {/*    <b>*Note For Voluntary*/}
                                                                                        {/*        Cancellation: </b>*/}
                                                                                        {/*    Service fees &*/}
                                                                                        {/*    gateway Charges will be*/}
                                                                                        {/*    additionally*/}
                                                                                        {/*    applied*/}
                                                                                        {/*    on*/}
                                                                                        {/*    top penalty amount. Some*/}
                                                                                        {/*    Taxes*/}
                                                                                        {/*    may*/}
                                                                                        {/*    not be*/}
                                                                                        {/*    refundable*/}
                                                                                        {/*</div>*/}
                                                                                        {/*<div*/}
                                                                                        {/*    className="bg-red-500 text-center rounded-full p-2 text-white">*/}
                                                                                        {/*    Show Details*/}
                                                                                        {/*</div>*/}
                                                                                        {/*<div*/}
                                                                                        {/*    style={{backgroundColor: '#F4ECCE'}}*/}
                                                                                        {/*    className="rounded-lg p-2 justify-between my-5">*/}
                                                                                        {/*    <b>*Important:</b> The*/}
                                                                                        {/*    airline*/}
                                                                                        {/*    fees*/}
                                                                                        {/*    are*/}
                                                                                        {/*    continuously*/}
                                                                                        {/*    changing. Flight Expert*/}
                                                                                        {/*    doesn’t*/}
                                                                                        {/*    certify the*/}
                                                                                        {/*    fees/price shown above.*/}
                                                                                        {/*    The*/}
                                                                                        {/*    mentioned fees are*/}
                                                                                        {/*    for prediction of*/}
                                                                                        {/*    easiness of*/}
                                                                                        {/*    our*/}
                                                                                        {/*    customer. You*/}
                                                                                        {/*    can change the date only*/}
                                                                                        {/*    by*/}
                                                                                        {/*    choosing*/}
                                                                                        {/*    the same*/}
                                                                                        {/*    airline on a different*/}
                                                                                        {/*    date.*/}
                                                                                        {/*    Users*/}
                                                                                        {/*    have to pay*/}
                                                                                        {/*    the fare difference of*/}
                                                                                        {/*    RBD or*/}
                                                                                        {/*    classes between*/}
                                                                                        {/*    booked and latest*/}
                                                                                        {/*    booking, if*/}
                                                                                        {/*    there*/}
                                                                                        {/*    is any.*/}
                                                                                        {/*</div>*/}

                                                                                    </div> : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                        : ""
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : <div className="text-center mt-5 text-lg">No result found</div>}</> :
                            <div className="mx-auto w-20 mt-2">
                                <img width={100} height={100}
                                     src="/images/jachai-loder-2nds.gif"
                                     alt="Logo" className="brand-image"/>
                            </div>
                        }
                        <div className="h-28">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FlightList;