import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import OrderList from "../../components/Orders";
import React from "react";

const OrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <OrderList />
    </MainLayout>
  );
};

export default OrderPage;
