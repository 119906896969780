import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {persistor, store} from "./redux/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import {ConfigProvider} from "antd";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ConfigProvider
                    theme={{
                        components: {
                            Radio: {
                                colorPrimary: "#00b96b",
                            },
                        },
                    }}
                >
                    <App/>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
