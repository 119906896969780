import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddCareer from "./AddCareers";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../utils/services";

const CareerList = (): ReactElement => {
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedCareer, setSelectedCareer] = useState(undefined);
  const [selectedCareerForEdit, setSelectedCareerForEdit] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const isDeleted = "false";
  const [careerOptions, setCareerOptions] = useState({
    loading: false,
    list: [],
  });
  const [careerData, setCareerData] = useState<any>({
    loading: false,
    data: null,
  });

  const getCareers = useCallback(async () => {
    setCareerData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    axios
      .get(
        `${encodedUri}/admin/career?deleted=${isDeleted}` +
          (key ? `&name=${key}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (page
            ? `&page=${
                page == careerData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCareerData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCareerData({ loading: false, data: [] });
        console.error("Career: Error", err);
      });
  }, [type, key, isActive,   sortBy,sortOrder, isDeleted, limit, page]);

  useEffect(() => {
    getCareers();
  }, [getCareers]);

  const onClose = () => {
    getCareers();
    setVisible(undefined);
    setSelectedCareer(undefined);
    setSelectedCareerForEdit(undefined);
  };

  const getCareersOptions = useCallback(async (key?: string) => {
    setCareerOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/career?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCareerOptions({
      loading: false,
      list: res?.data?.careers?.map((career: any) => ({
        label: career.name,
        value: career.name,
      })),
    });
  }, []);

  const onDelete = async (id: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/career`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          //setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Career Deleted Successfully", "success");
            setVisible(undefined);
            // setVisiblePopconfirm(false);
            getCareers();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/career`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          careers: [
            {
              id: id,
              isActive: val,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getCareers();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "career") getCareersOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCareersOptions]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };

  const reseAllFieldData = () => {
    setKey("");
    setIsActive("");
    setSortBy("");
    setSortOrder("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getCareersOptions("");
    }
  }, [showSearch]);
  return (
    <> 
      <BreadCrumb
        title={`Career List`}
        subTitle={`${
          careerData?.data?.totalElements ||
          careerData?.data?.careers?.length ||
          0
        } Career(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}> 
          <Form layout="vertical" form={form} className={styles.formInline}>
          <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            <Form.Item name="career_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Career"
                optionFilterProp="children"
                onChange={(val) => setKey(val)}
                onSearch={(e) => handleSearch(e, "career")}
                filterOption={() => {
                  return true;
                }}
                options={careerOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>

            </div>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {careerData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Career</th>
                      <th scope="col">Code</th>
                      <th scope="col">Country</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {careerData?.data?.careers?.length ? (
                      careerData?.data?.careers?.map(
                        (career: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            // onClick={() =>
                            //   navigate(`/careers/${career?.id}/details`)
                            // }
                          >
                            <td>
                              <Avatar size={40} src={career?.logo} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {career?.name}
                              </span>
                            </td>
                            <td>{career?.careerCode}</td>
                            <td>{career?.country}</td>

                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={career?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(career?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  career?.id === confirmLoading
                                }
                              />
                            </td>

                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedCareerForEdit(career);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                              {isAllowedService(`Carrers Delete`) && (
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={career?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    console.log(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDelete(career?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(career?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              )}
                              {/* <Popconfirm
                                placement="left"
                                title="Are you sure to delete?"
                                visible={career?.id === visiblePopconfirm}
                                onConfirm={(e: any) => {
                                  console.log(true);
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(career?.id);
                                }}
                                okButtonProps={{
                                  loading: confirmLoading,
                                  className: "bg-blue-400",
                                }}
                                onCancel={handleCancel}
                              >
                                <Button
                                  className="mx-1"
                                  type="dashed"
                                  danger
                                  shape="round"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    showPopconfirm(career?.id);
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </Popconfirm> */}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...careerData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Career"
            : selectedCareerForEdit
            ? "Edit Career"
            : "Career Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedCareer || selectedCareerForEdit}
        width={450}
      >
        {visible || selectedCareerForEdit ? (
          <AddCareer
            onCloseMethod={onClose}
            visibleData={selectedCareerForEdit}
          />
        ) : undefined}
      </Drawer>
    </>
  );
};

export default CareerList;
 
