import React from "react";
import MainLayout from "../../../../components/Layouts";
import CampaignProducts from "../../../../components/Promotions/Campaign/Product/campaign-products";

const CampaignProductPage = () => {
  return (
    <MainLayout>
      <CampaignProducts />
    </MainLayout>
  );
};

export default CampaignProductPage;
