import { Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import SubmitResetBtn from "../common/SubmitBtn";
import React from "react";

const AssistantOta = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [assistantOtaData, setAssistantOtaData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      agencyShareCode: data.agencyShareCode,
      email: data.email,
      isActive: true,
      mobileNumber: data.mobileNumber,
      name: data.name,
      shortName: data.shortName,
    };

    if (assistantOtaData) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/assistant-ota`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          agencyShareCode: data.agencyShareCode,
          email: data.email,
          isActive: true,
          mobileNumber: data.mobileNumber,
          name: data.name,
          shortName: data.shortName,
          id: assistantOtaData.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "AssistantOta Updated Successfully",
              "success"
            );
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/assistant-ota`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("AssistantOta Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setAssistantOtaData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (assistantOtaData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [assistantOtaData, form, visibleData]);

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...assistantOtaData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Short Name"
          rules={[
            {
              required: true,
              message: "Short Name is Required!",
            },
          ]}
          name="shortName"
        >
          <Input id="shortName" type="text" placeholder="Enter Short Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Agency Share Code"
          rules={[
            {
              required: true,
              message: "Agency Share Code is Required!",
            },
          ]}
          name="agencyShareCode"
        >
          <Input
            id="agencyShareCode"
            type="text"
            placeholder="Enter Agency Share Code"
          />
        </Form.Item>

        <Form.Item hasFeedback label="Email" name="email">
          <Input id="email" type="email" placeholder="Enter Email" />
        </Form.Item>

        <Form.Item hasFeedback label="Mobile Number" name="mobileNumber">
          <Input
            id="mobileNumber"
            type="number"
            min={0}
            placeholder="Enter Mobile Number"
          />
        </Form.Item>

        <input type="submit" />

        {/* <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
          }}
          onClickReset={resetData}
          disabled={loading || !!error}
          loading={loading || !!error}
        /> */}
      </Form>
    </>
  );
};

export default AssistantOta;
