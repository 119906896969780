import React, { useState } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { Button, Space, Upload } from "antd";
import Papa from "papaparse";
import styles from "../../../styles/tailwind/List.module.css";
import { responseNotification } from "../../../utils/notify";
import builkproductcsv from "../../../demo-files/shop_products.csv";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { structurizeData } from "../../../utils/csv/ProductUploadCSV";
import * as d3 from "d3";

const BulkProductUpdate = () => {
  const { token, shopInfo } = useSelector((state) => state?.authReducer);
  const shopId = shopInfo?.id;
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);
  //State to store the values
  const [values, setValues] = useState([]);
  const history = useNavigate();
  const readUploadFile = (val) => {
    console.log("val ", val);
    const mainFile = val.fileList[0]?.originFileObj;
    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        console.log(123, data);
        d3.csv(data).then(async (res) => {
          console.log(1234, res);

          const productCSVData = await structurizeData(res);
          console.log("get csv ", productCSVData);
          setParsedData(productCSVData.products || []);
        });
      };
      reader.readAsDataURL(mainFile);
    } else {
      setParsedData([]);
    }
  };

  const changeHandler = (event) => {
    readUploadFile(event.target);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    // Papa.parse(event.target.files[0], {
    //   header: true,
    //   skipEmptyLines: true,
    //   complete: function (results) {
    //     const rowsArray = [];
    //     const valuesArray = [];

    //     // Iterating data to get column name and their values
    //     results.data.map((d) => {
    //       rowsArray.push(Object.keys(d));
    //       valuesArray.push(Object.values(d));
    //     });
    //     // Parsed Data Response in array format
    //     setParsedData(results.data);
    //     // Filtered Column Names
    //     setTableRows(rowsArray[0]);
    //     // Filtered Values
    //     setValues(valuesArray);
    //   },
    // });
  };
  const handleOnSubmit = async (e) => {
    // e.preventDefault();

    setLoading(true);
    const readyData = e && {
      productUpdateRequests: parsedData,
    };
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/product/bulk-update?shopId=${shopId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Product Bulk Updated Successfully", "success");
          //history?.goBack();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const simpleFileDownload = () => {
    window.location.href = `${builkproductcsv}`;
  };
  //add new excl file end
  return (
    <React.Fragment>
      <ShopLayout shopId={shopInfo.id} title="Product List" subTitle="Details">
        <>
          <section className="product-lists">
            <div className="product-list-item-area white-bg">
              <BreadCrumb
                title="Bulk Product List"
                extra={[
                  <Button
                    shape="round"
                    type="primary"
                    className="bg-blue-600"
                    onClick={() => setReArrangeDrawer(true)}
                  ></Button>,
                  <div className="d-flex">
                    <Button
                      type="dashed"
                      className="mx-2"
                      onClick={simpleFileDownload}
                    >
                      <DownloadOutlined /> Sample File
                    </Button>
                  </div>,
                ]}
              />

              <div className="content-body_ rounded-2xl">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
                  <div
                    className="single-product-wrapper"
                    style={{ padding: "5px" }}
                  >
                    <div className="single-product-heading major">
                      {/* File Uploader */}
                      {/* <input
                        type="file"
                        name="file"
                        onChange={changeHandler}
                        accept=".csv"
                        style={{ display: "block", margin: "20px auto" }}
                      /> */}
                      <Upload onChange={readUploadFile}>
                        <Button
                          type="dashed"
                          size="large"
                          shape="round"
                          danger
                          htmlType="button"
                          icon={<UploadOutlined />}
                        >
                          Upload Contacts (.csv)
                        </Button>
                      </Upload>
                    </div>
                  </div>
                  <div className={styles.contentWrapper}>
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              {tableRows.map((rows, index) => {
                                return <th key={index}>{rows}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {values.map((value, index) => {
                              return (
                                <tr key={index}>
                                  {value.map((val, i) => {
                                    return <td key={i}>{val}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Space>
                      <Button
                        shape="round"
                        type="dashed"
                        danger
                        onClick={(e) => {
                          handleOnSubmit(e);
                        }}
                        disabled={parsedData.length < 1}
                        style={{ margin: "30px" }}
                      >
                        <UploadOutlined /> Upload Product
                      </Button>
                    </Space>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </ShopLayout>
    </React.Fragment>
  );
};

export default BulkProductUpdate;
