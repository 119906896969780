import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Modal,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../utils/services";
import AddBank from "./AddBank";
import BankBranchList from "./BankAssignBranchList";
import { AddBtn, Loader, Pagination } from "../common";

const BankList = (): ReactElement => {
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(10);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedBank, setSelectedBank] = useState(undefined);
  const [selectedBankForEdit, setSelectedBankForEdit] = useState(undefined);
  const [bankId, setBankId] = useState<any>();
  const [countryCode, setCountryCode] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [selectedBranchId, setSelectedBranchId] = useState<any>(undefined);
  const [selectedBankId, setSelectedBankId] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const isDeleted = "false"; 
  const [branchId, setBranchId] = useState<any>();
  const [branchOptions, setBranchOptions] = useState({
    loading: false,
    list: [],
  });
  const [selectedBranchForAssign, setSelectedBranchForAssign] =
    useState<any>(undefined);
  const [bankOptions, setBankOptions] = useState({
    loading: false,
    list: [],
  });

  const [bankData, setBankData] = useState<any>({
    loading: false,
    data: null,
  });

  const getBanks = useCallback(async () => {
    setBankData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    axios
      .get(
        `${encodedUri}/admin/bank?limit=${limit}` +
          (bankId ? `&key=${bankId}` : ``) +
          (page ? `&page=${page}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBankData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBankData({ loading: false, data: [] });
        console.error("Bank: Error", err);
      });
  }, [type, bankId, limit, page]);

  const getBranchOptions = useCallback(
    async (key: any) => {
      setBranchOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_PAY_API}`;
      return axios
        .get(
          `${encodedUri}/admin/branch?page=0&limit=100` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBranchOptions({
            loading: false,
            list: res.data?.branches?.map((branch: { name: any; id: any }) => ({
              label: branch.name,
              value: branch.id,
            })),
          });
        })
        .catch((err) => {
          setBranchOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );
  useEffect(() => {
    getBranchOptions("");
  }, [getBranchOptions]);
  useEffect(() => {
    getBanks();
  }, [getBanks]);


  const getBankOptions = useCallback(async (key?: string) => {
    setBankOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/bank?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBankOptions({
      loading: false,
      list: res?.data?.banks?.map((bank: any) => ({
        label: bank.name,
        value: bank.name,
      })),
    });
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      branchId: data?.branchId,
      bankId: selectedBranchForAssign?.id,
    };

    await fetch(
      `${process.env.REACT_APP_PAY_API}/admin/bank/add-branch`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Branch Assigned Successfully", "success");
          info(res?.tagMultiShopAddResponses);
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_PAY_API}/admin/bank`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bankList: [
            {
              id: id,
              isActive: val,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getBanks();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "bank") getBankOptions(value);
        if (field === "branch") getBranchOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getBankOptions,getBranchOptions]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };

  const reseAllFieldData = () => {
    setBankId("");
    setKey("");
    setIsActive("");
    setCountryCode("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getBankOptions("");
    }
  }, [showSearch]);

  const handleOk = () => {
    setSelectedBranchForAssign(false);
  };

  function info(alreadyExistsData: any) {
    Modal.info({
      title: "Assign Branch Info",
      content: (
        <>
          {alreadyExistsData?.map((data, i) => (
            <div key={i} className="mb-3">
              <p className="text-gray-800 font-semibold">
                Added {data?.newAdded} shop(s) and already exists{" "}
                {data?.alreadyExists} shop(s) in tag -{" "}
                <span className="font-bold text-red-900">{data?.tagName}</span>
              </p>
              <ol className="text-gray-500">
                {data?.alreadyExistsShopName?.map((shop, index) => (
                  <li key={index}>
                    {index + 1}. {shop}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </>
      ),
      width: 600,
      onOk() {},
      okText: "GOT IT",
      okButtonProps: { danger: true },
    });
  }
  const onClose = () => {
    getBanks();
    setVisible(undefined);
    setSelectedBank(undefined);
    setSelectedBankForEdit(undefined);
    setSelectedBranchForAssign(undefined);
    setSelectedBranchId(undefined);
    setSelectedBankId(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={`Bank List`}
        subTitle={`${
          bankData?.data?.totalElements || bankData?.data?.airports?.length || 0
        } Bank(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="vertical" form={form} className={styles.formInline}>
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <Form.Item label="Bank Name" name="airport_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Bank Name"
                  optionFilterProp="children"
                  onChange={(val) => setBankId(val)}
                  onSearch={(e) => handleSearch(e, "airport")}
                  filterOption={() => {
                    return true;
                  }}
                  options={bankOptions?.list}
                ></Select>
              </Form.Item>
            </div>
          </Form>

          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )} 

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {bankData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col">Bank Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Help Line</th>
                      <th scope="col">Swift Code</th>
                      <th scope="col">Status</th>
                      <th scope="col">Operation Status</th>
                      <th scope="col">Assign Branch</th>
                      <th scope="col">Action</th>
                    </tr> 
                  </thead>

                  <tbody>
                    {bankData?.data?.banks?.length ? (
                      bankData?.data?.banks?.map((bank: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          // onClick={() =>
                          //   navigate(`/airports/${airport?.id}/details`)
                          // }
                        >
                          <td>{index + 1}</td>
                          <td>
                            <span className="font-medium text-gray-500">
                              {bank?.name}
                            </span>
                          </td>
                          <td>{bank?.email}</td>
                          <td>{bank?.helpline}</td>
                          <td>{bank?.swiftCode}</td>
                          <td>
                            {bank?.isActive == true && (
                              <span className="border p-1 px-5 rounded-2xl bg-white">
                                Active
                              </span>
                            )}
                            {bank?.isActive == false && (
                              <span className="border p-1 px-5 rounded-2xl bg-white text-red-600">
                                Inactive
                              </span>
                            )}
                          </td>
                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={bank?.isActive}
                              onChange={(val, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(bank?.id, val);
                              }}
                              loading={
                                confirmLoading && bank?.id === confirmLoading
                              }
                            />
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="primary"
                              danger
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedBranchForAssign(bank);
                              }}
                            >
                              Assign Branch
                            </Button>
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="bg-white"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedBankForEdit(bank);
                              }}
                            >
                              <EditOutlined />
                            </Button>
                            <Button
                                title="Shop List"
                                shape="round"
                                type="primary"
                                danger
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedBankId(bank?.id);
                                }}
                              >
                                <UnorderedListOutlined />
                              </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...bankData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>


      <Modal
        title="Bank Branch List"
        onCancel={onClose}
        open={selectedBankId}
        width={700}
        centered
        footer={false}
      >
        <BankBranchList onCloseMethod={onClose} visibleData={selectedBankId} />
      </Modal>

      <Modal
        title="Assign Branch"
        onCancel={onClose}
        open={selectedBranchForAssign}
        onOk={handleOk}
        width={700}
        centered
        footer={false}
      >
        <div>
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            onFinish={onSubmit}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              hasFeedback
              label="Select Branch"
              name="branchId"
              rules={[
                {
                  required: true,
                  message: "Pleasse Select Minimum One Branch!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select Branch"
                optionFilterProp="children"
                onChange={(val) => setBranchId(val)}
                onSearch={(e) => handleSearch(e, "branch")}
                filterOption={(input, option) => {
                  return true;
                }}
                options={branchOptions?.list}
                loading={branchOptions?.loading}
            
              ></Select>
            </Form.Item>
            <div className="buttons-container mt-3">
              <Button
                type="primary"
                htmlType="submit"
                className="add-submit-btn text-center mr-2"
              >
                Submit
              </Button>
              <Button
                type="ghost"
                htmlType="button"
                onClick={() => form?.resetFields()}
                className="reset-submit-btn text-center mr-2"
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      

      <Drawer
        title={
          visible
            ? "Add Bank"
            : selectedBankForEdit
            ? "Edit Bank"
            : "Bank Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedBank || selectedBankForEdit}
        width={450}
      >
        {visible || selectedBankForEdit ? (
          <AddBank onCloseMethod={onClose} visibleData={selectedBankForEdit} />
        ) : undefined}
      </Drawer>
    </>
  );
};

export default BankList;
