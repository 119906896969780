import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import CampaignLayout from "../Layout";
import OrderList from "../../../Orders";
import { useSelector } from "react-redux";

const CampaignOrdersList = (): ReactElement => {
  const { campaignInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <CampaignLayout
        campaignId={campaignInfo?.id}
        title="Order List"
        subTitle="Order List"
        extra={<></>}
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            {/* <OrderList inCampaign={true} getCampaignId={campaignInfo?.id} /> */}
          </div>
        </div>
      </CampaignLayout>
    </React.Fragment>
  );
};

CampaignOrdersList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignOrdersList;
