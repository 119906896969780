import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Empty, Form, Modal, Select, Tree } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Loading from "../../common/Loader";
import { responseNotification } from "../../../utils/notify";
import ShopLayout from "../Layout";
import _ from "lodash";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
const { DirectoryTree } = Tree;

const ShopCategoryList = () => {
  const { type, token, shopInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [categoryName, setCategoryName] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [visible, setVisible] = useState(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [categoryOptions, setCategoriesOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [categoryId, setCategoryId] = useState<any>();
  const [categoryListData, setCategoryListData] = useState<any>({
    loading: false,
    data: null,
  });
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [form] = Form.useForm();

  // All Category List For Add Category
  const getCategoriesOptions = useCallback(
    async (key: any) => {
      setCategoriesOptions({ loading: true, list: [] });
      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/category/search?type=${type}&page=0&limit=20&isParent=true&key=${key}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategoriesOptions({
        loading: false,
        list: res?.data?.categories?.map(
          (category: { id: any; title: any }) => {
            return {
              value: category?.id,
              label: category?.title,
            };
          }
        ),
      });
    },
    [key]
  );

  useEffect(() => {
    if (isModalVisible) {
      getCategoriesOptions("");
    }
  }, [isModalVisible]);

  const fetchRef = useRef(0);
  const categorySearchDebounce = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      getCategoriesOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getCategoriesOptions]);

  // Category List By Shop ID
  const getShopCategory = useCallback((getShopId: string) => {
    if (getShopId) {
      try {
        setCategoryListData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/categories?shopId=${getShopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCategoryListData({
                loading: false,
                data: data?.data,
              });
            } else {
              setCategoryListData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setCategoryListData({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setCategoryListData({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    getShopCategory(shopInfo?.id);
  }, [getShopCategory]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      categories: data.categoryId.map((category: any, index: string) => ({
        categoryId: category,
        displayOrder:
          index +
          1 +
          (categoryListData?.data?.categories?.[
            categoryListData?.data?.categories?.length - 1
          ]?.displayOrder * 1 || 0),
      })),
      shopId: shopInfo?.id,
    };

    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/category-shop`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Category Assigned Successfully", "success");
          getShopCategory(shopInfo?.id);
          form.resetFields();
          handleOk();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onRemoveShopCategory = () => {
    Modal.confirm({
      title: "Do you confirm?",
      icon: <ExclamationCircleOutlined />,
      content: `Will you confirm to remove this category from this shop?`,
      okText: "Remove",
      cancelText: "Cancel",
      onOk: async () => {
        if (categoryId) {
          await fetch(
            `${process.env.REACT_APP_CATALOG_WRITER_API}/category-shop`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                categories: [
                  {
                    categoryId: categoryId,
                    displayOrder: 0,
                  },
                ],
                shopId: shopInfo.id,
                deleted: true,
              }),
            }
          )
            .then((res) => res.json())
            .then((res) => {
              if (res.statusCode === 200) {
                responseNotification(
                  "Category Deleted Successfully",
                  "success"
                );
                getShopCategory(shopInfo.id);
                setVisible(undefined);
              } else if (res.status === 500) {
                responseNotification("Internal server error", "error");
              } else {
                responseNotification(
                  res.message || "something wrong",
                  "warning"
                );
              }
            })
            .catch((err) => {
              responseNotification(
                `${"Internal server error"} ${err}`,
                "error"
              );
              console.error("err", err);
            });
        }
      },
    });
  };

  useEffect(() => {
    if (visible) {
      onRemoveShopCategory();
    }
  }, [visible]);

  const changeOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    categoryListData?.data?.categories?.find((category: any, i: string) => {
      if (category?.category?.id === node?.key) {
        curr = i;
        item = category;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? categoryListData?.data?.categories
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : categoryListData?.data?.categories?.slice(0, pos),
        item,
        curr > pos
          ? categoryListData?.data?.categories
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, categoryListData?.data?.categories?.length - 1)
          : categoryListData?.data?.categories?.slice(
              pos,
              categoryListData?.data?.categories?.length
            )
      )?.map((item, i) => ({
        categoryId: item?.category?.id,
        displayOrder: i + 1,
      }));

    if (formated) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/category-shop`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          categories: formated,
          shopId: shopInfo?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            getShopCategory(shopInfo?.id);
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const showPopconfirm = (id: any) => {
  //   setVisible(id);
  // };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setVisible(undefined);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (visible) {
      setSelectedCategory(undefined);
    }
  }, [selectedCategory, selectedCategory]);

  useEffect(() => {
    setSelectedCategory(undefined);
  }, [visible]);

  console.log("Length:", categoryListData);

  return (
    <React.Fragment>
      <ShopLayout
        shopId={shopInfo?.id}
        title="Category List"
        subTitle="Categories"
        extra={
          <Button
            danger
            type="primary"
            icon={<PlusOutlined />}
            title="Add Product"
            onClick={showModal}
          >
            Add Category
          </Button>
        }
      >
        <div className="content-body rounded-2xl bg-white shadow-lg shadow-gray-200 p-4 mb-6">
          <div className="">
            <h3 className="mb-4 text-xl font-bold">Categories</h3>

            {categoryListData?.loading ? (
              <Loading />
            ) : categoryListData?.data?.categories?.length ? (
              <DirectoryTree
                draggable
                showLine
                multiple
                showIcon={false}
                switcherIcon={<DownOutlined />}
                treeData={categoryListData?.data?.categories.map(
                  (category: any) => ({
                    title: category?.category?.title,
                    key: category?.category?.id,
                    children: category?.categories?.map((child: any) => ({
                      title: child?.categories?.title,
                      key: child?.categories?.id,
                    })),
                  })
                )}
                onDrop={(e: any) => {
                  changeOrder(e.dropPosition, e.dragNode);
                }}
                onRightClick={(val: any) => {
                  setCategoryId(val?.node?.key);
                  setCategoryName(val?.node?.title);
                  setVisible(val?.node?.key);
                }}
              />
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </ShopLayout>

      <Modal
        title="Assign Category"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...categoryListData,
          }}
        >
          <Form.Item
            hasFeedback
            label="Select Category"
            name="categoryId"
            rules={[
              {
                required: true,
                message: "Pleasse Select Minimum One Category!",
              },
            ]}
          >
            <Select
              disabled={!categoryListData?.data ? true : false}
              allowClear
              showSearch
              placeholder="Search Category"
              optionFilterProp="children"
              onChange={(val) => setCategoryId(val)}
              onSearch={categorySearchDebounce}
              filterOption={(input, option) => {
                return true;
              }}
              options={categoryOptions?.list}
              mode="multiple"
            ></Select>
          </Form.Item>

          <div className="buttons-container mt-3">
            <Button
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => form?.resetFields()}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ShopCategoryList;
