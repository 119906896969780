import {
    faBullhorn,
    faClone,
    faCopy,
    faCreditCard,
    faCube,
    faFilm,
    faGift,
    faGifts,
    faHandshake,
    faImages,
    faLaptop,
    faList,
    faObjectUngroup,
    faShop,
    faTicket,
    faUser,
    faUsers,
    faUserShield,
    faUserTie,

} from "@fortawesome/free-solid-svg-icons";

import UserManagementPage from "../pages/administration";
import {ServiceType} from "../type/service";
import ServiceGroupPage from "../pages/administration/groups";
import UserServicePage from "../pages/administration/services";
import DashboardPage from "../pages";
import OrderPage from "../pages/orders";
import SingleOrderPage from "../pages/orders/details";
import CancelRefundOrderPage from "../pages/orders/cancelRefundableOrder";
import ModifyOrderPage from "../pages/orders/modifyOrder";
import CancelOrderPage from "../pages/orders/cancelOrder";
import PendingCreatePage from "../pages/orders/pendingOrder";
import PartialConfirmedPage from "../pages/orders/partialConfirmedOrder";
import ConfirmedPage from "../pages/orders/confirmedOrder";
import CompletedPage from "../pages/orders/CompletedOrder";
import AirportPage from "../pages/airports";
import BankPage from "../pages/bank/index";
import BankBranchPage from "../pages/bank/bank-branch";
import JachaiBankAccountPage from "../pages/jachai-bank-account/index";
import TricketBookingPage from "../pages/tricketBooking/index";
import FlightList from "../pages/tricketBooking/flightList";
import BookingPreview from "../pages/tricketBooking/bookingPreview";
import PaymentPreview from "../pages/tricketBooking/paymentPreview";
import generalConfigurationPage from "../pages/generalConfiguration";
import AssisstantOtaPage from "../pages/assistant-ota";
import SharePnrPage from "../pages/share-pnr";
import GroupTicketPage from "../pages/group-ticket";
import GroupTicketAddPage from "../pages/group-ticket/add";
import GroupTicketSearchPage from "../pages/group-ticket/SearchTicket";
import SelectGroupTicketReviewPage from "../pages/group-ticket/groupTricketReview";
import CareerPage from "../pages/careers";
import FileUploaderPage from "../pages/file-uploader";
import CampaignOrderPage from "../pages/promotions/campaign/orders";
import CampaignDetailsPage from "../pages/promotions/campaign/details";
import CampaignProductPage from "../pages/promotions/campaign/products/index";
import CampaignOrderDetailsPage from "../pages/promotions/campaign/orders/details";
import CampaignShopsPage from "../pages/promotions/campaign/shops";
import CampaignBrandPage from "../pages/promotions/campaign/brand";
import CampaignPage from "../pages/promotions/campaign";
import PromoCodePage from "../pages/promotions/campaign/promo-codes";
import PromoCodeUsesUserPage from "../pages/promotions/promo/users/index";
import CampaignGroupsPage from "../pages/promotions/groups/index";
import AdvertisePage from "../pages/promotions/campaign/advertise";
import CampaignProductAssignPage from "../pages/promotions/campaign/products/assign";
import CampaignAddPage from "../pages/promotions/campaign/add";
import PaymentMethodPage from "../pages/payment-method";
import TagPage from "../pages/tags";
import CappingPage from "../pages/capping";


import ShopPage from "../pages/shops";
import ShopDetailsPage from "../pages/shops/details";
import ShopOrderPage from "../pages/shops/orders";
import ShopProductOutOfStockPage from "../pages/shops/products/out-of-stock";
import ShopBrandsPage from "../pages/shops/brands";
import ShopAddPage from "../pages/shops/add";
import ShopProductPage from "../pages/shops/products";
import ShopProductDetailsPage from "../pages/shops/products/details";
import ShopCategoryPage from "../pages/shops/categories";
import ShopTagPage from "../pages/shops/tags";
import ShopOrderDetailsPage from "../pages/shops/orders/details";
import ShopProductAddPage from "../pages/shops/products/add";
import ShopProductBulkUploadPage from "../pages/shops/products/bulkupload";
import ShopProductBulkUpdatePage from "../pages/shops/products/buklupdate";
import ShopProductEditPage from "../pages/shops/products/edit";

import MerchantPage from "../pages/merchants";
import MerchantAddPage from "../pages/merchants/add";
import MerchantDetailsPage from "../pages/merchants/details";
import MerchantShopPage from "../pages/merchants/shops";
import MerchantProductPage from "../pages/merchants/products";
import MerchantRequestedProductPage from "../pages/merchants/requested-products";
import MerchantRequestedProductEdit from "../pages/merchants/requested-products/edit";
import MerchantRecruiterPage from "../pages/merchants/recruiters";
import MerchantTypePage from "../pages/merchants/types";

export const isAllowedService = (name: string) => {
    const services = localStorage.getItem("services");

    if (services) {
        const listfromApi: ServiceType[] = JSON.parse(services);
        const data = listfromApi.find((itemfromApi) => {
            return itemfromApi.serviceName === name;
        });
        if (data) {
            return true;
        }
    }
    return false;
};

export const allowedActions = (name: string) => {
    const services = localStorage.getItem("services");

    if (services) {
        const list: ServiceType[] = JSON.parse(services);
        const data = list.filter((item) => item.serviceName === name);
        if (data.length > 0) {
            data.map((d) => d.action);
        }
    }
    return [];
};

export const isEditableService = (name: string) => {
    const services = localStorage.getItem("services");

    if (services) {
        const list: ServiceType[] = JSON.parse(services);
        const data = list.find(
            (item) => item.serviceName === name && item.action === "all"
        );

        if (data) {
            return true;
        }
    }
    return false;
};

export const getAllServices = () => {
    const services = localStorage.getItem("services");
    if (services) {
        return JSON.parse(services);
    }
    return [];
};

type AdminService = {
    name: string;
    path?: string;
    icon?: any;
    element?: any;
    sub?: AdminService[];
    isParent?: boolean;
};

export const adminServices: AdminService[] = [
    {
        name: "Dashboard",
        path: "/",
        icon: faLaptop,
        element: DashboardPage,
    },
// Tricket Booking 
    {
        name: "Booking",
        path: "/tricket-booking",
        icon: faCopy,
        sub: [
            {
                name: "Tricket Booking",
                path: "/",
                icon: faCopy,
                element: TricketBookingPage,
                isParent: true,
            },
            {
                name: "flight-list",
                path: "/:listOfData/flightSearch",
                element: FlightList,
            },
            {
                name: "Booking-Preview",
                path: "/:slug/flightPreview",
                element: BookingPreview,
            },
            {
                name: "Payment-Preview",
                path: "/:slug/paymentPreview",
                element: PaymentPreview,
            },
        ],
    },

    // Orders Routes
    {
        name: "Orders",
        path: "/orders",
        icon: faCopy,
        sub: [
            {
                name: "All Order",
                path: "/",
                icon: faCopy,
                element: OrderPage,
                isParent: true,
            },
            {
                name: "Details",
                path: "/:orderId/details",
                element: SingleOrderPage,
            },
            {
                name: "Cancel & Refundable Order",
                path: "/cancel-refundable-order",
                icon: faCopy,
                element: CancelRefundOrderPage,
                isParent: true,
            },
            {
                name: "Modify Order",
                path: "/modify-order",
                icon: faCopy,
                element: ModifyOrderPage,
                isParent: true,
            },
            {
                name: "Cancel Order",
                path: "/cancel-order",
                icon: faCopy,
                element: CancelOrderPage,
                isParent: true,
            },
            {
                name: "Pending Order",
                path: "/pending-order",
                icon: faCopy,
                element: PendingCreatePage,
                isParent: true,
            },
            {
                name: "Partial Confirmed Order",
                path: "/partial-order",
                icon: faCopy,
                element: PartialConfirmedPage,
                isParent: true,
            },
            {
                name: "Confirmed Order",
                path: "/confirm-order",
                icon: faCopy,
                element: ConfirmedPage,
                isParent: true,
            },
            {
                name: "Completed Order",
                path: "/completed-order",
                icon: faCopy,
                element: CompletedPage,
                isParent: true,
            },
            {
                name: "Extra",
            },
        ],
    },
    //Bank Controller
    {
        name: "All Bank",
        path: "/admin-bank",
        icon: faCopy,
        sub: [
            {
                name: "Bank",
                path: "/",
                icon: faCopy,
                element: BankPage,
                isParent: true,
            },
            {
                name: "Branch",
                path: "/branch",
                icon: faCopy,
                element: BankBranchPage,
                isParent: true,
            },
            {
                name: "Jachai Bank Account",
                path: "/jc-bank-account",
                icon: faCopy,
                element: JachaiBankAccountPage,
                isParent: true,
            },
        ],
    },
    //Airports routes
    {
        name: "Airports",
        path: "/airports",
        icon: faCopy,
        element: AirportPage,
    },

    //Group Ticket Routes
    {
        name: "Group Ticket",
        path: "/group-ticket",
        icon: faCube,
        sub: [
            {
                name: "All",
                path: "/",
                icon: faCube,
                element: GroupTicketPage,
                isParent: true,
            },
            {
                name: "Create",
                path: "/create",
                element: GroupTicketAddPage,
            },
            {
                name: "Search Ticket",
                path: "/search",
                element: GroupTicketSearchPage,
            }, 
            {
                name: "Group Ticket Review",
                path: "/select-group-tricket-review",
                element: SelectGroupTicketReviewPage,
            },
            {
                name: "Edit",
                path: "/:ticketId/edit",
                element: GroupTicketAddPage,
            },
        ],
    },
  // Shops Routes
  {
    name: "Shops",
    path: "/shops",
    icon: faShop,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faShop,
        element: ShopPage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: ShopAddPage,
      },
      {
        name: "Edit",
        path: "/:shopId/edit",
        element: ShopAddPage,
      },
      {
        name: "Delete",
        path: "/:shopId/delete",
      },
      {
        name: "Details",
        path: "/:shopId/details",
        element: ShopDetailsPage,
      },
      {
        name: "Orders",
        path: "/:shopId/orders",
        element: ShopOrderPage,
      },
      {
        name: "Orders Details",
        path: "/:shopId/orders/:orderId/details",
        element: ShopOrderDetailsPage,
      },
      {
        name: "Out-Of-Stock",
        path: "/:shopId/out-of-stock",
        element: ShopProductOutOfStockPage,
      },
      {
        name: "Brands",
        path: "/:shopId/brands",
        element: ShopBrandsPage,
      },
      {
        name: "Products",
        path: "/:shopId/products",
        element: ShopProductPage,
      },
      {
        name: "Product Details",
        path: "/:shopId/products/:slug/details",
        element: ShopProductDetailsPage,
      },
      {
        name: "Product Add",
        path: "/:shopId/products/create",
        element: ShopProductAddPage,
      },
      {
        name: "Product Bulkupload",
        path: "/:shopId/products/bulkupload",
        element: ShopProductBulkUploadPage,
      },
      {
        name: "Product Bulkupdate",
        path: "/:shopId/products/bulkupdate",
        element: ShopProductBulkUpdatePage,
      },
      {
        name: "Product Edit",
        path: "/:shopId/products/:slug/edit",
        element: ShopProductEditPage,
      },
      {
        name: "Categories",
        path: "/:shopId/categories",
        element: ShopCategoryPage,
      },
      {
        name: "Tags",
        path: "/:shopId/tags",
        element: ShopTagPage,
      },
    ],
  },
  // Merchant Routes
  {
    name: "Merchants",
    path: "/merchants",
    icon: faHandshake,
    sub: [
      {
        name: "All Merchant",
        path: "/",
        icon: faUsers,
        element: MerchantPage,
        // isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: MerchantAddPage,
      },
      {
        name: "Edit",
        path: "/:merchantId/edit",
        element: MerchantAddPage,
      },
      {
        name: "Details",
        path: "/:merchantId/details",
        element: MerchantDetailsPage,
      },
      {
        name: "Shops",
        path: "/:merchantId/shops",
        element: MerchantShopPage,
      },
      {
        name: "Products",
        path: "/:merchantId/products",
        element: MerchantProductPage,
      },

      // Requested Products Routes
      {
        name: "Requested Product",
        path: "/requested-product",
        icon: faClone,
        element: MerchantRequestedProductPage,
      },
      {
        name: "Requested Product Edit",
        path: "/requested-product/:slug",
        icon: faClone,
        element: MerchantRequestedProductEdit,
      },
      {
        name: "Recruiter",
        path: "/recruiters",
        icon: faUserTie,
        element: MerchantRecruiterPage,
      },
      {
        name: "Type",
        path: "/types",
        icon: faObjectUngroup,
        element: MerchantTypePage,
      },
    ],
  },
//carrers route
    {
        name: "Careers",
        path: "/careers",
        icon: faCopy,
        element: CareerPage,
    },
    {
        name: "File Uploader",
        path: "/file-uploader",
        icon: faCopy,
        element: FileUploaderPage,
    },
    // Promotions Routes
    {
        name: "Promotion",
        path: "/promotion",
        icon: faGift,
        sub: [
            {
                name: "Campaign",
                path: "/campaign",
                icon: faBullhorn,
                element: CampaignPage,
            },
            {
                name: "Campaign Details",
                path: "/campaign/:campaignId/details",
                element: CampaignDetailsPage,
            },
            {
                name: "Campaign Add",
                path: "/campaign/add",
                element: CampaignAddPage,
            },
            {
                name: "Campaign Edit",
                path: "/campaign/:campaignId/edit",
                element: CampaignAddPage,
            },
            {
                name: "Campaign Products",
                path: "/campaign/:campaignId/products",
                element: CampaignProductPage,
            },
            {
                name: "Campaign Products Assign",
                path: "/campaign/:campaignId/products-assign",
                element: CampaignProductAssignPage,
            },
            {
                name: "Campaign Orders",
                path: "/campaign/:campaignId/orders",
                element: CampaignOrderPage,
            },
            {
                name: "Campaign Orders Details",
                path: "/campaign/:campaignId/orders/:orderId/details",
                element: CampaignOrderDetailsPage,
            },
            {
                name: "Campaign Brands",
                path: "/campaign/:campaignId/brands",
                element: CampaignBrandPage,
            },
            {
                name: "Campaign Shops",
                path: "/campaign/:campaignId/shops",
                element: CampaignShopsPage,
            },
            {
                name: "Promo Code",
                path: "/promo-code",
                icon: faTicket,
                element: PromoCodePage,
            },
            {
                name: "Promo Code Users",
                path: "/promo-code/:promoCode/users",
                element: PromoCodeUsesUserPage,
            },
            {
                name: "Groups",
                path: "/groups",
                icon: faTicket,
                element: CampaignGroupsPage,
            },
            {
                name: "Gifts",
                path: "/gifts",
                icon: faGifts,
                element: CampaignPage,
            },
            {
                name: "Advertise",
                path: "/advertise",
                icon: faFilm,
                element: AdvertisePage,
            },
        ],
    },
//Assistant Ota
    {
        name: "Assistant OTA",
        path: "/assistant-ota",
        icon: faCopy,
        element: AssisstantOtaPage,
    },
//general configuration
    {
        name: "General Configuration",
        path: "/general-configuration",
        icon: faCopy,
        element: generalConfigurationPage,
    },
//Share PNR
    {
        name: "Share PNR",
        path: "/share-pnr",
        icon: faCopy,
        element: SharePnrPage,
    },
    // Tag Routes
    {
        name: "Capping-Career",
        path: "/capping-career",
        icon: faImages,
        element: CappingPage,
        sub: [
            {
                name: "All",
                path: "/",
                icon: faUsers,
                element: CappingPage,
                isParent: true,
            },
            {
                name: "Delete",
                path: "/:cappingId/delete",
            },
        ],
    },
    // Tag Routes
    {
        name: "Tags",
        path: "/tags",
        icon: faImages,
        element: TagPage,
        sub: [
            {
                name: "All",
                path: "/",
                icon: faUsers,
                element: TagPage,
                isParent: true,
            },
            {
                name: "Delete",
                path: "/:tagId/delete",
            },
        ],
    },
//Payment Method
    {
        name: "Payment Methods",
        path: "/payment-methods",
        icon: faCreditCard,
        element: PaymentMethodPage,
    },

    // Administration Routes
    {
        name: "Administration",
        path: "/administration",
        icon: faUserShield,
        sub: [
            {
                name: "User Management",
                icon: faUser,
                path: "/admins",
                element: UserManagementPage,
            },
            {
                name: "User Groups",
                icon: faUsers,
                path: "/groups",
                element: ServiceGroupPage,
            },
            {
                name: "User Services",
                icon: faList,
                path: "/services",
                element: UserServicePage,
            },
        ],
    },

];

export const getAllServicesList = () => {
    return adminServices?.flatMap((service) => {
        if (service.sub && service.sub.length) {
            return service.sub.map((sub) => `${service.name} ${sub.name}`);
        } else {
            return service.name;
        }
    });
};

export const getServicePaths = (path: string | undefined) => {
    if (path) {
        const parentService = adminServices?.find(
            (service) => service.path === path
        );
        if (parentService) {
            return parentService?.path || "";
        } else {
            const parentService = adminServices?.find((service) =>
                service.sub?.find((sub) => `${service.path}${sub.path}` === path)
            );
            return parentService?.path || "";
        }
    }
    return "";
};
