import { DatePicker, Divider, Form, Input, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ImageUploader from "../../common/ImageUploader";

const OwnerInfo = ({ form }: { form: any }) => {
  const [docType, setDocType] = useState<string>("Document");

  const [profilePicture, setProfilePicture] = useState<string>();
  const [docUrl, setDocUrl] = useState<string>();
  const [docUrl2, setDocUrl2] = useState<string>();

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        profilePicture: profilePicture,
        identificationDocumentUrl: docUrl,
        identificationDocumentUrl2: docUrl2,
      });
    }
  }, [profilePicture, docUrl, docUrl2]);

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-x-6">
        <div>
          <Form.Item
            hasFeedback
            label="Merchant Name"
            rules={[
              {
                required: true,
                message: "Merchant Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Merchant Name" />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Email Address"
            rules={[
              {
                required: false,
                message: "Email is required!",
              },
              {
                validator: async (_, email) => {
                  if (
                    email &&
                    !String(email)
                      .toLowerCase()
                      ?.match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      )
                  ) {
                    return Promise.reject(new Error("Enter valid email"));
                  }
                },
              },
            ]}
            name="email"
          >
            <Input id="email" type="email" placeholder="Enter Email" />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Date Of Birth" name="dateOfBirth">
            <DatePicker placeholder="Enter Date Of Birth" className="w-full" />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Gender" name="gender">
            <Select
              placeholder="Select Gender"
              options={[
                {
                  value: "MALE",
                  label: "MALE",
                },
                {
                  value: "FEMALE",
                  label: "FEMALE",
                },
                {
                  value: "OTHER",
                  label: "OTHER",
                },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Identification Document Type"
            name="identificationDocumentType"
          >
            <Select
              placeholder="Select Document Type"
              onChange={(val) => setDocType(val)}
              options={[
                {
                  value: "NID",
                  label: "NID",
                },
                {
                  value: "PASSPORT",
                  label: "PASSPORT",
                },
                {
                  value: "BIRTHCERTIFICATE",
                  label: "BIRTH CERTIFICATE",
                },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label={`${docType} NUMBER`}
            name="identificationDocumentNumber"
          >
            <Input
              id="identificationDocumentNumber"
              placeholder="Enter Document Number"
            />
          </Form.Item>
        </div>
      </div>

      <div className="flow-root mt-8">
        {/* <h3 className="text-xl font-bold">Images</h3> */}

        <div className="grid grid-cols-3 gap-6">
          <div className="mr-4">
            <Divider orientation="left">{docType} FRONT</Divider>
            <Form.Item name="profilePicture">
              <ImageUploader
                imgUrl={docUrl || ""}
                startUpload={startUpload2}
                setStartUpload={(val: any) => {
                  setStartUpload2(val);
                  setStartUpload2("Uploading");
                }}
                setGeneratedImgUrl={(url: any) => {
                  setDocUrl(url);
                }}
              />
            </Form.Item>
          </div>
          {docType === "NID" && (
            <div className="">
              <Divider orientation="left">{docType} BACK</Divider>
              <Form.Item name="identificationDocumentUrl">
                <ImageUploader
                  imgUrl={docUrl2 || ""}
                  startUpload={startUpload3}
                  setStartUpload={(val: any) => {
                    setStartUpload3(val);
                    setStartUpload3("Uploading");
                  }}
                  setGeneratedImgUrl={(url: any) => setDocUrl2(url)}
                />
              </Form.Item>
            </div>
          )}

          <div className="">
            <Divider orientation="left">PROFILE PICTURE</Divider>
            <Form.Item name="identificationDocumentUrl2">
              <ImageUploader
                imgUrl={profilePicture || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => {
                  setStartUpload(val);
                  setStartUpload("Uploading");
                }}
                setGeneratedImgUrl={(url: any) => setProfilePicture(url)}
                // crop={1}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerInfo;
