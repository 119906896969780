import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import CareersList from "../../components/Careers";
import React from "react";

const CareersPage = (): ReactElement => {
  return (
    <MainLayout>
      <CareersList />
    </MainLayout>
  );
};

export default CareersPage;
