import { Form, Input, Select } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../utils/notify";
import React from "react";
import BreadCrumb from "../Layouts/Breadcrumb";
import { SubmitResetBtn } from "../common";
import { useSelector } from "react-redux";

const GeneralConfig = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [serviceFee, setServiceFee] = useState("PERCENTAGE");
  const [singleConfigInfo, setSingleConfigInfo] = useState<any>({
    loading: false,
    data: null,
  });
  console.log("Single ConfigInfo::", singleConfigInfo?.data?.serviceFee);
  const fetchConfigDetails = useCallback(() => {
    try {
      setSingleConfigInfo({ loading: true, data: null });
      axios
        .get(`${process.env.REACT_APP_BOOKING_API}/general/configuration`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setSingleConfigInfo({
              loading: false,
              data: data?.data?.generalConfiguration,
            });
          } else {
            setSingleConfigInfo({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleConfigInfo({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setSingleConfigInfo({ loading: false, data: null });

      console.log(error, "error");
    }
  }, []);

  useEffect(() => {
    fetchConfigDetails();
  }, [fetchConfigDetails]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      convenienceCharge: data.convenienceCharge,
      serviceFee: {
        flat: data.flat,
        maxLimit: data.maxLimit,
        percentage: data.percentage,
      },
    };
    if (singleConfigInfo.data) {
      await fetch(
        `${process.env.REACT_APP_BOOKING_API}/general/configuration`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: singleConfigInfo?.data?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "General Configuration Updated Successfully",
              "success"
            );
            form.resetFields();
            fetchConfigDetails();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_BOOKING_API}/general/configuration`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...readyData }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "General Configuration Create Successfully",
              "success"
            );
            form.resetFields();
            fetchConfigDetails();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (singleConfigInfo.data) {
        setServiceFee(singleConfigInfo?.data?.serviceFee);
      form.resetFields(Object.keys(singleConfigInfo.data as any));
    }
  }, [form, singleConfigInfo]);

  return (
    <div className="content-body rounded-2xl">
      <BreadCrumb title="General Configuration" />
      {/* <div className="bg-red-200 block p-3 mb-6 mt-2 rounded-lg shadow-lg ">
        <h2 className="text-red-500 font-medium text-lg text-center">
          Summation of Delivery Charge Subsidy, Referral Subsidy, Retained must
          be equal to 100
        </h2>
      </div> */}
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-6">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...singleConfigInfo?.data,
            //...singleConfigInfo?.data?.serviceFee,
           // percentage:singleConfigInfo?.serviceFee?.percentage

          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
            <div>
              <Form.Item
                hasFeedback
                label="Convenience Charge"
                rules={[
                  {
                    required: true,
                    message: "Convenience Charge Required",
                  },
                ]}
                name="convenienceCharge"
              >
                <Input
                  id="convenienceCharge"
                  type="number"
                  placeholder="Enter Convenience Charge"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Service Fee"
                rules={[
                  {
                    required: false,
                    message: "Service Fee is Required!",
                  },
                ]}
                name="serviceFee"
                initialValue={serviceFee}
              >
                <Select
                  options={[
                    { value: null, label: "NONE" },
                    { value: "PERCENTAGE", label: "PERCENTAGE" },
                    { value: "FLAT", label: "FLAT" },
                  ]}
                  placeholder="Enter Commission Type"
                  value={serviceFee}
                  onChange={(val) => setServiceFee(val)}
                />
              </Form.Item>
            </div>
            {serviceFee === "FLAT" && (
              <div>
                <Form.Item
                  hasFeedback
                  label="Amount"
                  rules={[
                    {
                      required: false,
                      message: "Amount is Required!",
                    },
                  ]}
                  name="flat"
                  initialValue={singleConfigInfo?.data?.serviceFee?.flat}
                >
                  <Input
                    id="flat"
                    type="number"
                    min={0}
                    placeholder="Enter Amount"
                  />
                </Form.Item>
              </div>
            )}
            {serviceFee === "PERCENTAGE" && (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Commission Amount"
                    rules={[
                      {
                        required: false,
                        message: "Commission Amount is Required!",
                      },
                    ]}
                    name="percentage"
                    initialValue={singleConfigInfo?.data?.serviceFee?.percentage}
                  >
                    <Input
                      id="percentage"
                      type="number"
                      min={0}
                      placeholder="Enter Commission Amount"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Max Limit"
                    rules={[
                      {
                        required: false,
                        message: "Max Limit is Required!",
                      },
                    ]}
                    name="maxLimit"
                    initialValue={singleConfigInfo?.data?.serviceFee?.maxLimit}
                    
                  >
                    <Input
                      id="maxLimit"
                      type="number"
                      min={0}
                      placeholder="Enter Max Limit"
                    />
                  </Form.Item>
                </div>
              </>
            )}
          </div>
          <SubmitResetBtn
              onClickSubmit={(e) => {
                e.preventDefault();
                form.submit();
              }}
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
            />
        </Form>
      </div>
    </div>
  );
};

export default GeneralConfig;
