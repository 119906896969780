import React from "react";
import MainLayout from "../../components/Layouts";
import GetFlightDataList from "../../components/TricketBooking/Flight/BookingPreview";

const FlightDataPage = () => {
    return (
        <div className="bg-white">
            <MainLayout>
                <GetFlightDataList/>
            </MainLayout>
        </div>
    );
};

export default FlightDataPage;
