import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import SharePnrList from "../../components/SharePnr";
import React from "react";

const SharePnrPage = (): ReactElement => {
  return (
    <MainLayout>
      <SharePnrList />
    </MainLayout>
  );
};

export default SharePnrPage;
