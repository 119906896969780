import React from "react";
import MainLayout from "../../components/Layouts";
import BookingHome from "../../components/TricketBooking/Flight/bookingHome";

const TricketBookingPage = () => {
    return (
        <MainLayout>
            <BookingHome/>
        </MainLayout>
    );
};

export default TricketBookingPage;
