import {
    EditOutlined,
    SearchOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    WifiOutlined,
    DesktopOutlined,
    ApiOutlined,
    CoffeeOutlined,
    PlusOutlined,
    MinusOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
  } from "@ant-design/icons";
  import {
    Avatar,
    Badge,
    Button,
    Collapse,
    DatePicker,
    DatePickerProps,
    Drawer,
    Dropdown,
    Empty,
    Form,
    Input,
    Popconfirm,
    Select,
    Space,
    Switch,
    message,
  } from "antd";
  
  import axios from "axios";
  import { ReactElement, useCallback, useEffect, useState, useRef, useMemo } from "react";
  import React from "react";
  import { responseNotification } from "../../utils/notify";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { countyList, getPage, getParamValue } from "../../utils";
  import { useLocation, useNavigate } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import s from "./SearchCard.module.css";
  import { Image } from "antd";
  import { getImgUrl } from "../../utils";
  import { useSelector } from "react-redux";
  import _, { debounce } from "lodash";
  import { isAllowedService } from "../../utils/services";
  import AddGroupTicket from "./AddGroupTicket";
 
  import SearchCard  from '../SearchCard/SearchCard'
  import SearchDetailsTab from "../SearchDetails/SearchDetailsTab";
  import {
    BaggageAllowanceDescs,
    CarrierBody,
    GroupDescription,
    GroupedItineraryResponse,
    Itinerary,
    ItineraryGroup,
    LegDescs,
    PassengerInfo,
    ScheduleDesc,
  } from '../../../types'
  import { AddBtn, Loader, LoaderFull, Pagination } from "../common";
  import moment from "moment";
  import { Link } from "react-router-dom";
  import { countries } from '../../../lib/countries'
  import cn from 'classnames'
  const { Panel } = Collapse;

  interface Props {
    className: string
    id: string
  }
  interface SearchResponseType {
    lowFareSearchId: string
    groupedItineraryResponse: GroupedItineraryResponse
  }
  interface CarrierMapInterface {
    [key: string]: CarrierBody
  }


  const ReviewCalculation = (): ReactElement => {
// add new
const { type, token } = useSelector((state) => (state as any)?.authReducer);
const [form] = Form.useForm()
const [loading, setLoading] = useState(false)
const [itineryData, setItineryData] = useState<Itinerary>()
const [group, setGroup] = useState<GroupDescription>()
const [lowFareSearchRqId, setLowFareSearchRqId] = useState<string>()
const [open, setOpen] = useState<number>(0)


const router = useNavigate();
const { Panel } = Collapse

const onDeSelect = () => {
  try {
    const data: any = localStorage.getItem('SDFL') // Selected Data From List
    const decryptedData =  data
    let parsedData = decryptedData ? JSON.parse(decryptedData) : null
    const { itinerary, group, lowFareSearchRqId } = parsedData
    console.log('itinerary ::::', itinerary)
    console.log('group::::', group)
    console.log('lowFareSearchRqId::::', lowFareSearchRqId)

    setItineryData(itinerary)
    setGroup(group)
    setLowFareSearchRqId(lowFareSearchRqId)
  } catch (e) {
    console.log(e)
  }
}

useEffect(() => {
  onDeSelect()
}, [])

const fare = itineryData?.pricingInformation?.[0]?.fare
const fareSummary = itineryData?.fareSummary

const passengerMap: { [key: string]: PassengerInfo } = {}
const totalObj = fare?.passengerInfoList?.reduce(
  (obj, passengerInfo) => {
    passengerMap[passengerInfo?.passengerInfo?.passengerType] =
      passengerInfo?.passengerInfo
    return {
      fare:
        obj?.fare +
          passengerInfo?.passengerInfo?.passengerTotalFare?.totalFare || 0,
      passenger:
        obj?.passenger + passengerInfo?.passengerInfo?.passengerNumber || 0,
    }
  },
  {
    fare: 0,
    passenger: 0,
  }
)
const passengerTypeValue = {
  ADT: 'Adult',
  C09: 'Children',
  INF: 'Infant',
}

const passengerValue = {
  ADT: 1,
  C09: 2,
  INF: 3,
}

useEffect(() => {
  form?.resetFields()
}, [itineryData, group, fare])

console.log('Pass:', fare)
// console.log('Group', group)
// add new end

    return (
      <>
      <div className="">
 
        <div className="mt-20 bg-white rounded-md shadow-md hidden md:block">
          <div className="header border-b flex flex-col p-6 w-full">
            <div className="mb-3 pb-1">
              <h4 className="text-lg font-bold text-accent-5">Flight</h4>
            </div>
            <div className="flex gap-6">
              <div className="w-16 h-16">
              <Avatar size={45} src={getImgUrl(
                    `https://content.r9cdn.net/rimg/provider-logos/airlines/v/${itineryData?.legs?.[0]?.schedules[0]?.carrier?.details?.careerCode}.png?crop=false&width=300&height=300`
                  )} shape="square" />
              </div>
              <div className="flex flex-col">
                <span> 
                  {group?.legDescriptions?.length ? 'One way' : 'Round way'}
                </span>
                <span className="text-sm">
                  {moment(
                    `${group?.legDescriptions[0]?.departureDate}`
                  ).format("dd, DD MMM yyyy")}
                </span>
                <span className="font-bold">
                
                {itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.departure?.time?.slice(0, 5)} -{' '}
                {itineryData?.legs[0]?.schedules[0]?.scheduleDesc?.arrival?.time?.slice(0, 5)}
              </span>
                <span> 
                {group?.legDescriptions[0].departureLocation} -{' '}
                {group?.legDescriptions[0].arrivalLocation}
                  
                </span>

              </div>
            </div>
          </div>

          <div className="body p-6">
            <div className="flex justify-between items-center mb-3 pb-1">
              <h4 className="text-lg font-bold text-accent-5">Fare Summary</h4>
            </div>
            {fare?.passengerInfoList?.map((passenger, i) => (
              <div
                className={cn('flex flex-col gap-2 text-sm mb-5')}
                key={`${Date.now()}-${i}`}
              >
                <div className="text-accent-4">
                  {passengerTypeValue[passenger?.passengerInfo?.passengerType]}{' '}
                  ({passenger?.passengerInfo?.passengerNumber} Traveler)
                </div>
                <dl className="flex justify-between gap-2">
                  <dt className="text-accent-4">Base Fare</dt>
                  <dd>
                    {passenger?.passengerInfo?.passengerTotalFare?.currency}{' '}
                    {
                      passenger?.passengerInfo?.passengerTotalFare
                        ?.equivalentAmount
                    }
                  </dd>
                </dl>
                <dl className="flex justify-between gap-2">
                  <dt className="text-accent-4">Tax</dt>
                  <dd>
                    {passenger?.passengerInfo?.passengerTotalFare?.currency}{' '}
                    {
                      passenger?.passengerInfo?.passengerTotalFare
                        ?.totalTaxAmount
                    }
                  </dd>
                </dl>
              </div>
            ))}


            <div className="border-t flex flex-col gap-1 pt-4">
              <dl className="flex justify-between gap-2">
                <dt className="text-accent-4">Sub-Total</dt>
                <dd>
                  {fare?.totalFare?.currency} {fareSummary?.subTotalAmount}
                </dd> 
              </dl> 

              {fareSummary?.promoDiscount !=null && fareSummary?.promoDiscount !=0 && (
                <dl className="flex justify-between gap-2">
                  <dt className="text-accent-4">
                    Hot Deals{' '}
                    <span className="bg-accent-2 text-accent p-1 px-2 ml-2 rounded-md text-sm">
                    {fareSummary?.promoCode}
                    </span>
                  </dt>
                  <dd className="text-accent">{fareSummary?.promoDiscount}</dd>
                </dl>
              )}

              <dl className="flex justify-between gap-2">
                <dt className="text-accent-4">Convenience Charge</dt>
                <dd>
                  {fare?.totalFare?.currency} {fareSummary?.convenienceCharge}
                </dd>
              </dl>
            </div>
          </div>
          <div className="footer bg-red-100 font-bold flex flex-col justify-betwee gap-1 top-auto px-6 py-4 rounded-br-md self-end">
            <dl className="flex justify-between items-center gap-2">
              <dt>
                <span className="font-extrabold">You Pay</span>
                <span className="text-sm font-light ml-2">
                  (for {totalObj?.passenger} Traveler)
                </span>
              </dt>
              <dd>
                {fare?.totalFare?.currency} {fareSummary?.total}
              </dd>
            </dl>

            {false && (
              <dl className="flex justify-between items-center gap-2 text-accent text-sm">
                <dt>You Save</dt>
                <dd>
                  {fare?.totalFare?.currency} {0}
                </dd>
              </dl>
            )}
          </div>
        </div>
      </div>
      </>
    );
  };
  
  export default ReviewCalculation;
  