import React from "react";
import MainLayout from "../../../components/Layouts";
import MerchantShopList from "../../../components/Merchant/Shops";

const ShopProductPage = () => {
  return (
    <MainLayout>
      <MerchantShopList />
    </MainLayout>
  );
};

export default ShopProductPage;
