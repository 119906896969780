import MainLayout from "../../../components/Layouts";
import ShopProductList from "../../../components/Shop/Product";

const ShopProductPage = () => {
  return (
    <MainLayout>
      <ShopProductList />
    </MainLayout>
  );
};

export default ShopProductPage;
