import { Button, Form, Select } from "antd";
import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import styles from "../../../styles/tailwind/Add.module.css";
import { useParams } from "react-router";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";

const AddTag = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const shopID = (route as any)?.shopId;
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tagData, setTagData] = useState<any>(undefined);
  const [tagOptions, setTagOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const getTagOptions = useCallback(async (key?: string) => {
    setTagOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(`${encodedUri}/tag?type=${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setTagOptions({
      loading: false,
      list: res?.data?.tags,
    });

    setTagOptions({
      loading: false,
      list: res?.data?.tags?.map((tag: any) => {
        return {
          value: tag?.id,
          label: tag?.name,
        };
      }),
    });
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      tags: [{ tagId: data.tagId, displayOrder: data.displayOrder }],
    };

    if (tagData) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/tag-shop`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: tagData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Tag Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/tag-shop`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          shopId: shopID,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Tag Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setTagData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (tagData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [tagData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "tag") getTagOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getTagOptions, type]);

  // **********************************
  // Search Options
  // **********************************

  useEffect(() => {
    getTagOptions();
  }, [getTagOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...tagData,
        }}
      >
        <Form.Item hasFeedback label="Tag" name="tagId">
          <Select
            showSearch
            allowClear
            placeholder="Select Tag"
            optionFilterProp="children"
            // onChange={(val) => setCampaignId(val)}
            onSearch={(e) => {
              console.log(e);
            }}
            filterOption={() => {
              return true;
            }}
            options={tagOptions?.list}
            //mode="multiple"
          ></Select>
        </Form.Item>
        {/* 
        <Form.Item
          hasFeedback
          label="Display Order"
          rules={[
            {
              required: false,
              message: "displayOrder is Required!",
            },
          ]}
          name="displayOrder"
        >
          <Input
            id="displayOrder"
            type="text"
            placeholder="Enter Display Order"
          />
        </Form.Item> */}

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddTag;
