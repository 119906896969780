import React from "react";
import MainLayout from "../../../components/Layouts";
import CampaignShopsList from "../../../components/Promotions/Campaign/Shop/index";

const ShopOrderPage = () => {
  return (
    <MainLayout>
      <CampaignShopsList />
    </MainLayout>
  );
};

export default ShopOrderPage;
