import { Image } from "antd";
import { ReactElement } from "react";

const SingleRecruiter = ({
  SingleRecruiter,
}: {
  SingleRecruiter: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={SingleRecruiter?.bannerImage}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{SingleRecruiter?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Mobile Number</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{SingleRecruiter?.mobileNumber?.replace("+88", " ")}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Agency</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{SingleRecruiter?.agency}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRecruiter;
