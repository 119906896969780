import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../utils/services";

import { AddBtn, Loader, Pagination, SubmitResetBtn } from "../common";

const SharePnrList = (): ReactElement => {
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [orderId, setOrderId] = useState<any>();
  const [otaPCC, setOtaPCC] = useState<any>();

  console.log('order id ::',orderId);
  console.log('ota Pcc ::',otaPCC);
  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });

  const [otaPCCOptions, setOtaPCCOptions] = useState({
    loading: false,
    list: [],
  });
  const getOtaPccOptions = useCallback(async (key: any) => {
    setOtaPCCOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/assistant-ota?page=0&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setOtaPCCOptions({
      loading: false,
      list: res?.data?.assistantOTAS?.map((ota: any) => ({
        label: ota.shortName,
        value: ota.shortName,
      })),
    });
  }, []);

  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      const encodedUrl = `${process.env.REACT_APP_BOOKING_API}`;
      return axios
        .get(
          `${encodedUrl}/admin/booking-order?type=${"JC_AIRLINES_BOOKING"}` +
            (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
            `&page=${page || 0}` +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map((order: { orderId: string }) => ({
              label: `${order?.orderId}`,
              value: order?.orderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, type]
  );


  const sharePnr = useCallback(() => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BOOKING_API}/admin/booking-order/share-pnr?otaPCC=${otaPCC}&orderId=${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            responseNotification(
                "Share PNR Successfully",
                "success"
              );
          } else {
            responseNotification(
              "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {

          console.log(err);
        });
    } catch (error) {

      console.log(error, "error");
    }
  }, [otaPCC,orderId]);

  useEffect(() => {
    sharePnr();
  }, [sharePnr]);


  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
        otaPCC: orderId || data.orderId,
        orderId: otaPCC || data.otaPcc,
    };
    await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/booking-order/share-pnr`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Share PNR Successfully",
            "success"
          );
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  
 

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
        if (field === "otaPCC") getOtaPccOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions,getOtaPccOptions]);


  useEffect(() => {
    if (showSearch) {
      getOrderOptions("");
      getOtaPccOptions("");
    }
  }, [showSearch]);
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };



  return (
    <>
      <BreadCrumb title={`Share PNR`} />

      {showSearch && (
        <div className={styles?.searchBox}>
               <Form
                name="control-hooks"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={sharePnr}
                form={form} // like ref
                layout="vertical"
                className={styles.formInline}
               
              >
     
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <Form.Item label="OTA PCC" name="otaPCC">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter OTA PCC"
                  optionFilterProp="children"
                  onChange={(val) => setOtaPCC(val)}
                  onSearch={(e) => handleSearch(e, "otaPCC")}
                  filterOption={() => {
                    return true;
                  }}
                  options={otaPCCOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item label="Order Id" name="orderId">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Order Id"
                  optionFilterProp="children"
                  onChange={(val) => setOrderId(val)}
                  onSearch={(e) => handleSearch(e, "orderId")}
                  filterOption={() => {
                    return true;
                  }}
                  options={orderOptions?.list}
                ></Select>
              </Form.Item>
              
            
            </div>
            {/* <SubmitResetBtn /> */}
          </Form>

         
        </div>
      )}
    </>
  );
};

export default SharePnrList;
function setLoading(arg0: boolean) {
    throw new Error("Function not implemented.");
}



