import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import CancelOrderList from "../../components/Orders/CancelOrder";
import React from "react";

const CancelOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <CancelOrderList />
    </MainLayout>
  );
};

export default CancelOrderPage;
