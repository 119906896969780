import MainLayout from "../../../components/Layouts";
import ShopProductAdd from "../../../components/Shop/Product/add";

const ShopProductAddPage = () => {
  return (
    <MainLayout>
      <ShopProductAdd />
    </MainLayout>
  );
};

export default ShopProductAddPage;
