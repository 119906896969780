import React from "react";
import MainLayout from "../../../components/Layouts";
import CampaignList from "../../../components/Promotions/Campaign";

const CampaignPage = () => {
  return (
    <MainLayout>
      <CampaignList />
    </MainLayout>
  );
};

export default CampaignPage;
