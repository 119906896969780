import { SearchOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Collapse,
  DatePicker,
  DatePickerProps,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Popconfirm,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import React from "react";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { countyList, getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { Image } from "antd";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../utils/services";
import AddGroupTicket from "./AddGroupTicket";
import SearchCard from "../SearchCard/SearchCard";
import {
  GroupDescription,
  Itinerary,
  ItineraryGroup,
  CarrierBody,
  PassengerCodes,
  PassengerInfo,
  SearchResponseType,
  GroupedItineraryResponse,
  ScheduleDesc,
  LegDescs,
  BaggageAllowanceDescs,
} from "../../../types";
import { AddBtn, Loader, LoaderFull, Pagination } from "../common";
import moment from "moment";
import { Link } from "react-router-dom";
const { Panel } = Collapse;
interface CarrierMapInterface {
  [key: string]: CarrierBody;
}
const SearchGroupTicketList = ({
  itinerary,
  group,
  data,
  lowFareSearchRqId,
}: {
  itinerary: Itinerary;
  group: GroupDescription;
  lowFareSearchRqId: string;
  data: any;
}): ReactElement => {
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const loc = useLocation();
  const [carrierKey, setCarrierKey] = useState<any>([]);
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(10);
  const [showSearch, setShowSearch] = useState(true);
  const [arrayFlightList, setArrayFlightList] = useState<any>({
    loading: false,
    data: null,
  });
  const [searchData, setSearchData] = useState<SearchResponseType>();
  const [searchReviewData, setSearchReviewData] =
    useState<SearchResponseType>();

  const [visible, setVisible] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const isDeleted = "false";

  // add new requirment

  const allItineraries: (Itinerary & { itineraryGroupIndex: number })[] = [];
  searchData?.groupedItineraryResponse?.itineraryGroups?.forEach(
    (group: ItineraryGroup, i: number) => {
      allItineraries?.push();
      group?.itineraries?.forEach((itinerary) => {
        allItineraries.push({
          ...itinerary,
          itineraryGroupIndex: i,
        });
      });
    }
  );

  const organizeLeg = useMemo(() => {
    const func = (
      data: GroupedItineraryResponse,
      carrierMap: CarrierMapInterface = {}
    ) => {
      let tempData = {
        ...data,
      };

      let tempScheduleMap: { [key: number]: ScheduleDesc } = {};
      let tempScheduleList = data?.scheduleDescs;
      tempScheduleList?.forEach((schedule: ScheduleDesc) => {
        tempScheduleMap[schedule.id] = {
          ...(schedule || {}),
          carrier: {
            ...(schedule?.carrier || {}),
            details: carrierMap[schedule?.carrier?.operating] || {},
          },
        };
      });

      let tempLegMap: { [key: number]: LegDescs } = {};
      let tempLegList = data?.legDescs;
      tempLegList?.forEach((leg) => {
        tempLegMap[leg.id] = {
          ...(leg || {}),
          schedules: leg.schedules?.map((schedule) => ({
            ...(schedule || {}),
            ...(tempScheduleMap[schedule.ref] || {}),
          })),
        };
      });

      let baggageAllowanceMap: { [key: number]: BaggageAllowanceDescs } = {};
      let baggageAllowanceList = data?.baggageAllowanceDescs;
      baggageAllowanceList?.forEach((baggageAllowance) => {
        baggageAllowanceMap[baggageAllowance.id] = {
          ...(baggageAllowance || {}),
        };
      });

      tempData = {
        ...(tempData || {}),
        itineraryGroups: tempData?.itineraryGroups?.map((itineraryGroup) => {
          let tempItineraryGroup = {
            ...(itineraryGroup || {}),
            itineraries: itineraryGroup?.itineraries?.map((itinerary) => {
              return {
                ...(itinerary || {}),
                legs: itinerary?.legs?.map((leg) => tempLegMap[leg.ref]),
                pricingInformation: itinerary?.pricingInformation?.map(
                  (pricingInfo) => {
                    return {
                      ...pricingInfo,
                      fare: {
                        ...pricingInfo?.fare,
                        passengerInfoList:
                          pricingInfo?.fare?.passengerInfoList?.map(
                            (passengerInfo) => {
                              return {
                                passengerInfo: {
                                  ...passengerInfo?.passengerInfo,
                                  baggageInformation:
                                    passengerInfo?.passengerInfo?.baggageInformation?.map(
                                      (baggageInfo) => {
                                        return {
                                          ...baggageInfo,
                                          allowance:
                                            baggageAllowanceMap[
                                              baggageInfo?.allowance?.ref
                                            ],
                                        };
                                      }
                                    ),
                                },
                              };
                            }
                          ),
                      },
                    };
                  }
                ),
              };
            }),
          };

          return tempItineraryGroup;
        }),
      };

      return tempData;
    };
    return func;
  }, []);
  const fetchCarriersData = async (arr: any[] = []) => {
    const carrierMap: CarrierMapInterface = {};
    await fetch(
      `${process.env.REACT_APP_BOOKING_API}/career/by-codes?careerCodes=${
        arr || []
      }`,
      {
        method: "GET",
        headers: {
          // Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          const list = res?.careers || [];
          list?.forEach((career: CarrierBody) => {
            if (career.careerCode) carrierMap[career.careerCode] = career;
          });
        } else if (res.status === 500) {
          console.error("message", res.message);
        } else {
          console.error("message", res.message);
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
    return carrierMap;
  };
  const [airportCodeOptions, setAirportCodeOptions] = useState({
    loading: false,
    list: [],
  });
  const [careerOptions, setCareerOptions] = useState({
    loading: false,
    list: [],
  });
  const [destinationAirportOptions, setDestinationAirportOptions] = useState({
    loading: false,
    list: [],
  });
  const [countryOptions, setCountryOptions] = useState({
    loading: false,
    list: [],
  });
  const [wayType, setWayType] = useState("");
  const [departureDate, setDepartureDate] =
    useState<DatePickerProps["value"]>();
  const onChangeDepartureDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setDepartureDate(dateString);
  };

  const [departFrom, setDepartFrom] = useState<any>();
  const [goingTo, setGoingTo] = useState<any>();
  const [travelerClass, setTravelerClass] = useState<any>("Y");
  const [flightType, setFlightType] = useState<any>("ONE_WAY");
  const [travelInfo, setTravelInfo] = useState<any>([]);
  const [passengerTypeQuantities, setPassengerTypeQuantities] = useState<{
    [key: string]: any;
  }>({
    ADT: {
      title: "Adults",
      subtitle: "12 y+",
      Code: "ADT",
      Quantity: 1,
    },
    C09: {
      title: "Children",
      subtitle: "2-12 years",
      Code: "C09",
      Quantity: 0,
    },
    INF: {
      title: "Infant",
      subtitle: "Below 2 years",
      Code: "INF",
      Quantity: 0,
    },
  });

  const menu = (
    <div className="p-2 max-w-lg bg-white rounded shadow w-80	">
      {Object.values(passengerTypeQuantities).map((data: any, i) => (
        <div
          className="flex justify-between items-center py-2 text-gray-600"
          key={i}
        >
          <div className="grid">
            <span className="text-md">{data.title}</span>
            <span className="text-xs">{data.subtitle}</span>
          </div>
          <div className="w-2/5">
            <Button
              type="dashed"
              shape="circle"
              size="small"
              disabled={
                data.Code === "ADT" ? data.Quantity === 1 : data.Quantity === 0
              }
              onClick={() =>
                setPassengerTypeQuantities((prevData) => {
                  const temp = { ...prevData };
                  temp[data.Code].Quantity--;
                  return temp;
                })
              }
            >
              <MinusOutlined rev={undefined} />
            </Button>

            <span className="w-2/5 px-4 leading-none text-center">
              {data.Quantity}
            </span>

            <Button
              type="dashed"
              shape="circle"
              size="small"
              disabled={data.Quantity === 4}
              onClick={() =>
                setPassengerTypeQuantities((prevData) => {
                  const temp = { ...prevData };
                  temp[data.Code].Quantity++;
                  return temp;
                })
              }
            >
              <PlusOutlined rev={undefined} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
  useEffect(() => {
    if (data) {
      setTravelInfo(data?.originDestinationInformations);
      setPassengerTypeQuantities((prevData: any): any => {
        let temp = { ...prevData };

        data?.passengerTypeQuantities?.forEach((val) => {
          temp[val?.Code] = {
            ...temp[val?.Code],
            Quantity: val?.Quantity,
          };
        });

        return temp;
      });
    }
  }, [data]);
  const fetchSearchData = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      originDestinationInformations: [
        {
          DepartureDateTime: departureDate + "T00:00:00",
          DestinationLocation: {
            LocationCode: goingTo,
          },
          OriginLocation: {
            LocationCode: departFrom,
          },
          RPH: 0,
        },
      ],
      passengerTypeQuantities: [
        {
          Code: "ADT",
          Quantity: 1,
        },
      ],
      travelerClass: travelerClass,
      careerCodes: carrierKey,
    };
    await fetch(
      `${process.env.REACT_APP_BOOKING_API}/booking/search-low-fare`,
      {
        method: "POST",
        headers: {
          //Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          fetchCarriersData(
            res?.groupedItineraryResponse?.scheduleDescs?.map(
              (item) => item?.carrier?.operating
            ) || []
          ).then((carrierMap) => {
            let formatedRes = {
              lowFareSearchId: res?.lowFareSearchId,
              groupedItineraryResponse: organizeLeg(
                res?.groupedItineraryResponse,
                carrierMap
              ),
            };
            setSearchData(formatedRes);
          });

          setSearchReviewData(res?.groupedItineraryResponse);
        } else if (res.statusCode === 500) {
          console.error("message", res.message);
        } else {
          console.error("message", res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
      });
  };

  const onClose = () => {
    setVisible(undefined);
  };

  const getAirportListOptions = useCallback(
    async (key?: string) => {
      setAirportCodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/airport/get-all?page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAirportCodeOptions({
        loading: false,
        list: res?.data?.airports?.map((airport: any) => ({
          label: airport.code.concat(" - ", airport.name),
          value: airport.code,
        })),
      });
    },
    [limit, page]
  );

  const getCareersOptions = useCallback(
    async (key?: string) => {
      setCareerOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/career?page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCareerOptions({
        loading: false,
        list: res?.data?.careers?.map((career: any) => ({
          label: career.careerCode.concat(" - ", career.name),
          value: career.careerCode,
        })),
      });
    },
    [limit, page]
  );

  const getDestionationAirportListOptions = useCallback(
    async (key?: string) => {
      setDestinationAirportOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/airport/get-all?page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDestinationAirportOptions({
        loading: false,
        list: res?.data?.airports?.map((airport: any) => ({
          label: airport.code.concat(" - ", airport.name),
          value: airport.code,
        })),
      });
    },
    [limit, page]
  );

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "airport") getAirportListOptions(value);
        if (field === "career") getCareersOptions(value);
        if (field === "destinationAirport")
          getDestionationAirportListOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getAirportListOptions,
    getCareersOptions,
    getDestionationAirportListOptions,
  ]);
  const carrierLists: any = Object.entries(
    allItineraries.reduce((group: any, carrier: any) => {
      const carrieres = carrier?.pricingInformation[0]?.fare?.governingCarriers;
      group[carrieres] = group[carrieres] ?? [];
      group[carrieres].push(carrier);
      return group;
    }, {})
  );
  const groupedMap = useMemo(
    () =>
      allItineraries?.reduce(
        (entryMap, e: any) =>
          // e,
          entryMap.set(e?.pricingInformation[0]?.fare?.validatingCarrierCode, {
            carrier: e.legs[0]?.schedules[0]?.carrier?.details,
            flights: [
              ...(entryMap.get(
                e?.pricingInformation[0]?.fare?.validatingCarrierCode
              )?.flights || []),
              e,
            ],
          }),
        new Map()
      ),
    [allItineraries]
  );

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };

  const reseAllFieldData = () => {
    setDepartFrom("");
    setGoingTo("");
    setIsActive("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getAirportListOptions("");
      getCareersOptions("");
      getDestionationAirportListOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title={`Search Group Ticket List`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          <AddBtn onClick={() => navigate("/group-ticket/create")} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            layout="vertical"
            form={form}
            onFinish={fetchSearchData}
            autoComplete="off"
            hideRequiredMark
          >
            <div className="grid grid-cols-2 gap-x-2 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 xl:gap-x-6">
              <Form.Item label="Depart From" name="departFrom">
                <Select
                  allowClear
                  showSearch
                  placeholder="Type for the airport name or airport code"
                  optionFilterProp="children"
                  onChange={(val) => setDepartFrom(val)}
                  onSearch={(e) => handleSearch(e, "airport")}
                  filterOption={() => {
                    return true;
                  }}
                  options={airportCodeOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item label="Going To" name="goingTo">
                <Select
                  allowClear
                  showSearch
                  placeholder="Type for the airport name or airport code"
                  optionFilterProp="children"
                  onChange={(val) => setGoingTo(val)}
                  onSearch={(e) => handleSearch(e, "destinationAirport")}
                  filterOption={() => {
                    return true;
                  }}
                  options={destinationAirportOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item label="Departure Date" name="airport_search">
                <DatePicker
                  id="startFrom"
                  placeholder="Enter Starting Date"
                  onChange={onChangeDepartureDate}
                  style={{ minWidth: "100%" }}
                />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="Career Code"
                rules={[
                  {
                    required: false,
                    message: "careerCode is required!",
                  },
                ]}
                name="careerCode"
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select Career Code"
                  optionFilterProp="children"
                  onSearch={(val) => {
                    handleSearch(val, "career");
                  }}
                  onChange={(val) => setCarrierKey(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={careerOptions?.list}
                ></Select>
              </Form.Item>

              <Form.Item label="Traveler Class" name="travelerClass">
                <Select
                  options={[
                    { value: null, label: "NONE" },
                    { value: "Y", label: "Economy" },
                    { value: "B", label: "Business" },
                    { value: "F", label: "First Class" },
                  ]}
                  defaultValue={travelerClass || "Y"}
                  placeholder="Select Traveler Class"
                  onChange={(val) => setTravelerClass(val)}
                />
              </Form.Item>
            </div>
          </Form>

          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={() => form.submit()}
            // onClick={reseAllFieldData}
          >
            Search
          </Button>
        </div>
      )}

      {loading ? (
        <h1>Loading ...</h1>
      ) : (
        allItineraries?.map(
          (itinerary: Itinerary & { itineraryGroupIndex: number }, i: any) =>
            searchData && (
              <SearchCard
                itinerary={itinerary}
                group={
                  searchData?.groupedItineraryResponse?.itineraryGroups[
                    itinerary?.itineraryGroupIndex
                  ]?.groupDescription
                }
                lowFareSearchRqId={searchData?.lowFareSearchId}
                key={i}
              />
            )
        )
      )}
    </>
  );
};
export default SearchGroupTicketList;
