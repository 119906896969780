// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  padding: 7.5px 15px;
  background: #002140;
  min-height: 62px;
}

.logoImg {
  max-height: 50px;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/styles/base/logo.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".logo {\n  padding: 7.5px 15px;\n  background: #002140;\n  min-height: 62px;\n}\n\n.logoImg {\n  max-height: 50px;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
