import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
  SetStateAction,
} from "react";
import React from "react";
import { AutoComplete, Button, Empty, Form, Input, Select, Spin } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { AddBtn, Loader, Pagination } from "../../common";

const PromoCodeList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [campaignId, setCampaignId] = useState("");
  const [productId, setProductId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState("");
  const [key, setKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>("createdBy");
  const [sortOrder, setSortOrder] = useState<any>("DESC");

  const [groupData, setGroupData] = useState<any>({
    loading: false,
    data: null,
  });
  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });
  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });
  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });
  const getCampaignOptions = useCallback(async (campaignId?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign/by-type?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      })),
    });
  }, []);
  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (productId ? `&key=${productId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any; id: any }) => ({
              label: product.name,
              value: product.id,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [isActive, key, page, limit]
  );
  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order/v2?page=${page || 0}` +
            (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
            (type ? `&type=${type}` : ``) +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map(
              (order: { baseOrderId: string; orderId: string }) => ({
                label: `${order?.baseOrderId} || ${order?.orderId}`,
                value: order?.orderId,
              })
            ),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, status, page, type]
  );
  const getGroups = useCallback(async () => {
    setGroupData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/campaign/group-buy/groups?page=${
          page || 0
        }&limit=${limit || 16}` +
          (campaignId ? `&campaignId=${campaignId}` : ``) +
          (productId ? `&productId=${productId}` : ``) +
          (orderId ? `&orderId=${orderId}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setGroupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setGroupData({ loading: false, data: [] });
        console.error("Promo: Error", err);
      });
  }, [
    campaignId,
    productId,
    orderId,
    sortBy,
    sortOrder,
    limit,
    page,
    isActive,
    type,
  ]);

  useEffect(() => {
    if (showSearch) {
      getCampaignOptions();
      getProductOptions("");
      setOrderId("");
    }
  }, [showSearch]);
  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const onClose = () => {
    getGroups();
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "campaign") setCampaignOptions(value);
        if (field === "product") getProductOptions(value);
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [setCampaignOptions, getProductOptions, getOrderOptions]);

  const reseAllFieldData = () => {
    setCampaignId("");
    setProductId("");
    setOrderId("");
    form.resetFields();
  };

  return (
    <>
      <BreadCrumb
        title={`Group List`}
        subTitle={`${
          groupData?.data?.totalElements ||
          groupData?.data?.categories?.length ||
          0
        } Group(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="campaign_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Campaign"
                optionFilterProp="children"
                onChange={(val) => setCampaignId(val)}
                onSearch={(e) => handleSearch(e, "campaign")}
                filterOption={() => {
                  return true;
                }}
                options={campaignOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="product_search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setProductId(val?.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Product"
                  onSearch={(val) => setProductId(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item name="baseOrderIdSearch">
              <AutoComplete
                backfill
                allowClear
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
                dropdownMatchSelectWidth={335}
              >
                <Input.Search
                  placeholder="Order ID or Base Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {groupData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">No Of Participants</th>
                      <th scope="col">Campaign</th>
                      <th scope="col">Campaign Type</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {groupData?.data?.groupBuyGroupResponses?.length ? (
                      groupData?.data?.groupBuyGroupResponses?.map(
                        (groupBuyGroup: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {groupBuyGroup?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {groupBuyGroup?.noOfParticipants}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {groupBuyGroup?.campaign?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {groupBuyGroup?.campaign?.campaignType?.replace(
                                  "_",
                                  " "
                                )}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {groupBuyGroup?.status}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...groupData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default PromoCodeList;
