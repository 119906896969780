import {
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Layouts/Breadcrumb";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import { ImageUploader, SubmitResetBtn } from "../../common";
import { jodiConfig } from "../../../utils/editor-config";
import { range } from "lodash";
import moment from "moment";
const { RangePicker } = DatePicker;
const AddCampaign = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const navigate = useNavigate();
  const editor = useRef(null);
  const [appsImgUrl, setAppsImgUrl] = useState<string>();
  const [webImgUrl, setWebImgUrl] = useState<string>();
  const [campaignType, setCampaignType] = useState("DISCOUNT");
  const [campaignApplyType, setCampaignApplyType] = useState("ALL_CATEGORY");
  const [discountType, setDiscountType] = useState("PERCENTAGE");
  const [startingDate, setStartingDate] = useState<DatePickerProps["value"]>();
  const [endingDate, setEndingDate] = useState<DatePickerProps["value"]>();
  const [isFlashSale, setIsFlashSale] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isPayFirst, setIsPayFirst] = useState<boolean>(false);

  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const paymentTimePreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [error, setError] = useState("");
  const [campaignData, setCampaignData] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setCampaignData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCampaignData({
                loading: false,
                data: data?.data?.campaign,
              });
            } else {
              setCampaignData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setCampaignData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setCampaignData({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (appsImgUrl || webImgUrl) {
      setLoading(true);

      const readyData = data && {
        name: data.name,
        banner: appsImgUrl ? appsImgUrl : data?.banner,
        bannerWeb: webImgUrl ? webImgUrl : data?.bannerWeb,
        campaignType: campaignType || "DISCOUNT",
        campaign: data.campaign,
        campaignMaximumDiscountAmount: data.campaignMaximumDiscountAmount,
        campaignOrdersOverAmount: data.campaignOrdersOverAmount,
        colorCode: data.colorCode || "#FFFFFF",
        displayOrder: data.displayOrder,
        promoCode: data.promoCode,
        keyword: data.keyword,
        campaignFor: campaignApplyType,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        startAt: startingDate || data.startingDate,
        endAt: endingDate || data.endDate,
        groupBuy: {
          isPayFirst: isPayFirst,
          lastPaymentTimeInMinute: isPayFirst
            ? data.lastPaymentTimeInMinute * 60
            : 0,
          noOfParticipatorEachGroup: isPayFirst
            ? data.noOfParticipatorEachGroup
            : 0,
        },
        status: data.status,
        minimumDiscount: parseFloat(data.minimumDiscount),
        minimumDiscountType: data.minimumDiscountType,
        type: type || data?.type,
        isFlashSale: isFlashSale || data.isFlashSale,
        description: convertedText || data.description,
        termAndConditions: convertedTextForTnC || data.termAndConditions,
      };

      if (campaignData.data) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: campaignData?.data?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Campaign Updated Successfully", "success");
              form.resetFields();
              navigate("/promotion/campaign");
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            deleted: false,
            status: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("campaign Create Successfully", "success");
              form.resetFields();
              navigate("/promotion/campaign");
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);

  useEffect(() => {
    if (campaignData?.data) {
      form.resetFields();
      setAppsImgUrl(campaignData?.data?.banner);
      setWebImgUrl(campaignData?.data?.bannerWeb);
      setStartingDate(campaignData?.data?.startAt);
      setEndingDate(campaignData?.data?.endAt);
      setCampaignType(campaignData?.data?.campaignType);
      setCampaignApplyType(campaignData?.data?.campaignApplyType);
      setDiscountType(campaignData?.data?.minimumDiscountType);
      setIsFlashSale(campaignData?.data?.isFlashSale);
      setIsPayFirst(campaignData?.data?.groupBuy?.isPayFirst);
    }
  }, [campaignData]);

  const resetData = () => {
    form?.resetFields();
    setAppsImgUrl(undefined);
    setWebImgUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={campaignData?.data ? "Edit Campaign" : "Add Campaign"}
        extra={
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
          />
        }
      />

      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          hideRequiredMark
          initialValues={{
            ...campaignData?.data,
            startDate: dayjs(campaignData?.data?.startAt),
            endDate: dayjs(campaignData?.data?.endAt),
            lastPaymentTimeInMinute:
              campaignData?.data?.groupBuy?.lastPaymentTimeInMinute / 60,
            noOfParticipatorEachGroup:
              campaignData?.data?.groupBuy?.noOfParticipatorEachGroup,
            campaignType: campaignType,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <div>
              <Form.Item
                hasFeedback
                label="Campaign Type"
                rules={[
                  {
                    required: true,
                    message: "Campaign Type is Required!",
                  },
                ]}
                name="campaignType"
              >
                <Select
                  // disabled={campaignData?.data?.campaignType}
                  onChange={(val) => setCampaignType(val)}
                  options={[
                    { value: "DISCOUNT", label: "DISCOUNT" },
                    { value: "BOGO", label: "BUY ONE GET ONE" },
                    { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                    { value: "GROUP_BUY", label: "GROUP BUY" },
                    {
                      value: "PURCHASE_VOUCHER",
                      label: "PURCHASE VOUCHER",
                    },
                    { value: "VOUCHER", label: "VOUCHER" },
                    { value: "PROMO", label: "PROMO" },
                    { value: "MEMBERSHIP_CARD", label: "MEMBERSHIP CARD" },
                    { value: "GIFT_CARD", label: "GIFT CARD" },
                    { value: "POINT_CODE", label: "POINT CODE" },
                    { value: "CASH_BACK", label: "CASH BACK" },
                    { value: "FREE_DELIVERY", label: "FREE DELIVERY" },
                  ]}
                  placeholder="Enter Campaign Type"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Type is Required!",
                  },
                ]}
                name="isFlashSale"
                initialValue={isFlashSale}
              >
                <Radio.Group
                  onChange={(val) => setIsFlashSale((val as any).target.value)}
                  value={isFlashSale || false}
                  defaultValue={isFlashSale || false}
                >
                  <Space direction="horizontal">
                    <Radio defaultChecked value={false}>
                      Regular
                    </Radio>
                    <Radio value={true}>Flash Sale</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Campaign Apply Type"
                rules={[
                  {
                    required: true,
                    message: "Campaign Apply Type is Required!",
                  },
                ]}
                name="campaignApplyType"
              >
                <Select
                  // disabled={campaignData?.data?.campaignType}
                  onChange={(val) => setCampaignApplyType(val)}
                  options={[
                    { value: "ALL_CATEGORY", label: "ALL CATEGORY" },
                    {
                      value: "SPECIFIC_PRODUCT_CATEGORY",
                      label: "SPECIFIC PRODUCT CATEGORY",
                    },
                    { value: "PRODUCT", label: "PRODUCT" },
                    { value: "CUSTOMER", label: "CUSTOMER" },
                    {
                      value: "PAYMENT_PARTNER",
                      label: "PAYMENT PARTNER",
                    },
                    { value: "DELIVERY_PARTNER", label: "DELIVERY PARTNER" },
                    { value: "NEW_USER_BENIFIT", label: "NEW USER BENIFIT" },
                    { value: "SPECIFIC_SELLER", label: "SPECIFIC SELLER" },
                  ]}
                  placeholder="Enter Campaign Apply Type"
                />
              </Form.Item>
            </div>

            {campaignType === "MEMBERSHIP_CARD" && (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Membership Cards"
                    rules={[
                      {
                        required: true,
                        message: "Membership cards is Required!",
                      },
                    ]}
                    name="membershipCards"
                  >
                    <Select
                      // disabled={campaignData?.data?.campaignType}
                      onChange={(val) => setCampaignApplyType(val)}
                      options={[
                        {
                          value: "SELLER_MEMBERSHIP_CARDS",
                          label: "SELLER MEMBERSHIP CARDS",
                        },
                        {
                          value: "PLATFROM_MEMBERSHIP_CARDS",
                          label: "PLATFROM MEMBERSHIP CARDS",
                        },
                        {
                          value: "PARTNER_MEMBERSHIP_CARDS",
                          label: "PARTNER MEMBERSHIP CARDS",
                        },
                        {
                          value: "CUSTOMER_MEMBERSHIP_CARDS",
                          label: "CUSTOMER_MEMBERSHIP_CARDS",
                        },
                      ]}
                      placeholder="Enter Campaign Apply Type"
                    />
                  </Form.Item>
                </div>
              </>
            )}
            <div>
              <Form.Item
                hasFeedback
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Campaign Title is Required!",
                  },
                ]}
                name="name"
              >
                <Input
                  id="name"
                  type="text"
                  placeholder="Enter Campaign Title"
                />
              </Form.Item>
            </div>

            {campaignType === "DISCOUNT" ||
              campaignType === "PURCHASE_VOUCHER" ||
              campaignType === "VOUCHER" ||
              campaignType === "PROMO" ||
              campaignType === "MEMBERSHIP_CARD" ||
              campaignType === "GIFT_CARD" ||
              (campaignType === "POINT_CODE" || campaignType === "CASH_BACK" && (
                <>
                  <div>
                    <Form.Item hasFeedback label="Position" name="displayOrder">
                      <Input
                        id="displayOrder"
                        type="number"
                        placeholder="Enter Campaign Position"
                        min={0}
                      />
                    </Form.Item>
                  </div>
                </>
              ))}
            {campaignType === "CASH_BACK" && (
              <>
                <div>
                  <Form.Item hasFeedback label="Cash Back Code" name="cashBackCode">
                    <Input
                      id="cashBackCode"
                      type="text"
                      placeholder="Enter Cash Back Code"
                      min={0}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {campaignType === "POINT_CODE" && (
              <>
                <div>
                  <Form.Item hasFeedback label="Point Code" name="pointCode">
                    <Input
                      id="pointCode"
                      type="text"
                      placeholder="Enter Point Code"
                      min={0}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {campaignType === "GIFT_CARD" && (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Gift Card Code"
                    name="giftCardCode"
                  >
                    <Input
                      id="giftCardCode"
                      type="text"
                      placeholder="Enter Gift Card Code"
                      min={0}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {campaignType === "MEMBERSHIP_CARD" && (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Membership Code"
                    name="membershipCode"
                  >
                    <Input
                      id="membershipCode"
                      type="text"
                      placeholder="Enter Membership Code"
                      min={0}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {campaignType === "PROMO" && (
              <>
                <div>
                  <Form.Item hasFeedback label="Promo Code" name="promoCode">
                    <Input
                      id="promoCode"
                      type="text"
                      placeholder="Enter Campaign Promo Code"
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {campaignType === "GROUP_BUY" && (
              <>
                <div className="">
                  <Form.Item
                    hasFeedback
                    label="Is Pay First?"
                    name="isPayFirst"
                    className="mr-5"
                  >
                    <Switch
                      checkedChildren={`true`}
                      unCheckedChildren={`false`}
                      defaultChecked={
                        (campaignData as any)?.data?.groupBuy?.isPayFirst
                      }
                      onChange={() => setIsPayFirst(!isPayFirst)}
                    />
                  </Form.Item>
                </div>
                {isPayFirst && (
                  <>
                    <div className="isPayFirst">
                      <div className="">
                        <Form.Item
                          hasFeedback
                          label="Last Payment Time In Hours"
                          name="lastPaymentTimeInMinute"
                        >
                          <Input
                            id="lastPaymentTimeInMinute"
                            type="number"
                            min={0}
                            placeholder="Enter Last Payment Time In Hours"
                            onKeyPress={paymentTimePreventMinus}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <Form.Item
                    hasFeedback
                    label="No Of Participator Each Group"
                    rules={[
                      {
                        required: false,
                        message: "Campaign Title is Required!",
                      },
                    ]}
                    name="noOfParticipatorEachGroup"
                  >
                    <Input
                      id="noOfParticipatorEachGroup"
                      type="number"
                      min={0}
                      placeholder="Enter No Of Participator Each Group"
                    />
                  </Form.Item>
                </div>
              </>
            )}

            <div>
              <Form.Item
                hasFeedback
                label="Starting Date & Time"
                rules={[
                  {
                    required: true,
                    message: "Starting Date is Required!",
                  },
                ]}
                name="startDate"
              >
                <DatePicker
                  showTime
                  style={{ minWidth: "100%" }}
                  showNow={false}
                  placeholder="Starting Date"
                  onChange={(val: DatePickerProps["value"]) =>
                    setStartingDate(val)
                  }
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Ending Date & Time"
                rules={[
                  {
                    required: true,
                    message: "Ending Date is Required!",
                  },
                ]}
                name="endDate"
              >
                <DatePicker
                  showTime
                  id="endDate"
                  placeholder="Enter Ending Date"
                  showNow={false}
                  style={{ minWidth: "100%" }}
                  onChange={(val: DatePickerProps["value"]) =>
                    setEndingDate(val)
                  }
                  disabled={!startingDate}
                  disabledDate={(currentDate) =>
                    currentDate.isBefore(dayjs(startingDate))
                  }
                />
              </Form.Item>
            </div>
            {campaignType === "PURCHASE_VOUCHER" ||
              campaignType === "VOUCHER" ||
              campaignType == "PROMO" ||
              campaignType === "MEMBERSHIP_CARD" ||
              (campaignType === "GIFT_CARD" || campaignType === "POINT_CODE" || campaignType === "CASH_BACK" && (
                <>
                  <div>
                    <Form.Item label="Valid Date & Time" name="range">
                      <RangePicker
                        defaultValue={
                          range.from != null && range.to != null
                            ? [moment(range.from), moment(range.to)]
                            : null
                        }
                        onChange={(e, f) => {
                          console.log(
                            "momnet",
                            moment.utc(f[0])?.toISOString()
                          );
                          setRange({
                            from: moment.utc(f[0])?.toISOString(),
                            to: moment.utc(f[1])?.toISOString(),
                          });
                        }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [
                            moment("00:00:00", "HH:mm:ss"),
                            moment("23:59:59", "HH:mm:ss"),
                          ],
                        }}
                        inputReadOnly
                        allowClear
                      />
                    </Form.Item>
                  </div>
                </>
              ))}
            <div>
              <Form.Item
                hasFeedback
                label="Campaign Valid Type"
                rules={[
                  {
                    required: true,
                    message: "Campaign Valid Type is Required!",
                  },
                ]}
                name="campaignValidType"
              >
                <Select
                  // disabled={campaignData?.data?.campaignType}
                  onChange={(val) => setCampaignApplyType(val)}
                  options={[
                    { value: "1", label: "ONE TIMES" },
                    { value: "2", label: "TWO TIMES" },
                    { value: "3", label: "THREE TIMES" },
                    { value: "4", label: "FOUR TIMES" },
                    {
                      value: "5",
                      label: "FIVE TIMES",
                    },
                  ]}
                  placeholder="Enter Campaign Valid Type"
                />
              </Form.Item>
            </div>
            {campaignType === "DISCOUNT" ||
              campaignType === "GIFT_ITEMS" ||
              campaignType === "PURCHASE_VOUCHER" ||
              campaignType === "VOUCHER" ||
              campaignType === "PROMO" ||
              campaignType === "MEMBERSHIP_CARD" ||
              (campaignType === "GIFT_CARD" || campaignType === "POINT_CODE" || campaignType === "CASH_BACK" && (
                <>
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Discount Type"
                      rules={[
                        {
                          required: true,
                          message: "Discount Type is Required!",
                        },
                      ]}
                      name="minimumDiscountType"
                      initialValue={discountType}
                    >
                      <Select
                        onChange={(val) => setDiscountType(val)}
                        options={[
                          { value: "PERCENTAGE", label: "PERCENTAGE" },
                          { value: "FLAT", label: "FLAT" },
                        ]}
                        placeholder="Enter Discount Type"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      hasFeedback
                      label=" Discount Amount"
                      rules={[
                        {
                          required: true,
                          message: "Discount Amount is Required!",
                        },
                      ]}
                      name="minimumDiscount"
                    >
                      <Input
                        id="minimumDiscount"
                        type="number"
                        min={0}
                        placeholder="Enter Discount Amount"
                        addonAfter={discountType === "PERCENTAGE" ? "%" : "tk"}
                      />
                    </Form.Item>
                  </div>{" "}
                </>
              ))}
            {campaignType === "DISCOUNT" ||
              campaignType === "GIFT_ITEMS" ||
              campaignType === "PURCHASE_VOUCHER" ||
              campaignType === "VOUCHER" ||
              campaignType === "PROMO" ||
              campaignType === "MEMBERSHIP_CARD" ||
              (campaignType === "GIFT_CARD" || campaignType === "POINT_CODE" || campaignType === "CASH_BACK" && (
                <>
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Campaign Maximum Discount Amount"
                      name="campaignMaximumDiscountAmount"
                    >
                      <Input
                        id="campaignMaximumDiscountAmount"
                        type="number"
                        placeholder="Enter Campaign Maximum Discount Amount"
                        min={0}
                      />
                    </Form.Item>
                  </div>
                </>
              ))}
            {campaignType === "GIFT_ITEMS" ||
              campaignType === "PURCHASE_VOUCHER" ||
              campaignType === "VOUCHER" ||
              campaignType === "PROMO" ||
              campaignType === "MEMBERSHIP_CARD" ||
              (campaignType === "GIFT_CARD" && (
                <>
                  <div>
                    <Form.Item
                      hasFeedback
                      label="Campaign Minimum Discount Amount"
                      name="campaignMinimumDiscountAmount"
                    >
                      <Input
                        id="campaignMinimumDiscountAmount"
                        type="number"
                        placeholder="Enter Campaign Minimum Discount Amount"
                        min={0}
                      />
                    </Form.Item>
                  </div>
                </>
              ))}
            {campaignType === "POINT_CODE" || campaignType === "CASH_BACK" && (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Campaign Minimum Order Amount"
                    name="campaignMinimumOrderAmount"
                  >
                    <Input
                      id="campaignMinimnumOrderAmount"
                      type="number"
                      placeholder="Enter Campaign Minimum Order  Amount"
                      min={0}
                    />
                  </Form.Item>
                </div>
              </>
            )}
             {campaignType === "DISCOUNT" && (
              <>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Campaign Order Over Amount"
                    name="campaignOrdersOverAmount"
                  >
                    <Input
                      id="campaignOrdersOverAmount"
                      type="number"
                      placeholder="Enter Campaign Order Over Amount"
                      min={0}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            <div>
              <Form.Item hasFeedback label="Color Code" name="colorCode">
                <Input
                  id="colorCode"
                  type="color"
                  placeholder="Enter Color Code"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                <Input
                  id="metaTitle"
                  type="text"
                  placeholder="Enter Meta Title"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Meta Description"
                name="metaDescription"
              >
                <Input.TextArea
                  id="metaDescription"
                  placeholder="Enter Meta Description"
                  rows={3}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="keyword" name="keyword">
                <Input.TextArea
                  id="keyWord"
                  placeholder="Enter Product Keywords"
                  rows={3}
                />
              </Form.Item>
            </div>

            <div className="col-span-2">
              <Form.Item hasFeedback label="Description" name="description">
                <JoditEditor
                  ref={editor}
                  value={convertedText}
                  config={jodiConfig as any}
                  onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </Form.Item>
            </div>

            <div className="col-span-2">
              <Form.Item
                hasFeedback
                label="Term & Conditions"
                name="termAndConditions"
              >
                <JoditEditor
                  ref={editor}
                  value={convertedTextForTnC}
                  config={jodiConfig as any}
                  onBlur={(newContent) => setConvertedTextForTnC(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </Form.Item>
            </div>
          </div>
          <Row gutter={20} className="mb-6">
            <Col span={12}>
              <Divider orientation="left">
                Apps Image
                <Tooltip
                  placement="bottom"
                  title={"Web image should 500px/180px (Ratio 2.8)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={appsImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => setAppsImgUrl(url)}
                // crop={2.8}
              />
            </Col>
            <Col span={12}>
              <Divider orientation="left">
                Web Image
                <Tooltip
                  placement="bottom"
                  title={"Web image should 1200px/750px (Ratio 1.6)"}
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                // crop={1.6}
                imgUrl={webImgUrl || ""}
                startUpload={startUpload2}
                setStartUpload={(val: any) => setStartUpload2(val)}
                setGeneratedImgUrl={(url: any) => setWebImgUrl(url)}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default AddCampaign;
