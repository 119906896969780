import React from "react";
import MainLayout from "../../../components/Layouts";
import MerchantRequestedProductList from "../../../components/Merchant/RequestedProduct";

const MerchantRequestedProductPage = () => {
  return (
    <MainLayout>
      <MerchantRequestedProductList />
    </MainLayout>
  );
};

export default MerchantRequestedProductPage;
