import { Button } from "antd";
import PropTypes from "prop-types";
import { ReactElement } from "react";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";

const SubmitResetBtn = ({
  props,
  onClickSubmit,
  onClickReset,
  loading,
  disabled,
}: any): ReactElement => {
  return (
    <div className="mt-6_">
      <Button
        // {...props}
        disabled={disabled}
        loading={loading}
        size="large"
        htmlType="submit"
        className={styles.submitBtn}
        onClick={onClickSubmit}
      >
        Submit
      </Button>
      <Button
        type="ghost"
        htmlType="button"
        size="large"
        onClick={onClickReset}
        className={styles.resetBtn}
      >
        Reset
      </Button>
    </div>
  );
};

SubmitResetBtn.propTypes = {
  onClick: PropTypes.func,
  onClickSubmit: PropTypes.func,
  onClickReset: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SubmitResetBtn;
