import { Button, Empty, Pagination, Popconfirm } from "antd";
import { useEffect, useState, useCallback } from "react";
import { responseNotification } from "../../utils/notify";
import { DeleteOutlined } from "@ant-design/icons";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import React from "react";
import axios from "axios";
import { Loader } from "../common";
import { isAllowedService } from "../../utils/services";
import { getPage } from "../../utils";

const AssignBranchList = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [branchList, setBranchList] = useState<any>(undefined);
  const withProduct = false;

  

  const getBankBranchList = useCallback(async () => {
    setBranchList({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    axios
      .get(
        `${encodedUri}/admin/bank/details?bankId=${visibleData}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBranchList({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBranchList({ loading: false, data: [] });
        console.error("Product: Error", err);
      });
  }, [visibleData]);

  const onBranchRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_PAY_API}/admin/bank/remove-branch`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bankId: visibleData,
          branchId: id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Branch Remove Successfully", "success");
            getBankBranchList();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getBankBranchList();
  }, [getBankBranchList, onCloseMethod]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {branchList?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {branchList?.data?.bank?.branches?.length ? (
                      branchList?.data?.bank?.branches?.map((branch: any, index: any) => (
                        <tr className="border-t hover:bg-gray-100" key={index}>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {branch?.name}
                            </span>
                          </td>
                          <td>
                            <div className="flex gap-2">
                              
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to Branch remove?"
                                  visible={branch?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    console.log(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBranchRemove(branch?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(branch?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                             
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...branchList?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default AssignBranchList;
