import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import ConfirmedOrderList from "../../components/Orders/ConfirmedOrder";
import React from "react";

const ConfirmedOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <ConfirmedOrderList />
    </MainLayout>
  );
};

export default ConfirmedOrderPage;
