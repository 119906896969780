import { Button, Drawer, Empty, Form, Pagination, Rate, Skeleton } from "antd";
import { useEffect, useState, useCallback } from "react";
import { responseNotification } from "../../utils/notify";
import { DownOutlined, SwapOutlined, ToTopOutlined } from "@ant-design/icons";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import React from "react";
import axios from "axios";
import { Loader } from "../common";
import moment from "moment";
import { getPage, getParamValue } from "../../utils";
import BreadCrumb from "../Layouts/Breadcrumb";
import DirectoryTree from "antd/es/tree/DirectoryTree";

const ProductReviewList = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [form] = Form.useForm();
  const [showSearch, setShowSearch] = useState(true);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [reviewList, setReviewList] = useState<any>(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);

  const getReviewList = useCallback(async () => {
    setReviewList({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

    axios
      .get(
        `${encodedUri}/product/reviews?productId=${visibleData}&sortBy=displayOrder&sortOrder=ASC&page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setReviewList({ loading: false, data: res.data });
      })
      .catch((err) => {
        setReviewList({ loading: false, data: [] });
        console.error("Product: Error", err);
      });
  }, [visibleData]);

  const changeOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    reviewList?.data?.productReviews?.find((review: any, i: string) => {
      if (review?.id === node?.key) {
        curr = i;
        item = review;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? reviewList?.data?.productReviews
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : reviewList?.data?.productReviews?.slice(0, pos),
        item,
        curr > pos
          ? reviewList?.data?.productReviews
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, reviewList?.data?.productReviews?.length - 1)
          : reviewList?.data?.productReviews?.slice(
              pos,
              reviewList?.data?.productReviews?.length
            )
      )?.map((item, i) => ({
        id: item?.id,
        displayOrder: i + 1,
      }));

    if (formated) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/review/update-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: formated,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getReviewList();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onProductMoveToTop = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/review/update-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: [
              {
                id: id,
                isSendTop: true,
              },
            ],
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Review Move To Top Successfully", "success");
            getReviewList();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getReviewList();
  }, [getReviewList, onCloseMethod]);

  const onClose = () => {
    getReviewList();
    setReArrangeDrawer(undefined);
  };
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={type == "JC_FOOD" ? "Review List" : "Review List "}
        subTitle={`${
          reviewList?.data?.totalElements ||
          reviewList?.data?.productReviews?.length ||
          0
        } Review(s)`}
        extra={[
          <Button
            shape="round"
            type="primary"
            className="bg-blue-600"
            onClick={() => setReArrangeDrawer(true)}
          >
            <SwapOutlined />
          </Button>,
        ]}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {reviewList?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Review</th>
                      <th scope="col">Rating</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Move TO Top</th>
                    </tr>
                  </thead>

                  <tbody>
                    {reviewList?.data?.productReviews?.length ? (
                      reviewList?.data?.productReviews?.map(
                        (review: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {review?.description}
                              </span>
                            </td>
                            <td>
                              <Rate value={review?.rating || 1} disabled />
                              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                {review?.rating || 0}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {review?.createdBy?.name ||
                                  review?.createdBy?.mobileNumber?.replace(
                                    "+88",
                                    ""
                                  )}
                                {" - "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(review?.createdAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(review?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(review?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="primary"
                                danger
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onProductMoveToTop(review?.id);
                                }}
                              >
                                <ToTopOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...reviewList?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Re Arrange Review"
        placement="right"
        onClose={onClose}
        open={reArrangeDrawer}
        width={450}
      >
        {reviewList?.loading ? (
          <Skeleton />
        ) : reviewList?.data?.productReviews?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={reviewList?.data?.productReviews.map((review: any) => ({
              title: review?.description,
              key: review?.id,
            }))}
            onDrop={(e: any) => {
              changeOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>
    </>
  );
};

export default ProductReviewList;
