import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import MerchantLayout from "../Layout";
import ShopList from "../../Shop/index";
import { useSelector } from "react-redux";

const MerchantShopList = (): ReactElement => {
  const { merchantInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <MerchantLayout
        merchantId={merchantInfo.merchantId}
        title="Shop List"
        subTitle="Details"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <ShopList
              inMerchant={true}
              getMerchantId={merchantInfo.merchantId}
            />
          </div>
        </div>
      </MerchantLayout>
    </React.Fragment>
  );
};

MerchantShopList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MerchantShopList;
