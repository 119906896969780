import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import CampaignLayout from "../Layout";
import moment from "moment";
import { useSelector } from "react-redux";

const CampaignDetails = (): ReactElement => {
  const { campaignInfo } = useSelector((state) => (state as any)?.authReducer);

  return (
    <React.Fragment>
      <CampaignLayout
        campaignId={campaignInfo?.id}
        title={`Campaign Details`}
        subTitle={`Details`}
        extra={<></>}
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 h-full">
            <h2 className="text-xl font-semibold text-gray-700 capitalize pb-2 border-b border-gray-100 mb-6">
              General Information
            </h2>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div>
                <dt className="text-sm font-semibold text-gray-900">
                  Start Date
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {moment(campaignInfo?.startAt).format("lll")}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-semibold text-gray-900">
                  End Date
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {moment(campaignInfo?.endAt).format("lll")}
                </dd>
              </div>
              <div>
                <dd className="text-sm font-medium text-gray-500">
                  Is Pay First
                </dd>
                <dd className="text-sm font-medium text-gray-500">
                  {campaignInfo?.groupBuy?.isPayFirst}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-semibold text-gray-900">
                  Last Payment Time In Minute
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {campaignInfo?.groupBuy?.lastPaymentTimeInMinute / 60}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-semibold text-gray-900">
                  No Of Partici Pator Each Group
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {campaignInfo?.groupBuy?.noOfParticipatorEachGroup}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-semibold text-gray-900">
                  Campaign Name
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {campaignInfo?.name}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-semibold text-gray-900">
                  Campaign Type
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  {campaignInfo?.campaignType?.replace("_", " ")}
                </dd>
              </div> 

              {campaignInfo?.minimumDiscountType && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Minimum Discount Type
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {campaignInfo?.minimumDiscountType || 0}
                  </dd>
                </div>
              )}

              {campaignInfo?.minimumDiscount && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Minimum Discount
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    &#2547; {campaignInfo?.minimumDiscount || 0}
                  </dd>
                </div>
              )}

              {campaignInfo?.merchantOfferedDiscountType && (
                <div className="sm:col-span-2__">
                  <dt className="text-md font-semibold text-gray-900">
                    Merchant Offered Discount Type
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    <p>{campaignInfo?.merchantOfferedDiscountType}</p>
                  </dd>
                </div>
              )}

              {campaignInfo?.merchantOfferedDiscount && (
                <div className="sm:col-span-2__">
                  <dt className="text-md font-semibold text-gray-900">
                    Merchant Offered Discount
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    <p>{campaignInfo?.merchantOfferedDiscount}</p>
                  </dd>
                </div>
              )}

              {campaignInfo?.colorCode && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Color Code
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {campaignInfo?.colorCode}
                  </dd>
                </div>
              )}
              {campaignInfo?.metaTitle && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Meta Title
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {campaignInfo?.metaTitle}
                  </dd>
                </div>
              )}
              {campaignInfo?.metaDescription && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Meta Description
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {campaignInfo?.metaDescription}
                  </dd>
                </div>
              )}
              {campaignInfo?.keyword && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Keyword
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {campaignInfo?.keyword}
                  </dd>
                </div>
              )}

              {!!campaignInfo?.displayOrder && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Display Order
                  </dt>
                  <dd className="text-sm font-medium text-gray-500">
                    {campaignInfo?.displayOrder}
                  </dd>
                </div>
              )}

              {campaignInfo?.description && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Campaign Description
                  </dt>
                  <dd
                    className="mt-1 space-y-3 text-sm text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: campaignInfo?.description,
                    }}
                  ></dd>
                </div>
              )}

              {campaignInfo?.termAndConditions && (
                <div>
                  <dt className="text-sm font-semibold text-gray-900">
                    Term & Conditions
                  </dt>
                  <dd
                    className="mt-1 space-y-3 text-sm text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: campaignInfo?.termAndConditions,
                    }}
                  ></dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </CampaignLayout>
    </React.Fragment>
  );
};

CampaignDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CampaignDetails;
