import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import AirportList from "../../components/Airports";
import React from "react";

const AirportsPage = (): ReactElement => {
  return (
    <MainLayout>
      <AirportList />
    </MainLayout>
  );
};

export default AirportsPage;
