import React from "react";
import MainLayout from "../../../../components/Layouts";
import ShopProductList from "../../../../components/Promotions/Campaign/Product";
import CampaignProductAssign from "../../../../components/Promotions/Campaign/Product/assign";

const CampaignProductAssignPage = () => {
  return (
    <MainLayout>
      <CampaignProductAssign />
    </MainLayout>
  );
};

export default CampaignProductAssignPage;
