import React from "react";
import MainLayout from "../../../../components/Layouts";
import CampaignOrderDetails from "../../../../components/Promotions/Campaign/Order/details";

const CampaignOrderDetailsPage = () => {
  return (
    <MainLayout>
      <CampaignOrderDetails />
    </MainLayout>
  );
};

export default CampaignOrderDetailsPage;
