import MainLayout from "../../../components/Layouts";
import CampaignBrandsList from "../../../components/Promotions/Campaign/Brand/index";

const ShopOrderPage = () => {
  return (
    <MainLayout>
      <CampaignBrandsList />
    </MainLayout>
  );
};

export default ShopOrderPage;
