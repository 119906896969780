import React from "react";
import MainLayout from "../../components/Layouts";
import PaymentPreviewData from "../../components/TricketBooking/Flight/payment";

const PaymentPreview = () => {
    return (
        <div className="bg-white">
            <MainLayout>
                <PaymentPreviewData/>
            </MainLayout>
        </div>
    );
};

export default PaymentPreview;
