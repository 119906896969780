import React from "react";
import MainLayout from "../../../components/Layouts";
import GroupList from "../../../components/Promotions/Groups/index";

const GroupPage = () => {
  return (
    <MainLayout>
      <GroupList />
    </MainLayout>
  );
};

export default GroupPage;
