import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Modal,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import { isAllowedService } from "../../utils/services";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleCapping from "./Details";
import AddCapping from "./AddCapping";
import moment from "moment";
import React from "react";
import { debounce } from "lodash";

const CappingList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [key, setKey] = useState("");
  const [isActive, setIsActive] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const isDeleted = "false";
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedCareer, setSelectedCareer] = useState(undefined);
  const [selectedCareerForEdit, setSelectedCareerForEdit] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shopId, setShopId] = useState<any>();
  const [selectedCareerId, setSelectedCareerId] = useState<any>(undefined);
  const [selectedShopForAssign, setSelectedShopForAssign] =
    useState<any>(undefined);
  const [cappingData, setCappingData] = useState<any>({
    loading: false,
    data: null,
  });
  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });
  const [careerOptions, setCareerOptions] = useState({
    loading: false,
    list: [],
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setIsModalVisible(false);
  };
  const getCappings = useCallback(async () => {
    setCappingData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    axios
      .get(
        `${encodedUri}/admin/capping/career?deleted=${isDeleted}` +
          (key ? `&name=${key}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (page
            ? `&page=${
                page == cappingData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCappingData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCappingData({ loading: false, data: [] });
        console.error("Capping: Error", err);
      });
  }, [type, key, isActive,   sortBy,sortOrder, isDeleted, limit, page]);

  useEffect(() => {
    getCappings();
  }, [getCappings]);

 

  const getCareersOptions = useCallback(async (key?: string) => {
    setCareerOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_BOOKING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/career?page=0&limit=20` + (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCareerOptions({
      loading: false,
      list: res?.data?.careers?.map((career: any) => ({
        label: career.name,
        value: career.name,
      })),
    });
  }, []);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_BOOKING_API}/admin/capping/career`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          careers: [
            {
              id: id,
              isActive: val,
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getCappings();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "career") getCareersOptions(value);
      }
    };

    return debounce(loadOptions, 500);
  }, [getCareersOptions]);

  const reseAllFieldData = () => {
    setKey("");
    setIsActive("");
    setSortBy("");
    setSortOrder("");
    form.resetFields();
  };
  const onClose = () => {
    form.resetFields();
    getCappings();
    setVisible(undefined);
    setSelectedCareer(undefined);
    setSelectedCareerForEdit(undefined);
    setSelectedShopForAssign(undefined);
    setSelectedCareerId(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Capping Career List"
        subTitle={`${
          cappingData?.data?.totalElements ||
          cappingData?.data?.careers?.length ||
          0
        } Career(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="vertical" form={form} className={styles.formInline}>
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <Form.Item name="career_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Career"
                  optionFilterProp="children"
                  onChange={(val) => setKey(val)}
                  onSearch={(e) => handleSearch(e, "career")}
                  filterOption={() => {
                    return true;
                  }}
                  options={careerOptions?.list}
                ></Select>
              </Form.Item>
              <Form.Item
                name="deleted_search"
                initialValue={
                  isActive == "true"
                    ? "ACTIVE"
                    : isActive == "false"
                    ? "INACTIVE"
                    : "ALL"
                }
              >
                <Select
                  showSearch
                  placeholder="Status"
                  onChange={(val) => setIsActive(val as string)}
                >
                  <Select.Option value={undefined}>ALL</Select.Option>
                  <Select.Option value={"true"} title="ACTIVE">
                    ACTIVE
                  </Select.Option>
                  <Select.Option value={"false"} title="INACTIVE">
                    INACTIVE
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="orderP_by_search"
                initialValue={
                  sortBy == "displayOrder"
                    ? "DISPLAY ORDER"
                    : sortBy == "createdAt"
                    ? "CREATED AT"
                    : sortBy == "updatedAt"
                    ? "UPDATED AT"
                    : "SORTING BY"
                }
              >
                <Select
                  showSearch
                  placeholder="Status"
                  onChange={(val) => setSortBy(val as string)}
                >
                  <Select.Option value={undefined}>SORTING BY</Select.Option>
                  <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                    DISPLAY ORDER
                  </Select.Option>
                  <Select.Option value={"createdAt"} title="CREATED AT">
                    CREATED AT
                  </Select.Option>
                  <Select.Option value={"updatedAt"} title="UPDATED AT">
                    UPDATED AT
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="sorting_order_search"
                initialValue={
                  sortOrder == "ASC"
                    ? "ASC"
                    : sortOrder == "DESC"
                    ? "DESC"
                    : "SORTING ORDER"
                }
              >
                <Select
                  showSearch
                  placeholder="sorting order"
                  onChange={(val) => setSortOrder(val as string)}
                >
                  <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                  <Select.Option value={"ASC"} title="ASC">
                    ASC
                  </Select.Option>
                  <Select.Option value={"DESC"} title="DESC">
                    DESC
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {cappingData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Code</th>
                      <th scope="col">TD</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {cappingData?.data?.careers?.length ? (
                      cappingData?.data?.careers?.map(
                        (career: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedCareer(career)}
                          >
                            <td>
                              <Avatar size={40} src={career?.logo} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {career?.name}
                              </span>
                            </td>
                            <td>{career?.careerCode}</td>
                            <td>{career?.careerCodeTD}</td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={career?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(career?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  career?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(career?.updatedAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(career?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(career?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {career?.updatedBy?.name ||
                                  career?.updatedBy?.mobileNumber}
                              </span>
                            </td>

                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedCareerForEdit(career)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...cappingData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Capping Career"
            : selectedCareerForEdit
            ? "Edit Capping Career"
            : "Capping Career Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedCareer || selectedCareerForEdit}
        width={450}
      >
        {visible || selectedCareerForEdit ? (
          <AddCapping
            onCloseMethod={onClose}
            visibleData={selectedCareerForEdit}
          />
        ) : (
          <SingleCapping BannerDetails={selectedCareer} />
        )}
      </Drawer>
    </>
  );
};

export default CappingList;
function setLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
