import { FilePdfOutlined } from "@ant-design/icons";
import {
  faBiking,
  faExchangeAlt,
  faHandHoldingUsd,
  faSortAmountDownAlt,
  faDownload, 
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Button, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef, 
  useState,
} from "react";
import { useSelector } from "react-redux";
import { OrderTypeArray } from "../../../utils";
import { responseNotification } from "../../../utils/notify";
import { isAllowedService } from "../../../utils/services";
import OrderPaymentLogs from "./paymentLogs";
import OrderInvoice from "./OrderInvoice";

const { Option } = Select;

const OrderExtra = ({
  orderDetails,
  selectedProducts,
  refetch, 
}: any): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [orderTransferModal, setOrderTransferModal] = useState(false);
  const [assignment, setAssignment] = useState(false);
  const [showInoice, setShowInvoice] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [merchantId, setMerchantId] = useState<any>();
  const [shopId, setShopId] = useState<any>();
  const [selectedDeliveryManId, setSelectedDeliveryManId] = useState("");

  const [singleOrderInfo, setSingleOrderInfo] = useState<any>();
  const [pdfloading, setPdfloading] = useState(false);
  const [status, setStatus] = useState("");
  const [note, setNote] = useState("");
  const [form] = Form.useForm();
  const fetchRef = useRef(0);

  const [deliveryManData, setDeliveryManData] = useState({
    loading: false,
    list: [],
  });

  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });

  const onStatusChange = async (data: any) => {
    if (status === "CANCELLED" && !note) {
      responseNotification("To cancel note is required!");
    } else {
      setLoading(true);

      const readyData = data && {
        orderId: singleOrderInfo?.orderId,
        status: status,
        note: note,
      };

      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/order`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            form.resetFields();
            refetch();
            setStatusModal(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchDeliveryMan = useCallback(async (value: any) => {
    setDeliveryManData({ loading: true, list: [] });
    await fetch(
      `${
        process.env.REACT_APP_RIDER_API
      }/admin/driver?status=VERIFIED&mobileNumberOrName=${value?.replace(
        "+88",
        ""
      )}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setDeliveryManData({
          loading: true,
          list: res?.drivers?.map((driver: any) => ({
            label: `${driver?.mobileNumber?.replace("+88", "")} - ${
              driver?.name
            }`,
            value: driver?.id,
          })),
        });
      });
  }, []);

  // =========================================
  const getMerchantsOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/merchant/all?page=0&limit=20` +
          (type ? `&type=${type}` : ``) +
          (key ? `&name=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMerchantOptions({
        loading: false,
        list: res.data?.merchants?.map((merchant: any) => ({
          label: merchant.name,
          value: merchant.id,
        })),
      });
    },
    [type]
  );

  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/by-merchant?page=0&limit=20` +
          (merchantId ? `&merchantId=${merchantId}` : ``) +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop: any) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type, merchantId]
  );

  //
  const onOrderTransfer = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      orderId: orderDetails?.orderId,
      shopId: shopId,
    };

    await fetch(
      `${process.env.REACT_APP_ORDER_API}/order/order-transfer-between-shop`,
      {
        method: "PUT", 
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Order Transfered Successfully", "success");
          getShopsOptions();
          setOrderTransferModal(!orderTransferModal);
          form.resetFields();
          refetch();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  //

  const onBkashManual = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      orderId: singleOrderInfo?.baseOrderId,
      amount: data.amount,
      transactionId: data.transactionId,
    };

    await fetch(
      `${process.env.REACT_APP_PAY_API}/bkash/manual/success/from-app`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Payment Received Successfully", "success");
          getShopsOptions();
          setShowForm(false);
          form.resetFields();
          refetch();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  // =======================================

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "merchant") getMerchantsOptions(value);
        else if (field === "shop") getShopsOptions(value);
        else if (field === "driver") fetchDeliveryMan(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getMerchantsOptions, getShopsOptions, fetchDeliveryMan]);

  const assignDeliveryHero = () => {
    if (selectedDeliveryManId) {
      setLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_ORDER_API}/admin/order`,
          {
            deliveryManId: selectedDeliveryManId,
            orderId: orderDetails?.orderId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            responseNotification("Assigned Successfully!");
            refetch();
            setAssignment(false);
          } else {
            responseNotification("Assigmenation Failed!", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(
            err?.message || "Assigmenation Failed!",
            "error"
          );
          console.error(err);
        });
    } else {
      responseNotification("Select a delivery man", "warning");
    }
  };

  useEffect(() => {
    setPdfloading(true);
    setSingleOrderInfo(orderDetails);
    setTimeout(() => setPdfloading(false), 3000);
    setStatus(orderDetails?.status);
  }, [orderDetails]);

  useEffect(() => {
    if (orderTransferModal) {
      getMerchantsOptions();
    }
  }, [getMerchantsOptions, orderTransferModal]);

  useEffect(() => {
    if (merchantId) {
      getShopsOptions();
    }
  }, [getShopsOptions, merchantId]);

  useEffect(() => {
    if (type) {
      getMerchantsOptions();
    }
  }, [getMerchantsOptions, type]);

  return ( 
    <React.Fragment>
      <div className="bg-white rounded-md shadow p-2 grid grid-cols-5 gap-3">
        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            onClick={() => setPaymentStatus(true)}
            title="Payment Status"
          >
            <FontAwesomeIcon icon={faHandHoldingUsd} />
          </Button>
        )}

        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            onClick={() => setAssignment(true)}
            disabled={
              // !!orderDetails?.deliveryManId ||
              orderDetails?.status === "ON_DELIVERY" ||
              orderDetails?.status === "PICKED" ||
              orderDetails?.status === "DELIVERED" ||
              orderDetails?.status === "COMPLETED" ||
              orderDetails?.status === "REVIEWED" ||
              orderDetails?.status === "CANCELLED"
            }
            title="Assign Delivery Hero"
          >
            <FontAwesomeIcon
              icon={faBiking}
              className={` text-${
                orderDetails?.deliveryManId ? "success" : "dark"
              }`}
            />
          </Button>
        )}

        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            onClick={() => setStatusModal(true)}
            disabled={
              orderDetails?.status === "PICKED" ||
              orderDetails?.status === "COMPLETED" ||
              orderDetails?.status === "DELIVERED" ||
              orderDetails?.status === "REVIEWED" ||
              orderDetails?.status === "CANCELLED"
            }
            title="Change Status"
          >
            <FontAwesomeIcon icon={faSortAmountDownAlt} />
          </Button>
        )}

        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            title="Transfer Order"
            onClick={() => setOrderTransferModal(true)}
            disabled={
              orderDetails?.status === "PICKED" ||
              orderDetails?.status === "COMPLETED" ||
              orderDetails?.status === "DELIVERED" ||
              orderDetails?.status === "REVIEWED" ||
              orderDetails?.status === "CANCELLED"
            }
          >
            <FontAwesomeIcon icon={faExchangeAlt} />
          </Button>
        )}

        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            title="Order Invoice"
            onClick={() => setShowInvoice(!showInoice)}
          >
            <FilePdfOutlined />
          </Button>
        )}
      </div>

      <Modal
        title={"Payment History"}
        okButtonProps={{
          disabled:
            !!!paymentStatus ||
            singleOrderInfo?.total <= singleOrderInfo?.totalPaid,
        }}
        open={!!paymentStatus}
        okText="bKash Manual"
        onOk={() => {
          setShowForm(true);
        }}
        cancelButtonProps={{
          color: "red",
          type: "dashed",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setPaymentStatus(false);
          refetch();
        }}
        destroyOnClose={true}
        width={"62%"}
      >
        {showForm && (
          <Form layout="inline" onFinish={onBkashManual} className="mb-4">
            <Form.Item
              hasFeedback
              name="amount"
              rules={[
                { required: true },
                {
                  validator: async (_, value) => {
                    if (
                      value >
                      singleOrderInfo?.total - singleOrderInfo?.totalPaid
                    ) {
                      return Promise.reject(
                        new Error("Amount Must be Equal or less than Balance")
                      );
                    }
                  },
                },
              ]}
              initialValue={
                singleOrderInfo?.baseAmount - singleOrderInfo?.totalPaid
              }
            >
              <Input id="amount" placeholder="Enter Amount" />
            </Form.Item>

            <Form.Item
              hasFeedback
              name="transactionId"
              rules={[
                {
                  required: true,
                  message: "Transaction ID is required",
                },
              ]}
            >
              <Input id="transactionId" placeholder="Enter Transaction ID" />
            </Form.Item>

            <Button danger type="primary" htmlType="submit">
              Recieve
            </Button>
          </Form>
        )}

        <OrderPaymentLogs orderDetails={orderDetails} />
      </Modal>
   
      <Modal
        title={"Transfer Order"}
        open={!!orderTransferModal}
        okText="Transfer"
        onOk={onOrderTransfer}
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setOrderTransferModal(!orderTransferModal);
        }}
        destroyOnClose={true}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Select Merchant">
            <Select
              allowClear
              showSearch
              placeholder="Select Merchant"
              optionFilterProp="children"
              onChange={(val) => setMerchantId(val)}
              onSearch={(e) => handleSearch(e, "category")}
              filterOption={() => {
                return true;
              }}
              options={merchantOptions.list}
            ></Select>
          </Form.Item>

          <Form.Item label="Select Shop" name="shopId">
            <Select
              allowClear
              autoClearSearchValue
              onSearch={() => {
                getShopsOptions();
              }}
              onChange={(val) => setShopId(val)}
              options={shopsOptions?.list}
              maxTagTextLength={20}
              disabled={!merchantId}
              placeholder="Select Shop to Transfer Order"
            ></Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"Change Order Status"}
        okButtonProps={{ disabled: !!!status }}
        open={!!statusModal}
        onOk={onStatusChange}
        okText="Change"
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setStatusModal(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <Form layout="vertical" onFinish={onStatusChange}>
            <Form.Item
              hasFeedback
              label="Status"
              name="status"
              initialValue={status}
              rules={[
                {
                  required: true,
                  message: "Status is required",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(val) => setStatus(val as string)}
                filterOption={() => {
                  return true;
                }}
              >
                {Object.values(OrderTypeArray)?.map((type, i) => (
                  <Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item hasFeedback label="Note" name="note">
              <Input.TextArea
                onChange={(val) => setNote(val?.target.value)}
                rows={5}
                id="note"
                placeholder="Write some note"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        title={
          <div className="d-flex-l">
            <FontAwesomeIcon
              icon={faBiking}
              className="mr-2"
              style={{ color: "#e73d42" }}
            />{" "}
            Assign a Delivery Man
          </div>
        }
        okButtonProps={{ disabled: !!!selectedDeliveryManId }}
        open={!!assignment}
        onOk={assignDeliveryHero}
        okText="Assign"
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setDeliveryManData({ loading: false, list: [] });
          setSelectedDeliveryManId("");
          setAssignment(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <Select
            showSearch
            style={{ width: "100%", marginBottom: 15 }}
            placeholder="Search by mobile number"
            optionFilterProp="children"
            onChange={(val) => setSelectedDeliveryManId(val as string)}
            onSearch={(e) => handleSearch(e, "driver")}
            filterOption={() => {
              return true;
            }}
            options={deliveryManData?.list}
          ></Select>
        </div>
      </Modal>
      <Modal
        centered
        title={
          <div className="d-flex-l">
            <FontAwesomeIcon
              icon={faDownload}
              className="mr-2"
              style={{ color: "#e73d42" }}
            />{" "}
            Download Invoice
          </div>
        }
        width={800}
        visible={!!showInoice}
        // onOk={() => print(['1'])}
        okText={
          <PDFDownloadLink
            document={
              <OrderInvoice
                orderDetails={singleOrderInfo}
                selectedProducts={selectedProducts}
              />
            }
            fileName="order.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        }
        cancelButtonProps={{
          type: "primary",
          danger: true,
        }}
        // confirmLoading={loading}
        onCancel={() => {
          setShowInvoice(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <PDFViewer
            showToolbar={false}
            style={{ width: "100%", minHeight: 600, border: 0 }}
          >
            <OrderInvoice
              orderDetails={singleOrderInfo}
              selectedProducts={selectedProducts}
            />
          </PDFViewer>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OrderExtra;
