import React from "react";
import MainLayout from "../../components/Layouts";
import GroupTicketReview from "../../components/Group-Ticket/GroupTicketReview";

const GroupTicketReviewPage = () => {
  return (
    <MainLayout>
      <GroupTicketReview />
    </MainLayout>
  );
};
export default GroupTicketReviewPage;
