import React from "react";
import MainLayout from "../../../../components/Layouts";
import CampaignOrdersList from "../../../../components/Promotions/Campaign/Order/index";

const CampaignOrderPage = () => {
  return (
    <MainLayout>
      <CampaignOrdersList />
    </MainLayout>
  );
};

export default CampaignOrderPage;
