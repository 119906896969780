import { Avatar, Tabs } from 'antd'
import { Image } from 'antd';
import React, { useState } from 'react'
import s from './SearchDetailsTab.module.css'
import { useEffect } from 'react'
import {
  GroupDescription,
  Itinerary,
  ItineraryGroup,
  PassengerCodes,
  PassengerInfo,
} from '../../../types'
import moment from 'moment'
import cn from 'classnames' 
const { TabPane } = Tabs

const SearchDetailsTab = ({
  itinerary,
  group,
  orientation = 'horizontal',
}: {
  itinerary: Itinerary | undefined
  group: GroupDescription | undefined
  orientation?: 'vertical' | 'horizontal'
}) => {
  const [itineraryData, setItineraryData] = useState<Itinerary>()
  const [leg, setLeg] = useState(0)
  const [groupData, setGroupData] = useState<GroupDescription>()

  useEffect(() => {
    setItineraryData(itinerary)
  }, [itinerary])

  console.log('itinerary', itinerary)

  const carrier = itineraryData?.legs[0]?.schedules?.[0]?.carrier?.details
  const firstSchedule = itineraryData?.legs[0]?.schedules[0]
  const lastSchedule =
    itineraryData?.legs[0]?.schedules[
      itineraryData?.legs[0]?.schedules?.length - 1
    ]
  const fromAirport = firstSchedule?.departure
  const toAirport = lastSchedule?.arrival

  const fare = itinerary?.pricingInformation?.[0]?.fare

  const passengerMap: { [key: string]: PassengerInfo } = {}
  const totalObj = fare?.passengerInfoList?.reduce(
    (obj, passengerInfo) => {
      passengerMap[passengerInfo?.passengerInfo?.passengerType] =
        passengerInfo?.passengerInfo
      return {
        fare:
          obj?.fare +
            passengerInfo?.passengerInfo?.passengerTotalFare?.totalFare || 0,
        passenger:
          obj?.passenger + passengerInfo?.passengerInfo?.passengerNumber || 0,
      }
    },
    {
      fare: 0,
      passenger: 0,
    }
  )

  const passengerTypeValue = {
    ADT: 'Adult',
    C09: 'Children',
    INF: 'Infant',
  }

  const onDeSelect = () => {
    try {
      const data: string | null = localStorage.getItem('SDFL') // Selected Data From List
      if (data) {
        //const parsedData = decryptedData ? JSON.parse(decryptedData) : {}
        //const { itinerary, group } = parsedData

        setItineraryData(itinerary)
        setGroupData(group)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onDeSelect()
  }, [])

  return (
    <div className="grid grid-cols-5 gap-8 max-w-full">
      <div
        className={cn('pt-2 col-span-3 bg-white', {
          'col-span-5': orientation === 'vertical',
        })}
      >
        <Tabs defaultActiveKey={leg.toString()} type="card" centered>
          {itinerary?.legs?.map((leg, legIndex) => (
            <TabPane
              tab={`${leg?.schedules[0]?.departure?.airport}-${
                leg?.schedules[leg?.schedules?.length - 1]?.arrival?.airport
              }`}
              key={legIndex.toString()}
              className={cn({ 'p-6': orientation === 'vertical' })}
            >
              {leg?.schedules?.map((schedule) => (
                <div key={schedule?.id}>
                  <div className="mb-6">
                    <div className="flex items-center justify-between gap-4 p-1 rounded-md shadow-sm mb-2">
                    <Avatar size={60} src={schedule?.carrier?.details?.logo} shape="square" />
                      
                      <span className="font-light text-gray-800 text-lg">
                        {schedule?.carrier?.details?.name}
                      </span>
                      <span className="text-sm text-gray-500 font-light">
                        {/* (Business) */}
                      </span>
                    </div>

                    <div className="flex items-center justify-between gap-4 py-3">
                      <div className="grid text-gray-500">
                        <span className="text-lg font-bold text-gray-700">
                          {schedule?.departure?.time?.slice(0, 5)}
                        </span>
                        <span className="text-sm">
                          {moment(
                            `${group?.legDescriptions[legIndex]?.departureDate}`
                          ).format('dd, DD MMM yyyy')}
                        </span>
                        <span className="text-lg">
                          {schedule?.departure?.airport}
                        </span>
                      </div>
                      <div className="grid text-center">
                        {Math.floor(schedule?.elapsedTime / 60)}h{' '}
                        {schedule?.elapsedTime % 60}m{' '}
                        <div className={s.custom_arrow_desing}></div>
                      </div>
                      <div className="grid text-gray-500 text-right">
                        <span className="text-xl font-bold text-gray-700">
                          {schedule?.arrival?.time?.slice(0, 5)}
                        </span>
                        <span className="text-sm">
                          {moment(
                            group?.legDescriptions[legIndex]?.departureDate
                          ).format('dd, DD MMM yyyy')}{' '}
                        </span>
                        <span className="text-lg">
                          {schedule?.arrival?.airport}
                        </span>
                      </div>
                    </div>

                    <div className="m-auto bg-red-100 rounded-full text-center max-w-max px-10 py-2 text-gray-800 text-sm">
                      Change plane at AMS | 2h 55m layover
                    </div>
                  </div>
                </div>
              ))}
            </TabPane>
          ))}
        </Tabs>
      </div>

      <div
        className={cn('pt-2 col-span-2 bg-white', {
          'col-span-5': orientation === 'vertical',
        })}
      >
        <Tabs defaultActiveKey="1" centered>
          <TabPane
            tab="Fare Summury"
            key="Fare-Summury"
            className={cn({ 'p-6': orientation === 'vertical' })}
          >
            <div className="flex items-center justify-between font-bold gap-4 border-b text-md">
              <div className="p-2">Fare Summary</div>
              <div className="p-2">Base Fare</div>
              <div className="p-2">Tax</div>
            </div>
            {fare?.passengerInfoList?.map((passenger, i) => (
              <div
                className={cn(
                  'flex items-center justify-between gap-4 text-sm',
                  { 'p-4': orientation === 'vertical' }
                )}
                key={`${Date.now()}-${i}`}
              >
                <div className="p-2">
                  {passengerTypeValue[passenger?.passengerInfo?.passengerType]}{' '}
                  x {passenger?.passengerInfo?.passengerNumber}
                </div>
                <div className="p-2">
                  {passenger?.passengerInfo?.passengerTotalFare?.currency}{' '}
                  {
                    passenger?.passengerInfo?.passengerTotalFare
                      ?.equivalentAmount
                  }
                </div>
                <div className="p-2">
                  {passenger?.passengerInfo?.passengerTotalFare?.currency}{' '}
                  {passenger?.passengerInfo?.passengerTotalFare?.totalTaxAmount}
                </div>
              </div>
            ))}
            <div className="bg-red-100 text-accent font-bold flex justify-between top-auto p-2 rounded-br-md self-end">
              <span>
                Total
                <span className="text-sm font-light ml-2">
                  ({totalObj?.passenger} Traveler)
                </span>
              </span>
              <span>BDT {totalObj?.fare}</span>
            </div>
          </TabPane>
          <TabPane tab="Baggage" key="Baggage">
            <div className="flex items-center justify-between font-bold gap-4 border-b text-md">
              <div className="p-2">Flight</div>
              <div className="p-2">Piece Count</div>
              <div className="p-2">Weight</div>
            </div>
            {fare?.passengerInfoList?.[0]?.passengerInfo?.baggageInformation?.map(
              (baggage, i) => {
                const fareComponent =
                  fare?.passengerInfoList?.[0]?.passengerInfo?.fareComponents[i]
                return (
                  <div
                    className="flex items-center justify-between gap-4 text-md"
                    key={`${Date.now()}-${i}`}
                  >
                    <div className="p-2">
                      {fareComponent?.beginAirport} -{' '}
                      
                      {fareComponent?.endAirport}
                  
                    </div>
                    <div className="p-2 pr-4">
                        
                      {baggage?.allowance?.pieceCount || '-'}
                    </div>
                    <div className="p-2">
                      {baggage?.allowance?.weight} {baggage?.allowance?.unit}
                    </div>
                  </div>
                )
              }
            )}
          </TabPane>
        
        </Tabs>
      </div>
    </div>
  )
}

export default SearchDetailsTab
