import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AppURL = process.env.REACT_APP_BOOKING_API;


function Flight(props) {
    const [journeyDate, setJourneyDate] = useState("");
    const [returnDate, setReturnDate] = useState("");
    const [checkWay, setCheckWay] = useState(1);
    const [checkTravelerClass, setCheckTravelerClass] = useState(false);
    const [totalTravelers, setTotalTravelers] = useState(1);
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [childrenOneAge, setChildrenOneAge] = useState(0);
    const [childrenTwoAge, setChildrenTwoAge] = useState(0);
    const [childrenThreeAge, setChildrenThreeAge] = useState(0);
    const [childrenFourAge, setChildrenFourAge] = useState(0);
    const [infant, setInfant] = useState(0);
    const [travelerClass, setTravelerClass] = useState("Y");
    const [checkSearchFrom, setCheckSearchFrom] = useState(false);
    const [checkSearchTo, setCheckSearchTo] = useState(false);
    const [multiCitySearchFrom, setMultiCitySearchFrom] = useState("");
    const [multiCitySearchTo, setMultiCitySearchTo] = useState("");
    const [multiCityTravelerClass, setMultiCityTravelerClass] = useState("");
    const [cityNameFrom, setCityNameFrom] = useState("Dhaka");
    const [codeFrom, setCodeFrom] = useState("DAC");
    const [nameFrom, setNameFrom] = useState("Hazrat Shahjalal International Airport");

    const [cityNameTo, setCityNameTo] = useState("Cox's Bazar");
    const [codeTo, setCodeTo] = useState("CXB");
    const [nameTo, setNameTo] = useState("Cox's Bazar Airport");
    const [airportListFrom, setAirportListFrom] = useState([{
        "id": "633ac25eb19d504dd8099ea5",
        "cityName": "Dhaka",
        "code": "DAC",
        "name": "Hazrat Shahjalal International Airport",
    }]);
    const [airportListTo, setAirportListTo] = useState([{
        "id": "633ac25eb19d504dd8099e9f",
        "cityName": "Cox's Bazar",
        "code": "CXB",
        "name": "Cox's Bazar Airport"
    }]);

    const searchAirportFrom = (value) => {
        let staticURL = AppURL + "/airport?key=" + value;
        axios({
            method: 'GET',
            url: staticURL,
        }).then(response => {
            setAirportListFrom(response.data.airports);
        }).catch(error => {
            console.log(error.response.data.message);
        });
    }

    const searchAirportTo = (value) => {
        let staticURL = AppURL + "/airport?key=" + value;
        axios({
            method: 'GET',
            url: staticURL,
        }).then(response => {
            setAirportListTo(response.data.airports);
        }).catch(error => {
            console.log(error);
        });
    }
    const multiCitySearchFromCheck = (index) => {
        if (multiCitySearchFrom === index) {
            setMultiCitySearchFrom("");
        } else {
            setMultiCitySearchFrom(index);
        }
    }
    const multiCitySearchToCheck = (index) => {
        if (multiCitySearchTo === index) {
            setMultiCitySearchTo("");
        } else {
            setMultiCitySearchTo(index);
        }
    }
    const initialCustomizedArray = [{
        "DepartureDateTime": "",
        "DestinationLocation": {
            "LocationCode": "CXB"
        },
        "OriginLocation": {
            "LocationCode": "DAC"
        },
        "RPH": 0
    }, {
        "DepartureDateTime": "",
        "DestinationLocation": {
            "LocationCode": "CXB"
        },
        "OriginLocation": {
            "LocationCode": "DAC"
        },
        "RPH": 1
    }];


    const [customizedArray, setCustomizedArray] = useState(initialCustomizedArray);

    const initialMultiCity = [{
        "cityNameFrom": "Dhaka",
        "codeFrom": "DAC",
        "nameFrom": "Hazrat Shahjalal International Airport",
        "cityNameTo": "Cox's Bazar",
        "codeTo": "CXB",
        "nameTo": "Cox's Bazar Airport",
        "journeyDate": "",
        "totalTravelers": 1,
        "travelerClass": "Y",
        "travelerType": [
            {"adults": 1},
            {"children": 0},
            {"infant": 0}
        ],
        "checkSearchFrom": 0,
        "checkSearchTo": 0,
    }, {
        "cityNameFrom": "Dhaka",
        "codeFrom": "DAC",
        "nameFrom": "Hazrat Shahjalal International Airport",
        "cityNameTo": "Cox's Bazar",
        "codeTo": "CXB",
        "nameTo": "Cox's Bazar Airport",
        "journeyDate": "",
        "totalTravelers": 1,
        "travelerClass": "Y",
        "travelerType": [
            {"adults": 1},
            {"children": 0},
            {"infant": 0}
        ],
        "checkSearchFrom": 1,
        "checkSearchTo": 1,
    }];
    const [multiCity, setMultiCity] = useState(initialMultiCity);
    const multiCityArrayPush = () => {
        let temp = [{
            "cityNameFrom": "Dhaka",
            "codeFrom": "DAC",
            "nameFrom": "Hazrat Shahjalal International Airport",
            "cityNameTo": "Cox's Bazar",
            "codeTo": "CXB",
            "nameTo": "Cox's Bazar Airport",
            "journeyDate": "",
            "totalTravelers": 1,
            "travelerClass": "Y",
            "travelerType": [
                {"adults": 1},
                {"children": 0},
                {"infant": 0}
            ],
            "checkSearchFrom": multiCity.length,
            "checkSearchTo": multiCity.length,
        }];
        setMultiCity([...multiCity, ...temp]);
        const temp1 = [{
            "DepartureDateTime": "",
            "DestinationLocation": {
                "LocationCode": ""
            },
            "OriginLocation": {
                "LocationCode": ""
            },
            "RPH": multiCity.length,
        }];
        setCustomizedArray([...customizedArray, ...temp1]);
    }
    const multiCityArrayRemove = (event) => {
        multiCity.splice(event, 1);
        setMultiCity([...multiCity]);
        customizedArray.splice(event, 1);
        setCustomizedArray([...customizedArray]);
    }
    const setAirportFrom = (value) => {
        const resultArray = value.split(",");
        setCityNameFrom(resultArray[0]);
        setCodeFrom(resultArray[1]);
        setNameFrom(resultArray[2]);
        setCheckSearchFrom(false);
        setCheckSearchTo(true);
    }

    const setAirportTo = (value) => {
        const resultArray = value.split(",");
        setCityNameTo(resultArray[0]);
        setCodeTo(resultArray[1]);
        setNameTo(resultArray[2]);
        setCheckSearchTo(false);
    }

    const multiAirportFrom = (index, value) => {
        const resultArray = value.split(",");
        let data = [...multiCity];
        data[index]["cityNameFrom"] = resultArray[0];
        data[index]["codeFrom"] = resultArray[1];
        data[index]["nameFrom"] = resultArray[2];
        setMultiCity(data);
        setMultiCitySearchFrom("");
        let tempData = [...customizedArray];
        tempData[index]["OriginLocation"]["LocationCode"] = resultArray[1];
        setCustomizedArray(tempData);
        console.log("customized ", customizedArray);
    }
    const multiAirportTo = (index, value) => {
        const resultArray = value.split(",");
        let data = [...multiCity];
        data[index]["cityNameTo"] = resultArray[0];
        data[index]["codeTo"] = resultArray[1];
        data[index]["nameTo"] = resultArray[2];
        setMultiCity(data);
        setMultiCitySearchTo("");
        let tempData = [...customizedArray];
        tempData[index]["DestinationLocation"]["LocationCode"] = resultArray[1];
        setCustomizedArray(tempData);
    }

    const incrementAdults = () => {
        setAdults(parseInt(adults) + parseInt(1));
        setTotalTravelers(parseInt(totalTravelers) + parseInt(1));
    }
    const decrementAdults = () => {
        if (adults > 0) {
            setAdults(parseInt(adults) - parseInt(1));
            setTotalTravelers(parseInt(totalTravelers) - parseInt(1));
        } else {
            setAdults(0);
        }
    }

    const incrementChildren = () => {
        if (children < 4) {
            setChildren(parseInt(children) + parseInt(1));
            setTotalTravelers(parseInt(totalTravelers) + parseInt(1));
        }
    }

    const decrementChildren = () => {
        if (children > 0) {
            setChildren(parseInt(children) - parseInt(1));
            setTotalTravelers(parseInt(totalTravelers) - parseInt(1));
        } else {
            setChildren(0);
        }
    }

    const incrementInfant = () => {
        setInfant(parseInt(infant) + parseInt(1));
        setTotalTravelers(parseInt(totalTravelers) + parseInt(1));
    }

    const decrementInfant = () => {
        if (infant > 0) {
            setInfant(parseInt(infant) - parseInt(1));
            setTotalTravelers(parseInt(totalTravelers) - parseInt(1));
        } else {
            setInfant(0);
        }
    }
    const searchParamater = [{
        "totalTravelers": totalTravelers,
        "travelerClass": travelerClass,
        "adults": adults,
        "children": children,
        "infant": infant,
        "checkWay": checkWay,
        "childrenOneAge": childrenOneAge,
        "childrenTwoAge": childrenTwoAge,
        "childrenThreeAge": childrenThreeAge,
        "childrenFourAge": childrenFourAge
    }]
    const margeArray = [...customizedArray, ...searchParamater];
    const searchValueMultiCity = JSON.stringify(margeArray);
    const searchValue = cityNameFrom + ',' + codeFrom + ',' + nameFrom + ',' + cityNameTo + ',' + codeTo + ',' + nameTo + ',' + journeyDate + ',' + returnDate + ',' + totalTravelers + ',' + travelerClass + ',' + adults + ',' + children + ',' + infant + ',' + checkWay + ',' + childrenOneAge + ',' + childrenTwoAge + ',' + childrenThreeAge + ',' + childrenFourAge;

    const reversFlight = () => {
        setCityNameFrom(cityNameTo);
        setCodeFrom(codeTo);
        setNameFrom(nameTo);
        setCityNameTo(cityNameFrom);
        setCodeTo(codeFrom);
        setNameTo(nameFrom);
    }

    const childAgeCalculate = (event, val) => {
        if (val === "one") {
            if (event.target.value > 9) {
                setChildrenOneAge(event.target.value);
            } else {
                setChildrenOneAge("0" + event.target.value);
            }
        } else if (val === "two") {
            if (event.target.value > 9) {
                setChildrenTwoAge(event.target.value);
            } else {
                setChildrenTwoAge("0" + event.target.value);
            }
        } else if (val === "three") {
            if (event.target.value > 9) {
                setChildrenThreeAge(event.target.value);
            } else {
                setChildrenThreeAge("0" + event.target.value);
            }
        } else {
            if (event.target.value > 9) {
                setChildrenFourAge(event.target.value);
            } else {
                setChildrenFourAge("0" + event.target.value);
            }
        }
    }

    const [childMsg, setChildMsg] = useState("");
    const checkChildAge = () => {
        if (children > 0) {
            if (children === 1) {
                if (childrenOneAge === 0) {
                    setChildMsg("Please select child 1 age !!!");
                } else {
                    setCheckTravelerClass(false);
                    setChildMsg("");
                }
            } else if (children === 2) {
                if ((childrenOneAge === 0) || (childrenTwoAge === 0)) {
                    setChildMsg("Please select child 1 & 2 age !!!");
                } else {
                    setCheckTravelerClass(false);
                    setChildMsg("");
                }
            } else if (children === 3) {
                if ((childrenOneAge === 0) || (childrenTwoAge === 0) || (childrenThreeAge === 0)) {
                    setChildMsg("Please select child 1,2 & 3 age !!!");
                } else {
                    setCheckTravelerClass(false);
                    setChildMsg("");
                }
            } else if (children === 4) {
                if ((childrenOneAge === 0) || (childrenTwoAge === 0) || (childrenThreeAge === 0) || (childrenFourAge === 0)) {
                    setChildMsg("Please select all child age !!!");
                } else {
                    setCheckTravelerClass(false);
                    setChildMsg("");
                }
            } else {
                setChildMsg("");
                setCheckTravelerClass(false);
            }
        } else {
            setCheckTravelerClass(false);
            setChildMsg("");
        }
    }

    return (
        <div className="bg-white">
            <div
                className="h-auto mt-5 mx-[5%] lg:mx-[35%] flex-row flex md:justify-between gap-x-2">
                <div className="pt-1 flex flex-row gap-1">
                    <div className="mt-1 ml-2">
                        {checkWay === 1 ?
                            <input defaultChecked className="form-check-input"
                                   onClick={() => setCheckWay(1)} type="radio"
                                   name="flexRadioDefault"/> :
                            <input className="form-check-input"
                                   onClick={() => setCheckWay(1)} type="radio"
                                   name="flexRadioDefault"/>
                        }
                    </div>
                    <div className="text-black pt-0.5">One Way</div>
                </div>

                <div className="pt-1 flex flex-row gap-1">
                    <div className="mt-1">
                        {checkWay === 2 ?
                            <input defaultChecked
                                   className="form-check-input" onClick={() => setCheckWay(2)} type="radio"
                                   name="flexRadioDefault"/> :
                            <input className="form-check-input" onClick={() => setCheckWay(2)} type="radio"
                                   name="flexRadioDefault"/>
                        }
                    </div>
                    <div className="text-black pt-0.5">Round Way</div>
                </div>

                <div className="pt-1 flex flex-row gap-1">
                    <div className="mt-1">
                        {checkWay === 3 ?
                            <input defaultChecked className="form-check-input"
                                   onClick={() => setCheckWay(3)} type="radio"
                                   name="flexRadioDefault"/> :
                            <input className="form-check-input" onClick={() => setCheckWay(3)} type="radio"
                                   name="flexRadioDefault"/>
                        }
                    </div>
                    <div className="text-black pt-0.5">Multi City</div>
                </div>
            </div>

            {checkWay === 3 ?
                <div>
                    {multiCity.map((v_multi_city, index) => (
                        <div key={index}>
                            <div
                                className="h-auto mt-5 mx-[12%] gap-1 md:flex-row md:flex text-black">
                                <div>
                                    <div>From</div>
                                    <div onClick={() => multiCitySearchFromCheck(index)}
                                         className="md:w-28 lg:w-52 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2">
                                        <div className="flex flex-row justify-between">
                                            <div style={{fontSize: 15}}>{v_multi_city.cityNameFrom}</div>
                                            <div style={{fontSize: 15}}
                                                 className="pr-2">{v_multi_city.codeFrom}</div>
                                        </div>
                                        <div style={{fontSize: 11}}>{v_multi_city.nameFrom}</div>
                                    </div>
                                    {v_multi_city.checkSearchFrom === multiCitySearchFrom ?
                                        <div
                                            className="absolute h-auto border border-gray-300 cursor-pointer bg-white rounded-lg md:w-28 lg:w-52 mt-2">
                                            <div className="flex flex-row border-b border-gray-300">
                                                <i className="bi bi-search" style={{marginTop: 15, marginLeft: 5}}></i>
                                                <input style={{fontSize: 11}}
                                                       onKeyUp={(event) => searchAirportFrom(event.target.value)}
                                                       className="w-[97%] outline-0 mt-1 mx-1 h-11"
                                                       placeholder="Type for the airport name or airport code"/>
                                            </div>
                                            {airportListFrom?.map((v_airportList, index1) => {
                                                const setVlaueFrom = v_airportList.cityName + "," + v_airportList.code + "," + v_airportList.name;
                                                return (
                                                    <div
                                                        onClick={() => multiAirportFrom(index, setVlaueFrom)}
                                                        className="border-b bg-white border-gray-300 p-2"
                                                        key={index1}>
                                                        <div className="flex flex-row justify-between">
                                                            <div style={{fontSize: 14}}>{v_airportList.cityName}</div>
                                                            <div style={{fontSize: 14}}>{v_airportList.code}</div>
                                                        </div>
                                                        <div style={{fontSize: 11}}>
                                                            {v_airportList.name}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div> : <></>
                                    }
                                </div>

                                <div>
                                    <div>To</div>
                                    <div onClick={() => multiCitySearchToCheck(index)}
                                         className="md:w-28 lg:w-52 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2">
                                        <div className="flex flex-row justify-between">
                                            <div style={{fontSize: 15}}>{v_multi_city.cityNameTo}</div>
                                            <div style={{fontSize: 15}} className="pr-2">{v_multi_city.codeTo}</div>
                                        </div>
                                        <div style={{fontSize: 11}}>{v_multi_city.nameTo}</div>
                                    </div>
                                    {v_multi_city.checkSearchTo === multiCitySearchTo ?
                                        <div
                                            className="absolute h-auto border border-gray-300 cursor-pointer bg-white rounded-lg md:w-28 lg:w-52 mt-2">
                                            <div className="flex flex-row border-b border-gray-300">
                                                <i className="bi bi-search" style={{marginTop: 15, marginLeft: 5}}></i>
                                                <input style={{fontSize: 11}}
                                                       onKeyUp={(event) => searchAirportTo(event.target.value)}
                                                       className="w-[97%] outline-0 mt-1 mx-1 h-11"
                                                       placeholder="Type for the airport name or airport code"/>
                                            </div>
                                            {airportListTo?.map((v_airportList1, index1) => {
                                                const setVlaueTo = v_airportList1.cityName + "," + v_airportList1.code + "," + v_airportList1.name;
                                                return (
                                                    <div
                                                        onClick={() => multiAirportTo(index, setVlaueTo)}
                                                        className="border-b bg-white border-gray-300 p-2"
                                                        key={index1}>
                                                        <div className="flex flex-row justify-between">
                                                            <div style={{fontSize: 14}}>{v_airportList1.cityName}</div>
                                                            <div style={{fontSize: 14}}>{v_airportList1.code}</div>
                                                        </div>
                                                        <div style={{fontSize: 11}}>
                                                            {v_airportList1.name}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div> : <></>
                                    }
                                </div>
                                <div>
                                    <div>Journey Date</div>
                                    <div>
                                        <div
                                            className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        </div>
                                        <input datepicker type="text"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               placeholder="Select date"/>
                                    </div>

                                    {/*<input type="date" onChange={(e) => multiCityJourneyDate(index, e.target.value)}*/}
                                    {/*       className="md:w-16 lg:w-32 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2"/>*/}
                                </div>
                                {index === 0 ?
                                    <div>
                                        <div>Traveler, Class</div>
                                        <div onClick={() => setCheckTravelerClass(!checkTravelerClass)}
                                             className="md:w-28 lg:w-52 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2">
                                            <div style={{fontSize: 14}}>{totalTravelers} Travelers</div>
                                            <div style={{fontSize: 11}}>
                                                {travelerClass === 'Y' ? "Economy" : ""}
                                                {travelerClass === 'J' ? "Business" : ""}
                                                {travelerClass === 'F' ? "First" : ""}
                                            </div>
                                        </div>
                                        {checkTravelerClass ?
                                            <div style={{fontSize: 14}}
                                                 className="absolute h-auto border border-gray-300 cursor-pointer bg-white rounded-lg md:w-28 lg:w-52">
                                                <div
                                                    className="flex flex-row p-1.5 justify-between mx-[4%] border-b border-gray-300">
                                                    <div>
                                                        <div>Adults</div>
                                                        <div>12 y+</div>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <div onClick={() => decrementAdults()}
                                                             className="bg-gray-300 shadow-lg my-auto h-5 px-1 rounded-full">
                                                            <i className="fa-brands fa-facebook"></i>
                                                        </div>
                                                        <div
                                                            className="h-5 px-1 my-auto rounded-full">
                                                            {adults}
                                                        </div>
                                                        <div onClick={() => incrementAdults()}
                                                             className="bg-gray-300 shadow-lg h-5 px-1 my-auto rounded-full">
                                                            <i className="fa-brands fa-facebook"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="flex flex-row p-1.5 justify-between mx-[4%] border-b border-gray-300">
                                                    <div>
                                                        <div>Children</div>
                                                        <div>2-12 years</div>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <div onClick={() => decrementChildren()}
                                                             className="bg-gray-300 shadow-lg my-auto h-5 px-1 rounded-full">
                                                            <i className="bi bi-dash"></i>
                                                        </div>
                                                        <div
                                                            className="h-5 px-1 my-auto rounded-full">
                                                            {children}
                                                        </div>
                                                        <div onClick={() => incrementChildren()}
                                                             className="bg-gray-300 shadow-lg h-5 px-1 my-auto rounded-full">
                                                            <i className="bi bi-plus"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                {children > 0 ?
                                                    <>
                                                        <div className="mx-[4%]">Select age for</div>
                                                        <div
                                                            className="flex flex-row mt-2 mx-[4%] space-x-1">
                                                            <div>
                                                                <div style={{fontSize: 12}}>Child 1</div>
                                                                <select
                                                                    onChange={(event) => childAgeCalculate(event, "one")}
                                                                    className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                                    <option></option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                    <option>5</option>
                                                                    <option>6</option>
                                                                    <option>7</option>
                                                                    <option>8</option>
                                                                    <option>9</option>
                                                                    <option>10</option>
                                                                    <option>11</option>
                                                                </select>
                                                            </div>
                                                            {children > 1 ?
                                                                <div>
                                                                    <div style={{fontSize: 12}}>Child 2</div>
                                                                    <select
                                                                        onChange={(event) => childAgeCalculate(event, "two")}
                                                                        className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                                        <option></option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                        <option>6</option>
                                                                        <option>7</option>
                                                                        <option>8</option>
                                                                        <option>9</option>
                                                                        <option>10</option>
                                                                        <option>11</option>
                                                                    </select>
                                                                </div> : ""
                                                            }
                                                            {children > 2 ?
                                                                <div>
                                                                    <div style={{fontSize: 12}}>Child 3</div>
                                                                    <select
                                                                        onChange={(event) => childAgeCalculate(event, "three")}
                                                                        className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                                        <option></option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                        <option>6</option>
                                                                        <option>7</option>
                                                                        <option>8</option>
                                                                        <option>9</option>
                                                                        <option>10</option>
                                                                        <option>11</option>
                                                                    </select>
                                                                </div> : ""
                                                            }
                                                            {children > 3 ?
                                                                <div>
                                                                    <div style={{fontSize: 12}}>Child 4</div>
                                                                    <select
                                                                        onChange={(event) => childAgeCalculate(event, "four")}
                                                                        className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                                        <option></option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                        <option>6</option>
                                                                        <option>7</option>
                                                                        <option>8</option>
                                                                        <option>9</option>
                                                                        <option>10</option>
                                                                        <option>11</option>
                                                                    </select>
                                                                </div> : ""
                                                            }
                                                        </div>
                                                    </> : ""
                                                }

                                                <div
                                                    className="flex flex-row border-b border-gray-300 p-1.5 justify-between mx-[4%]">
                                                    <div>
                                                        <div>Infant</div>
                                                        <div>Below 2 years</div>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <div onClick={() => decrementInfant()}
                                                             className="bg-gray-300 shadow-lg my-auto h-5 px-1 rounded-full">
                                                            <i className="bi bi-dash"></i>
                                                        </div>
                                                        <div
                                                            className="h-5 px-1 my-auto rounded-full">
                                                            {infant}
                                                        </div>
                                                        <div onClick={() => incrementInfant()}
                                                             className="bg-gray-300 shadow-lg h-5 px-1 my-auto rounded-full">
                                                            <i className="bi bi-plus"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mx-[4%] mt-1"
                                                     style={{fontSize: 12}}>{childMsg}</div>
                                                <div onClick={() => checkChildAge()}
                                                     className="ml-auto w-16 py-2 mb-2 mr-2 text-center rounded-lg bg-yellow-500">Done
                                                </div>
                                            </div> : <></>
                                        }
                                    </div> :
                                    <div className="flex flex-row space-x-2">
                                        {multiCity.length === index + 1 ?
                                            <div onClick={() => multiCityArrayPush()}
                                                 className="bg-red-200 w-28 text-center h-10 rounded-lg mt-6 px-5 py-2 cursor-pointer"
                                                 style={{fontSize: 13}}>
                                                Add City
                                            </div> : <></>
                                        }

                                        {index < 2 ?
                                            <div
                                                className="bg-gray-200 w-28 text-center text-white h-10 rounded-lg mt-6 px-5 py-2 cursor-pointer"
                                                style={{fontSize: 13}}>
                                                Remove <i className="bi bi-x-circle"></i>
                                            </div>
                                            :
                                            <div onClick={() => multiCityArrayRemove(index)}
                                                 className="bg-red-500 w-28 text-center text-white h-10 rounded-lg mt-6 px-5 py-2 cursor-pointer"
                                                 style={{fontSize: 13}}>
                                                Remove <i className="bi bi-x-circle"></i>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div
                                className="border-red-600 border-b w-[75%] ml-12 mt-6 testimonials d-md-none"></div>
                        </div>
                    ))}

                    <div className="flex flex-row gap-x-2 mx-[12%] mt-10">
                        <div className="font-bold">Fare Type:</div>
                        <div>
                            {travelerClass === 'Y' ?
                                <input defaultChecked onClick={() => setTravelerClass("Y")}
                                       className="form-check-input"
                                       type="radio"
                                       name="flexRadioClass"/> :
                                <input onClick={() => setTravelerClass("Y")}
                                       className="form-check-input"
                                       type="radio"
                                       name="flexRadioClass"/>
                            }
                            Economy
                        </div>
                        <div>
                            {travelerClass === 'J' ?
                                <input defaultChecked onClick={() => setTravelerClass("J")}
                                       className="form-check-input" type="radio"
                                       name="flexRadioClass"/> :
                                <input onClick={() => setTravelerClass("J")}
                                       className="form-check-input" type="radio"
                                       name="flexRadioClass"/>
                            }
                            Business
                        </div>
                        <div>
                            {travelerClass === 'F' ?
                                <input defaultChecked onClick={() => setTravelerClass("F")}
                                       className="form-check-input" type="radio"
                                       name="flexRadioClass"/> :
                                <input onClick={() => setTravelerClass("F")}
                                       className="form-check-input" type="radio"
                                       name="flexRadioClass"/>
                            }
                            First
                        </div>
                    </div>
                </div>
                :
                <div
                    className="h-auto mt-5 mx-[12%] gap-1 md:flex-row md:flex text-black">
                    <div>
                        <div>From</div>
                        <div onClick={() => setCheckSearchFrom(!checkSearchFrom)}
                             className="md:w-28 lg:w-52 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2">
                            <div className="flex flex-row justify-between">
                                <div style={{fontSize: 15}}>{cityNameFrom}</div>
                                <div style={{fontSize: 15}} className="pr-2">{codeFrom}</div>
                            </div>
                            <div style={{fontSize: 11}}>{nameFrom}</div>
                        </div>
                        {checkSearchFrom ?
                            <div
                                className="absolute h-auto border border-gray-300 cursor-pointer bg-white rounded-lg w-72 md:w-28 lg:w-52 mt-2">
                                <div className="flex flex-row border-b border-gray-300">
                                    <i className="bi bi-search" style={{marginTop: 15, marginLeft: 5}}></i>
                                    <input style={{fontSize: 11}}
                                           onKeyUp={(event) => searchAirportFrom(event.target.value)}
                                           className="w-[97%] outline-0 mt-1 mx-1 h-11"
                                           placeholder="Type for the airport name or airport code"/>
                                </div>
                                {airportListFrom?.map((v_airportList, index) => {
                                    const setVlaueFrom = v_airportList.cityName + "," + v_airportList.code + "," + v_airportList.name;
                                    return (
                                        <div
                                            onClick={() => setAirportFrom(setVlaueFrom)}
                                            className="border-b bg-white border-gray-300 p-2"
                                            key={index}>
                                            <div className="flex flex-row justify-between">
                                                <div style={{fontSize: 14}}>{v_airportList.cityName}</div>
                                                <div style={{fontSize: 14}}>{v_airportList.code}</div>
                                            </div>
                                            <div style={{fontSize: 11}}>
                                                {v_airportList.name}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div> : <></>
                        }
                    </div>


                    <div id="mtm">
                        <div>To</div>
                        <div onClick={() => setCheckSearchTo(!checkSearchTo)}
                             className="md:w-28 lg:w-52 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2">
                            <div className="flex flex-row justify-between">
                                <div style={{fontSize: 15}}>{cityNameTo}</div>
                                <div style={{fontSize: 15}} className="pr-2">{codeTo}</div>
                            </div>
                            <div style={{fontSize: 11}}>{nameTo}</div>
                        </div>
                        {checkSearchTo ?
                            <div
                                className="absolute h-auto border border-gray-300 cursor-pointer bg-white rounded-lg w-72 md:w-28 lg:w-52 mt-2">
                                <div className="flex flex-row border-b border-gray-300">
                                    <i className="bi bi-search" style={{marginTop: 15, marginLeft: 5}}></i>
                                    <input style={{fontSize: 11}}
                                           onKeyUp={(event) => searchAirportTo(event.target.value)}
                                           className="w-[97%] outline-0 mt-1 mx-1 h-11"
                                           placeholder="Type for the airport name or airport code"/>
                                </div>
                                {airportListTo?.map((v_airportList1, index) => {
                                    const setVlaueTo = v_airportList1.cityName + "," + v_airportList1.code + "," + v_airportList1.name;
                                    return (
                                        <div
                                            onClick={() => setAirportTo(setVlaueTo)}
                                            className="border-b bg-white border-gray-300 p-2"
                                            key={index}>
                                            <div className="flex flex-row justify-between">
                                                <div style={{fontSize: 14}}>{v_airportList1.cityName}</div>
                                                <div style={{fontSize: 14}}>{v_airportList1.code}</div>
                                            </div>
                                            <div style={{fontSize: 11}}>
                                                {v_airportList1.name}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div> : <></>
                        }
                    </div>

                    <div className="md:flex md:flex-row md:gap-x-5">
                        <div>
                            <div>Journey Date</div>
                            <DatePicker placeholderText={'Journey Date'} selected={journeyDate}
                                        onChange={(date) => setJourneyDate(date)}
                                        className="md:w-16 lg:w-32 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2"/>
                        </div>

                        {checkWay == 1 ?
                            <></> :
                            <div>
                                <div className="pl-1">Return Date</div>
                                <input type="date" onChange={(e) => setReturnDate(e.target.value)}
                                       className="md:w-16 lg:w-32 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2"/>
                            </div>
                        }
                    </div>
                    <div>
                        <div>Traveler, Class</div>
                        <div onClick={() => setCheckTravelerClass(!checkTravelerClass)}
                             className="md:w-28 lg:w-52 w-72 cursor-pointer rounded-lg border-gray-300 border h-11 pl-2">
                            <div style={{fontSize: 14}}>{totalTravelers} Travelers</div>
                            <div style={{fontSize: 11}}>
                                {travelerClass === 'Y' ? "Economy" : ""}
                                {travelerClass === 'J' ? "Business" : ""}
                                {travelerClass === 'F' ? "First" : ""}
                            </div>
                        </div>
                        {checkTravelerClass ?
                            <div style={{fontSize: 14}}
                                 className="absolute h-auto border border-gray-300 cursor-pointer bg-white rounded-lg md:w-28 lg:w-52 w-72">
                                <div
                                    className="flex flex-row p-1.5 justify-between mx-[4%] border-b border-gray-300">
                                    <div>
                                        <div>Adults</div>
                                        <div>12 y+</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div onClick={() => decrementAdults()}
                                             className="bg-gray-300 shadow-lg my-auto h-5 px-1 rounded-full">
                                            <i className="bi bi-dash"></i>
                                        </div>
                                        <div
                                            className="h-5 px-1 my-auto rounded-full">
                                            {adults}
                                        </div>
                                        <div onClick={() => incrementAdults()}
                                             className="bg-gray-300 shadow-lg h-5 px-1 my-auto rounded-full">
                                            <i className="bi bi-plus"></i>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="flex flex-row p-1.5 justify-between mx-[4%] border-b border-gray-300">
                                    <div>
                                        <div>Children</div>
                                        <div>2-11 years</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div onClick={() => decrementChildren()}
                                             className="bg-gray-300 shadow-lg my-auto h-5 px-1 rounded-full">
                                            <i className="bi bi-dash"></i>
                                        </div>
                                        <div
                                            className="h-5 px-1 my-auto rounded-full">
                                            {children}
                                        </div>
                                        <div onClick={() => incrementChildren()}
                                             className="bg-gray-300 shadow-lg h-5 px-1 my-auto rounded-full">
                                            <i className="bi bi-plus"></i>
                                        </div>
                                    </div>
                                </div>

                                {children > 0 ?
                                    <>
                                        <div className="mx-[4%]">Select age for</div>
                                        <div className="flex flex-row mt-2 mx-[4%] space-x-1">
                                            <div>
                                                <div style={{fontSize: 12}}>Child 1</div>
                                                <select onChange={(event) => childAgeCalculate(event, "one")}
                                                        className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                    <option></option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                </select>
                                            </div>
                                            {children > 1 ?
                                                <div>
                                                    <div style={{fontSize: 12}}>Child 2</div>
                                                    <select onChange={(event) => childAgeCalculate(event, "two")}
                                                            className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                        <option></option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                    </select>
                                                </div> : ""
                                            }
                                            {children > 2 ?
                                                <div>
                                                    <div style={{fontSize: 12}}>Child 3</div>
                                                    <select
                                                        onChange={(event) => childAgeCalculate(event, "three")}
                                                        className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                        <option></option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                    </select>
                                                </div> : ""
                                            }
                                            {children > 3 ?
                                                <div>
                                                    <div style={{fontSize: 12}}>Child 4</div>
                                                    <select onChange={(event) => childAgeCalculate(event, "four")}
                                                            className="w-11 h-10 border border-gray-200 outline-0 rounded-md">
                                                        <option></option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                    </select>
                                                </div> : ""
                                            }
                                        </div>
                                    </> : ""
                                }

                                <div
                                    className="flex flex-row border-b border-gray-300 p-1.5 justify-between mx-[4%]">
                                    <div>
                                        <div>Infant</div>
                                        <div>Below 2 years</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div onClick={() => decrementInfant()}
                                             className="bg-gray-300 shadow-lg my-auto h-5 px-1 rounded-full">
                                            <i className="bi bi-dash"></i>
                                        </div>
                                        <div
                                            className="h-5 px-1 my-auto rounded-full">
                                            {infant}
                                        </div>
                                        <div onClick={() => incrementInfant()}
                                             className="bg-gray-300 shadow-lg h-5 px-1 my-auto rounded-full">
                                            <i className="bi bi-plus"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <div className="mx-[4%] border-b border-gray-300 mt-1">Class:</div>
                                    <div className="flex flex-row gap-x-2 mx-[4%] mt-2"
                                         style={{fontSize: 12}}>
                                        <div>
                                            {travelerClass === 'Y' ?
                                                <input defaultChecked onClick={() => setTravelerClass("Y")}
                                                       className="form-check-input"
                                                       type="radio"
                                                       name="flexRadioClass"/> :
                                                <input onClick={() => setTravelerClass("Y")}
                                                       className="form-check-input"
                                                       type="radio"
                                                       name="flexRadioClass"/>
                                            }
                                            Economy
                                        </div>
                                        <div>
                                            {travelerClass === 'J' ?
                                                <input defaultChecked onClick={() => setTravelerClass("J")}
                                                       className="form-check-input" type="radio"
                                                       name="flexRadioClass"/> :
                                                <input onClick={() => setTravelerClass("J")}
                                                       className="form-check-input" type="radio"
                                                       name="flexRadioClass"/>
                                            }
                                            Business
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-x-3 mx-[4%]"
                                         style={{fontSize: 12}}>
                                        <div className="mt-1">
                                            {travelerClass === 'F' ?
                                                <input defaultChecked onClick={() => setTravelerClass("F")}
                                                       className="form-check-input" type="radio"
                                                       name="flexRadioClass"/> :
                                                <input onClick={() => setTravelerClass("F")}
                                                       className="form-check-input" type="radio"
                                                       name="flexRadioClass"/>
                                            }
                                            First
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-[4%]" style={{fontSize: 12}}>{childMsg}</div>
                                <div onClick={() => checkChildAge()}
                                     className="ml-auto w-16 py-2 mb-2 mr-2 text-center rounded-lg bg-yellow-500">Done
                                </div>
                            </div> : <></>
                        }
                    </div>
                </div>
            }
            <div
                className="bg-red-500 h-12 mx-auto w-36 rounded-full md:mt-14 mt-5 text-center pt-1 text-2xl">
                <Link to={`/tricket-booking/${JSON.stringify(searchValue)}/flightSearch`}
                      className="no-underline text-white">Search</Link>
            </div>
            <div className="h-3"></div>
        </div>
    );
}

export default Flight;