import {
    EditOutlined,
    SearchOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
  } from "@ant-design/icons";
  import {
    Avatar,
    Button,
    Drawer,
    Empty,
    Form,
    Popconfirm,
    Select,
    Switch,
  } from "antd";
  import axios from "axios";
  import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
  import React from "react";
  import { responseNotification } from "../../utils/notify";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { getPage, getParamValue } from "../../utils";
  import { useLocation, useNavigate } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import _, { debounce } from "lodash";
  import { isAllowedService } from "../../utils/services";
  import AddBranch from "./AddBranch";
  
  import { AddBtn, Loader, Pagination } from "../common";
  
  const BranchList = (): ReactElement => {
    const navigate = useNavigate();
    const { type, token } = useSelector((state) => (state as any)?.authReducer);
    const fetchRef = useRef(0);
    const [form] = Form.useForm();
    const [key, setKey] = useState("");
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(10);
    const [showSearch, setShowSearch] = useState(true);
    const [visible, setVisible] = useState<any>(undefined);
    const [selectedBranch, setSelectedBranch] = useState(undefined);
    const [selectedBranchForEdit, setSelectedBranchForEdit] = useState(undefined);
    const [branchId, setBranchId] = useState<any>();
    const [countryCode, setCountryCode] = useState<any>();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isActive, setIsActive] = useState<any>(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const isDeleted = "false";
    const [branchOptions, setBranchOptions] = useState({
      loading: false,
      list: [],
    });
  
    const [branchData, setBranchData] = useState<any>({
      loading: false,
      data: null,
    });
  
    const getBranchs = useCallback(async () => {
      setBranchData({ loading: true, data: null });
  
      const encodedUri = `${process.env.REACT_APP_PAY_API}`;
      axios
        .get(
          `${encodedUri}/admin/branch?limit=${limit}` +
            (branchId ? `&key=${branchId}` : ``) +
            (page ? `&page=${page}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBranchData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setBranchData({ loading: false, data: [] });
          console.error("Barnch Branch: Error", err);
        });
    }, [branchId, limit, page]);
  
    useEffect(() => {
      getBranchs();
    }, [getBranchs]);
  
    const onClose = () => {
      getBranchs();
      setVisible(undefined);
      setSelectedBranch(undefined);
      setSelectedBranchForEdit(undefined);
    };
  
    const getBranchOptions = useCallback(async (key?: string) => {
      setBranchOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_PAY_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/branch?page=0&limit=20` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setBranchOptions({
        loading: false,
        list: res?.data?.branches?.map((branch: any) => ({
          label: branch.name,
          value: branch.name,
        })),
      });
    }, []);
  
    const onStatusChange = async (id: any, val: any) => {
      setConfirmLoading(true);
      if (id) {
        await fetch(`${process.env.REACT_APP_PAY_API}/admin/branch`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            branchList: [
              {
                id: id,
                isActive: val,
              },
            ],
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification("Status Changed Successfully", "success");
              getBranchs();
              setVisible(false);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
  
    const handleSearch = React.useMemo(() => {
      const loadOptions = (value: string, field: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
  
        if (fetchId !== fetchRef.current) {
          return;
        }
        if (value) {
          if (field === "branch") getBranchOptions(value);
        }
      };
  
      return debounce(loadOptions, 800);
    }, [getBranchOptions]);
  
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
  
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisible(undefined);
      setVisiblePopconfirm(undefined);
    };
  
    const reseAllFieldData = () => {
      setBranchId("");
      setKey("");
      setIsActive("");
      setCountryCode("");
      form.resetFields();
    };
    useEffect(() => {
      if (showSearch) {
        getBranchOptions("");
      }
    }, [showSearch]);
    return (
      <>
        <BreadCrumb
          title={`Branch List`}
          subTitle={`${
            branchData?.data?.totalElements || branchData?.data?.airports?.length || 0
          } Branch(s)`}
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,
  
            <AddBtn onClick={() => setVisible(true)} key={2} />,
          ]}
        />
  
        {showSearch && (
          <div className={styles?.searchBox}>
            <Form layout="vertical" form={form} className={styles.formInline}>
              <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                <Form.Item label="Branch Name" name="airport_search">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Filter by Branch Name"
                    optionFilterProp="children"
                    onChange={(val) => setBranchId(val)}
                    onSearch={(e) => handleSearch(e, "airport")}
                    filterOption={() => {
                      return true;
                    }}
                    options={branchOptions?.list}
                  ></Select>
                </Form.Item>
              </div>
            </Form>
  
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}
  
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {branchData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">SL</th>
                        <th scope="col">Branch Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">Status</th>
                        <th scope="col">Operation Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {branchData?.data?.branches?.length ? (
                        branchData?.data?.branches?.map((branche: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            // onClick={() =>
                            //   navigate(`/airports/${airport?.id}/details`)
                            // }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {branche?.name}
                              </span>
                            </td>
                            <td>{branche?.address}</td>
                            <td>
                              {branche?.isActive == true && (
                                <span className="border p-1 px-5 rounded-2xl bg-white">
                                  Active
                                </span>
                              )}
                              {branche?.isActive == false && (
                                <span className="border p-1 px-5 rounded-2xl bg-white text-red-600">
                                  Inactive
                                </span>
                              )}
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={branche?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(branche?.id, val);
                                }}
                                loading={
                                  confirmLoading && branche?.id === confirmLoading
                                }
                              />
                            </td>
  
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedBranchForEdit(branche);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...branchData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Branch"
              : selectedBranchForEdit
              ? "Edit Branch"
              : "Branch Details"
          }
          placement="right"
          onClose={onClose}
          visible={visible || selectedBranch || selectedBranchForEdit}
          width={450}
        >
          {visible || selectedBranchForEdit ? (
            <AddBranch onCloseMethod={onClose} visibleData={selectedBranchForEdit} />
          ) : undefined}
        </Drawer>
      </>
    );
  };
  
  export default BranchList;
  