import {
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Switch,
  Image,
  Tooltip,
} from "antd"; 
import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getQueryParams, priceCalculationType } from "../../utils";
import { responseNotification } from "../../utils/notify";
import { useNavigate, useParams } from "react-router";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useSelector } from "react-redux";
import {
  CloseOutlined,
  GiftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ImageUploader, SubmitResetBtn, TreeSelectTwo } from "../common";
import cn from "classnames";
// text editor
// import "jodit";
// import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";

const Option = Select;

const AddProduct = (props: any) => {
  // text editor end
  const editor = useRef(null);
  const { inShop, getShopId } = props;
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [priceCalcType, setPriceCalcType] = useState("SHOP_BASED_COMMISSION");
  const [commissionType, setCommissionType] = useState("PERCENTAGE");
  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForFAQ, setConvertedTextForFAQ] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [saveAndNew, setSaveAndNew] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [generatedThumbUrl, setGeneratedThumbUrl] = useState<string>();
  const [shopId, setShopId] = useState<string>(getShopId);
  const [selectedProduct, setSelectedProduct] = useState<any>(undefined);
  const [discountImageUrl, setDiscountImageUrl] = useState<string[]>([]);
  const [skuValue, setSkuValue] = useState<any>();
  const [categoryId, setCategoryId] = useState<any>();
  const [readOnly, setReadOnly] = useState(false);
  const [isPreOrder, setIsPreOrder] = useState<boolean>(false);


  //new text editor

  const [discountType, setDiscountType] = useState<{
    [key: string]: {
      type: "disFlat" | "disPercentage" | null;
      value: undefined | number;
    };
  }>({});

  const [qtyDiscountType, setQtyDiscountType] = useState<{
    [key: string]: {
      type: "freeProduct" | "qtyFlat" | "qtyPercentage" | null;
      value: undefined | number;
      minimumQuantity: number;
    };
  }>({});

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const [campaignOptions, setCampaignOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [brandsOptions, setBrandsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  function productSKU(length: any) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setSkuValue(result.toUpperCase());
    return result;
  }

  useEffect(() => {
    if (skuValue) {
      form.resetFields(["sku"]);
    }
  }, [skuValue]);

  const route = useParams();
  const multi = (getQueryParams() as any)?.for === "multiple-shop";

  const fetchProductDetails = useCallback((productSlug: any) => {
    if (productSlug) {
      try {
        setSingleProductInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/product/details?slug=${productSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleProductInfo({
                loading: false,
                data: data?.data?.product,
              });
              setConvertedText(data?.data?.product?.description || "");
              setConvertedTextForFAQ(data?.data?.product?.faq || "");
              setVariationImageUrl(
                data?.data?.product?.variations?.map(
                  (item: any) => item?.imageUrl
                )
              );

              setDiscountImageUrl(
                data?.data?.product?.variations?.map(
                  (item: any) =>
                    item?.quantitativeProductDiscount?.freeProduct?.productImage
                )
              );
              setIsActive(data?.data?.product?.isActive);
              setGeneratedThumbUrl(data?.data?.product?.productImage);
              setGeneratedImgUrl(data?.data?.product?.images);

              const diss = {} as any;
              data?.data?.product?.variations?.map((va: any, i: string) => {
                diss[i] = {
                  type: va?.productDiscount?.flat
                    ? "disFlat"
                    : va?.productDiscount?.percentage
                    ? "disPercentage"
                    : null,
                  value:
                    va?.productDiscount?.flat ||
                    va?.productDiscount?.percentage ||
                    0,
                };
              });
              const qtyDis = {} as any;
              data?.data?.product?.variations?.map((va: any, i: string) => {
                qtyDis[i] = {
                  type: va?.quantitativeProductDiscount?.freeProduct
                    ? "freeProduct"
                    : va?.quantitativeProductDiscount?.productDiscount?.flat
                    ? "qtyFlat"
                    : va?.quantitativeProductDiscount?.productDiscount
                        ?.percentage
                    ? "qtyPercentage"
                    : null,
                  value:
                    va?.quantitativeProductDiscount?.freeProduct ||
                    va?.quantitativeProductDiscount?.productDiscount?.flat ||
                    va?.quantitativeProductDiscount?.productDiscount
                      ?.percentage ||
                    0,
                  minimumQuantity:
                    va?.quantitativeProductDiscount?.minimumQuantity,
                };
              });

              setDiscountType(diss);
              setQtyDiscountType(qtyDis);
            } else {
              setSingleProductInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleProductInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleProductInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    if (saveAndNew) {
      setStartUpload("Uploading");
      setStartUpload2("Uploading");
    }
  }, [saveAndNew]);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl || generatedThumbUrl) {
      setLoading(true);
      let readyData = data && {
        barCode: data.barCode,
        sku: data.sku || skuValue,
        name: data.name,
        productOrigin: data.productOrigin,
        type: type,
        categoryId: categoryId,
        brandId: data.brandId,
        shopId: shopId || data.shopId,
        campaignId: data.campaignId,
        keyword: data.keyword,
        metaTitle: data.metaTitle,
        canonicalTag: data.canonicalTag,
        imageAltrText: data.imageAltrText,
        imageCaption: data.imageCaption,
        imageDescription: data.imageDescription,
        imageTitle: data.imageTitle,
        metaDescription: data.metaDescription,
        isAllowWarranty: data.isAllowWarranty,
        isReturnable: data.isReturnable,
        isPopular: data.isPopular,
        isPreOrder: isPreOrder,
        paymentTimeInSeconds: isPreOrder
          ? data.paymentTimeInSeconds * 60 * 60
          : 0,
        prePaidAmountInPercentage: isPreOrder
          ? data.prePaidAmountInPercentage
          : 0,
        isForCheckOut: data.isForCheckOut,
        excludeCOD: data.excludeCOD,
        excludeFromPromo: data.excludeFromPromo,
        displayOrder: parseInt(data.displayOrder),
        priceCalculationType: priceCalcType || data.priceCalculationType,
        commissionType: data.commissionType,
        commissionAmount: Math.round(data.commissionAmount),
        isActive: isActive,
        faq: convertedTextForFAQ || "<p></p>",
        description: convertedText || "<p></p>",
        productImage: generatedThumbUrl,
        images: generatedImgUrl,

        variations: data?.variations?.map((variant: any, i: string) => ({
          variationName: variant.variationName,
          variationId: variant.variationId,
          imageUrl: variant.imageUrl,
          barCode: variant.barCode,
          regularVariationId: data?.variations?.[i + 1]?.variationId,
          subsidyAmount: Math.round(variant?.subsidyAmount || 0),
          subsidyType: variant?.subsidyType,
          maximumOrderLimit: variant.maximumOrderLimit
            ? parseInt(variant.maximumOrderLimit)
            : undefined,
          price: {
            mrp: Math.ceil(variant.mrp) * 1,
            tp:
              priceCalcType == "TP_BASED_COMMISSION"
                ? Math.round(variant.tp) * 1
                : 0,
          },
          productDiscount: {
            flat: Math.round(variant.flat) || 0,
            percentage: Math.round(variant.percentage) || 0,
          },

          // quantitativeProductDiscount: {
          //   freeProductId: variant.quantitativeProductDiscount.freeProductId,
          //   minimumQuantity:
          //     variant.quantitativeProductDiscount.minimumQuantity || 0,
          //   productDiscount: {
          //     flat:
          //       parseFloat(
          //         variant.quantitativeProductDiscount.productDiscount.flat
          //       ) || 0,
          //     percentage:
          //       parseFloat(
          //         variant.quantitativeProductDiscount.productDiscount.percentage
          //       ) || 0,
          //   },
          // },
          stock: Math.round(variant.stock) || 0,
        })),
      };

      if (singleProductInfo?.data) {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/product${
            multi ? `/multiple-shop` : ``
          }`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              id: singleProductInfo?.data?.id,
              // isActive: isActive,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Product Updated Successfully", "success");
              form.resetFields();
              fetchProductDetails((route as any)?.slug);
              // if (history?.length === 1) {
              //   navigate(`/products`);
              // } else {
              //   navigate(-1);
              // }
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            deleted: false,
            isActive: isActive,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Product Create Successfully", "success");
              if (saveAndNew) {
                console.log(saveAndNew);
              } else {
                form.resetFields();
                navigate(-1);
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Product Thumbnail & Image required`, "error");
    }
  };

  // **********************************
  // Search Options
  // **********************************

  const getCampaignOptions = useCallback(async (val?: string) => {
    setCampaignOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => {
        return {
          value: campaign?.id,
          label: campaign?.name,
        };
      }),
    });
  }, []);

  const getBrandsOptions = useCallback(async (val?: string) => {
    setBrandsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandsOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => {
        return {
          value: brand?.id,
          label: brand?.name,
        };
      }),
    });
  }, []);

  const getShopsOptions = useCallback(async (val?: string) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=${type}&page=0&limit=20` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "brand") getBrandsOptions(value);
          else if (field === "shop") getShopsOptions(value);
          else if (field === "campaing") getCampaignOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getBrandsOptions, getShopsOptions, getCampaignOptions, type]);

  const prePaidAmountPreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const paymentTimePreventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  // **********************************
  // Search Options

  // **********************************

  useEffect(() => {
    getShopsOptions();
    getCampaignOptions();
    getBrandsOptions();
  }, [getShopsOptions, getCampaignOptions, getBrandsOptions]);

  useEffect(() => {
    fetchProductDetails((route as any)?.slug);
  }, [fetchProductDetails, route]);

  useEffect(() => {
    if (singleProductInfo?.data) {
      setShopsOptions({
        loading: false,
        list: [
          {
            value: singleProductInfo?.data?.shop?.id,
            label: singleProductInfo?.data?.shop?.name,
          },
          ...(shopsOptions.list?.filter(
            (item) => item.value !== singleProductInfo?.data?.shop?.id
          ) || []),
        ],
      });

      setBrandsOptions({
        loading: false,
        list: [
          {
            value: singleProductInfo?.data?.brand?.id,
            label: singleProductInfo?.data?.brand?.name,
          },
          ...(brandsOptions.list?.filter(
            (item) => item.value !== singleProductInfo?.data?.brand?.id
          ) || []),
        ],
      });

      setCampaignOptions({
        loading: false,
        list: [
          {
            value: singleProductInfo?.data?.campaign?.id,
            label: singleProductInfo?.data?.campaign?.name,
          },
          ...(campaignOptions.list?.filter(
            (item) => item.value !== singleProductInfo?.data?.campaign?.id
          ) || []),
        ],
      });

      form.resetFields(Object.keys(singleProductInfo?.data));
      form.resetFields();

      setPriceCalcType(singleProductInfo?.data?.priceCalculationType);
      setCommissionType(singleProductInfo?.data?.commissionType);
      setShopId(singleProductInfo?.data?.shopId);
      setIsPreOrder(singleProductInfo?.data?.isPreOrder);

      setGeneratedThumbUrl(singleProductInfo?.data?.productImage);
      setGeneratedImgUrl(singleProductInfo?.data?.images || []);
      setVariationImageUrl(
        singleProductInfo?.data?.variations?.map((item: any) => item?.imageUrl)
      );
    }
  }, [form, singleProductInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    setVariationImageUrl([]);
    setGeneratedImgUrl(singleProductInfo?.data?.images || []);
    setGeneratedThumbUrl(singleProductInfo?.data?.productImage);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={singleProductInfo?.data ? "Edit Product" : "Add Product"}
        extra={[
          <Button
            size="large"
            className="bg-green-600 hover:bg-green-500 hover:text-white border-none text-white font-semibold rounded-lg mr-1"
            onClick={(e) => {
              e.preventDefault();
              setSaveAndNew(true);
            }}
            disabled={loading}
            loading={loading}
          >
            Save & New
          </Button>,
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading}
            loading={loading}
          />,
        ]}
      />

      <div className="content-body rounded-2xl">
        <div className="">
          <Form
            name="control-hooks"
            form={form} // like ref
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...singleProductInfo?.data,
              type: type || singleProductInfo?.data?.shop?.type,
              sku: skuValue || singleProductInfo?.data?.sku,
              paymentTimeInSeconds:
                singleProductInfo?.data?.paymentTimeInSeconds / 60 / 60,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <div
              className={
                inShop
                  ? "grid grid-cols-2 xl:grid-cols-1 xl:gap-6"
                  : "grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6"
              }
            >
              <div
                className={cn(
                  `grid grid-cols-2 md:grid-cols-2 xl:grid-cols-1 col-span-3 md:col-span-3 lg:col-span-1 gap-6 grid-flow-row auto-rows-max mb-6`,
                  { ["grid grid-cols-2 xl:grid-cols-2 xl:gap-6"]: inShop }
                )}
              >
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 pb-2">
                  <Divider orientation="left">
                    Thumbnail
                    <Tooltip
                      placement="bottom"
                      title={
                        "Thumbnail Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    // crop={1}
                    imgUrl={generatedThumbUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => {
                      setGeneratedThumbUrl(url);
                    }}
                  />
                  <Divider orientation="left">
                    Image Gallery
                    <Tooltip
                      placement="bottom"
                      title={
                        "Product Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    multiple
                    imgUrl={generatedImgUrl || []}
                    startUpload={startUpload2}
                    setStartUpload={(val: any) => setStartUpload2(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 pb-2">
                  <Divider orientation="left">Extra Options</Divider>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Allow Warranty?"
                        name="isAllowWarranty"
                      >
                        <Switch
                          checkedChildren={`Allow`}
                          unCheckedChildren={`Not Allow`}
                          defaultChecked={
                            (singleProductInfo as any)?.data?.isAllowWarranty
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Is Returnable?"
                        name="isReturnable"
                      >
                        <Switch
                          checkedChildren={`Yes`}
                          unCheckedChildren={`No`}
                          defaultChecked={
                            (singleProductInfo as any)?.data?.isReturnable
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Exclude From COD?"
                        name="excludeCOD"
                      >
                        <Switch
                          checkedChildren={`Exclude`}
                          unCheckedChildren={`Include`}
                          defaultChecked={
                            (singleProductInfo as any)?.data?.excludeCOD
                          }
                        />
                      </Form.Item>
                    </div>

                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Exclude From Promo?"
                        name="excludeFromPromo"
                      >
                        <Switch
                          checkedChildren={`Exclude`}
                          unCheckedChildren={`Include`}
                          defaultChecked={
                            (singleProductInfo as any)?.data?.excludeFromPromo
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Popular Product?"
                        name="isPopular"
                        className="mr-5"
                      >
                        <Switch
                          checkedChildren={`Yes`}
                          unCheckedChildren={`Not Sure`}
                          defaultChecked={
                            (singleProductInfo as any)?.data?.isPopular
                          }
                        />
                      </Form.Item>
                    </div>

                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Is For Check Out?"
                        name="isForCheckOut"
                        className="mr-5"
                      >
                        <Switch
                          checkedChildren={`true`}
                          unCheckedChildren={`false`}
                          defaultChecked={
                            (singleProductInfo as any)?.data?.isForCheckOut
                          }
                        />
                      </Form.Item>
                    </div>

                    <div className="">
                      <Form.Item
                        hasFeedback
                        label="Is Pre Order?"
                        name="isPreOrder"
                        className="mr-5"
                      >
                        <Switch
                          checkedChildren={`true`}
                          unCheckedChildren={`false`}
                          defaultChecked={
                            (singleProductInfo as any)?.data?.isPreOrder
                          }

                          onChange={() => setIsPreOrder(!isPreOrder)}

                        />
                      </Form.Item>
                    </div>
                    {isPreOrder && (
                      <>
                        <div className="isPreOrder">
                          <div className="">
                            <Form.Item
                              hasFeedback
                              label="Pre Paid Amount Percentage"
                              name="prePaidAmountInPercentage"
                            >
                              <Input
                                id="prePaidAmountInPercentage"
                                type="number"
                                min={0}
                                placeholder="Enter pre paid amount percentage"
                                onKeyPress={prePaidAmountPreventMinus}
                              />
                            </Form.Item>
                          </div>
                          <div className="">
                            <Form.Item
                              hasFeedback
                              label="Payment Time hours"
                              name="paymentTimeInSeconds"
                            >
                              <Input
                                id="paymentTimeInSeconds"
                                type="number"
                                min={0}
                                placeholder="Enter Payment Time Hours"
                                onKeyPress={paymentTimePreventMinus}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={
                  inShop ? "col-span-full" : `grid-cols-3 lg:col-span-2`
                }
              >
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">
                    General Info
                  </h3>

                  <div>
                    <Form.Item
                      hasFeedback
                      label="Product Barcode"
                      name="barCode"
                    >
                      <Input
                        id="barCode"
                        type="text"
                        placeholder="Scan Barcode"
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Product Name"
                      rules={[
                        {
                          required: true,
                          message: "Product Name is Required!",
                        },
                      ]}
                      name="name"
                    >
                      <Input
                        id="name"
                        type="text"
                        placeholder="Enter Product Name"
                        onBlur={() => productSKU(8)}
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Product Origin"
                      name="productOrigin"
                    >
                      <Input
                        id="productOrigin"
                        type="text"
                        placeholder="Enter Product Origin"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="SKU"
                      name="sku"
                      rules={[
                        {
                          required: type !== "JC_FOOD",
                          message: "Product SKU is Required!",
                        },
                      ]}
                    >
                      <Input id="sku" type="text" placeholder="Enter SKU" />
                    </Form.Item>
                  </div>
                  <div className="col-span-2 mb-8">
                    <Form.Item hasFeedback label="Product Slug" name="slug">
                      <Input id="slug" type="text" placeholder="Enter Slug" />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Display Order"
                      rules={[
                        {
                          required: false,
                          message: "displayOrder is Required!",
                        },
                      ]}
                      name="displayOrder"
                    >
                      <Input
                        id="displayOrder"
                        type="text"
                        placeholder="Enter Display Order"
                      />
                    </Form.Item>
                  </div>

                  {/* {singleProductInfo?.data && ( */}
                  <div>
                    <Form.Item
                      label="Price Calculation Type"
                      name="priceCalculationType"
                      rules={[
                        {
                          required: true,
                          message: "Price Calculation Type is Required!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Price Calculation Type"
                        onChange={(val) => setPriceCalcType(val)}
                      >
                        {Object.values(priceCalculationType)?.map((type, i) => (
                          <Option value={type} key={i}>
                            {type?.split("_").join(" ")}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  {/* )} */}

                  {priceCalcType === "TP_BASED_COMMISSION" && (
                    <>
                      <div>
                        <Form.Item
                          hasFeedback
                          label="Commission Type"
                          rules={[
                            {
                              required: false,
                              message: "Commission Type is Required!",
                            },
                          ]}
                          name="commissionType"
                          initialValue={commissionType}
                        >
                          <Select
                            options={[
                              { value: null, label: "NONE" },
                              { value: "PERCENTAGE", label: "PERCENTAGE" },
                              { value: "FLAT", label: "FLAT" },
                            ]}
                            placeholder="Enter Commission Type"
                            onChange={(val) => setCommissionType(val)}
                          />
                        </Form.Item>
                      </div>
                      {commissionType && (
                        <div>
                          <Form.Item
                            hasFeedback
                            label="Commission Amount"
                            rules={[
                              {
                                required: false,
                                message: "Commission Amount is Required!",
                              },
                            ]}
                            name="commissionAmount"
                          >
                            <Input
                              disabled={!discountType}
                              id="commissionAmount"
                              type="number"
                              min={0}
                              placeholder="Enter Commission Amount"
                            />
                          </Form.Item>
                        </div>
                      )}
                    </>
                  )}
                  {!inShop && !singleProductInfo?.data && (
                    <div className="">
                      <Form.Item
                        hasFeedback
                        label={`Shop - (${singleProductInfo?.data?.shop?.name})`}
                        rules={[
                          {
                            required: true,
                            message: "Shop is required!",
                          },
                        ]}
                        name="shopId"
                      >
                        <Select
                          showSearch
                          placeholder="Select Shop"
                          optionFilterProp="children"
                          onSearch={(val) => {
                            handleSearch(val, "shop");
                          }}
                          onChange={(val) => setShopId(val)}
                          filterOption={() => {
                            return true;
                          }}
                          options={shopsOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>
                  )}

                  <div>
                    <Form.Item
                      hasFeedback
                      label={`Category -(${singleProductInfo?.data?.category?.title})`}
                      name="categoryId"
                      rules={[
                        {
                          required: !categoryId,
                          message: "Category is required!",
                        },
                      ]}
                      initialValue={categoryId}
                    >
                      <TreeSelectTwo
                        setCategoryId={setCategoryId}
                        value={categoryId}
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label={`Brand (${singleProductInfo?.data?.brand?.name})`}
                      name="brandId"
                    >
                      <Select
                        showSearch
                        placeholder="Select Brand"
                        optionFilterProp="children"
                        onSearch={(val) => {
                          handleSearch(val, "brand");
                        }}
                        filterOption={() => {
                          return true;
                        }}
                        options={brandsOptions?.list}
                      ></Select>
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item hasFeedback label="Campaign" name="campaignId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select Campaign"
                        optionFilterProp="children"
                        // onChange={(val) => setCampaignId(val)}
                        onSearch={(e) => {
                          console.log(e);
                        }}
                        filterOption={() => {
                          return true;
                        }}
                        options={campaignOptions?.list}
                      ></Select>
                    </Form.Item>
                  </div>

                  <div className="col-span-2 mb-8">
                    <h4 className="font-semibold mb-1">Product Details</h4>
                    <JoditEditor
                      ref={editor}
                      value={convertedText}
                      config={jodiConfig as any}
                      // tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {}}
                    />
                  </div>
                  <div className="col-span-2 mb-4">
                    <h4 className="font-semibold mb-1">FAQ</h4>
                    <JoditEditor
                      ref={editor}
                      value={convertedTextForFAQ}
                      config={jodiConfig as any}
                      onBlur={(newContent) =>
                        setConvertedTextForFAQ(newContent)
                      }
                      onChange={(newContent) => {}}
                    />
                  </div>
                </div>
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                  <h3 className="mb-4 text-xl font-bold">Variations</h3>

                  <Form.List
                    name="variations"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(
                              new Error("At least 1 variation required!")
                            );
                          }
                        },
                      },
                    ]}
                    initialValue={[{}]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }: any) => (
                            <Card className="mb-2" size="small" key={key}>
                              <Space wrap align="center">
                                {/* {variationImageUrl && (
                                    <div>
                                      <Avatar
                                        src={variationImageUrl[name]}
                                        shape="square"
                                        size="large"
                                      />
                                    </div>
                                  )} */}
                                <Form.Item
                                  {...restField}
                                  name={[name, "imageUrl"]}
                                  fieldKey={[fieldKey, "imageUrl"]}
                                  hasFeedback
                                  label="Image"
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.imageUrl
                                  }
                                >
                                  <Input
                                    placeholder="Enter Image"
                                    onChange={async (e) => {
                                      setVariationImageUrl((prev) => {
                                        const newArr = Array.from(prev);
                                        newArr[name] = e.target.value;
                                        return newArr;
                                      });
                                    }}
                                    size="small"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "barCode"]}
                                  fieldKey={[fieldKey, "barCode"]}
                                  hasFeedback
                                  label="Barcode"
                                  style={{ width: 100 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.barCode
                                  }
                                >
                                  <Input placeholder="Enter Barcode" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "variationId"]}
                                  fieldKey={[fieldKey, "variationId"]}
                                  hasFeedback
                                  label="ID"
                                  style={{ width: 80 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.variationId
                                  }
                                >
                                  <Input
                                    id="variationId"
                                    placeholder="Enter ID"
                                    disabled={singleProductInfo?.data}
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "variationName"]}
                                  fieldKey={[fieldKey, "variationName"]}
                                  hasFeedback
                                  label="Variation"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.variationName
                                  }
                                >
                                  <Input placeholder="1pc/5kg/1pkt" />
                                </Form.Item>

                                

                                {
                                  priceCalcType == "TP_BASED_COMMISSION" && (
                                    <>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "givetp"]}
                                      fieldKey={[fieldKey, "givetp"]}
                                      hasFeedback
                                      label="Give TP"
                                      style={{ width: 90 }}
                                      initialValue={
                                        singleProductInfo?.data?.variations?.[
                                          name
                                        ]?.price?.givetp
                                      }
                                    >
                                      <Input placeholder="Enter Give TP" />
                                    </Form.Item>
                                  <Form.Item
                                  {...restField}
                                  name={[name, "paybletp"]}
                                  fieldKey={[fieldKey, "paybletp"]}
                                  hasFeedback
                                  label="Payble TP"
                                  style={{ width: 90 }}
                                  
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[
                                      name
                                    ]?.price?.paybletp
                                  }
                                >
                                  <Input placeholder="Enter Payble TP" disabled={true} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "mrp"]}
                                  fieldKey={[fieldKey, "mrp"]}
                                  hasFeedback
                                  label="MRP"
                                  style={{ width: 90 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.price?.mrp
                                  }
                                >
                                  <Input placeholder="Enter MRP" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "discount"]}
                                  fieldKey={[fieldKey, "discount"]}
                                  hasFeedback
                                  label="Discount"
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[
                                      name
                                    ]?.price?.discount
                                  }
                                >
                                  <Input placeholder="Enter Discount" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "discountType"]}
                                  fieldKey={[fieldKey, "discountType"]}
                                  hasFeedback
                                  label="Discount Type"
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.discountType
                                  }
                                  // style={{ width: 120 }}
                                >
                                  <Select
                                    options={[
                                      { value: null, label: "NONE" },
                                      {
                                        value: "PERCENTAGE",
                                        label: "PERCENTAGE",
                                      },
                                      { value: "FLAT", label: "FLAT" },
                                    ]}
                                    placeholder="Enter Discount Type"
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "saleprice"]}
                                  fieldKey={[fieldKey, "saleprice"]}
                                  hasFeedback
                                  label="Sale Price"
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[
                                      name
                                    ]?.price?.saleprice
                                  }
                                >
                                  <Input placeholder="Enter sale price" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "revenue"]}
                                  fieldKey={[fieldKey, "revenue"]}
                                  hasFeedback
                                  label="Revenue"
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[
                                      name
                                    ]?.price?.revenue
                                  }
                                >
                                  <Input placeholder="Enter Revenue" disabled />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "contract"]}
                                  fieldKey={[fieldKey, "contract"]}
                                  hasFeedback
                                  label="Contract"
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[
                                      name
                                    ]?.price?.contract
                                  }
                                >
                                  <Input placeholder="Enter Contract" disabled />
                                </Form.Item>
                                </>
                                  )}
                                {singleProductInfo?.data && (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "discountedPrice"]}
                                    fieldKey={[fieldKey, "discountedPrice"]}
                                    hasFeedback
                                    label="Revenue"
                                    style={{ width: 90 }}
                                    rules={[
                                      {
                                        required: false,
                                        message: "Required!",
                                      },
                                    ]}
                                    initialValue={Math.ceil(
                                      singleProductInfo?.data?.variations?.[
                                        name
                                      ]?.price?.discountedPrice -
                                        singleProductInfo?.data?.variations?.[
                                          name
                                        ]?.price?.tp
                                    )}
                                  >
                                    <Input
                                      placeholder="Revenue"
                                      disabled={singleProductInfo?.data}
                                    />
                                  </Form.Item>
                                )}

                                <Form.Item
                                  {...restField}
                                  name={[name, "maximumOrderLimit"]}
                                  fieldKey={[fieldKey, "maximumOrderLimit"]}
                                  hasFeedback
                                  label="Max Limit"
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.maximumOrderLimit
                                  }
                                  style={{ width: 80 }}
                                >
                                  <Input placeholder="Maximum Order Limit" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "stock"]}
                                  fieldKey={[fieldKey, "stock"]}
                                  hasFeedback
                                  label="Stock"
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.stock
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                  style={{ width: 70 }}
                                >
                                  <Input placeholder="Stock" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "subsidyType"]}
                                  fieldKey={[fieldKey, "subsidyType"]}
                                  hasFeedback
                                  label="Subsidy Type"
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.subsidyType
                                  }
                                  // style={{ width: 120 }}
                                >
                                  <Select
                                    options={[
                                      { value: null, label: "NONE" },
                                      {
                                        value: "PERCENTAGE",
                                        label: "PERCENTAGE",
                                      },
                                      { value: "FLAT", label: "FLAT" },
                                    ]}
                                    placeholder="Enter Subsidy Type"
                                  />
                                </Form.Item>

                                <Form.Item
                                  hasFeedback
                                  {...restField}
                                  name={[name, "subsidyAmount"]}
                                  fieldKey={[fieldKey, "subsidyAmount"]}
                                  label="Subsidy"
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.subsidyAmount
                                  }
                                >
                                  <Input
                                    id="subsidyAmount"
                                    type="number"
                                    min={0}
                                    placeholder="Enter Subsidy"
                                  />
                                </Form.Item>
                              </Space>

                              <Space wrap align="center" className="mb-3">
                                <h6 className="bg-green-500 rounded text-white px-2 font-bold">
                                  General Discount
                                </h6>
                                <Radio.Group
                                  value={discountType[name]?.type}
                                  onChange={(e: any) => {
                                    setDiscountType(() => {
                                      const temp = {} as any;
                                      temp[name] = {
                                        type: e.target.value,
                                        value: undefined,
                                      };
                                      return temp;
                                    });
                                  }}
                                  name="productDiscount"
                                >
                                  <Radio value={undefined}>None</Radio>
                                  <Radio value={`disFlat`}>Flat</Radio>

                                  <Radio value={`disPercentage`}>
                                    Percentage
                                  </Radio>
                                </Radio.Group>
                                <div className="">
                                  {discountType[name]?.type === "disFlat" && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "flat"]}
                                      fieldKey={[fieldKey, "flat"]}
                                      initialValue={discountType?.[name]?.value}
                                      hasFeedback
                                      style={{
                                        width: 100,
                                        marginBottom: 0,
                                      }}
                                    >
                                      <Input
                                        placeholder="Flat (Tk.)"
                                        size="small"
                                      />
                                    </Form.Item>
                                  )}

                                  {discountType?.[name]?.type ===
                                    "disPercentage" && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "percentage"]}
                                      fieldKey={[fieldKey, "percentage"]}
                                      hasFeedback
                                      initialValue={discountType?.[name]?.value}
                                      style={{
                                        width: 100,
                                        marginBottom: 0,
                                      }}
                                    >
                                      <Input
                                        placeholder="Percent (%)"
                                        size="small"
                                      />
                                    </Form.Item>
                                  )}
                                </div>
                              </Space>

                              <Space wrap key={key} align="center">
                                <h6 className="bg-green-500 rounded text-white px-2 font-bold">
                                  Quantitive Discount
                                </h6>

                                <Radio.Group
                                  value={qtyDiscountType?.[name]?.type}
                                  onChange={(val) => {
                                    setQtyDiscountType(() => {
                                      const temp = {} as any;
                                      temp[name] = {
                                        type: val.target.value,
                                        value: undefined,
                                        minimumQuantity: undefined,
                                      };
                                      return temp;
                                    });
                                  }}
                                  name="quantitiveProductDiscount"
                                >
                                  <Radio value={undefined}>None</Radio>
                                  <Radio value={`qtyFlat`}>Flat</Radio>
                                  <Radio value={`qtyPercentage`}>
                                    Percentage
                                  </Radio>
                                  <Radio value="freeProduct">
                                    Free Product
                                  </Radio>
                                </Radio.Group>

                                <Space wrap key={key} align="center">
                                  {qtyDiscountType?.[name]?.type !== null && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "minimumQuantity"]}
                                      fieldKey={[fieldKey, "minimumQuantity"]}
                                      hasFeedback
                                      initialValue={
                                        qtyDiscountType?.[name]?.minimumQuantity
                                      }
                                      style={{
                                        width: 100,
                                        marginBottom: 0,
                                      }}
                                    >
                                      <Input
                                        placeholder="Min Quantity"
                                        size="small"
                                      />
                                    </Form.Item>
                                  )}

                                  {qtyDiscountType?.[name]?.type ===
                                    "freeProduct" &&
                                    (qtyDiscountType?.[name]?.value ||
                                    selectedProduct?.productImage ? (
                                      <div className="d-flex align-items-start">
                                        {discountImageUrl && (
                                          <Avatar
                                            src={
                                              <Image
                                                src={
                                                  selectedProduct?.productImage ||
                                                  discountImageUrl[name]
                                                }
                                              />
                                            }
                                            shape="square"
                                            size="large"
                                            className="border"
                                          />
                                        )}
                                        <Button
                                          danger
                                          type="text"
                                          onClick={() =>
                                            setSelectedProduct(undefined)
                                          }
                                          size="large"
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      </div>
                                    ) : (
                                      <Button
                                        type="dashed"
                                        onClick={() => setVisible(true)}
                                        size="large"
                                      >
                                        <GiftOutlined /> Free Product
                                      </Button>
                                    ))}

                                  {qtyDiscountType?.[name]?.type ===
                                    "qtyFlat" && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qtyFlat"]}
                                      fieldKey={[fieldKey, "flat"]}
                                      hasFeedback
                                      initialValue={
                                        qtyDiscountType?.[name]?.value
                                      }
                                      style={{
                                        width: 100,
                                        marginBottom: 0,
                                      }}
                                    >
                                      <Input
                                        placeholder="Flat (Tk.)"
                                        size="small"
                                      />
                                    </Form.Item>
                                  )}

                                  {qtyDiscountType?.[name]?.type ===
                                    "qtyPercentage" && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qtyPercentage"]}
                                      fieldKey={[fieldKey, "percentage"]}
                                      hasFeedback
                                      // label="Percentage Discount"
                                      initialValue={
                                        qtyDiscountType?.[name]?.value
                                      }
                                      style={{
                                        width: 100,
                                        marginBottom: 0,
                                      }}
                                    >
                                      <Input
                                        placeholder="Percent (%)"
                                        size="small"
                                      />
                                    </Form.Item>
                                  )}
                                </Space>
                              </Space>

                              <Button
                                type="primary"
                                danger
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  bottom: 0,
                                }}
                                onClick={() => {
                                  remove(name);
                                  setVariationImageUrl((prev) => {
                                    const newArr = prev.filter(
                                      (_, i) => i !== name
                                    );
                                    return newArr;
                                  });
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            </Card>
                          )
                        )}

                        <Form.Item
                          style={{
                            width: "100%",
                            flex: "1 1 100%",
                            marginBottom: 5,
                          }}
                        >
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              setVariationImageUrl((prev) => {
                                const newArr = Array.from(prev);
                                newArr.push("");
                                return newArr;
                              });
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Variation
                          </Button>
                        </Form.Item>

                        <Form.ErrorList errors={errors} />
                      </>
                    )}
                  </Form.List>
                </div>{" "}
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6  grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">SEO</h3>

                  <div className="col-span-2">
                    <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                      <Input
                        id="metaTitle"
                        type="text"
                        placeholder="Enter Meta Title"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item hasFeedback label="Keywords" name="keyword">
                      <Input.TextArea
                        id="keyword"
                        placeholder="Enter Product Keywords"
                        rows={3}
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Meta Description"
                      name="metaDescription"
                    >
                      <Input.TextArea
                        id="metaDescription"
                        placeholder="Enter Meta Description"
                        rows={3}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Canonical Tag"
                      name="canonicalTag"
                    >
                      <Input
                        id="canonicalTag"
                        type="text"
                        placeholder="Enter Canonical Tag"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Title"
                      name="imageTitle"
                    >
                      <Input
                        id="imageTitle"
                        type="text"
                        placeholder="Enter Image Title"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Altr Text"
                      name="imageAltrText"
                    >
                      <Input
                        id="imageAltrText"
                        type="text"
                        placeholder="Enter Image Altr Text"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Caption"
                      name="imageCaption"
                    >
                      <Input
                        id="imageCaption"
                        type="text"
                        placeholder="Enter Image Caption"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-2">
                    <Form.Item
                      hasFeedback
                      label="Image Description"
                      name="imageDescription"
                    >
                      <Input.TextArea
                        id="imageDescription"
                        placeholder="Enter Image Description"
                        rows={2}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
