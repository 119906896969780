import { Form, Input, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RegistrationInfo = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);

  const [merchantTypeList, setMerchantType] = useState<any>({
    loading: false,
    data: null,
  });

  const getMerchantTypeOptions = useCallback(async () => {
    setMerchantType({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    return axios
      .get(
        `${encodedUri}/merchant/type?page=${0}&limit=${16}` +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMerchantType({
          loading: false,
          list: res.data?.merchantTypes?.map((type: { name: any }) => ({
            label: type.name,
            value: type.name,
          })),
        });
      })
      .catch((err) => {
        setMerchantType({ loading: false, list: [] });
        console.error("MTypes: Error", err);
      });
  }, [type]);

  useEffect(() => {
    getMerchantTypeOptions();
  }, [getMerchantTypeOptions]);
  // **************************************

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-x-6">
        <div>
          <Form.Item
            hasFeedback
            label="Mobile Number (Login)"
            rules={[
              {
                required: true,
                message: "Mobile Number is required!",
              },
              {
                validator: async (_, names) => {
                  if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                    return Promise.reject(
                      new Error("Enter valid mobile number")
                    );
                  }
                },
              },
            ]}
            name="mobileNumber"
            initialValue={"01"}
          >
            <Input
              id="mobileNumber"
              type="contact"
              addonBefore={"+88"}
              placeholder="Enter Mobile Number"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item hasFeedback label="Merchant Type" name="merchantType">
            <Select
              id="merchantType"
              allowClear
              placeholder="Select Merchant Type"
              optionFilterProp="children"
              filterOption={() => {
                return true;
              }}
              options={merchantTypeList?.list}
            ></Select>
          </Form.Item>
        </div>

        <div className="mb-4">
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password maxLength={11} placeholder="Enter Password" />
          </Form.Item>
        </div>

        <div className="mb-4">
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password maxLength={11} placeholder="Re-Type Password" />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Service Type"
            name="serviceType"
            initialValue={type}
            hidden
          >
            <Input
              id="serviceType"
              type="hidden"
              placeholder="Enter Service Type"
              value={type}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default RegistrationInfo;
