import d3 from "d3";

export const getValue = (key, val) => {
  if (key.split(":")?.length === 1) {
    return {
      [key]: getData(val),
    };
  } else {
    return {
      [key?.split(":")[0]]: {
        ...getValue(key?.split(":")?.slice(1)?.join(":"), val),
      },
    };
  }
};

export const isBool = (data) => {
  if (typeof data === "boolean") {
    return true;
  } else {
    const form = data?.toLowerCase();
    if (form === "true" || form === "false") {
      return true;
    }
  }
  return false;
};

export const getBoolValue = (data) => {
  const form = data?.toLowerCase();
  if (form === "true") {
    return true;
  }
  return false;
};

export const getData = (data) => {
  const form = (data as string)?.trim();
  return form
    ? isNaN(Number(form))
      ? isBool(form)
        ? getBoolValue(form)
        : form
      : Number(form)
    : undefined;
};

export const structurizeData = async (data) => {
  const filteredData: any[] = [];

  data?.forEach((row) => {
    const structuredData = {};
    for (let [key, val] of Object.entries(row)) {
      if (key.search(/[-:]/gi) === -1) {
        if (
          key === "insideDhakaDeliveryCharge" ||
          key === "outsideDhakaDeliveryCharge"
        ) {
          structuredData[key] = val ? parseInt(val as string) || 0 : 0;
        }
        // plain field
        structuredData[key] = getData(val);
      } else {
        // for variants - :
        let arrKey = key.split("-")[0];
        let arrKeyRest = key.split("-")[1];
        if (Array.isArray(structuredData[arrKey])) {
          Object.assign(structuredData[arrKey][0], getValue(arrKeyRest, val));
        } else {
          structuredData[arrKey] = [{}];
          Object.assign(structuredData[arrKey][0], getValue(arrKeyRest, val));
        }
      }
    } // one row complete
    structuredData["images"] = row["images"]
      ? row["images"]
          ?.split(",")
          ?.filter((url) => url)
          ?.map((url) => url?.replace(/\n/gi, "")?.trim())
      : undefined;

    for (let i = 0; i <= 5; i++) {
      if (
        row[`variations-price:mrp${i ? i : ``}`] ||
        row[`variations-variationName${i ? i : ``}`] ||
        row[`variations-stock${i ? i : ``}`] ||
        row[`variations-barCode${i ? i : ``}`]
      ) {
        structuredData["variations"][i] = {};
        Object.assign(structuredData["variations"][i], {
          barCode: getData(row[`variations-barCode${i ? i : ``}`]),
          imageUrl: getData(row[`variations-imageUrl${i ? i : ``}`]),
          maximumOrderLimit: getData(
            row[`variations-maximumOrderLimit${i ? i : ``}`]
          ),
          regularVariationId: getData(
            row[`variations-regularVariationId${i ? i : ``}`]
          ),
          stock: getData(row[`variations-stock${i ? i : ``}`]),
          variationId: getData(row[`variations-variationId${i ? i : ``}`]),
          variationName: getData(row[`variations-variationName${i ? i : ``}`]),
          price: {
            mrp: getData(row[`variations-price:mrp${i ? i : ``}`]),
            tp: getData(row[`variations-price:tp${i ? i : ``}`]),
          },
          productDiscount: {
            flat: getData(row[`variations-productDiscount:flat${i ? i : ``}`]),
            percentage: getData(
              row[`variations-productDiscount:percentage${i ? i : ``}`]
            ),
          },
          quantitativeProductDiscount: {
            freeProductId: getData(
              row[
                `variations-quantitativeProductDiscount:freeProductId${
                  i ? i : ``
                }`
              ]
            ),
            minimumQuantity: getData(
              row[
                `variations-quantitativeProductDiscount:minimumQuantity${
                  i ? i : ``
                }`
              ]
            ),
            productDiscount: {
              flat: getData(
                row[
                  `variations-quantitativeProductDiscount:productDiscount:flat${
                    i ? i : ``
                  }`
                ]
              ),
              percentage: getData(
                row[
                  `variations-quantitativeProductDiscount:productDiscount:percentage${
                    i ? i : ``
                  }`
                ]
              ),
            },
          },
        });
      }
    }
    filteredData.push(structuredData);
  });

  return {
    products: filteredData?.find((row) => {
      if (
        Object.values(row)?.find(
          (value) => typeof value !== "object" && (value || value === 0)
        )
      ) {
        return true;
      }
    })
      ? filteredData
      : [],
  };
};


