import React from "react";
import MainLayout from "../../components/Layouts";
import PaymentMethodList from "../../components/PaymentMethod";

const PaymentMethodPage = () => {
  return (
    <MainLayout>
      <PaymentMethodList />
    </MainLayout>
  );
};

export default PaymentMethodPage;
